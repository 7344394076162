<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">mdi-account</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Configuração - Nota Fiscal Eletrônica</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-expansion-panels v-model="painel">
            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>NFe de Venda</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap>
                  <v-flex xs12 md3>
                    <v-switch
                      v-model="configNFe.financeiro"
                      :label="`${configNFe.financeiro == 1 ? 'Emite Notas com Parcelas do Financeiro' : 'Não emite notas com parcelas do Financeiro'}`"
                    ></v-switch>
                  </v-flex>

                  <v-flex xs12 md3>
                    <v-switch
                      v-model="configNFe.assinatura"
                      :label="`${configNFe.assinatura == 1 ? 'Emite Notas com Parcelas de Assinatura' : 'Não emite notas com parcelas de Assinatura'}`"
                    ></v-switch>
                  </v-flex>

                  <v-flex xs12 md3>
                    <v-switch
                      v-model="configNFe.vendaAvulsa"
                      :label="`${configNFe.vendaAvulsa == 1 ? 'Emite Notas com Parcelas de Venda Avulsa' : 'Não emite notas com parcelas de Venda Avulsa'}`"
                    ></v-switch>
                  </v-flex>

                  <v-flex xs12 md3>
                    <v-switch
                      v-model="configNFe.anuncio"
                      :label="`${configNFe.anuncio == 1 ? 'Emite Notas com Parcelas de Anúncio' : 'Não emite notas com parcelas de Anúncio'}`"
                    ></v-switch>
                  </v-flex>

                  <v-flex xs6 md2>
                    <v-text-field
                      v-model="configNFe.serie"
                      autofocus
                      label="Série"
                      maxlength="3"
                      hint="Obrigatório"
                      :rules="[validacao.serie == false || validacao.serie, value => !!value || 'Obrigatório.']"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      @change="
                        configNFe.serie = configNFe.serie.toUpperCase();
                        validacao.serie = false;
                      "
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 md2>
                    <v-text-field
                      v-model="configNFe.ultimoNumero"
                      label="Último Número"
                      v-mask="'#########'"
                      color="blue-grey lighten-2"
                      hint="Obrigatório"
                      :rules="[validacao.ultimoNumero == false || validacao.ultimoNumero, value => parseInt(value) >= 0 || 'Obrigatório']"
                      @change="validacao.ultimoNumero = false"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 md2>
                    <v-text-field
                      v-model="configNFe.ultimoLote"
                      label="Último Lote"
                      v-mask="'#########'"
                      color="blue-grey lighten-2"
                      hint="Obrigatório"
                      :rules="[validacao.ultimoLote == false || validacao.ultimoLote, value => parseInt(value) >= 0 || 'Obrigatório']"
                      @change="validacao.ultimoLote = false"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 md2>
                    <v-text-field
                      v-model="configNFe.ncm"
                      label="NCM"
                      v-mask="'########'"
                      hint="Obrigatório"
                      :rules="[
                        validacao.ncm == false || validacao.ncm,
                        value => !!value || 'Obrigatório.',
                        value => value.length == 8 || 'Preencha a Nomenclatura Comum do Mercosul com 8 dígitos numéricos'
                      ]"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      @change="validacao.ncm = false"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 md2>
                    <v-text-field
                      v-model="configNFe.cest"
                      label="CEST"
                      v-mask="'#######'"
                      :rules="[validacao.cest == false || validacao.cest]"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      @change="validacao.cest = false"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 md2>
                    <v-text-field
                      v-model="configNFe.cfop"
                      label="CFOP"
                      v-mask="'####'"
                      hint="Obrigatório"
                      :rules="[
                        validacao.cfop == false || validacao.cfop,
                        value => !!value || 'Obrigatório.',
                        value => value.length == 4 || 'Preencha o Código Fiscal de Operações e Prestações com 4 dígitos numéricos'
                      ]"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      @change="validacao.cfop = false"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 md2>
                    <v-text-field
                      v-model="configNFe.codICMS"
                      label="Código ICMS"
                      v-mask="'##'"
                      hint="Obrigatório. Use 00 para SIMPLES"
                      :rules="[
                        validacao.codICMS == false || validacao.codICMS,
                        value => !!value || 'Obrigatório.',
                        value => value.length == 2 || 'Preencha o Código do ICMS com 2 dígitos numéricos'
                      ]"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      @change="validacao.codICMS = false"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 md2>
                    <v-text-field
                      v-model="configNFe.codCST"
                      label="Código CST"
                      v-mask="'##'"
                      hint="Obrigatório"
                      :rules="[
                        validacao.codCST == false || validacao.codCST,
                        value => !!value || 'Obrigatório.',
                        value => value.length == 2 || 'Preencha o Código de Situação Tributária com 2 dígitos numéricos'
                      ]"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      @change="validacao.codCST = false"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 md2>
                    <v-text-field
                      v-model="configNFe.hsCanc"
                      label="Prazo Cancelamento"
                      suffix="hs"
                      v-mask="'###'"
                      hint="Obrigatório"
                      :rules="[validacao.hsCanc == false || validacao.hsCanc, value => !!value || 'Preencha o prazo de cancelamento (em horas)']"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      @change="validacao.hsCanc = false"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 md2>
                    <v-text-field
                      v-model="configNFe.senha"
                      label="Senha Certificado"
                      maxlength="20"
                      :rules="[validacao.senha == false || validacao.senha, value => !!value || 'Preencha a senha do certificado da NFe']"
                      hint="Obrigatório"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      @change="validacao.senha = false"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 md2>
                    <v-currency-field
                      :locale="`pt-${$store.state.usuario.site_pais}`"
                      v-model="configNFe.percTrib"
                      label="Percentual Tributos"
                      suffix="%"
                      :allowNegative="false"
                      :max="99"
                      :rules="[validacao.percTrib == false || validacao.percTrib]"
                      color="blue-grey lighten-2"
                      @change="validacao.percTrib = false"
                    ></v-currency-field>
                  </v-flex>

                  <v-flex xs6 md2>
                    <v-text-field
                      v-model="configNFe.csosn"
                      label="CSOSN"
                      v-mask="'###'"
                      hint="Código de Situação da Operação no Simples Nacional"
                      :rules="[
                        validacao.csosn == false || validacao.csosn,
                        value => !configNFe.simples || (configNFe.simples && !!value) || 'Obrigatório.',
                        value => !configNFe.simples || (configNFe.simples && value.length == 3) || 'Preencha o CSOSN com 3 dígitos numéricos'
                      ]"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      @change="validacao.csosn = false"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 md2>
                    <v-text-field
                      v-model="configNFe.cBenef"
                      label="Código Benefício Fiscal"
                      maxlength="8"
                      :rules="[validacao.cBenef == false || validacao.cBenef]"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      @change="validacao.cBenef = false"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 md2>
                    <v-autocomplete
                      v-model="configNFe.frete"
                      :items="fretes"
                      :filter="filtrarObjetos"
                      label="Frete"
                      item-text="nome"
                      item-value="id"
                      :rules="[validacao.frete == false || validacao.frete]"
                      :disabled="isUpdating"
                      autocomplete="disabled"
                      :name="Math.random()"
                      no-data-text
                      @change="validacao.frete = false"
                    ></v-autocomplete>
                  </v-flex>

                  <v-flex xs12 md4>
                    <v-switch
                      v-model="configNFe.dadosBancarios"
                      :label="`${configNFe.dadosBancarios == 1 ? 'Apresenta Dados Bancários na NFe' : 'Não apresenta dados bancários na NFe'}`"
                    ></v-switch>
                  </v-flex>
                  <v-flex xs12 md4>
                    <v-switch v-model="configNFe.textoEstendido" :label="`${configNFe.textoEstendido == 1 ? 'Utilizar Texto Estendido' : 'Sem texto estendido'}`"></v-switch>
                  </v-flex>

                  <v-flex xs12 md2>
                    <v-text-field
                      v-model="configNFe.natOp"
                      label="Natureza da Operação"
                      maxlength="40"
                      hint="Obrigatório"
                      :rules="[validacao.natOp == false || validacao.natOp, value => !!value || 'Obrigatório.', value => value.length > 2 || 'Preencha a Natureza da Operação']"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      @change="
                        configNFe.natOp = pLetra(configNFe.natOp);
                        validacao.natOp = false;
                      "
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 md6>
                    <v-text-field
                      v-model="configNFe.msgICMS"
                      label="Mensagem ICMS"
                      maxlength="100"
                      hint="Preencha com a justificativa da isenção de ICMS, se aplicável"
                      persistent-hint="true"
                      :rules="[validacao.msgICMS == false || validacao.msgICMS]"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      @change="validacao.msgICMS = false"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 md4>
                    <v-text-field
                      v-model="configNFe.descricaoAss"
                      label="Descrição Fixa para Assinaturas"
                      maxlength="100"
                      hint="Substitui o texto padrão: ASSINATURA ID - PLANO - PERÍODO, incluindo X/N parcelas e EMPENHO"
                      persistent-hint="true"
                      :rules="[validacao.descricaoAss == false || validacao.descricaoAss]"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      @change="validacao.descricaoAss = false"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>NFe de Remessa Futura</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap>
                  <v-flex xs6 md2>
                    <v-text-field
                      v-model="configNFe.rfCFOP"
                      autofocus
                      label="CFOP"
                      v-mask="'####'"
                      :rules="[validacao.rfCFOP == false || validacao.rfCFOP]"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      @change="validacao.rfCFOP = false"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs6 md2>
                    <v-autocomplete
                      v-model="configNFe.rfFrete"
                      :items="fretes"
                      :filter="filtrarObjetos"
                      label="Frete"
                      item-text="nome"
                      item-value="id"
                      :rules="[validacao.rfFrete == false || validacao.rfFrete]"
                      :disabled="isUpdating"
                      autocomplete="disabled"
                      :name="Math.random()"
                      no-data-text
                      @change="validacao.rfFrete = false"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex md8> </v-flex>

                  <v-flex xs12 md4>
                    <v-text-field
                      v-model="configNFe.rfNatOp"
                      label="Natureza da Operação"
                      maxlength="40"
                      :rules="[validacao.rfNatOp == false || validacao.rfNatOp]"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      @change="
                        configNFe.rfNatOp = pLetra(configNFe.rfNatOp);
                        validacao.rfNatOp = false;
                      "
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 md8>
                    <v-text-field
                      v-model="configNFe.rfMsg"
                      label="Mensagem"
                      maxlength="100"
                      hint="Preencha com a justificativa para Remessa Futura, se houver"
                      :rules="[validacao.rfMsg == false || validacao.rfMsg]"
                      :disabled="isUpdating"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      @change="validacao.rfMsg = false"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-flex xs6>
            <v-switch v-model="configNFe.simples" :label="`${configNFe.simples == 1 ? 'Empresa do SIMPLES' : 'Não é empresa do SIMPLES'}`"></v-switch>
          </v-flex>

          <v-flex xs6>
            <v-switch color="success" v-model="configNFe.producao" :label="`${configNFe.producao == 1 ? 'Ambiente de Produção' : 'Ambiente de Homologação'}`"></v-switch>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn to="/financeiro/desktop" color="orange lighten-2 white" style="color:white"> <v-icon left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn v-if="direitoEditar" :loading="isUpdating" color="blue white" style="color:white" depressed @click="salvar"> <v-icon left>update</v-icon>Salvar </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      configNFe: {
        id: "",
        idSite: null,
        financeiro: 0,
        assinatura: 0,
        vendaAvulsa: 0,
        anuncio: 0,
        producao: 0,
        senha: "",
        ultimoNumero: null,
        ultimoLote: null,
        simples: 0,
        serie: "",
        natOp: "",
        ncm: "",
        cest: null,
        cfop: "",
        csosn: "",
        codICMS: "",
        codCST: "",
        hsCanc: "",
        msgICMS: null,
        percTrib: 0,
        cBenef: null,
        frete: 9,
        dadosBancarios: 1,
        rfNatOp: null,
        rfCFOP: null,
        rfMsg: null,
        rfFrete: 0,
        descricaoAss: null,
        textoEstendido: 0
      },
      validacao: {
        id: false,
        idSite: false,
        financeiro: false,
        assinatura: false,
        vendaAvulsa: false,
        anuncio: false,
        producao: false,
        senha: false,
        ultimoNumero: false,
        ultimoLote: false,
        simples: false,
        serie: false,
        natOp: false,
        ncm: false,
        cest: false,
        cfop: false,
        csosn: false,
        codICMS: false,
        codCST: false,
        hsCanc: false,
        msgICMS: false,
        percTrib: false,
        cBenef: false,
        frete: false,
        dadosBancarios: false,
        rfNatOp: false,
        rfCFOP: false,
        rfMsg: false,
        rfFrete: false,
        descricaoAss: false,
        textoEstendido: false,

        mensagemErro: false
      },
      fretes: [
        { id: 0, nome: "0-Emitente" },
        { id: 1, nome: "1-Destinatário" },
        { id: 2, nome: "2-Terceiros" },
        { id: 3, nome: "3-Próprio Emitente" },
        { id: 9, nome: "9-Sem Frete" }
      ],

      idSitePrincipal: null,

      painel: 0,
      direitoEditar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    async salvar() {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "fin/config-nfe/" + this.id, this.configNFe);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "fin/config-nfe?idSite=" + this.idSitePrincipal, this.configNFe);
          this.id = retorno.data.id;
          this.configNFe.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    }
  },

  async created() {
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA") {
        this.direitoEditar = true;
      }
    });

    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    try {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_CONFIG_NFE_ACESSO"]);
      //* Carrega a configuração para o site, se existir
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/config-nfe/site/" + this.idSitePrincipal);
      if (!response.data) {
        this.id = "";
        this.configNFe.idSite = this.idSitePrincipal;
      } else {
        this.id = response.data.id;
        this.configNFe = response.data;
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
