<template>
  <div id="app">
    <v-app id="inspire">
      <v-card class="hide-overflow fill-height">
        <v-toolbar height="50" color="#333333">
          <v-icon color="white" class="mr-2">add_circle_outline</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">
            Parcelas - Contas a
            {{
              (receber ? "Receber" : "Pagar") +
                ($route.query.idLoteFaturamento ? ($route.query.idLoteFaturamento == "0" ? " - Sem Lote" : " - Lote ID " + $route.query.idLoteFaturamento) : "") +
                ($route.query.idNF ? " - Sem Notas Fiscais" : "") +
                ($route.query.idNFe ? " - NFe ID " + $route.query.idNFe : "") +
                ($route.query.idNFSe ? " - NFSe ID " + $route.query.idNFSe : "") +
                ($route.query.idNFSC ? " - NFSC ID " + $route.query.idNFSC : "") +
                ($route.query.idAnuncio ? " - Anúncio ID " + $route.query.idAnuncio : "") +
                ($route.query.idPacote ? " - Pacote ID " + $route.query.idPacote : "") +
                ($route.query.idAssinatura ? " - Assinatura ID " + $route.query.idAssinatura : "") +
                ($route.query.idRenovacao ? " - Renovação ID " + $route.query.idRenovacao : "") +
                ($route.query.idOperacao ? " - Operação ID " + $route.query.idOperacao : "")
            }}
          </v-toolbar-title>
        </v-toolbar>
        <div class="px-4 mt-3">
          <v-container fluid grid-list-md>
            <v-layout wrap>
              <v-flex md1 xs6 v-if="alteracoes.length > 0">
                <v-card>
                  <div>Alterar</div>
                  <v-card-actions>
                    <v-overflow-btn v-model="acao_alterar" @change="acaoLote('alterar')" :items="alteracoes" dense filled></v-overflow-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>

              <v-flex md2 xs6 v-if="operacoes.length > 0">
                <v-card>
                  <div>Operação</div>
                  <v-card-actions>
                    <v-overflow-btn v-model="acao_operacao" @change="acaoLote('operacao')" :items="operacoes" filled dense></v-overflow-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>

              <v-flex md1 xs6 v-if="cancelamentos.length > 0">
                <v-card>
                  <div>Cancelar</div>
                  <v-card-actions>
                    <v-overflow-btn v-model="acao_cancelar" @change="acaoLote('cancelar')" :items="cancelamentos" filled dense></v-overflow-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>

              <v-flex md1 xs6 v-if="creditos.length > 0">
                <v-card>
                  <div>Crédito</div>
                  <v-card-actions>
                    <v-overflow-btn v-model="acao_credito" @change="acaoLote('credito')" :items="creditos" filled dense></v-overflow-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>

              <v-flex md1 xs6 v-if="visualizacoes.length > 0">
                <v-card>
                  <div>Visualizar</div>
                  <v-card-actions>
                    <v-overflow-btn v-model="acao_visualizar" @change="acaoLote('visualizar')" :items="visualizacoes" filled dense></v-overflow-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>

              <v-flex md1 xs6 v-if="emails.length > 0">
                <v-card>
                  <div>E-mail</div>
                  <v-card-actions>
                    <v-overflow-btn v-model="acao_email" @change="acaoLote('email')" :items="emails" filled dense></v-overflow-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>

              <v-flex md1 xs12>
                <v-dialog v-model="dialogFiltroAvancado" max-width="900px">
                  <template v-slot:activator="{ on }">
                    <v-btn class="ml-2" v-on="on" color="blue" fab small @click="tab = 0" :disabled="loading || loadingExp">
                      <v-icon color="white" title="Filtro Avançado">search</v-icon>
                    </v-btn>
                    <v-btn
                      color="red lighten-2"
                      style="color:white"
                      class="btn-rsn ml-n2 mb-6"
                      id="btn-limpa-filtro"
                      fab
                      @click="filtroAvancadoLimpar"
                      :disabled="loading || loadingExp"
                    >
                      <v-icon class="icon-rsn" small center title="Limpar Filtro">clear</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-tabs v-model="tab" background-color="blue accent-4" center-active dark>
                      <v-tab>Parcela</v-tab>
                      <v-tab>Cliente</v-tab>
                      <v-tab :disabled="tabAnu" v-if="$store.state.usuario.site_assinaturas && receber">Assinatura</v-tab>
                      <v-tab :disabled="tabAss" v-if="$store.state.usuario.site_comercial && receber">Anúncio</v-tab>
                      <v-tab v-if="receber">Nota Fiscal</v-tab>
                      <v-tab>Pesquisas</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                      <v-tab-item>
                        <FiltroParcela ref="filtroParcela" :origem="receber ? 'REC' : 'PAG'" />
                      </v-tab-item>
                      <v-tab-item>
                        <FiltroCliente ref="filtroCliente" prefixo="c." />
                      </v-tab-item>
                      <v-tab-item v-if="$store.state.usuario.site_assinaturas && receber">
                        <FiltroAssinatura ref="filtroAssinatura" prefixo="a." />
                      </v-tab-item>
                      <v-tab-item v-if="$store.state.usuario.site_comercial && receber">
                        <FiltroAnuncio ref="filtroAnuncio" prefixo="u." />
                      </v-tab-item>
                      <v-tab-item v-if="receber">
                        <FiltroNF ref="filtroNF" prefixo="n." v-bind:nfe="true" v-bind:nfsc="true" v-bind:nfse="true" />
                      </v-tab-item>
                      <v-tab-item>
                        <Pesquisas ref="pesquisas" prefixo="p" />
                      </v-tab-item>
                    </v-tabs-items>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="orange lighten-2 white" style="color:white" class="btn-rsn" @click="filtroAvancadoRetornar">
                        <v-icon class="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
                      </v-btn>

                      <v-btn outlined color="red white" class="btn-rsn" style="color:white" @click="filtroAvancadoLimpar">
                        <v-icon class="icon-rsn" left>clear</v-icon>Limpar
                      </v-btn>

                      <v-btn v-if="tab != 5" color="blue white" class="btn-rsn" style="color:white" depressed @click="limpaFiltro">
                        <v-icon class="icon-rsn" left>tune</v-icon>Filtrar
                      </v-btn>
                      <v-btn v-if="direitoExportar" outlined color="blue white" style="color:white" class="btn-rsn" depressed @click="filtroAvancadoFiltrar(true)">
                        <v-icon class="icon-rsn" left>description</v-icon>Exportar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-btn v-if="direitoExportar" class="ml-2" color="blue" :loading="loadingExp" :disabled="loading || loadingExp" fab small @click="filtroAvancadoFiltrar(true)">
                  <v-icon color="white" title="Exportar">description</v-icon>
                </v-btn>
                <v-btn color="success" fab small v-if="direitoLote" @click="loteAltCanc" class="ml-2" :loading="loadingExp" :disabled="loading || loadingExp">
                  <v-icon color="white" title="Lote Alteração/Cancelamento IMEDIATO">add_circle_outline</v-icon>
                </v-btn>
              </v-flex>
              <v-flex md1 xs6>
                <v-text-field
                  background-color="white"
                  class="elevation-0"
                  v-model="idParcelaPesquisa"
                  v-mask="'#######'"
                  append-icon="search"
                  label="ID"
                  @keyup.enter="limpaFiltro"
                  @click:append="limpaFiltro"
                  autocomplete="disabled"
                  :name="Math.random()"
                  :disabled="loading || loadingExp"
                ></v-text-field>
              </v-flex>
              <v-flex md1 xs6>
                <v-text-field
                  background-color="white"
                  class="elevation-0"
                  v-model="identificacaoPesquisa"
                  v-mask="'##############'"
                  append-icon="search"
                  label="Identificação"
                  @keyup.enter="limpaFiltro"
                  @click:append="limpaFiltro"
                  autocomplete="disabled"
                  :name="Math.random()"
                  :disabled="loading || loadingExp"
                ></v-text-field>
              </v-flex>
              <v-flex md2 xs12>
                <v-text-field
                  background-color="white"
                  class="elevation-0"
                  v-model="nomePesquisa"
                  append-icon="search"
                  label="Nome"
                  @keyup.enter="limpaFiltro"
                  @click:append="limpaFiltro"
                  autocomplete="disabled"
                  :name="Math.random()"
                  :disabled="loading || loadingExp"
                ></v-text-field>
              </v-flex>

              <v-flex xs7>
                <v-checkbox v-model="selectAll" :label="selectAll ? 'Desmarcar todos' : 'Marcar todos da página'" @change="marcarDesmarcarTodos(selectAll)"></v-checkbox>
              </v-flex>
              <v-flex xs5>
                <v-switch v-model="expandir" class="ml-2" label="Expandir"></v-switch>
              </v-flex>
            </v-layout>
          </v-container>

          <v-dialog persistent v-model="dialogParcela" :width="receber ? '700px' : '880px'">
            <v-card>
              <div v-if="receber">
                <Parcela :id="idParcela" :receber="receber + ''" :tela="'parcelas'" :dialogParcela.sync="dialogParcela" />
              </div>
              <div v-if="!receber">
                <v-tabs v-model="tab" background-color="#333333" center-active dark>
                  <v-tab>Parcela</v-tab>
                  <v-tab v-if="cedentesBRR.length > 0">BRR</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item>
                    <Parcela
                      :id="idParcela"
                      :receber="receber + ''"
                      :tela="'parcelas'"
                      :dialogParcela.sync="dialogParcela"
                      :atMonetaria.sync="atMonetaria"
                      :atualiza.sync="atualiza"
                    />
                  </v-tab-item>
                  <v-tab-item v-if="cedentesBRR.length > 0">
                    <BRR :id="idParcela" :dialogParcela.sync="dialogParcela" :atMonetaria.sync="atMonetaria" :atualiza.sync="atualiza" />
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </v-card>
          </v-dialog>
          <v-data-table
            dense
            mobile-breakpoint="0"
            :headers="registrosPagina > 4999 ? headersBemRed : registrosPagina > 499 ? headersRed : !expandir ? headers : headersExpand"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            height="500"
            fixed-header
            :disable-sort="carregando"
            :disable-pagination="carregando"
            :loading="loading"
            loading-text="Carregando..."
          >
            <template v-slot:item.reduzido="{ item }">
              <v-icon v-if="item.id" color="orange" title="Selecionar" @click="ativarDesativarItem(item)">
                {{ item.selecionado == 1 ? "check_box" : "check_box_outline_blank" }}
              </v-icon>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon v-if="item.id && item.status != 'EXCLUÍDA'" color="orange" title="Selecionar" @click="ativarDesativarItem(item)">
                {{ item.selecionado == 1 ? "check_box" : "check_box_outline_blank" }}
              </v-icon>
              <v-icon
                v-if="direitoEditar && item.origem && item.status != 'EXCLUÍDA' && (receber || !item.confirmada || direitoEditarConfirmada)"
                color="blue"
                title="Editar"
                @click="editarParcela(item)"
                >edit</v-icon
              >
              <v-icon v-if="direitoLog" color="blue" title="Visualizar Log de Parcelas" @click="visualizarLogAlteracao(item)">info</v-icon>
              <v-icon v-if="direitoLog" color="blue" title="Visualizar Log do Banco" @click="visualizarLogBanco(item)">loupe</v-icon>
              <v-icon
                v-if="
                  !item.formaQuitada &&
                    ((direitoQuitar &&
                      item.status == 'ABERTA' &&
                      (receber || (!receber && item.confirmada == 1)) &&
                      !item.payAsaas &&
                      (!item.idLoteFaturamento || (item.idLoteFaturamento && item.tipoLote == 3 && item.remessa == 'ENVIADA'))) ||
                      (direitoCancelar && item.status == 'QUITADA' && !item.conciliado && !item.payAsaas && (!item.idLoteFaturamento || direitoReverterBanco)))
                "
                color="teal"
                :title="item.status == 'ABERTA' ? 'Quitar' : 'Reverter Quitação'"
                @click="preQuitarDesquitar(item)"
              >
                {{ item.status == "ABERTA" ? "attach_money" : "money_off" }}
              </v-icon>
              <!-- se parcela está em lote, deverá remover do lote para quitar manualmente -->
              <!-- //TODO: remover a linha acima    && (!item.idLoteFaturamento || (item.idLoteFaturamento && item.tipoLote == 3 && item.remessa == 'ENVIADA'))    para testes do ND -->
              <v-icon
                v-if="direitoCancelar && ['ABERTA', 'BLOQUEADA'].includes(item.status) && !item.idLoteFaturamento && !item.payAsaas"
                color="indigo"
                title="Desmembrar"
                @click="openDesmembrar(item)"
                >plus_one</v-icon
              >
              <v-icon v-if="direitoContato && item.status == 'ABERTA'" color="light-blue" title="Contato (Promessa/Bloqueio)" @click="editarContato(item)">monetization_on</v-icon>
              <!-- se parcela estiver BLOQUEADA, o melhor é desbloquear primeiro, para ficar o registro no devido lugar, para depois descancelar -->
              <v-icon
                v-if="
                  direitoCancelar &&
                    item.formaPagamentoTipo != 'PACOTE' &&
                    ((item.status == 'ABERTA' &&
                      !item.payAsaas &&
                      (!item.idLoteFaturamento || (item.idLoteFaturamento && ((item.tipoLote == 3 && item.remessa == 'ENVIADA') || item.statusCobranca == 40)))) ||
                      item.status == 'CANCELADA')
                "
                color="red"
                :title="item.status == 'ABERTA' ? 'Cancelar' : 'Reverter Cancelamento'"
                @click="preCancelarDescancelar(item)"
              >
                {{ item.status == "ABERTA" ? "cancel" : "settings_backup_restore" }}
              </v-icon>
              <!-- se parcela está aberta e em lote de inclusão ou alteração já enviado, permite marca-la para inclusão em lote de cancelamento -->
              <v-icon
                v-if="
                  direitoCancelar &&
                    item.status == 'ABERTA' &&
                    ((item.idLoteFaturamento && item.statusCobranca != 30 && item.tipoLote != 3 && item.remessa) || item.payAsaas) &&
                    ((receber && (item.formaPagamentoTipo == 'BOLETO' || item.formaPagamentoTipo == 'DEBITO_CONTA' || (item.formaPagamentoTipo == 'PIX' && item.payAsaas))) ||
                      !receber)
                "
                color="red"
                :title="receber ? `Cancelar Cobrança ${item.payAsaas ? 'Asaas' : item.formaPagamentoTipo == 'BOLETO' ? 'Boleto' : 'Débito em Conta'}` : 'Cancelar Cobrança'"
                @click="baixar(item)"
                >360</v-icon
              >
              <!-- 360 é o nome do ícone -->
              <v-icon
                v-if="direitoCobrarCartao && item.status == 'ABERTA' && item.formaPagamentoTipo == 'CARTAO_CREDITO'"
                color="green"
                title="Cobrar Cartão"
                @click="cobrarCartao(item)"
                >credit_card</v-icon
              >
              <v-icon v-if="item.conciliado" color="green" title="Conciliada" @click="visualizarConciliacao(item)">copyright </v-icon>
            </template>

            <template v-slot:item.idOperacao="{ item }">
              <span>
                {{
                  item.idAssinatura
                    ? item.idAssinatura + "/" + item.renovacao + ` '${item.codAntigo || item.idRenovacao}'`
                    : item.idAnuncio
                    ? item.idAnuncio
                    : item.idPacote
                    ? item.idPacote
                    : item.idOperacao
                }}
              </span>
            </template>
            <template v-slot:item.idOperacao2="{ item }">
              <span>
                {{ item.idOperacao }}
              </span>
            </template>
            <template v-slot:item.idProtheus="{ item }">
              <span>
                {{ item.idProtheusOp || item.idProtheus }}
              </span>
            </template>
            <template v-slot:item.indice="{ item }">
              <span>
                {{ item.indice ? item.indice + "-" + item.numero + "/" + item.nroParcelas : "" }}
              </span>
            </template>

            <template v-slot:item.cliente="{ item }">
              {{ receber ? item.cliente : $store.state.usuario.site_identificacao == item.identificacao ? item.contaD : item.cliente }}
            </template>

            <template v-slot:item.identificacao="{ item }">
              {{ receber ? item.identificacao : $store.state.usuario.site_identificacao == item.identificacao ? "" : item.identificacao }}
            </template>

            <template v-slot:item.status="{ item }">
              <span
                :style="item.status == 'EXCLUÍDA' ? 'font-weight:bold;' : ''"
                :class="
                  item.status == 'ABERTA'
                    ? 'blue--text'
                    : item.status == 'BLOQUEADA'
                    ? 'orange--text'
                    : item.status == 'CANCELADA' || item.status == 'EXCLUÍDA'
                    ? 'red--text'
                    : item.status == 'QUITADA'
                    ? 'green--text'
                    : ''
                "
                >{{ item.status }}</span
              >
            </template>

            <template v-slot:item.idLoteFaturamento="{ item }">{{
              item.idLoteFaturamento ? item.idLoteFaturamento + (item.tipoLote == 2 ? " Alt" : item.tipoLote == 3 ? " Can" : " Inc") : ""
            }}</template>

            <template v-slot:item.statusCobranca="{ item }">
              <span :class="!item.statusCobranca ? '' : item.statusCobranca < 12 ? 'green--text' : 'red--text'">
                {{
                  !item.statusCobranca
                    ? ""
                    : item.statusCobranca == 10
                    ? "Emitida"
                    : item.statusCobranca == 11
                    ? "Confirmada"
                    : item.statusCobranca == 20 || item.statusCobranca == 21
                    ? "Alterada"
                    : item.statusCobranca == 30
                    ? "Cancelada"
                    : "Erro"
                }}
              </span>
            </template>

            <template v-slot:item.dtCriacao="{ item }">
              <span>{{ formatDateUTC(item.dtCriacao) }}</span>
            </template>
            <template v-slot:item.dtVecto="{ item }">
              <span>{{ formatDateUTC(item.dtVecto) }}</span>
            </template>
            <template v-slot:item.dtPromessa="{ item }">
              <span>{{ formatDateUTC(item.dtPromessa) }}</span>
            </template>
            <template v-slot:item.dtBloqueio="{ item }">
              <span>{{ formatDateUTC(item.dtBloqueio) }}</span>
            </template>
            <template v-slot:item.dtQuitacao="{ item }">
              <span>{{ formatDateUTC(item.dtQuitacao) }}</span>
            </template>
            <template v-slot:item.diasAtraso="{ item }">
              <span>{{ item.diasAtraso ? item.diasAtraso : "" }}</span>
            </template>
            <template v-slot:item.dtCancelamento="{ item }">
              <span>{{ formatDateUTC(item.dtCancelamento) }}</span>
            </template>

            <template v-slot:item.valor="{ item }">
              <span>{{ formatCurrency(item.valor) }}</span>
            </template>
            <template v-slot:item.descAcr="{ item }">
              <span :class="item.descAcr < 0 ? 'red--text' : item.descAcr > 0 ? 'blue--text' : ''">{{ item.descAcr ? formatCurrency(item.descAcr) : "" }}</span>
            </template>
            <template v-slot:item.liquido="{ item }">
              <span>{{ formatCurrency(item.valor + item.descAcr) }}</span>
            </template>

            <template v-slot:item.exemplares="{ item }">
              <span v-html="`${item.exemplares ? `${item.exemplares} | ${item.ativos} | ${item.entregues} | ${formatCurrency(item.vlrLiquido / item.ativos)}` : ''}`"></span
            ></template>

            <template v-slot:item.actions2="{ item }">
              <v-icon v-if="direitoAssinante && item.origem == 'ASS'" color="orange" title="Assinante" @click="visualizarCliente(item)">account_circle</v-icon>
              <v-icon v-if="receber && direitoCliente && item.origem == 'FIN'" color="orange" title="Cliente" @click="visualizarCliente(item)">account_circle</v-icon>
              <v-icon v-if="!receber && direitoCliente && item.origem == 'FIN'" color="orange" title="Fornecedor" @click="visualizarCliente(item)">account_circle</v-icon>
              <v-icon v-if="direitoOperacao && item.origem == 'FIN'" color="blue" title="Operação" @click="visualizarOperacao(item)">{{
                receber ? "add_circle" : "remove_circle"
              }}</v-icon>
              <v-icon v-if="direitoAssinatura && item.origem == 'ASS'" color="red" title="Assinatura" @click="visualizarRenovacao(item)">assignment</v-icon>
              <v-icon v-if="direitoAnuncio && item.origem == 'ANU'" color="yellow darken-2" title="Anúncio" @click="visualizarAnuncio(item)">{{
                item.idAnuncio ? "chrome_reader_mode" : "edit"
              }}</v-icon>
              <v-icon v-if="direitoPacote && item.origem == 'PAC'" color="yellow darken-3" title="Pacote" @click="visualizarPacote(item)">library_books</v-icon>
              <v-icon v-if="direitoVendaAvulsa && item.origem == 'AVU'" color="blue" title="Venda Avulsa" @click="visualizarVendaAvulsa(item)">list_alt</v-icon>
              <v-icon
                v-if="receber && direitoRecibo && (item.status == 'QUITADA' || item.formaPagamentoTipo == 'CARTEIRA')"
                color="cyan"
                title="Recibo"
                @click="visualizarRecibo(item)"
                >insert_drive_file</v-icon
              >
              <v-icon
                v-if="
                  receber &&
                    direitoBoleto &&
                    item.nossoNumero &&
                    ((item.idLoteFaturamento && parseInt(item.statusCobranca) >= 10 && parseInt(item.statusCobranca) <= 20) || item.payAsaas) &&
                    item.formaPagamentoTipo == 'BOLETO' &&
                    ['ABERTA', 'PROPOSTA'].includes(item.status)
                "
                color="indigo"
                title="Boleto"
                @click="visualizarBoleto(item)"
                >format_bold</v-icon
              >
              <v-icon
                v-if="
                  receber &&
                    direitoNotaFiscal &&
                    ((item.idNFe && item.statusNFe == 'Processada') ||
                      (item.idNFSC && item.situacaoNFSC == 'N' && item.pdfNFSC == 2) ||
                      (item.idNFSe && item.statusNFSe == 'Processada'))
                "
                color="teal"
                title="Nota Fiscal"
                @click="visualizarNotaFiscal(item)"
                >account_balance</v-icon
              >
              <v-badge overlap v-if="item.payAsaas && direitoCobrarCartao" :content="item.parcAsaas ? item.parcAsaas.substring(item.parcAsaas.length - 1) : ''">
                <img v-if="item.payAsaas && direitoCobrarCartao" src="/asaas.png" width="18" :title="item.payAsaas" style="position: relative; top: 5px;" />
              </v-badge>
            </template>
          </v-data-table>
          <div style="text-align: right;">
            <v-btn x-small text @click="filtroAvancadoFiltrar(false, true)" :outlined="calcular == 'sim'">
              Calcular
            </v-btn>
          </div>
          <div class="text-xs-center">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 11" circle></v-pagination>
          </div>
        </div>
        <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
      </v-card>

      <v-dialog persistent v-model="dialogMensagem" max-width="400">
        <v-card>
          <v-toolbar>
            <span class="headline">Mensagem</span>
          </v-toolbar>
          <v-card-text>
            <br />
            <span>{{ mensagem }}</span>
          </v-card-text>
          <v-card-actions>
            <v-btn color="blue lighten-2" text @click="dialogMensagem = false">Fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="dialogConfirm" max-width="400">
        <v-card>
          <v-toolbar>
            <span class="headline">Confirmação</span>
          </v-toolbar>
          <v-card-text class="mt-4" v-html="msgConfirm"></v-card-text>
          <v-card-actions>
            <v-btn color="red darken-1" text @click="naoConfirmado">Não</v-btn>
            <v-btn color="green darken-1" text @click="confirmado">Sim</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="dialogDesmembrar" max-width="300">
        <v-card>
          <v-toolbar>
            <span class="headline">Desmembrar Parcela {{ desmembrarItem.id }}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDesmembrar">
              <v-icon color="darken-1">cancel</v-icon>
            </v-btn>
          </v-toolbar>
          <v-form ref="formDesmembrar" v-model="desmembrarValid" lazy-validation>
            <div class="pa-2">Desmembrar Valor Principal</div>
            <v-container>
              <v-layout wrap>
                <v-flex xs12>
                  <v-currency-field
                    :locale="`pt-${$store.state.usuario.site_pais}`"
                    v-model="desmembrarItem.valorOriginal"
                    label="Valor Original"
                    readonly
                    color="blue-grey lighten-2"
                  ></v-currency-field>
                </v-flex>
                <v-flex xs12>
                  <v-currency-field
                    :locale="`pt-${$store.state.usuario.site_pais}`"
                    v-model="desmembrarItem.novoValor"
                    autofocus
                    label="Novo Valor"
                    :allowNegative="false"
                    :max="9999999"
                    :rules="[
                      validacaoDesmembrar.valor == false || validacaoDesmembrar.valor,
                      value =>
                        (value && parseFloat(value) >= 0 && parseFloat(value.replace('.', '').replace(',', '.')) < parseFloat(desmembrarItem.valorOriginal)) ||
                        'Verifique o valor a desmembrar'
                    ]"
                    color="blue-grey lighten-2"
                  ></v-currency-field>
                </v-flex>
                <div>Dividir Valor Restante</div>
                <v-flex xs12>
                  <v-text-field
                    v-model="desmembrarItem.parcelasDesmembrar"
                    label="Número de Parcelas"
                    v-mask="'##'"
                    hint="Obrigatório"
                    :rules="[
                      validacaoDesmembrar.parcelas == false || validacaoDesmembrar.parcelas,
                      value => !!value || 'Obrigatório.',
                      value => (value && parseInt(value) > 0 && parseInt(value) < 13) || 'Verifique o número de parcelas'
                    ]"
                    color="blue-grey lighten-2"
                    autocomplete="disabled"
                    :name="Math.random()"
                  ></v-text-field>
                </v-flex>
                <div>Ajustar Vencimentos</div>
                <v-flex xs12>
                  <v-menu ref="mnuDtInicio" v-model="mnuDtInicio" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="dtInicio"
                        label="Vencimento Inicial"
                        v-mask="'##/##/####'"
                        :rules="[validacaoDesmembrar.dtInicio == false || validacaoDesmembrar.dtInicio, value => valida_data_futura(value) || 'Data inválida']"
                        prepend-icon="event"
                        v-on="on"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @keyup="dtInicioUS = valida_data_futura(dtInicio) ? formatDateUS(dtInicio) : null"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="dtInicioUS" locale="pt-br" no-title @input="mnuDtInicio = false"></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 v-if="dtInicioUS != null">
                  <v-text-field
                    v-model="desmembrarItem.periodicidade"
                    label="Periodicidade (dias)"
                    v-mask="'##'"
                    :rules="[
                      validacaoDesmembrar.periodicidade == false || validacaoDesmembrar.periodicidade,
                      value => !value || (value && parseInt(value) > 0 && parseInt(value) <= 90) || 'Verifique a periodicidade (1 a 90 dias)'
                    ]"
                    color="blue-grey lighten-2"
                    autocomplete="disabled"
                    :name="Math.random()"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="closeDesmembrar">Retornar</v-btn>
            <v-btn color="blue darken-1" @click="desmembrar">Desmembrar</v-btn>
          </v-card-actions>
        </v-card>
        <v-snackbar v-model="snackDesmembrar" :timeout="5000" relative bottom left>{{ msgDesmembrar }}</v-snackbar>
      </v-dialog>

      <v-dialog persistent v-model="dialogContato" max-width="1200px">
        <v-card>
          <Contato
            idEtapa=""
            :idParcela="idParcela"
            :idAssinatura="idAssinatura"
            :idRenovacao="idRenovacao"
            :idAnuncio="idAnuncio"
            :idPacote="idPacote"
            :idOperacao="idOperacao"
            :idCliente="idCliente"
            :cliente="cliente"
            :tipo="tipo"
            :origem="origem"
            :dialogContato.sync="dialogContato"
          />
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogCartao" max-width="1200px">
        <v-card>
          <CobrancaCartao :parcelas="parcelasCartao" :nroParcelas="nroParcelasCartao" :dialogCartao.sync="dialogCartao" />
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogLogAlteracao" max-width="1200px">
        <v-card>
          <LogAlteracao :idParcela="idParcela" :dialogLogAlteracao.sync="dialogLogAlteracao" />
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogLogBanco" max-width="1200px">
        <v-card>
          <LogBanco :idParcela="idParcela" :idLoteFaturamento="idLoteFaturamento" :dialogLogBanco.sync="dialogLogBanco" />
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="dialogTroco" max-width="400">
        <v-card>
          <v-toolbar>
            <span class="headline">Quitar Parcela {{ trocoItem.id }}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeTroco">
              <v-icon color="darken-1">cancel</v-icon>
            </v-btn>
          </v-toolbar>
          <v-container>
            <v-layout wrap>
              <v-flex xs12>
                <v-progress-linear :active="quitando" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
              </v-flex>
              <v-flex xs12 offset-md3 md6>
                <v-currency-field
                  :locale="`pt-${$store.state.usuario.site_pais}`"
                  v-model="troco.vlrReceberFormat"
                  label="Valor a Receber"
                  readonly
                  color="blue-grey lighten-2"
                ></v-currency-field>
              </v-flex>
              <v-flex xs12 offset-md3 md6>
                <v-currency-field
                  :locale="`pt-${$store.state.usuario.site_pais}`"
                  autofocus
                  v-model="troco.vlrRecebido"
                  label="Valor Recebido"
                  color="blue-grey lighten-2"
                  @keyup.native="troco.vlrTroco = troco.vlrRecebido - troco.vlrReceber"
                ></v-currency-field>
              </v-flex>
              <v-flex xs12 offset-md3 md6>
                <v-currency-field :locale="`pt-${$store.state.usuario.site_pais}`" v-model="troco.vlrTroco" label="Troco" readonly color="blue-grey lighten-2"></v-currency-field>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="closeTroco">Retornar</v-btn>
            <v-btn color="blue darken-1" @click="quitarTroco" :disabled="quitando">Quitar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="dialogCancelarItem" max-width="400">
        <v-card>
          <v-toolbar>
            <span class="headline">Cancelar Parcela {{ itemCancelarItem ? itemCancelarItem.id : "" }}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogCancelarItem = false">
              <v-icon color="darken-1">cancel</v-icon>
            </v-btn>
          </v-toolbar>
          <v-form ref="formCancelarItem" v-model="cancelarItemValid" lazy-validation>
            <v-container>
              <v-layout wrap>
                <v-flex xs12>
                  <v-progress-linear :active="cancelando" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
                </v-flex>
                <v-flex xs12 md12 v-if="!motivoParcela">
                  <v-autocomplete
                    v-model="idMotivoParcela"
                    :items="motivosParcelas"
                    hint="Obrigatório"
                    :rules="[value => !!value || 'Obrigatório.']"
                    :filter="filtrarObjetos"
                    label="Motivo do Cancelamento"
                    item-text="nome"
                    item-value="id"
                    autocomplete="disabled"
                    :name="Math.random()"
                    no-data-text
                    return-object
                    @change="motivoParcela = idMotivoParcela.nome"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 md12 v-if="idMotivoParcela && motivoParcela">
                  <v-text-field
                    v-model="motivoParcela"
                    label="Motivo do Cancelamento"
                    maxlength="255"
                    color="blue-grey lighten-2"
                    autocomplete="disabled"
                    :name="Math.random()"
                    :rules="[value => !!value || 'Obrigatório.', value => (value != null && value.length > 3) || 'Preencha o motivo do cancelamento']"
                    clearable
                    @click:clear="
                      motivoParcela = null;
                      idMotivoParcela = null;
                    "
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="dialogCancelarItem = false">Retornar</v-btn>
            <v-btn color="blue darken-1" @click="cancelarDescancelar" :disabled="cancelando">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialogs das ações em lote: Alterar -->
      <v-dialog persistent v-model="dialogAlterarVecto" max-width="290">
        <v-card>
          <v-toolbar>
            <span class="headline">Alterar Vencimento</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeAlterarVecto">
              <v-icon color="darken-1">cancel</v-icon>
            </v-btn>
          </v-toolbar>
          <v-form ref="formAlterarVecto" v-model="alterarVectoValid" lazy-validation>
            <v-container>
              <v-layout wrap>
                <v-flex xs12>
                  <v-menu
                    ref="mnuDtalterarDtVectoItem"
                    v-model="mnuDtalterarDtVectoItem"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="alterarDtVectoItem"
                        autofocus
                        label="Novo Vencimento"
                        v-mask="'##/##/####'"
                        hint="Obrigatório"
                        prepend-icon="event"
                        v-on="on"
                        :rules="[
                          value => valida_data_futura(value) || 'Data inválida',
                          value => !!value || 'Obrigatório.',
                          value => (value != null && value.length == 10) || 'Preencha a nova data de vencimento'
                        ]"
                        color="blue-grey lighten-2"
                        autocomplete="disabled"
                        :name="Math.random()"
                        @keyup="alterarDtVectoItemUS = valida_data_futura(alterarDtVectoItem) ? formatDateUS(alterarDtVectoItem) : null"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="alterarDtVectoItemUS" locale="pt-br" no-title @input="mnuDtalterarDtVectoItem = false"></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 md12 v-if="alterarVectoItem.ativo">
                  <v-progress-linear color="blue-grey" height="25" indeterminate></v-progress-linear>
                </v-flex>
                <v-flex xs12 md12 v-if="alterarVectoItem.retorno">
                  <v-textarea
                    v-model="alterarVectoItem.retorno"
                    label="Retorno do Processamento"
                    rows="10"
                    :rules="[validacaoAlterarVecto.retorno == false || validacaoAlterarVecto.retorno]"
                    readonly
                    color="blue-grey lighten-2"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="closeAlterarVecto">Retornar</v-btn>
            <v-btn color="blue darken-1" @click="alterarVecto" :disabled="alterarVectoItem.ativo">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="dialogAlterarFormaPgto" max-width="500">
        <v-card>
          <v-toolbar>
            <span class="headline">Alterar Forma de Pagamento</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeAlterarFormaPgto">
              <v-icon color="darken-1">cancel</v-icon>
            </v-btn>
          </v-toolbar>
          <v-form ref="formAlterarFormaPgto" v-model="alterarFormaPgtoValid" lazy-validation>
            <v-container>
              <v-layout wrap>
                <v-flex xs12>
                  <v-autocomplete
                    v-model="formaPagamentoSelecionada"
                    autofocus
                    :items="formasPgto"
                    hint="Obrigatório"
                    :rules="[value => (!!value && !!value.id) || 'Obrigatório.']"
                    :filter="filtrarObjetos"
                    label="Forma de Pagamento"
                    item-text="nome"
                    item-value="id"
                    filled
                    autocomplete="disabled"
                    :name="Math.random()"
                    no-data-text
                    return-object
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 md12 v-if="alterarFormaPgtoItem.ativo">
                  <v-progress-linear color="blue-grey" height="25" indeterminate></v-progress-linear>
                </v-flex>
                <v-flex xs12 md12 v-if="alterarFormaPgtoItem.retorno">
                  <v-textarea
                    v-model="alterarFormaPgtoItem.retorno"
                    label="Retorno do Processamento"
                    rows="10"
                    :rules="[validacaoAlterarFormaPgto.retorno == false || validacaoAlterarFormaPgto.retorno]"
                    readonly
                    color="blue-grey lighten-2"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="closeAlterarFormaPgto">Retornar</v-btn>
            <v-btn color="blue darken-1" @click="alterarFormaPgto" :disabled="alterarFormaPgtoItem.ativo || alterarFormaPgtoItem.processado">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="dialogAlterarValor" max-width="290">
        <v-card>
          <v-toolbar>
            <span class="headline">Alterar Valor</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeAlterarValor">
              <v-icon color="darken-1">cancel</v-icon>
            </v-btn>
          </v-toolbar>
          <v-form ref="formAlterarValor" v-model="alterarValorValid" lazy-validation>
            <v-container>
              <v-layout wrap>
                <v-flex xs12>
                  <v-currency-field
                    :locale="`pt-${$store.state.usuario.site_pais}`"
                    v-model="alterarValorItem.valor"
                    autofocus
                    label="Novo Valor"
                    :allowNegative="false"
                    :max="9999999"
                    hint="Obrigatório"
                    :rules="[
                      validacaoAlterarValor.valor == false || validacaoAlterarValor.valor,
                      value => (value && parseFloat(value.replace('.', '').replace(',', '.')) >= 0) || 'Verifique o valor'
                    ]"
                    color="blue-grey lighten-2"
                  ></v-currency-field>
                </v-flex>
                <v-flex xs12 md12 v-if="alterarValorItem.ativo">
                  <v-progress-linear color="blue-grey" height="25" indeterminate></v-progress-linear>
                </v-flex>
                <v-flex xs12 md12 v-if="alterarValorItem.retorno">
                  <v-textarea
                    v-model="alterarValorItem.retorno"
                    label="Retorno do Processamento"
                    rows="10"
                    :rules="[validacaoAlterarValor.retorno == false || validacaoAlterarValor.retorno]"
                    readonly
                    color="blue-grey lighten-2"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="closeAlterarValor">Retornar</v-btn>
            <v-btn color="blue darken-1" @click="alterarValor" :disabled="alterarValorItem.ativo">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="dialogAlterarTextoFatura" max-width="600">
        <v-card>
          <v-toolbar>
            <span class="headline">Alterar Texto para Fatura</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeAlterarTextoFatura">
              <v-icon color="darken-1">cancel</v-icon>
            </v-btn>
          </v-toolbar>
          <v-form ref="formAlterarTextoFatura" v-model="alterarTextoFaturaValid" lazy-validation>
            <v-container>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    autofocus
                    v-model="alterarTextoFaturaItem.obsCobranca"
                    label="Texto para Fatura"
                    maxlength="100"
                    color="blue-grey lighten-2"
                    autocomplete="disabled"
                    :name="Math.random()"
                    :rules="[validacaoAlterarTextoFatura.obsCobranca == false || validacaoAlterarTextoFatura.obsCobranca]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md12 v-if="alterarTextoFaturaItem.ativo">
                  <v-progress-linear color="blue-grey" height="25" indeterminate></v-progress-linear>
                </v-flex>
                <v-flex xs12 md12 v-if="alterarTextoFaturaItem.retorno">
                  <v-textarea
                    v-model="alterarTextoFaturaItem.retorno"
                    label="Retorno do Processamento"
                    rows="10"
                    :rules="[validacaoAlterarTextoFatura.retorno == false || validacaoAlterarTextoFatura.retorno]"
                    readonly
                    color="blue-grey lighten-2"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="closeAlterarTextoFatura">Retornar</v-btn>
            <v-btn color="blue darken-1" @click="alterarTextoFatura" :disabled="alterarTextoFaturaItem.ativo">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog das ações em lote: Operação -->
      <v-dialog persistent v-model="dialogOperacao" max-width="600">
        <v-card>
          <v-toolbar>
            <span class="headline">{{ acao_operacao }}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeOperacao">
              <v-icon color="darken-1">cancel</v-icon>
            </v-btn>
          </v-toolbar>
          <v-form ref="formOperacao" v-model="operacaoValid" lazy-validation>
            <v-container>
              <v-layout wrap>
                <v-flex xs12>
                  <v-progress-linear :active="executando" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
                </v-flex>

                <v-flex xs12 v-if="acao_operacao == 'Quitar'">
                  <v-flex xs12>
                    <v-autocomplete
                      v-model="contaSelecionada"
                      :items="contasMovimento"
                      hint="Selecione ou deixe em branco para quitar na conta padrão"
                      persistent-hint
                      :filter="filtrarObjetos"
                      label="Conta Movimento"
                      item-text="nome"
                      item-value="id"
                      filled
                      autocomplete="disabled"
                      :name="Math.random()"
                      no-data-text
                      clearable
                      return-object
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12>
                    <v-menu
                      ref="mnuDtoperacaoItemdtQuitacao"
                      v-model="mnuDtoperacaoItemdtQuitacao"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="operacaoItemdtQuitacao"
                          label="Data de Quitação"
                          v-mask="'##/##/####'"
                          hint="Obrigatório"
                          prepend-icon="event"
                          v-on="on"
                          :rules="[
                            value => valida_data_passada(value) || 'Data inválida',
                            value => !!value || 'Obrigatório.',
                            value => (value != null && value.length == 10) || 'Preencha a data de quitação'
                          ]"
                          color="blue-grey lighten-2"
                          autocomplete="disabled"
                          :name="Math.random()"
                          @keyup="operacaoItemdtQuitacaoUS = valida_data_passada(operacaoItemdtQuitacao) ? formatDateUS(operacaoItemdtQuitacao) : null"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="operacaoItemdtQuitacaoUS" locale="pt-br" no-title @input="mnuDtoperacaoItemdtQuitacao = false"></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 offset-md3 md6>
                    <v-currency-field
                      :locale="`pt-${$store.state.usuario.site_pais}`"
                      v-model="troco.vlrReceberFormat"
                      label="Valor a Receber"
                      readonly
                      color="blue-grey lighten-2"
                    ></v-currency-field>
                  </v-flex>
                  <v-flex xs12 offset-md3 md6>
                    <v-currency-field
                      :locale="`pt-${$store.state.usuario.site_pais}`"
                      autofocus
                      v-model="troco.vlrRecebido"
                      label="Valor Recebido"
                      color="blue-grey lighten-2"
                      @keyup.native="troco.vlrTroco = troco.vlrRecebido - troco.vlrReceber"
                    ></v-currency-field>
                  </v-flex>
                  <v-flex xs12 offset-md3 md6>
                    <v-currency-field
                      :locale="`pt-${$store.state.usuario.site_pais}`"
                      v-model="troco.vlrTroco"
                      label="Troco"
                      readonly
                      color="blue-grey lighten-2"
                    ></v-currency-field>
                  </v-flex>
                </v-flex>

                <v-flex xs12 v-if="acao_operacao == 'Bloquear' || acao_operacao == 'Cancelar'">
                  <v-flex xs12 md12 v-if="!motivoParcela">
                    <v-autocomplete
                      v-model="idMotivoParcela"
                      :items="motivosParcelas"
                      hint="Obrigatório"
                      :rules="[value => !!value || 'Obrigatório.']"
                      :filter="filtrarObjetos"
                      :label="`Motivo do ${acao_operacao == 'Bloquear' ? 'Bloqueio' : 'Cancelamento'}`"
                      item-text="nome"
                      item-value="id"
                      autocomplete="disabled"
                      :name="Math.random()"
                      no-data-text
                      return-object
                      @change="motivoParcela = idMotivoParcela.nome"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12 md12 v-if="idMotivoParcela && motivoParcela">
                    <v-text-field
                      v-model="motivoParcela"
                      :label="`Motivo do ${acao_operacao == 'Bloquear' ? 'Bloqueio' : 'Cancelamento'}`"
                      maxlength="255"
                      color="blue-grey lighten-2"
                      autocomplete="disabled"
                      :name="Math.random()"
                      :rules="[
                        value => !!value || 'Obrigatório.',
                        value => (value != null && value.length > 3) || `Preencha o motivo do ${acao_operacao == 'Bloquear' ? 'bloqueio' : 'cancelamento'}`
                      ]"
                      clearable
                      @click:clear="
                        motivoParcela = null;
                        idMotivoParcela = null;
                      "
                    ></v-text-field>
                  </v-flex>
                </v-flex>

                <v-flex xs12 v-if="acao_operacao && acao_operacao.includes('Criar Lote') && cedentesBoleto.length > 1 && escolherCedente">
                  <v-autocomplete
                    v-model="cedenteBoletoSelecionado"
                    autofocus
                    :items="cedentesBoleto"
                    :filter="filtrarObjetos"
                    label="Selecione o Cedente para Boletos"
                    item-text="nome"
                    item-value="id"
                    hint="Obrigatório"
                    :rules="[value => (!!value && !!value.id) || 'Obrigatório']"
                    no-data-text
                    autocomplete="disabled"
                    :name="Math.random()"
                    return-object
                  ></v-autocomplete>
                </v-flex>

                <v-flex xs12 md12 v-if="operacaoItem.ativo">
                  <v-progress-linear color="blue-grey" height="25" indeterminate></v-progress-linear>
                </v-flex>
                <v-flex xs12 md12 v-if="operacaoItem.retorno">
                  <v-textarea
                    v-model="operacaoItem.retorno"
                    label="Retorno do Processamento"
                    rows="10"
                    :rules="[validacaoOperacao.retorno == false || validacaoOperacao.retorno]"
                    readonly
                    color="blue-grey lighten-2"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="closeOperacao">Retornar</v-btn>
            <v-btn
              v-if="
                !operacaoItem.ativo &&
                  acao_operacao &&
                  (acao_operacao == 'Quitar' || acao_operacao == 'Bloquear' || acao_operacao == 'Cancelar' || acao_operacao.includes('Criar Lote'))
              "
              color="blue darken-1"
              @click="executarOperacaoLote"
              :disabled="executando"
              >{{ acao_operacao == "Quitar" ? "Quitar" : acao_operacao == "Bloquear" ? "Bloquear" : acao_operacao == "Cancelar" ? "Cancelar" : "Criar" }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog das ações em lote: Cancelar -->
      <v-dialog persistent v-model="dialogCancelar" max-width="290">
        <v-card>
          <v-toolbar>
            <span class="headline">{{ acao_cancelar }}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeCancelar">
              <v-icon color="darken-1">cancel</v-icon>
            </v-btn>
          </v-toolbar>
          <v-form ref="formCancelar" v-model="cancelarValid" lazy-validation>
            <v-container>
              <v-layout wrap>
                <v-flex xs12 md12 v-if="cancelarItem.ativo">
                  <v-progress-linear color="blue-grey" height="25" indeterminate></v-progress-linear>
                </v-flex>
                <v-flex xs12 md12 v-if="cancelarItem.retorno">
                  <v-textarea
                    v-model="cancelarItem.retorno"
                    label="Retorno do Processamento"
                    rows="10"
                    :rules="[validacaoCancelar.retorno == false || validacaoCancelar.retorno]"
                    readonly
                    color="blue-grey lighten-2"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="closeCancelar">Retornar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogConciliacao" max-width="1200px">
        <v-card>
          <Conciliacao :idExtratoLancamento="idExtratoLancamento" :dialogConciliacao.sync="dialogConciliacao" />
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>
<script>
import Parcela from "@/views/fin/Parcela.vue";
import BRR from "@/views/fin/BRR.vue";
import Contato from "@/views/ass/Contato.vue";
import CobrancaCartao from "@/views/fin/CobrancaCartao.vue";
import FiltroParcela from "@/views/components/FiltroParcela.vue";
import FiltroCliente from "@/views/components/FiltroCliente.vue";
import FiltroAssinatura from "@/views/components/FiltroAssinatura.vue";
import FiltroAnuncio from "@/views/components/FiltroAnuncio.vue";
import FiltroNF from "@/views/components/FiltroNF.vue";
import LogAlteracao from "@/views/components/LogAlteracao.vue";
import LogBanco from "@/views/fin/LogBanco.vue";
import Pesquisas from "@/views/components/Pesquisas.vue";
import Conciliacao from "@/views/fin/Conciliacao.vue";
import util from "../../util.js";
import { api } from "@/services/api.js";
import download from "downloadjs";
import { mapMutations } from "vuex";
export default {
  components: {
    Parcela,
    BRR,
    Contato,
    CobrancaCartao,
    FiltroParcela,
    FiltroCliente,
    FiltroAssinatura,
    FiltroAnuncio,
    FiltroNF,
    LogAlteracao,
    LogBanco,
    Pesquisas,
    Conciliacao
  },
  data() {
    return {
      headersBemRed: [
        { text: "Ações", value: "reduzido" },
        { text: "ID", value: "id" },
        { text: "Código", value: "idOperacao" },
        { text: "Status", value: "status" },
        { text: "Vencimento", value: "dtVecto" },
        { text: "Líquido", value: "liquido", align: "end" },
        { text: "Origem", value: "origem" }
      ],
      headersRed: [
        { text: "Ações", value: "reduzido" },
        { text: "ID", value: "id" },
        { text: "Código", value: "idOperacao" },
        { text: "Titular", value: "identificacao" },
        { text: "Status", value: "status" },
        { text: "Parcela", value: "indice" },
        { text: "Forma Pgto", value: "formaPagamento" },
        { text: "Vencimento", value: "dtVecto" },
        { text: "Líquido", value: "liquido", align: "end" },
        { text: "Origem", value: "origem" }
      ],
      headers: [
        { text: "Ações", value: "actions", width: "280" },
        { text: "ID", value: "id" },
        { text: "Código", value: "idOperacao" },
        { text: "Cliente", value: "cliente" },
        { text: "Titular", value: "identificacao" },
        { text: "Status", value: "status" },
        { text: "Parcela", value: "indice" },
        { text: "Forma Pgto", value: "formaPagamento" },
        { text: "Emissão", value: "dtCriacao" },
        { text: "Vencimento", value: "dtVecto" },
        { text: "Promessa", value: "dtPromessa" },
        { text: "Quitação", value: "dtQuitacao" },
        { text: "Bruto", value: "valor", align: "end" },
        { text: "Líquido", value: "liquido", align: "end" },
        { text: "Visualizar", value: "actions2", width: "150" }
      ],
      headersExpand: [
        { text: "Ações", value: "actions", width: "280" },
        { text: "ID", value: "id" },
        { text: "Código", value: "idOperacao" },
        { text: "Cliente", value: "cliente" },
        { text: "Titular", value: "identificacao" },
        { text: "Status", value: "status" },
        { text: "Parcela", value: "indice" },
        { text: "Forma Pgto", value: "formaPagamento" },
        { text: "Lote", value: "idLoteFaturamento" },
        { text: "Nosso Número", value: "nossoNumero" },
        { text: "Cobrança", value: "statusCobranca" },
        { text: "Emissão", value: "dtCriacao" },
        { text: "Vencimento", value: "dtVecto" },
        { text: "Promessa", value: "dtPromessa" },
        { text: "Bloqueio", value: "dtBloqueio" },
        { text: "Quitação", value: "dtQuitacao" },
        { text: "Atraso (dias)", value: "diasAtraso" },
        { text: "Cancelamento", value: "dtCancelamento" },
        { text: "Bruto", value: "valor" },
        { text: "Ajuste", value: "descAcr" },
        { text: "Líquido", value: "liquido" },
        { text: "Status Contrato", value: "statusContrato" },
        { text: "Exemplares", value: "exemplares", width: "140" },
        { text: "Operação", value: "idOperacao2" },
        { text: "NF", value: "nroNF" },
        { text: "Visualizar", value: "actions2", width: "130" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50, 100, 500, 1000],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },
      inicial: true,
      expandir: false,
      alteracoes: [],
      operacoes: [],
      cancelamentos: [],
      creditos: [],
      visualizacoes: [],
      emails: [],
      tab: null,
      tabAss: false,
      tabAnu: false,

      cedenteBoletoSelecionado: null,
      cedentesBoleto: [],
      cedentesDebitoConta: [],
      cedenteAsaas: null,
      cedentesBRR: [],

      configNF: false,
      remessaFutura: false,

      filtroParcela: null,
      dialogFiltroAvancado: false,

      //* Dados para o componente DialogParcela
      dialogParcela: false,
      idParcela: "",
      atMonetaria: 0,
      atualiza: "sim",

      //* Dados para o componente DialogContato
      dialogContato: false,
      idAssinatura: "",
      idRenovacao: "",
      idAnuncio: "",
      idPacote: "",
      idOperacao: "",
      idCliente: "",
      cliente: "",
      tipo: "",
      origem: "",

      //* Dados para o componente DialogCartao
      dialogCartao: false,
      parcelasCartao: "",
      nroParcelasCartao: 1,

      //* Dados para o componente DialogDesmembrar
      dialogDesmembrar: false,
      desmembrarValid: true,
      desmembrarIndex: -1,
      desmembrarItem: {
        valorOriginal: 0,
        novoValor: 0,
        parcelasDesmembrar: 1,
        periodicidade: null
      },
      validacaoDesmembrar: {
        valor: false,
        parcelas: false,
        dtInicio: false,
        periodicidade: false
      },
      msgDesmembrar: "",
      snackDesmembrar: false,
      dtInicio: null,
      dtInicioUS: null,
      mnuDtInicio: false,

      //* Dados para o componente DialogAlterarVecto
      dialogAlterarVecto: false,
      alterarVectoValid: true,
      alterarVectoIndex: -1,
      alterarVectoItem: {
        dtVecto: null,
        ativo: false,
        retorno: ""
      },
      alterarDtVectoItem: null,
      alterarDtVectoItemUS: null,
      mnuDtalterarDtVectoItem: false,
      validacaoAlterarVecto: {
        retorno: false
      },

      //* Dados para o componente DialogTroco
      dialogTroco: false,
      trocoIndex: -1,
      trocoItem: {},
      troco: {
        vlrReceber: 0,
        vlrRecebido: 0,
        vlrTroco: 0
      },
      quitando: false,

      //* Dados para o componente DialogAlterarValor
      dialogAlterarValor: false,
      alterarValorValid: true,
      alterarValorIndex: -1,
      alterarValorItem: {
        valor: 0,
        ativo: false,
        retorno: ""
      },
      validacaoAlterarValor: {
        valor: false,
        retorno: false
      },

      //* Dados para o componente DialogAlterarTextoFatura
      dialogAlterarTextoFatura: false,
      alterarTextoFaturaValid: true,
      alterarTextoFaturaIndex: -1,
      alterarTextoFaturaItem: {
        obsCobranca: "",
        ativo: false,
        retorno: ""
      },
      validacaoAlterarTextoFatura: {
        obsCobranca: false,
        retorno: false
      },

      //* Dados para o componente DialogAlterarFormaPgto
      formaPagamentoSelecionada: null,
      dialogAlterarFormaPgto: false,
      alterarFormaPgtoValid: true,
      alterarFormaPgtoIndex: -1,
      alterarFormaPgtoItem: {
        ativo: false,
        processado: false,
        retorno: ""
      },
      validacaoAlterarFormaPgto: {
        retorno: false
      },

      //* Dados para o componente DialogOperacao
      dialogOperacao: false,
      operacaoValid: true,
      operacaoIndex: -1,
      operacaoItem: {
        dtQuitacao: null,
        ativo: false,
        retorno: ""
      },
      escolherCedente: false,
      executando: false,

      contaSelecionada: {},
      operacaoItemdtQuitacao: null,
      operacaoItemdtQuitacaoUS: null,
      mnuDtoperacaoItemdtQuitacao: false,

      validacaoOperacao: {
        retorno: false
      },

      //* Dados para o componente DialogCancelar
      dialogCancelar: false,
      cancelarValid: true,
      cancelarIndex: -1,
      cancelarItem: {
        ativo: false,
        retorno: ""
      },
      validacaoCancelar: {
        retorno: false
      },

      //* Dados para o componente DialogLogAlteracao
      dialogLogAlteracao: false,

      //* Dados para o componente DialogLogBanco
      dialogLogBanco: false,
      idLoteFaturamento: "",

      //* Motivo do bloqueio/cancelamento
      dialogCancelarItem: false,
      cancelarItemValid: true,
      idMotivoParcela: null,
      motivoParcela: null,
      motivosParcelas: [],
      itemCancelarItem: null,
      cancelarItemIndex: -1,
      cancelarValid: true,
      cancelando: false,

      //* Ações em lote
      acao_alterar: null,
      acao_operacao: null,
      acao_cancelar: null,
      acao_credito: null,
      acao_visualizar: null,
      acao_email: null,

      dialogMensagem: false,
      //* Confirmação
      dialogConfirm: false,
      botaoAcaoLote: null,
      msgConfirm: "",

      dialogConciliacao: false,
      idExtratoLancamento: "",

      direitoEditar: false,
      direitoEditarConfirmada: false,
      direitoQuitar: false,
      direitoReverterBanco: false,
      direitoCobrarCartao: false,
      direitoCancelar: false,
      direitoRecibo: false,
      direitoBoleto: false,
      direitoNotaFiscal: false,
      direitoCliente: false,
      direitoAssinante: false,
      direitoFornecedor: false,
      direitoOperacao: false,
      direitoAssinatura: false,
      direitoAnuncio: false,
      direitoPacote: false,
      direitoLog: false,
      direitoContato: false,
      direitoVendaAvulsa: false,
      direitoCaixa: false,
      direitoLote: false,
      direitoExportar: false,

      mensagem: "",
      snackbar: false,
      snackErro: false,

      selectAll: false,

      idSitePrincipal: null,
      formasPgto: [],
      contas: [],
      mensagemPesquisa: "",

      idParcelaPesquisa: null,
      identificacaoPesquisa: null,
      nomePesquisa: null,

      registros: [],
      registrosPagina: 0,
      totalRegistros: 0,
      valorTotal: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {},
      loader: null,
      loading: false,
      loadingExp: false,
      calcular: "nao"
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO", "OCULTAR_MENU"]),

    limpaFiltro() {
      var pgAnterior = this.paginaAtual;
      this.paginaAtual = 1;
      if (pgAnterior == 1) {
        this.filtroAvancadoFiltrar(false);
      }
    },

    async carregarFormasPgto() {
      //* Busca todas as formas de cobrança ativas (a receber ou a pagar) exceto as de parcela única, que não podem ser alteradas (CORTESIA, BONIFICAÇÃO, etc)
      const response = await api.get(
        process.env.VUE_APP_URL_WEB_API + "adm/forma-pagamento/lista?idSite=" + this.idSitePrincipal + "&editaveis=1" + (this.receber ? "" : "&pagar=1")
      );
      if (response.data) {
        this.formasPgto = response.data;
      }
    },
    async carregarContas() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/conta/movimento");
      if (response.data) {
        this.contasMovimento = response.data;
      }
    },
    async carregarCedentesBoleto() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/cedente/boleto?idSite=" + this.idSitePrincipal);
      if (response.data) {
        this.cedentesBoleto = response.data;
        var incluiAuto = false;
        response.data.forEach(element => {
          if (element.principal) {
            this.cedenteBoletoSelecionado = element;
          }
          if (element.origens || element.idFormaPgto) {
            incluiAuto = true;
          }
        });
        if (incluiAuto) {
          this.cedentesBoleto.unshift({
            id: 99,
            nome: "#Automático",
            formato: 0
          });
        }
      }
    },
    async carregarCedentesDebitoConta() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/cedente/debito?idSite=" + this.idSitePrincipal);
      if (response.data) {
        this.cedentesDebitoConta = response.data;
      }
    },
    async carregarCedenteAsaas() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/cedente/asaas?idSite=" + this.idSitePrincipal);
      if (response.data) {
        this.cedenteAsaas = response.data;
      }
    },
    async carregarConfigNF() {
      this.configNF = false;
      this.remessaFutura = false;
      const responseNFe = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/config-nfe/site/" + this.idSitePrincipal);
      if (responseNFe.data) {
        this.configNF = true;
        if (responseNFe.data.rfCFOP && responseNFe.data.rfNatOp) {
          this.remessaFutura = true;
        }
      }
      const responseNFSC = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/config-nfsc/site/" + this.idSitePrincipal);
      if (responseNFSC.data) {
        this.configNF = true;
      }
      const responseNFSe = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/config-nfse/site/" + this.idSitePrincipal);
      if (responseNFSe.data) {
        this.configNF = true;
      }
    },

    async carregarCedentesBRR() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/cedente/brr?idSite=" + this.idSitePrincipal);
      if (response.data) {
        this.cedentesBRR = response.data;
      }
    },
    async carregarMotivosParcela() {
      try {
        if (!this.motivosParcelas.length) {
          //* Carrega os motivos de bloqueio/cancelamento de parcelas
          const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/motivo-parcela/lista");
          this.motivosParcelas = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os motivos de bloqueio/cancelamento de parcelas";
        this.snackErro = true;
      }
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },

    editarParcela(item) {
      this.idParcela = item.id + "";
      this.tab = 0;
      this.dialogParcela = true;
    },
    closeParcela() {
      this.dialogParcela = false;
      this.idParcela = "";
    },
    visualizarLogAlteracao(item) {
      this.idParcela = item.id + "";
      this.dialogLogAlteracao = true;
    },
    visualizarLogBanco(item) {
      this.idParcela = item.id + "";
      this.dialogLogBanco = true;
    },
    closeLogAlteracao() {
      this.dialogLogAlteracao = false;
      this.idParcela = "";
    },
    closeLogBanco() {
      this.dialogLogBanco = false;
      this.idParcela = "";
    },

    closeFiltroParcela() {
      if (this.dialogFiltroParcela) {
        this.dialogFiltroParcela = false;
        this.filtroAvancadoFiltrar(false);
      }
    },

    preQuitarDesquitar(item) {
      if (this.direitoTroco && item.status == "ABERTA") {
        this.trocoIndex = this.registros.indexOf(item);
        this.trocoItem = Object.assign({}, item);

        this.troco = {
          vlrReceber: item.valor + item.descAcr,
          vlrReceberFormat: util.formatCurrency(item.valor + item.descAcr),
          vlrRecebido: 0,
          vlrTroco: 0
        };
        this.dialogTroco = true;
      } else {
        this.quitarDesquitar(item);
      }
    },
    async quitarTroco() {
      this.quitando = true;
      var item = Object.assign({}, this.trocoItem);
      await this.quitarDesquitar(item);
      setTimeout(() => {
        this.closeTroco();
      }, 500);
    },
    closeTroco() {
      this.dialogTroco = false;
      this.quitando = false;
      this.troco = {
        vlrReceber: 0,
        vlrRecebido: 0,
        vlrTroco: 0
      };
      this.trocoIndex = -1;
      this.trocoItem = {};
    },

    async quitarDesquitar(item) {
      try {
        const parcelas = [
          {
            id: item.id
          }
        ];

        const operacaoCommit = {
          status: item.status == "QUITADA" ? "ABERTA" : "QUITADA",
          statusReverso: item.status,
          parcelas: parcelas
        };

        await api.put(
          process.env.VUE_APP_URL_WEB_API + "fin/parcela/operacoes" + (item.status == "QUITADA" && this.direitoReverterBanco && item.idLoteFaturamento ? "?reverterBanco=1" : ""),
          operacaoCommit
        );

        if (item.status == "ABERTA") {
          this.mensagem = "Parcela quitada com sucesso";
        } else {
          this.mensagem = "Quitação da parcela revertida com sucesso";
        }
        this.snackbar = true;

        var dadoIndex = this.trocoIndex > -1 ? this.trocoIndex : this.registros.indexOf(item);
        var dadoItem = this.trocoItem.id ? this.trocoItem : Object.assign({}, item);
        dadoItem.status = item.status == "ABERTA" ? "QUITADA" : "ABERTA";
        dadoItem.dtQuitacao = item.status == "ABERTA" ? new Date() : null;
        if (item.status == "QUITADA" && item.idLoteFaturamento && this.direitoReverterBanco) {
          dadoItem.nossoNumero = null;
          dadoItem.idLoteFaturamento = null;
          dadoItem.statusCobranca = 0;
        }
        Object.assign(this.registros[dadoIndex], dadoItem);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    preCancelarDescancelar(item) {
      this.itemCancelarItem = Object.assign({}, item);
      this.cancelarItemIndex = this.registros.indexOf(item);

      if (item.status == "ABERTA") {
        this.carregarMotivosParcela();

        this.idMotivoParcela = null;
        this.motivoParcela = null;
        this.cancelando = false;

        this.dialogCancelarItem = true;
      } else {
        this.cancelarDescancelar();
      }
    },
    async cancelarDescancelar() {
      if (this.itemCancelarItem.status == "ABERTA") {
        if (!this.$refs.formCancelarItem.validate()) {
          return;
        }
      }
      var item = Object.assign({}, this.itemCancelarItem);
      try {
        const parcelas = [
          {
            id: item.id
          }
        ];

        var operacaoCommit = {
          status: item.status == "CANCELADA" ? "ABERTA" : "CANCELADA",
          statusReverso: item.status,
          parcelas: parcelas
        };

        if (item.status == "ABERTA") {
          operacaoCommit.motivo = this.idMotivoParcela.nome;
        }

        await api.put(process.env.VUE_APP_URL_WEB_API + "fin/parcela/operacoes", operacaoCommit);

        if (item.status == "ABERTA") {
          this.mensagem = "Parcela cancelada com sucesso";
        } else {
          this.mensagem = "Cancelamento da parcela revertido com sucesso";
        }
        this.snackbar = true;

        var dadoItem = Object.assign({}, item);
        dadoItem.status = item.status == "ABERTA" ? "CANCELADA" : "ABERTA";
        dadoItem.dtCancelamento = item.status == "ABERTA" ? new Date() : null;
        Object.assign(this.registros[this.cancelarItemIndex], dadoItem);
        this.dialogCancelarItem = false;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    async baixar(item) {
      //* Envia parcela para ser incluída em lote de cancelamento
      try {
        const parcelas = [
          {
            id: item.id,
            nossoNumero: item.nossoNumero,
            idLoteFaturamento: item.idLoteFaturamento,
            payAsaas: item.payAsaas
          }
        ];

        const operacaoCommit = {
          parcelas: parcelas
        };

        await api.put(process.env.VUE_APP_URL_WEB_API + "fin/parcela/baixar", operacaoCommit);

        var dadoIndex = this.registros.indexOf(item);
        var dadoItem = Object.assign({}, item);
        if (item.statusCobranca == 40) {
          dadoItem.nossoNumero = null;
          dadoItem.idLoteFaturamento = null;
          dadoItem.statusCobranca = 0;
          this.mensagem = this.receber ? "Boleto/DC cancelado" : "Cobrança cancelada";
          this.snackbar = true;
        } else {
          dadoItem.statusCobranca = 30;
          this.mensagem = this.receber && !item.payAsaas ? "Parcela marcada para inclusão em lote de cancelamento" : "Cobrança cancelada";
          this.snackbar = true;
        }
        Object.assign(this.registros[dadoIndex], dadoItem);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    acaoLote(botao) {
      this.botaoAcaoLote = botao;
      this.selecionadas = [];
      var recusadas = [];
      var status = [];
      var formaPgtoTipo = [];
      var formaPgtoTipoExclui = [];
      var dtVecto = null;
      var lote = false;
      var semLote = false;
      var comPay = false;
      var nf = false;
      var zero = false;
      var dadoCobranca = false;
      var conciliacao = false;
      var confirmada = null;

      if (botao == "alterar") {
        status = ["ABERTA", "BLOQUEADA"];
      } else if (
        botao == "operacao" ||
        (botao == "cancelar" && (this.acao_cancelar == "Boleto" || this.acao_cancelar == "Débito em Conta")) ||
        (botao == "visualizar" && this.acao_visualizar == "Carta-Cobrança") ||
        (botao == "email" && (this.acao_email == "Carta-Cobrança" || this.acao_email.includes("Boleto")))
      ) {
        if (botao == "operacao" && this.acao_operacao && this.acao_operacao.includes("Criar N")) {
          if (this.acao_operacao.includes("Remessa Futura")) {
            status = ["ABERTA", "BLOQUEADA"];
          } else {
            status = ["ABERTA", "BLOQUEADA", "QUITADA", "CANCELADA"];
          }
          formaPgtoTipoExclui = ["PACOTE"];
        } else {
          status = ["ABERTA"];
          if (botao == "operacao" && this.acao_operacao && (this.acao_operacao.includes("Criar Lote") || this.acao_operacao.includes("Carnê"))) {
            dtVecto = util.parseDateUS(new Date());
            if (this.receber) {
              if (this.acao_operacao.includes("Carnê")) {
                //formaPgtoTipo = ["BOLETO"];
                status = [];
                dtVecto = null;
              } else {
                formaPgtoTipo = ["BOLETO", "DEBITO_CONTA"]; //"CELESC", é pelo atalho na tela de remessas //removido: "SAMAE"
              }
            }
          }
          if (botao == "operacao" && this.acao_operacao == "Cobrar Cartão") {
            if (this.receber) {
              formaPgtoTipo = ["CARTAO_CREDITO"];
            }
          }
          if (botao == "operacao" && this.acao_operacao == "Cobrar Asaas") {
            if (this.receber) {
              formaPgtoTipo = ["BOLETO", "PIX"];
            }
          }
          if (botao == "cancelar" && this.receber) {
            if (this.acao_cancelar == "Boleto") {
              formaPgtoTipo.push("BOLETO");
            }
            if (this.acao_cancelar == "Débito em Conta") {
              formaPgtoTipo.push("DEBITO_CONTA");
            }
          }
        }
      }
      if (botao == "cancelar" && this.acao_cancelar == "Bloqueio") {
        status = ["BLOQUEADA"];
      }
      if (botao == "visualizar" && this.acao_visualizar == "Recibo") {
        status = ["QUITADA"];
      }
      if (botao == "email" && this.acao_email == "Recibo") {
        status = ["QUITADA"];
      }
      if (botao == "operacao" && this.acao_operacao == "Quitar") {
        status = ["ABERTA"];
        formaPgtoTipoExclui = ["PERMUTA_RECEBER", "PERMUTA_PAGAR", "PACOTE"];
      }
      if (botao == "operacao" && this.acao_operacao == "Cancelar") {
        status = ["ABERTA"];
        formaPgtoTipoExclui = ["PERMUTA_RECEBER", "PERMUTA_PAGAR", "PACOTE"];
      }
      if (botao == "cancelar" && this.acao_cancelar == "Quitação") {
        status = ["QUITADA"];
        formaPgtoTipoExclui = ["BONIFICACAO", "COMPENSACAO", "CORTESIA", "PERMUTA_RECEBER", "PERMUTA_PAGAR", "PACOTE"];
      }
      if (botao == "cancelar" && this.acao_cancelar == "Cancelamento") {
        status = ["CANCELADA"];
        formaPgtoTipoExclui = ["PERMUTA_RECEBER", "PERMUTA_PAGAR", "PACOTE"];
      }
      if (botao == "visualizar" && this.acao_visualizar == "Cobrança Carteira") {
        status = ["ABERTA"];
        formaPgtoTipo.push("CARTEIRA");
      }

      var dtLimite = new Date();
      dtLimite.setDate(dtLimite.getDate() + 1);

      //* Filtra os registros selecionados pelo status necessário para aplicação da ação em lote
      this.registros.forEach(element => {
        if (element.selecionado == 1) {
          if (botao == "visualizar" && this.acao_visualizar == "Recibo") {
            formaPgtoTipo.push("CARTEIRA");
            if (!status.includes(element.status) && !formaPgtoTipo.includes(element.formaPagamentoTipo)) {
              recusadas.push(element.id);
              element.selecionado = 0;
            } else {
              this.selecionadas.push(element);
            }
            formaPgtoTipo = [];
          } else if (
            (status.length == 0 || status.includes(element.status)) &&
            (formaPgtoTipo.length == 0 || formaPgtoTipo.includes(element.formaPagamentoTipo)) &&
            (formaPgtoTipoExclui.length == 0 || !formaPgtoTipoExclui.includes(element.formaPagamentoTipo)) &&
            (dtVecto == null || util.parseDateUS(element.dtVecto) >= dtVecto)
          ) {
            if (botao == "operacao" && this.acao_operacao && this.acao_operacao.includes("Criar Lote")) {
              if (dtVecto != null && element.idLoteFaturamento) {
                //* Se é criação de lote e parcela já está em lote, não reinclui
                semLote = true;
                recusadas.push(element.id);
                element.selecionado = 0;
              } else if (parseFloat(element.valor) + parseFloat(element.descAcr) == 0) {
                zero = true;
                recusadas.push(element.id);
                element.selecionado = 0;
                //na criação do lote será adicionado automaticamente o último dado de cobrança ativo do cliente (mais atual) para a parcela, se não houver vinculado
              } else {
                this.selecionadas.push(element);
              }
            } else if (botao == "operacao" && this.acao_operacao && this.acao_operacao.includes("Cobrar Asaas")) {
              if (element.payAsaas) {
                //* Se cobrança já foi criada, não reinclui
                comPay = true;
                recusadas.push(element.id);
                element.selecionado = 0;
              } else if (parseFloat(element.valor) + parseFloat(element.descAcr) == 0) {
                zero = true;
                recusadas.push(element.id);
                element.selecionado = 0;
              } else {
                this.selecionadas.push(element);
              }
            } else if (botao == "operacao" && ["Cancelar", "Quitar", "Bloquear"].includes(this.acao_operacao)) {
              if (element.idLoteFaturamento && element.tipoLote < 3) {
                //* Se parcela está em lote de inclusão ou alteração (ou em cobrança Asaas) não permite alterar seu status
                semLote = true;
                recusadas.push(element.id);
                element.selecionado = 0;
              } else if (element.statusCobranca == 30 && (element.tipoLote < 3 || element.remessa != "ENVIADA")) {
                //* Se parcela está com statusCobranca cancelada, só permite alterar se ja estiver em lote de alteração enviado
                lote = true;
                recusadas.push(element.id);
                element.selecionado = 0;
              } else if (
                element.formaPagamentoTipo == "DEBITO_CONTA" &&
                element.idLoteFaturamento &&
                element.statusCobranca != 40 &&
                util.parseDateUS(element.dtVecto) < dtLimite
              ) {
                //* Se parcela está em lote de débito em conta sem erro e não dá tempo de cancelar (parcela já venceu ou vence amanhã), não permite alterar - tem que aguardar o retorno: QUITADA ou ERRO
                lote = true;
                recusadas.push(element.id);
                element.selecionado = 0;
              } else if (element.formaPagamentoTipo == "CELESC" && element.idLoteFaturamento && element.statusCobranca != 40) {
                //* Se parcela está em lote celesc sem erro, não permite alterar - tem que aguardar o retorno: QUITADA ou ERRO
                lote = true;
                recusadas.push(element.id);
                element.selecionado = 0;
              } else if (element.payAsaas) {
                //* Se cobrança já foi criada, não permite alterar status
                comPay = true;
                recusadas.push(element.id);
                element.selecionado = 0;
              } else {
                this.selecionadas.push(element);
              }
            } else if (botao == "cancelar" && (this.acao_cancelar == "Boleto" || this.acao_cancelar == "Débito em Conta")) {
              //* Se é cancelamento de boleto ou débito em conta
              if (!element.idLoteFaturamento) {
                lote = true;
                recusadas.push(element.id);
                element.selecionado = 0;
              } else {
                this.selecionadas.push(element);
              }
            } else if (botao == "cancelar" && this.acao_cancelar == "Quitação") {
              //* Se é cancelamento de quitação
              if (element.idLoteFaturamento) {
                semLote = true;
                recusadas.push(element.id);
                element.selecionado = 0;
              } else if (element.conciliado) {
                //* Se parcela está conciliada, só permite desquitar se tiver permissão
                conciliacao = true;
                recusadas.push(element.id);
                element.selecionado = 0;
              } else {
                this.selecionadas.push(element);
              }
            } else if (botao == "cancelar" && this.acao_cancelar == "Bloqueio") {
              //* Se é cancelamento de bloqueio
              if (!this.receber && !element.confirmada) {
                confirmada = true;
                recusadas.push(element.id);
                element.selecionado = 0;
              } else {
                this.selecionadas.push(element);
              }
            } else if (botao == "operacao" && this.acao_operacao && this.acao_operacao.includes("Criar N")) {
              if (element.idNFe || element.idNFSC || element.idNFSe) {
                //* Se é criação de nota fiscal e parcela já está vinculada a uma nota fiscal, não reinclui
                nf = true;
                recusadas.push(element.id);
                element.selecionado = 0;
              } else if (parseFloat(element.valor) + parseFloat(element.descAcr) == 0) {
                zero = true;
                recusadas.push(element.id);
                element.selecionado = 0;
              } else {
                this.selecionadas.push(element);
              }
            } else if (botao == "visualizar" && this.acao_visualizar.includes("Boleto")) {
              if (
                element.nossoNumero &&
                element.idLoteFaturamento &&
                parseInt(element.statusCobranca) >= 10 &&
                parseInt(element.statusCobranca) <= 20 &&
                element.formaPagamentoTipo == "BOLETO" &&
                element.status == "ABERTA"
              ) {
                this.selecionadas.push(element);
              } else {
                recusadas.push(element.id);
                element.selecionado = 0;
              }
            } else if (botao == "alterar" && !this.receber && element.confirmada == 1 && this.$store.state.usuario.site_confirmaOpPagar) {
              confirmada = false;
              recusadas.push(element.id);
              element.selecionado = 0;
            } else {
              this.selecionadas.push(element);
            }
          } else {
            recusadas.push(element.id);
            element.selecionado = 0;
          }
        }
      });
      if (this.selecionadas.length == 0) {
        this.selectAll = false;
        setTimeout(() => {
          this.acao_alterar = null;
          this.acao_operacao = null;
          this.acao_cancelar = null;
          this.acao_credito = null;
          this.acao_visualizar = null;
          this.acao_email = null;
        }, 30);
        this.mensagem =
          "Selecione pelo menos 1 parcela " +
          (status.length > 0 ? status : "") +
          (formaPgtoTipo.length > 0 ? " da forma de pagamento " + formaPgtoTipo.toString().replace(/[,]/g, ", ") : "") +
          (formaPgtoTipoExclui.length > 0 ? " que não seja da forma de pagamento " + formaPgtoTipoExclui.toString().replace(/[,]/g, ", ") : "") +
          (dtVecto ? " com vencimento maior ou igual a data atual" : "") +
          (lote || semLote
            ? " e que " +
              (semLote ? "não " : "") + //|| botao == "operacao"
              "esteja em lote de faturamento"
            : "") +
          (comPay ? " e que não tenha sido enviada ao Asaas" : "") +
          (nf ? " e que não esteja vinculada a uma nota fiscal" : "") +
          (conciliacao ? " e que não esteja CONCILIADA" : "") +
          (confirmada != null ? " associada a operação a pagar " + (confirmada ? "" : "NÃO ") + "CONFIRMADA" : "") +
          (zero ? " e que não tenha valor ZERO" : "") +
          (dadoCobranca ? " e que tenha o dado de cobrança definido" : "") +
          (botao == "visualizar" && this.acao_visualizar == "Recibo" ? " ou com a forma de pagamento CARTEIRA" : "");
        this.dialogMensagem = true;
        return;
      }

      if (recusadas.length > 0) {
        this.msgConfirm =
          "As seguintes parcelas foram recusadas por não terem " +
          (status.length > 0 ? "o status " + status : "") +
          (formaPgtoTipo.length > 0 ? " e a forma de pagamento " + formaPgtoTipo : "") +
          (dtVecto ? " e a data de vencimento maior ou igual a atual" : "") +
          (dadoCobranca ? " e dado de cobrança definido" : "") +
          (lote ? " e por já estarem em lote de faturamento" : "") +
          (comPay ? " e que não tenha sido enviada ao Asaas" : "") +
          (nf ? " e por já estarem vinculadas a notas fiscais" : "") +
          (zero ? " e por terem valor ZERO" : "") +
          (botao == "visualizar" && this.acao_visualizar == "Recibo" ? " ou a forma de pagamento CARTEIRA" : "") +
          ":<br />";

        recusadas.forEach(element => {
          this.msgConfirm += element + " ";
        });
        this.msgConfirm += "<br /><br /><strong>Deseja aplicar a ação nas outras parcelas selecionadas?</strong>";
        this.dialogConfirm = true;
        return;
      }
      if (this.acao_operacao && this.acao_operacao.includes("Criar N")) {
        this.msgConfirm = "<br /><br /><strong>Tem certeza que deseja emitir as NOTAS FISCAIS das parcelas selecionadas?</strong>";
        this.dialogConfirm = true;
        return;
      }

      this.processarAcaoLote();
    },
    processarAcaoLote() {
      if (this.botaoAcaoLote == "alterar") {
        if (this.acao_alterar == "Vencimento") {
          this.alterarDtVectoItem = null;
          this.alterarVectoItem.ativo = false;
          this.alterarVectoItem.retorno = "";
          this.dialogAlterarVecto = true;
        } else if (this.acao_alterar == "Forma de Pagamento") {
          this.formaPagamentoSelecionada = null;
          this.alterarFormaPgtoItem.ativo = false;
          this.alterarFormaPgtoItem.processado = false;
          this.alterarFormaPgtoItem.retorno = "";
          this.dialogAlterarFormaPgto = true;
        } else if (this.acao_alterar == "Valor") {
          this.alterarValorItem.valor = 0;
          this.alterarValorItem.ativo = false;
          this.alterarValorItem.retorno = "";
          this.dialogAlterarValor = true;
        } else if (this.acao_alterar == "Texto Fatura") {
          this.alterarTextoFaturaItem.obsCobranca = "";
          this.alterarTextoFaturaItem.ativo = false;
          this.alterarTextoFaturaItem.retorno = "";
          this.dialogAlterarTextoFatura = true;
        }
      } else if (this.botaoAcaoLote == "operacao") {
        this.operacaoItemdtQuitacao = util.formatDate(new Date()); //* Sugere data do dia
        this.operacaoItem.retorno = "";
        if (this.acao_operacao == "Quitar") {
          this.carregarContaPadrao();
          this.operacaoItem.ativo = false;
          this.dialogOperacao = true;

          var vlr = 0;
          this.selecionadas.forEach(element => {
            vlr += element.valor + element.descAcr;
          });
          this.troco = {
            vlrReceber: vlr,
            vlrReceberFormat: util.formatCurrency(vlr),
            vlrRecebido: 0,
            vlrTroco: 0
          };
        } else if (this.acao_operacao == "Bloquear") {
          this.carregarMotivosParcela();
          this.operacaoItem.ativo = false;
          this.idMotivoParcela = null;
          this.motivoParcela = null;
          this.dialogOperacao = true;
        } else if (this.acao_operacao == "Cancelar") {
          this.carregarMotivosParcela();
          this.operacaoItem.ativo = false;
          this.dialogOperacao = true;
        } else if (this.acao_operacao && this.acao_operacao.includes("Criar Lote")) {
          this.escolherCedente = false;
          this.selecionadas.forEach(element => {
            if (element.formaPagamentoTipo == "BOLETO") {
              this.escolherCedente = true;
            }
          });
          if (this.cedentesBoleto.length > 1 && this.escolherCedente) {
            this.operacaoItem.ativo = false;
            this.dialogOperacao = true;
          } else {
            this.operacaoItem.ativo = true;
            this.dialogOperacao = true;
            this.executarOperacaoLote();
          }
        } else if (this.acao_operacao && this.acao_operacao.includes("Cobrar Asaas")) {
          this.operacaoItem.ativo = true;
          this.dialogOperacao = true;
          this.executarOperacaoLote();
        } else {
          //* Como não há o que selecionar, já abre o dialog com o processamento em andamento
          this.operacaoItem.ativo = true;
          this.dialogOperacao = true;
          this.executarOperacaoLote();
        }
      } else if (this.botaoAcaoLote == "cancelar") {
        //* Como não há o que selecionar, já abre o dialog com o processamento em andamento
        this.cancelarItem.retorno = "";
        this.cancelarItem.ativo = true;
        this.dialogCancelar = true;
        this.executarCancelarLote();
      } else if (this.botaoAcaoLote == "credito") {
        this.statusCreditoLote();
      } else if (this.botaoAcaoLote == "visualizar") {
        if (this.acao_visualizar == "Carta-Cobrança") {
          this.cartaCobrancaLote();
        } else if (this.acao_visualizar == "Recibo") {
          this.reciboLote(false);
        } else if (this.acao_visualizar == "Cobrança Carteira") {
          this.cobrancaCarteiraLote();
        } else if (this.acao_visualizar.includes("Boleto")) {
          this.imprimirBoletos();
        }
      } else if (this.botaoAcaoLote == "email") {
        if (this.acao_email == "Carta-Cobrança") {
          this.cartaCobrancaEmail();
        } else if (this.acao_email == "Recibo") {
          this.reciboLote(true);
        } else if (this.acao_email == "Boleto") {
          this.boletoEmail();
        } else if (this.acao_email == "Nota Fiscal") {
          this.nfEmail();
        } else {
          this.boletoNfEmail();
        }
      }
    },

    confirmado() {
      this.dialogConfirm = false;
      this.processarAcaoLote();
    },
    naoConfirmado() {
      this.dialogConfirm = false;
      if (this.botaoAcaoLote) {
        this.acao_alterar = null;
        this.acao_operacao = null;
        this.acao_cancelar = null;
        this.acao_credito = null;
        this.acao_visualizar = null;
        this.acao_email = null;
      }
      this.botaoAcaoLote = null;
    },

    visualizarCliente(item) {
      if (item.origem == "ASS" && this.direitoAssinante) {
        window.open("/assinaturas/assinante/" + item.idCliente, "_blank");
      } else if (this.receber && item.origem == "FIN" && this.direitoCliente) {
        window.open("/financeiro/cliente/" + item.idCliente, "_blank");
      } else if (!this.receber && item.origem == "FIN" && this.direitoFornecedor) {
        window.open("/financeiro/fornecedor/" + item.idCliente, "_blank");
      }
    },
    visualizarOperacao(item) {
      window.open("/financeiro/operacao/" + (this.receber ? "receber/" : "pagar/") + item.idOperacao, "_blank");
    },
    visualizarRenovacao(item) {
      window.open("/assinaturas/assinatura/" + item.idAssinatura + "/" + item.idRenovacao, "_blank");
    },
    visualizarAnuncio(item) {
      if (item.idAnuncio) {
        window.open("/comercial/anuncio/" + item.idAnuncio, "_blank");
      } else {
        window.open("/financeiro/operacao/receber/" + item.id, "_blank");
      }
    },
    visualizarPacote(item) {
      window.open("/comercial/pacote/" + item.idPacote, "_blank");
    },

    visualizarVendaAvulsa(item) {
      window.open("/financeiro/operacao/receber/" + item.idOperacao, "_blank");
    },
    visualizarRecibo(item) {
      this.selecionadas = [];
      this.selecionadas.push(item);
      this.reciboLote(false);
    },

    async visualizarBoleto(item) {
      try {
        if (item.payAsaas) {
          var url = "https://asaas.com/b/pdf/";
          var url2 = "";
          if (this.$store.state.usuario.site_gateway.includes("SANDBOX")) {
            url2 = "https://sandbox.asaas.com/b/pdf/";
            window.open(url2 + item.payAsaas.replace("pay_", ""));
          }
          window.open(url + item.payAsaas.replace("pay_", ""));
        } else {
          var caminhoBoleto = `/${this.idSitePrincipal}/boletos/${item.bancoNumero}/boletos/${item.nossoNumero}.pdf`; //incluir anoMes da emissão (ou criação do lote)
          window.open(process.env.VUE_APP_URL_WEB_API + "fin/arquivo/pdf?caminho=" + caminhoBoleto, "_blank");
        }
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    async visualizarNotaFiscal(item) {
      var caminhoNota = "";
      if (item.idNFe) {
        caminhoNota = `/${this.idSitePrincipal}/notas-fiscais/nfe/notas/${item.anoMesNFe}/NFe${item.chaveNFe}.pdf`;
      } else if (item.idNFSC) {
        caminhoNota = `/${this.idSitePrincipal}/notas-fiscais/nfsc/notas/${item.anoMesNFSC}/${item.numeroNFSC.toString().padStart(6, "0")}.pdf`;
      } else if (item.idNFSe) {
        caminhoNota = (item.urlImpressao.startsWith("http") ? "" : "http://") + item.urlImpressao;
      }

      if (caminhoNota) {
        try {
          window.open(caminhoNota.indexOf("notas-fiscais") > 0 ? process.env.VUE_APP_URL_WEB_API + "fin/arquivo/pdf?caminho=" + caminhoNota : caminhoNota, "_blank");
        } catch (e) {
          this.mensagem =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackErro = true;
        }
      } else {
        this.mensagem = "Nota inválida";
        this.snackErro = true;
      }
    },

    ativarDesativarItem(item) {
      var dadoIndex = this.registros.indexOf(item);
      var dadoItem = Object.assign({}, item);
      dadoItem.selecionado = dadoItem.selecionado == 1 ? 0 : 1;
      Object.assign(this.registros[dadoIndex], dadoItem);

      var vlrTotal = 0;
      var itens = 0;
      this.registros.forEach(element => {
        if (element.selecionado) {
          itens++;
          vlrTotal += element.valor + element.descAcr;
        }
      });
      this.rodape.pageText = this.totalRegistros + " itens = " + util.formatCurrency(this.valorTotal, this.$store.state.usuario.site_pais, true);
      if (itens) {
        this.rodape.pageText += " | " + itens + " selecionado" + (itens > 1 ? "s" : "") + " = " + util.formatCurrency(vlrTotal, this.$store.state.usuario.site_pais, true);
      }
    },

    //* Métodos para controle dos dialogs
    openDesmembrar(item) {
      this.desmembrarIndex = this.registros.indexOf(item);
      this.desmembrarItem = Object.assign({}, item);

      this.desmembrarItem.valorOriginal = parseFloat(this.desmembrarItem.valor) + parseFloat(this.desmembrarItem.descAcr);
      this.desmembrarItem.novoValor = 0;
      this.desmembrarItem.parcelasDesmembrar = 1;
      this.desmembrarItem.ajustarVectos = 1;
      this.desmembrarItem.periodicidade = null;

      this.dtInicioUS = null;

      this.validacaoDesmembrar.valor = false;
      this.validacaoDesmembrar.parcelas = false;
      this.validacaoDesmembrar.periodicidade = false;

      this.dialogDesmembrar = true;
    },
    closeDesmembrar() {
      this.dialogDesmembrar = false;
    },
    async desmembrar() {
      await util.limparObjeto(this.validacaoDesmembrar, false);
      if (!this.$refs.formDesmembrar.validate()) {
        return;
      }
      if (parseFloat(this.desmembrarItem.novoValor) == 0 && parseInt(this.desmembrarItem.parcelasDesmembrar) == 1) {
        this.validacaoDesmembrar.valor = "Informe o valor a desmembrar ou o parcelamento";
        this.validacaoDesmembrar.parcelas = "Informe o valor a desmembrar ou o parcelamento";
        return;
      }

      var vlrRestanteTotal = parseFloat(this.desmembrarItem.valorOriginal) - parseFloat(this.desmembrarItem.novoValor);
      var nroParcelas = parseInt(this.desmembrarItem.parcelasDesmembrar);

      if (vlrRestanteTotal / nroParcelas < 0.01) {
        this.validacaoDesmembrar.parcelas = "O valor restante é incompatível com o número de parcelas";
        return;
      }

      var dtVecto = this.desmembrarItem.dtVecto;

      if (this.dtInicio != null) {
        if (!util.valida_data_futura(util.formatDateUS(this.dtInicio), true)) {
          this.validacaoDesmembrar.dtInicio = "Data inválida";
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
          return;
        }
        dtVecto = util.parseDateUS(this.dtInicioUS);

        var dtLimiteHoje = new Date();
        dtLimiteHoje.setMonth(dtLimiteHoje.getMonth() + 12); //alterado de 3 para 6 meses em 18/08/2022 - alterado de 6 para 12 meses em 08/03/2024
        var dtLimiteMin = util.parseDateUS(this.desmembrarItem.dtVectoOriginal);
        dtLimiteMin.setMonth(dtLimiteMin.getMonth() - 12);
        var dtLimiteMax = util.parseDateUS(this.desmembrarItem.dtVectoOriginal);
        dtLimiteMax.setMonth(dtLimiteMax.getMonth() + 3);
        if (dtVecto < dtLimiteMin || (dtVecto > dtLimiteMax && dtVecto > dtLimiteHoje)) {
          //* Se tentar antecipar a parcela mais de 180 dias ou adiar mais de 90 dias do vencimento original e da data de hoje, não permite
          //* Se tentar adiar mais de 90 dias da data de hoje, mas menos de 90 dias do vencimento original, permite
          this.validacaoDesmembrar.dtInicio = "Alteração não permitida pois ultrapassou o limite";
          return;
        }
      }

      var vlrParcelaAtual = 0;
      if (parseFloat(this.desmembrarItem.novoValor) > 0) {
        //* Se foi informado o valor a desmembrar, valor da parcela atual = novoValor
        vlrParcelaAtual = parseFloat(this.desmembrarItem.novoValor);
      } else {
        //* Se não foi informado o valor a desmembrar, valor da parcela atual = valor atual / número de parcelas (pegará o valor da primeira parcela)
        vlrParcelaAtual = parseFloat(this.desmembrarItem.valorOriginal) / nroParcelas;
        vlrParcelaAtual = vlrParcelaAtual.toFixed(2);
        vlrRestanteTotal = vlrRestanteTotal - parseFloat(vlrParcelaAtual);
        nroParcelas--;
      }

      var parcelas = [
        //* Inclui o novo valor da parcela atual no array (somente id e o valor com o descAcr, uma vez que foi considerado na apresentação), para atualização na base
        {
          id: this.desmembrarItem.id,
          idOperacao: this.desmembrarItem.idOperacao,
          valor: vlrParcelaAtual - this.desmembrarItem.descAcr,
          valorOriginal: this.desmembrarItem.valor,
          dtVecto: util.dateToStringUS(dtVecto)
        }
      ];

      //* Divide o valor restante no número de parcelas indicado
      var vlrRestante = vlrRestanteTotal;
      if (vlrRestanteTotal > 0) {
        for (let index = 1; index <= nroParcelas; index++) {
          var vlr = vlrRestanteTotal / nroParcelas;
          vlr = vlr.toFixed(2);
          if (index == nroParcelas) {
            vlr = vlrRestante;
          } else {
            vlrRestante -= parseFloat(vlr);
            vlrRestante = vlrRestante.toFixed(2);
          }

          if (this.dtInicioUS) {
            if (this.desmembrarItem.periodicidade) {
              dtVecto.setDate(dtVecto.getDate() + parseInt(this.desmembrarItem.periodicidade));
            } else {
              dtVecto.setMonth(dtVecto.getMonth() + 1);
            }
          }

          var parcela = {
            id: null,
            valor: vlr,
            idOperacao: this.desmembrarItem.idOperacao,
            indice: this.desmembrarItem.indice, //* Parcelas desmembradas manterão o mesmo número
            numero: this.desmembrarItem.numero, //* Parcelas desmembradas manterão o mesmo número
            status: this.desmembrarItem.status, //* Só é desmembrada se estiver aberta/bloqueada
            idFormaPgto: this.desmembrarItem.idFormaPgto,
            dtVectoOriginal: this.desmembrarItem.dtVectoOriginal,
            dtVecto: util.dateToStringUS(dtVecto),
            dtPromessa: this.desmembrarItem.dtPromessa,
            dtQuitacao: null,
            idClienteCartao: this.desmembrarItem.idClienteCartao,
            idClienteConta: this.desmembrarItem.idClienteConta,
            idClienteEndereco: this.desmembrarItem.idClienteEndereco,
            idClienteCelesc: this.desmembrarItem.idClienteCelesc,
            descAcr: 0,
            obsCobranca: this.desmembrarItem.obsCobranca,
            ajustada: 0,
            codAntigoCirculacao: this.desmembrarItem.codAntigoCirculacao
          };
          parcelas.push(parcela);
        }
      }

      const desmembrarCommit = {
        parcelas: parcelas
      };
      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "fin/parcela/desmembramento?idSite=" + this.idSitePrincipal, desmembrarCommit);

        this.desmembrarItem.valor = vlrParcelaAtual - this.desmembrarItem.descAcr;
        Object.assign(this.registros[this.desmembrarIndex], this.desmembrarItem);

        this.msgDesmembrar = "Parcela desmembrada com sucesso";
        this.snackDesmembrar = true;

        this.filtroAvancadoFiltrar(false);

        setTimeout(() => {
          this.closeDesmembrar();
        }, 1500);
      } catch (e) {
        this.msgDesmembrar =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackDesmembrar = true;
      }
    },

    async alterarVecto() {
      //* ALTERAR VENCIMENTO
      this.acao_alterar = null;

      await util.limparObjeto(this.validacaoAlterarVecto, false);
      if (!this.$refs.formAlterarVecto.validate()) {
        return;
      }
      this.alterarVectoItem.retorno = "";

      var dtVecto = util.parseDate(this.alterarDtVectoItem);
      var dtLimiteHoje = new Date();
      dtLimiteHoje.setMonth(dtLimiteHoje.getMonth() + 12); //alterado de 3 para 6 meses em 18/08/2022 - alterado de 6 para 12 meses em 08/03/2024

      this.selecionadas.forEach(element => {
        var dtLimiteMin = util.parseDateUS(element.dtVectoOriginal);
        dtLimiteMin.setMonth(dtLimiteMin.getMonth() - 12);
        var dtLimiteMax = util.parseDateUS(element.dtVectoOriginal);
        dtLimiteMax.setMonth(dtLimiteMax.getMonth() + 3);
        if (util.parseDateUS(dtVecto) < dtLimiteMin || (util.parseDateUS(dtVecto) > dtLimiteMax && util.parseDateUS(dtVecto) > dtLimiteHoje)) {
          //* Se tentar antecipar a parcela mais de 1 ano do vencimento original, não permite
          //* Se tentar adiar mais de 1 ano da data de hoje, não permite
          this.alterarVectoItem.retorno += element.id + ": Alteração não permitida pois ultrapassou o limite\r\n";
        }

        if (element.idLoteFaturamento) {
          if (!element.remessa || element.remessa == "SOLICITADA") {
            //* Se parcela está em lote ainda não CRIADO, não permite alterar - tem que gerar a remessa ou excluir o lote
            this.alterarVectoItem.retorno += element.id + ": Alteração não permitida até a geração da remessa (ou exclusão do lote)\r\n";
          }
        }
        if (element.statusCobranca == 30) {
          //* Se a cobrança da parcela está como cancelamento, não permite alterar - tem que aguardar o retorno: CANCELADA ou ERRO de cancelamento
          this.alterarVectoItem.retorno += element.id + ": Alteração não permitida até a confirmação do cancelamento no banco\r\n";
        }
        if (element.formaPagamentoTipo == "DEBITO_CONTA" && element.idLoteFaturamento && element.statusCobranca != 40) {
          dtLimiteMin = new Date();
          dtLimiteMin.setDate(dtLimiteMin.getDate() - 1);
          dtLimiteMin = util.parseDateUS(dtLimiteMin);
          dtLimiteMax = new Date();
          dtLimiteMax.setDate(dtLimiteMax.getDate() + 4);
          dtLimiteMax = util.parseDateUS(dtLimiteMax);

          //Não deixar alterar se está a 5 dias do vencimento e até 2 dias após (ag. retorno do banco)
          //exemplo: hoje=17/02, não deixa alterar parcelas com vecto em 16, [17] ,18,19,20,21
          if (util.parseDateUS(element.dtVecto) >= dtLimiteMin && util.parseDateUS(element.dtVecto) <= dtLimiteMax) {
            //* Se parcela está em lote de débito em conta sem erro e não dá tempo de cancelar (parcela está a 5 dias do vencimento ou venceu a menos de 2 dias), não permite alterar - tem que aguardar o retorno: QUITADA ou ERRO
            this.alterarVectoItem.retorno += element.id + ": Alteração não permitida até o retorno do banco\r\n";
          }
        }

        if (element.formaPagamentoTipo == "BOLETO" && element.idLoteFaturamento && element.statusCobranca != 40) {
          dtLimiteMin = new Date();
          dtLimiteMin.setDate(dtLimiteMin.getDate() - 1);
          dtLimiteMin = util.parseDateUS(dtLimiteMin);
          dtLimiteMax = new Date();
          dtLimiteMax = util.parseDateUS(dtLimiteMax);

          //LIBERADO em 10/09/2024, para o usuário poder alterar o vencimento do boleto independentemente - pois mesmo se o cliente já pagou o boleto, não tem problema, pois alteração mantém o nossoNumero
          //Não deixar alterar se está no dia do vencimento e até 2 dias após (ag. retorno do banco)
          //exemplo: hoje=17/02, não deixa alterar parcelas com vecto em 16, [17]
          // if (util.parseDateUS(element.dtVecto) >= dtLimiteMin && util.parseDateUS(element.dtVecto) <= dtLimiteMax) {
          //   //* Se parcela está em lote de boleto sem erro e não dá tempo de cancelar (parcela está no dia do vencimento ou venceu a menos de 2 dias), não permite alterar - tem que aguardar o retorno: QUITADA ou ERRO
          //   this.alterarVectoItem.retorno += element.id + ": Alteração não permitida até o retorno do banco\r\n";
          // }
        }

        if (element.formaPagamentoTipo == "CELESC" && element.idLoteFaturamento && element.statusCobranca != 40) {
          //* Se parcela está em lote celesc sem erro, não permite alterar - tem que aguardar o retorno: QUITADA ou ERRO
          this.alterarVectoItem.retorno += element.id + ": Alteração não permitida até o retorno da Celesc\r\n";
        }

        if (element.parcAsaas) {
          //* Não deveria cair aqui, pois parcela em parcelamento já deveria estar QUITADA
          this.alterarVectoItem.retorno += element.id + ": Alteração não permitida pois parcela encontra-se em parcelamento\r\n";
        }
      });

      if (this.alterarVectoItem.retorno) {
        this.validacaoAlterarVecto.retorno = "Erros encontrados - remova as parcelas indicadas ou ajuste a data de vencimento.";
        this.$refs.formAlterarVecto.validate();
        return;
      }

      this.alterarVectoItem.ativo = true;

      var parcelas = [];
      this.selecionadas.forEach(element => {
        var parcela = {
          id: element.id
        };
        parcelas.push(parcela);
      });

      const vencimentosCommit = {
        dtVecto: dtVecto,
        parcelas: parcelas
      };
      try {
        const retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "fin/parcela/vencimentos", vencimentosCommit);

        this.selecionadas.forEach(element => {
          var dadoIndex = this.registros.indexOf(element);
          var dadoItem = Object.assign({}, element);
          dadoItem.dtVecto = dtVecto;
          if (element.idLoteFaturamento && (element.formaPagamentoTipo == "DEBITO_CONTA" || element.formaPagamentoTipo == "BOLETO")) {
            if (util.dateToStringUS(element.dtVecto) != dtVecto) {
              if (element.statusCobranca == 40) {
                //* Se status é erro, não precisa reenviar ao banco
                element.statusCobranca = 0;
                element.nossoNumero = "";
                element.idLoteFaturamento = "";
              } else {
                //* Se vencimento foi alterado
                if (element.formaPagamentoTipo == "DEBITO_CONTA") {
                  //* Se for débito em conta altera o status da cobrança para que seja cancelada no banco
                  element.statusCobranca = 30;
                } else {
                  //* Se for boleto altera o status da cobrança para que seja alterada no banco
                  element.statusCobranca = 20;
                }
              }
            }
          }

          dadoItem.statusCobranca = element.statusCobranca;
          dadoItem.nossoNumero = element.nossoNumero;
          dadoItem.idLoteFaturamento = element.idLoteFaturamento;
          Object.assign(this.registros[dadoIndex], dadoItem);
        });

        this.alterarVectoItem.retorno = "Vencimentos das parcelas alterados com sucesso\r\n\r\n";
        if (retorno.data.ids) {
          this.alterarVectoItem.retorno += "Parcelas com mesmo NOSSO NÚMERO também foram alteradas: " + retorno.data.ids;
          this.alterarVectoItem.ativo = false;
        } else {
          setTimeout(() => {
            this.closeAlterarVecto();
          }, 2500);
        }
      } catch (e) {
        this.alterarVectoItem.retorno =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");

        await util.limparObjeto(this.validacaoAlterarVecto, false);
        this.validacaoAlterarVecto.retorno = "Ocorreram erros no processamento";
        this.$refs.formAlterarVecto.validate();
        this.alterarVectoItem.ativo = false;
      }
    },
    closeAlterarVecto() {
      this.dialogAlterarVecto = false;
      this.acao_alterar = null;
      this.alterarDtVectoItemUS = null;
    },

    async alterarFormaPgto() {
      this.acao_alterar = null;

      await util.limparObjeto(this.validacaoAlterarFormaPgto, false);
      if (!this.$refs.formAlterarFormaPgto.validate()) {
        return;
      }
      this.alterarFormaPgtoItem.retorno = "";

      //* Confere consistência das formas de pagamento
      const processar = this.selecionadas.map(async element => {
        if (util.isFormaPgtoEspeciais(element.formaPagamentoTipo)) {
          //* pois geraram o registro de Receita em local específico
          if (this.formaPagamentoSelecionada.tipo != element.formaPagamentoTipo) {
            this.alterarFormaPgtoItem.retorno += element.id + ": " + element.formaPagamentoTipo + " - alteração não permitida\r\n";
          }
        }
        if (element.idFormaPgto == this.formaPagamentoSelecionada.id) {
          this.alterarFormaPgtoItem.retorno += element.id + ": " + element.formaPagamentoTipo + " - parcela já está nesta forma\r\n";
        }
      });
      await Promise.all(processar);

      if (!this.alterarFormaPgtoItem.retorno) {
        this.selecionadas.forEach(element => {
          if (element.idLoteFaturamento) {
            if (!element.remessa) {
              //* Se parcela está em lote ainda não gerado, não permite alterar - tem que gerar a remessa ou excluir o lote
              this.alterarFormaPgtoItem.retorno += element.id + ": Alteração não permitida até a geração da remessa (ou exclusão do lote)\r\n";
            }
          }
          if (element.statusCobranca == 30) {
            //* Se a cobrança da parcela está como cancelamento, não permite alterar - tem que aguardar o retorno: CANCELADA ou ERRO de cancelamento
            this.alterarFormaPgtoItem.retorno += element.id + ": Alteração não permitida até a confirmação do cancelamento no banco\r\n";
          }

          if (element.formaPagamentoTipo == "DEBITO_CONTA" && element.idLoteFaturamento && element.statusCobranca != 40) {
            this.alterarFormaPgtoItem.retorno += element.id + ": Alteração não permitida - cancele o Débito em Conta antes\r\n";
          }

          if (element.formaPagamentoTipo == "BOLETO" && element.idLoteFaturamento && element.statusCobranca != 40) {
            this.alterarFormaPgtoItem.retorno += element.id + ": Alteração não permitida - cancele o Boleto antes\r\n";
          }

          if (element.formaPagamentoTipo == "CELESC" && element.idLoteFaturamento && element.statusCobranca != 40) {
            //* Se parcela está em lote celesc sem erro, não permite alterar - tem que aguardar o retorno: QUITADA ou ERRO
            this.alterarFormaPgtoItem.retorno += element.id + ": Alteração não permitida até o retorno da Celesc\r\n";
          }

          if (element.parcAsaas) {
            //* Não deveria cair aqui, pois parcela em parcelamento já deveria estar QUITADA
            this.alterarFormaPgtoItem.retorno += element.id + ": Alteração não permitida pois parcela encontra-se em parcelamento\r\n";
          }
        });
      }

      if (this.alterarFormaPgtoItem.retorno) {
        this.validacaoAlterarFormaPgto.retorno = "Erros encontrados - remova as parcelas indicadas ou ajuste a forma de pagamento.";
        this.$refs.formAlterarFormaPgto.validate();
        return;
      }

      this.alterarFormaPgtoItem.ativo = true;
      this.alterarFormaPgtoItem.processado = true;

      var parcelas = [];
      this.selecionadas.forEach(element => {
        var parcela = {
          id: element.id,
          idCliente: element.idCliente
        };
        parcelas.push(parcela);
      });

      const formasPgtoCommit = {
        idFormaPgto: this.formaPagamentoSelecionada.id,
        tipo: this.formaPagamentoSelecionada.tipo,
        parcelas: parcelas
      };
      try {
        const retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "fin/parcela/formas-pagamento", formasPgtoCommit);

        this.selecionadas.forEach(element => {
          var dadoIndex = this.registros.indexOf(element);
          var dadoItem = Object.assign({}, element);
          if (element.idLoteFaturamento && (element.formaPagamentoTipo == "DEBITO_CONTA" || element.formaPagamentoTipo == "BOLETO")) {
            if (element.statusCobranca == 40) {
              //* Se status é erro, não precisa reenviar ao banco
              element.statusCobranca = 0;
              element.nossoNumero = "";
              element.idLoteFaturamento = "";
            } else {
              //* Altera o status da cobrança para que seja cancelada no banco
              element.statusCobranca = 30;
            }
          }

          dadoItem.idFormaPgto = this.formaPagamentoSelecionada.id;
          dadoItem.formaPagamento = this.formaPagamentoSelecionada.nome;
          dadoItem.formaPagamentoTipo = this.formaPagamentoSelecionada.tipo;
          dadoItem.statusCobranca = element.statusCobranca;
          dadoItem.nossoNumero = element.nossoNumero;
          dadoItem.idLoteFaturamento = element.idLoteFaturamento;

          Object.assign(this.registros[dadoIndex], dadoItem);
        });

        this.alterarFormaPgtoItem.retorno = "Formas de pagamento das parcelas alteradas com sucesso\r\n\r\n";
        if (retorno.data.ids) {
          this.alterarFormaPgtoItem.retorno += "Parcelas com mesmo NOSSO NÚMERO também foram alteradas: " + retorno.data.ids;
          this.alterarFormaPgtoItem.ativo = false;
          this.alterarFormaPgtoItem.processado = true;
        } else {
          setTimeout(() => {
            this.closeAlterarFormaPgto();
          }, 2500);
        }
      } catch (e) {
        this.alterarFormaPgtoItem.retorno =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");

        await util.limparObjeto(this.validacaoAlterarFormaPgto, false);
        this.validacaoAlterarFormaPgto.retorno = "Ocorreram erros no processamento";
        this.$refs.formAlterarFormaPgto.validate();
        this.alterarFormaPgtoItem.ativo = false;
        this.alterarFormaPgtoItem.processado = true;
      }
    },
    closeAlterarFormaPgto() {
      this.dialogAlterarFormaPgto = false;
      this.acao_alterar = null;
    },
    async carregarContaPadrao() {
      this.contaSelecionada = {};
      var formas = "";
      this.selecionadas.forEach(element => {
        if (!formas.includes(element.idFormaPgto + ",")) {
          formas += element.idFormaPgto + ",";
        }
      });
      //* Busca as contas padrão para quitação das formas de pagamento das parcelas selecionadas
      const responsePadrao = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/conta/formas?formasPgto=" + formas);
      if (responsePadrao.data) {
        //* Se localizou somente 1 conta, define para quitação
        if (responsePadrao.data.length == 1) {
          this.contasMovimento.forEach(element => {
            if (responsePadrao.data[0].id == element.id) {
              this.contaSelecionada = element;
            }
          });
        } else if (responsePadrao.data.length == 0) {
          //* Se não há contas vinculadas, define como a primeira (Caixa)
          this.contaSelecionada = this.contasMovimento[0];
        }
      }
      //* Se localizou mais de uma conta, ou deu erro, deixa vazio para usuário selecionar
    },

    async alterarValor() {
      //* ALTERAR VALOR
      this.acao_alterar = null;

      await util.limparObjeto(this.validacaoAlterarValor, false);
      if (!this.$refs.formAlterarValor.validate()) {
        return;
      }
      this.alterarValorItem.retorno = "";

      var dtLimiteMin = null;
      var dtLimiteMax = null;

      this.selecionadas.forEach(element => {
        if (element.idLoteFaturamento) {
          if (!element.remessa || element.remessa == "SOLICITADA") {
            //* Se parcela está em lote ainda não CRIADO, não permite alterar - tem que gerar a remessa ou excluir o lote
            this.alterarValorItem.retorno += element.id + ": Alteração não permitida até a geração da remessa (ou exclusão do lote)\r\n";
          }
        }
        if (element.statusCobranca == 30) {
          //* Se a cobrança da parcela está como cancelamento, não permite alterar - tem que aguardar o retorno: CANCELADA ou ERRO de cancelamento
          this.alterarValorItem.retorno += element.id + ": Alteração não permitida até a confirmação do cancelamento no banco\r\n";
        }
        if (element.formaPagamentoTipo == "DEBITO_CONTA" && element.idLoteFaturamento && element.statusCobranca != 40) {
          dtLimiteMin = new Date();
          dtLimiteMin.setDate(dtLimiteMin.getDate() - 1);
          dtLimiteMin = util.parseDateUS(dtLimiteMin);
          dtLimiteMax = new Date();
          dtLimiteMax.setDate(dtLimiteMax.getDate() + 4);
          dtLimiteMax = util.parseDateUS(dtLimiteMax);

          //Não deixar alterar se está a 5 dias do vencimento e até 2 dias após (ag. retorno do banco)
          //exemplo: hoje=17/02, não deixa alterar parcelas com vecto em 16, [17] ,18,19,20,21
          if (util.parseDateUS(element.dtVecto) >= dtLimiteMin && util.parseDateUS(element.dtVecto) <= dtLimiteMax) {
            //* Se parcela está em lote de débito em conta sem erro e não dá tempo de cancelar (parcela está a 5 dias do vencimento ou venceu a menos de 2 dias), não permite alterar - tem que aguardar o retorno: QUITADA ou ERRO
            this.alterarValorItem.retorno += element.id + ": Alteração não permitida até o retorno do banco\r\n";
          }
        }

        if (element.formaPagamentoTipo == "BOLETO" && element.idLoteFaturamento && element.statusCobranca != 40) {
          dtLimiteMin = new Date();
          dtLimiteMin.setDate(dtLimiteMin.getDate() - 1);
          dtLimiteMin = util.parseDateUS(dtLimiteMin);
          dtLimiteMax = new Date();
          dtLimiteMax = util.parseDateUS(dtLimiteMax);

          //Não deixar alterar se está no dia do vencimento e até 2 dias após (ag. retorno do banco)
          //exemplo: hoje=17/02, não deixa alterar parcelas com vecto em 16, [17]
          if (util.parseDateUS(element.dtVecto) >= dtLimiteMin && util.parseDateUS(element.dtVecto) <= dtLimiteMax) {
            //* Se parcela está em lote de boleto sem erro e não dá tempo de cancelar (parcela está no dia do vencimento ou venceu a menos de 2 dias), não permite alterar - tem que aguardar o retorno: QUITADA ou ERRO
            this.alterarValorItem.retorno += element.id + ": Alteração não permitida até o retorno do banco\r\n";
          }
        }

        if (element.formaPagamentoTipo == "CELESC" && element.idLoteFaturamento && element.statusCobranca != 40) {
          //* Se parcela está em lote celesc sem erro, não permite alterar - tem que aguardar o retorno: QUITADA ou ERRO
          this.alterarValorItem.retorno += element.id + ": Alteração não permitida até o retorno da Celesc\r\n";
        }
      });

      if (this.alterarValorItem.retorno) {
        this.validacaoAlterarValor.retorno = "Erros encontrados - remova as parcelas indicadas ou ajuste o valor.";
        this.$refs.formAlterarValor.validate();
        return;
      }

      this.alterarValorItem.ativo = true;

      var parcelas = [];
      this.selecionadas.forEach(element => {
        var parcela = {
          id: element.id
        };
        parcelas.push(parcela);
      });

      const valoresCommit = {
        valor: this.alterarValorItem.valor,
        parcelas: parcelas
      };
      try {
        const retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "fin/parcela/valores", valoresCommit);

        this.selecionadas.forEach(element => {
          var dadoIndex = this.registros.indexOf(element);
          var dadoItem = Object.assign({}, element);
          dadoItem.descAcr = this.alterarValorItem.valor - dadoItem.valor;
          if (element.idLoteFaturamento && (element.formaPagamentoTipo == "DEBITO_CONTA" || element.formaPagamentoTipo == "BOLETO")) {
            if (element.valor + element.descAcr != dadoItem.valor) {
              if (element.statusCobranca == 40) {
                //* Se status é erro, não precisa reenviar ao banco
                element.statusCobranca = 0;
                element.nossoNumero = "";
                element.idLoteFaturamento = "";
              } else {
                //* Se valor foi alterado, altera o status da cobrança para que seja cancelado no banco
                element.statusCobranca = 30;
              }
            }
          }

          dadoItem.statusCobranca = element.statusCobranca;
          dadoItem.nossoNumero = element.nossoNumero;
          dadoItem.idLoteFaturamento = element.idLoteFaturamento;
          Object.assign(this.registros[dadoIndex], dadoItem);
        });

        this.alterarValorItem.retorno = "Valores das parcelas alterados com sucesso\r\n\r\n";
        if (retorno.data && retorno.data.ids) {
          this.alterarValorItem.retorno += "Parcelas com mesmo NOSSO NÚMERO também foram alteradas: " + retorno.data.ids;
          this.alterarValorItem.ativo = false;
        } else {
          setTimeout(() => {
            this.closeAlterarValor();
          }, 2500);
        }
      } catch (e) {
        this.alterarValorItem.retorno =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");

        await util.limparObjeto(this.validacaoAlterarValor, false);
        this.validacaoAlterarValor.retorno = "Ocorreram erros no processamento";
        this.$refs.formAlterarValor.validate();
        this.alterarValorItem.ativo = false;
      }
    },
    closeAlterarValor() {
      this.dialogAlterarValor = false;
      this.acao_alterar = null;
    },

    async alterarTextoFatura() {
      //* ALTERAR TEXTO PARA FATURA
      this.acao_alterar = null;

      await util.limparObjeto(this.validacaoAlterarTextoFatura, false);
      if (!this.$refs.formAlterarTextoFatura.validate()) {
        return;
      }
      this.alterarTextoFaturaItem.retorno = "";

      this.alterarTextoFaturaItem.ativo = true;

      var parcelas = [];
      this.selecionadas.forEach(element => {
        var parcela = {
          id: element.id
        };
        parcelas.push(parcela);
      });

      const valoresCommit = {
        obsCobranca: this.alterarTextoFaturaItem.obsCobranca,
        parcelas: parcelas
      };
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "fin/parcela/obsCobranca", valoresCommit);

        this.alterarTextoFaturaItem.retorno = "Texto para Fatura das parcelas alterados com sucesso\r\n\r\n";
        setTimeout(() => {
          this.closeAlterarTextoFatura();
        }, 2500);
      } catch (e) {
        this.alterarTextoFaturaItem.retorno =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");

        await util.limparObjeto(this.validacaoAlterarTextoFatura, false);
        this.validacaoAlterarTextoFatura.retorno = "Ocorreram erros no processamento";
        this.$refs.formAlterarTextoFatura.validate();
        this.alterarTextoFaturaItem.ativo = false;
      }
    },
    closeAlterarTextoFatura() {
      this.dialogAlterarTextoFatura = false;
      this.acao_alterar = null;
    },

    async executarOperacaoLote() {
      if (this.acao_operacao == "Quitar" || this.acao_operacao == "Bloquear" || this.acao_operacao == "Cancelar") {
        if (!this.$refs.formOperacao.validate()) {
          return;
        }
      } else if (this.acao_operacao && this.acao_operacao.includes("Criar Lote") && this.cedentesBoleto.length > 1 && this.escolherCedente) {
        if (!this.$refs.formOperacao.validate()) {
          return;
        }
      }

      this.operacaoItem.ativo = true;
      this.executando = true;
      if (this.acao_operacao && this.acao_operacao.includes("Criar Lote")) {
        //* Criação de Lote

        var cedentes = [];
        if (this.cedenteBoletoSelecionado && this.cedenteBoletoSelecionado.id == 99) {
          //* Se cedente selecionado é o Automático, inclui todos os cedentes de boleto para processar as parcelas
          this.cedentesBoleto.forEach(element => {
            if (element.id != 99 && (element.origens || element.idFormaPgto)) {
              cedentes.push(element);
            }
          });
        } else {
          //* Senão, processa parcelas somente para o cedente selecionado
          cedentes.push(this.cedenteBoletoSelecionado);
        }

        var erro = false;
        for (let index = 0; index < cedentes.length; index++) {
          const cedente = cedentes[index];

          var ids = "";
          this.selecionadas.forEach(element => {
            if (this.cedenteBoletoSelecionado && this.cedenteBoletoSelecionado.id == 99) {
              //* Se for cedente automático, só inclui se a parcela for da origem e/ou forma de pagamento do cedente
              if ((!cedente.origens || cedente.origens.includes(element.origem)) && (!cedente.idFormaPgto || cedente.idFormaPgto == element.idFormaPgto)) {
                ids += (ids ? "," : "") + element.id;
              }
            } else {
              ids += (ids ? "," : "") + element.id;
            }
          });

          if (!ids) {
            continue;
          }

          const loteCommit = {
            idSite: this.idSitePrincipal,
            tipo: 1,
            individual: this.acao_operacao && this.acao_operacao.includes("por Parcela") ? 1 : 0,
            pagamento: this.receber ? 0 : 1,
            origem: 0,
            idCedenteBoleto: cedente ? cedente.id : null,
            parcelas: ids
          };

          try {
            if (this.receber) {
              await api.post(process.env.VUE_APP_URL_WEB_API + "fin/lote-faturamento?idSite=" + this.idSitePrincipal, loteCommit);
            } else {
              await api.post(process.env.VUE_APP_URL_WEB_API + "fin/lote-faturamento/pagar?idSite=" + this.idSitePrincipal, loteCommit);
            }
          } catch (e) {
            erro = true;
            if (e.toString().includes("status code 400")) {
              this.operacaoItem.retorno = "SESSÃO EXPIRADA";
            } else if (e.response && e.response.data) {
              this.operacaoItem.retorno = "ERRO:\r\n";
              e.response.data.forEach(element => {
                this.operacaoItem.retorno += element.mensagem + "\r\n";
              });
            } else {
              this.operacaoItem.retorno = "Ocorreu um erro indeterminado, verifique sua conexão";
            }
            await util.limparObjeto(this.validacaoOperacao, false);
            this.$refs.formOperacao.validate();
            this.operacaoItem.ativo = false;
            this.validacaoOperacao.retorno = "Ocorreram erros no processamento";
          }
          if (erro) {
            break;
          }
        }

        if (!erro) {
          this.selecionadas.forEach(element => {
            var dadoIndex = this.registros.indexOf(element);
            var dadoItem = Object.assign({}, element);
            dadoItem.statusCobranca = 10;
            dadoItem.remessa = "";
            dadoItem.idLoteFaturamento = -1;
            Object.assign(this.registros[dadoIndex], dadoItem);
          });

          this.operacaoItem.retorno = "Lote(s) criado(s) com sucesso";

          setTimeout(() => {
            this.closeOperacao();
          }, 1500);
        }
      } else if (this.acao_operacao && this.acao_operacao.includes("Cobrar Asaas")) {
        //* Cobrança Boleto/Pix Asaas

        var ids = "";
        this.selecionadas.forEach(element => {
          //* Só inclui se a parcela for BOLETO ou PIX
          if (["BOLETO", "PIX"].includes(element.formaPagamentoTipo)) {
            ids += (ids ? "," : "") + element.id;
          }
        });

        if (!ids) {
          return;
        }

        const loteCommit = {
          parcelas: ids
        };

        try {
          await api.post(process.env.VUE_APP_URL_WEB_API + "fin/lote-faturamento/asaas/?idSite=" + this.idSitePrincipal, loteCommit);
        } catch (e) {
          erro = true;
          if (e.toString().includes("status code 400")) {
            this.operacaoItem.retorno = "SESSÃO EXPIRADA";
          } else if (e.response && e.response.data) {
            this.operacaoItem.retorno = "ERRO:\r\n";
            e.response.data.forEach(element => {
              this.operacaoItem.retorno += element.mensagem + "\r\n";
            });
          } else {
            this.operacaoItem.retorno = "Ocorreu um erro indeterminado, verifique sua conexão";
          }
          await util.limparObjeto(this.validacaoOperacao, false);
          this.$refs.formOperacao.validate();
          this.operacaoItem.ativo = false;
          this.validacaoOperacao.retorno = "Ocorreram erros no processamento";
        }

        if (!erro) {
          this.selecionadas.forEach(element => {
            var dadoIndex = this.registros.indexOf(element);
            var dadoItem = Object.assign({}, element);
            dadoItem.payAsaas = "enviada";
            Object.assign(this.registros[dadoIndex], dadoItem);
          });

          this.operacaoItem.retorno = "Parcela(s) enviadas ao Asaas com sucesso";

          setTimeout(() => {
            this.closeOperacao();
          }, 1500);
        }
      } else if (this.acao_operacao && this.acao_operacao.includes("Criar N")) {
        //* Criação de Nota Fiscal
        var idsNF = "";
        this.selecionadas.forEach(element => {
          idsNF += (idsNF ? "," : "") + element.id;
        });

        const notaCommit = {
          idSite: this.idSitePrincipal,
          agrupamento: this.acao_operacao && this.acao_operacao.includes("por Cliente") ? 2 : 0,
          rf: this.acao_operacao.includes("Remessa Futura") ? 1 : 0,
          parcelas: idsNF
        };

        try {
          await api.post(process.env.VUE_APP_URL_WEB_API + "fin/nota-fiscal?idSite=" + this.idSitePrincipal, notaCommit);
          this.operacaoItem.retorno = "Nota(s) Fiscal(is) criada(s) com sucesso";

          setTimeout(() => {
            this.closeOperacao();
          }, 1500);
        } catch (e) {
          if (e.toString().includes("status code 400")) {
            this.operacaoItem.retorno = "SESSÃO EXPIRADA";
          } else if (e.response && e.response.data) {
            this.operacaoItem.retorno = "ERRO:\r\n";
            e.response.data.forEach(element => {
              this.operacaoItem.retorno += element.mensagem + "\r\n";
            });
          } else {
            this.operacaoItem.retorno = "Ocorreu um erro indeterminado, verifique sua conexão";
          }
          await util.limparObjeto(this.validacaoOperacao, false);
          this.$refs.formOperacao.validate();
          this.operacaoItem.ativo = false;
          this.validacaoOperacao.retorno = "Ocorreram erros no processamento";
        }
      } else if (this.acao_operacao == "Cobrar Cartão") {
        setTimeout(() => {
          this.closeOperacao();
          this.cobrarCartao();
        }, 1500);
      } else if (this.acao_operacao && this.acao_operacao.includes("Carnê")) {
        setTimeout(() => {
          this.closeOperacao();
          this.gerarTxt();
        }, 1500);
      } else {
        //* Alteração de Status

        this.operacaoItem.retorno = "";
        this.operacaoItem.ativo = false;

        //bloquear, não pode se débito com vencimento amanhã  - senão, incluir em lote de cancelamento

        var parcelas = [];
        this.selecionadas.forEach(element => {
          var parcela = {
            id: element.id
          };
          if (element.idLoteFaturamento) {
            //* Se parcela está sendo bloqueada e for boleto ou débito em conta altera o status da cobrança para que seja cancelada no banco
            parcela.statusCobranca = 30;
            element.statusCobranca = 30;
          }
          parcelas.push(parcela);
        });

        const status = this.acao_operacao == "Cancelar" ? "CANCELADA" : this.acao_operacao == "Quitar" ? "QUITADA" : "BLOQUEADA";

        var operacaoCommit = {
          status: status,
          statusReverso: "ABERTA",
          parcelas: parcelas
        };

        if (this.acao_operacao == "Quitar") {
          operacaoCommit.dtQuitacao = this.operacaoItemdtQuitacaoUS;
        }
        if (this.acao_operacao == "Quitar" && this.contaSelecionada.id) {
          operacaoCommit.idConta = this.contaSelecionada.id;
        }
        if ((this.acao_operacao == "Bloquear" || this.acao_operacao == "Cancelar") && this.idMotivoParcela.id) {
          operacaoCommit.motivo = this.idMotivoParcela.nome;
        }
        try {
          const retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "fin/parcela/operacoes", operacaoCommit);

          this.selecionadas.forEach(element => {
            var dadoIndex = this.registros.indexOf(element);
            var dadoItem = Object.assign({}, element);
            dadoItem.status = status;
            if (this.acao_operacao == "Bloquear") {
              dadoItem.dtBloqueio = new Date();
            }
            if (this.acao_operacao == "Quitar") {
              //parseDateUS para remover o horário da data selecionada
              dadoItem.dtQuitacao = util.parseDateUS(this.operacaoItemdtQuitacaoUS);
            }
            if (this.acao_operacao == "Cancelar") {
              dadoItem.dtCancelamento = new Date();
            }
            Object.assign(this.registros[dadoIndex], dadoItem);
          });

          this.operacaoItem.retorno = "Status das parcelas alterados com sucesso\r\n\r\n";
          if (retorno.data.ids) {
            this.operacaoItem.retorno += "Parcelas com mesmo NOSSO NÚMERO também foram alteradas: " + retorno.data.ids;
            this.operacaoItem.ativo = false;
          } else {
            setTimeout(() => {
              this.closeOperacao();
            }, 2500);
          }
        } catch (e) {
          this.operacaoItem.retorno =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");

          await util.limparObjeto(this.validacaoOperacao, false);
          this.validacaoOperacao.retorno = "Ocorreram erros no processamento";
          this.$refs.formOperacao.validate();
          this.executando = false;
          this.operacaoItem.ativo = false;
        }
      }
    },
    closeOperacao() {
      this.executando = false;
      this.dialogOperacao = false;
      this.acao_operacao = null;
    },

    async executarCancelarLote() {
      this.cancelarItem.ativo = true;

      //* a princípio, um boleto/débito em conta é cancelado quando seu valor (ou vencimento dc) é alterado ou quando deve ser cobrado por outra forma

      var parcelas = [];
      this.selecionadas.forEach(element => {
        parcelas.push({
          id: element.id
        });
      });

      const statusReverso = this.acao_cancelar == "Cancelamento" ? "CANCELADA" : this.acao_cancelar == "Quitação" ? "QUITADA" : "BLOQUEADA";

      const cancelarCommit = {
        operacao: { tipo: this.receber ? 1 : 0 },
        status: "ABERTA",
        statusReverso: statusReverso,
        parcelas: parcelas
      };

      try {
        const retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "fin/parcela/operacoes", cancelarCommit);

        this.selecionadas.forEach(element => {
          var dadoIndex = this.registros.indexOf(element);
          var dadoItem = Object.assign({}, element);
          dadoItem.status = "ABERTA";
          dadoItem.dtBloqueio = null;
          dadoItem.dtQuitacao = null;
          dadoItem.dtCancelamento = null;
          Object.assign(this.registros[dadoIndex], dadoItem);
        });

        this.cancelarItem.retorno = "Status das parcelas revertidos com sucesso\r\n\r\n";
        if (retorno.data.ids) {
          this.cancelarItem.retorno += "Parcelas com mesmo NOSSO NÚMERO também foram alteradas: " + retorno.data.ids;
          this.cancelarItem.ativo = false;
        } else {
          setTimeout(() => {
            this.closeCancelar();
          }, 1500);
        }
      } catch (e) {
        this.cancelarItem.retorno =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");

        await util.limparObjeto(this.validacaoCancelar, false);
        this.validacaoCancelar.retorno = "Ocorreram erros no processamento";
        this.$refs.formCancelar.validate();
        this.cancelarItem.ativo = false;
      }
    },
    closeCancelar() {
      this.dialogCancelar = false;
      this.acao_cancelar = null;
    },

    async statusCreditoLote() {
      var clientes = "";
      this.selecionadas.forEach(element => {
        clientes += (clientes ? "," : "") + element.idCliente;
      });

      var statusCredito = this.acao_credito == "Bloquear" ? 0 : this.acao_credito == "Liberar" ? 1 : 2;
      const creditoCommit = {
        clientes: clientes
      };

      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente/status-creditos/" + statusCredito, creditoCommit);

        this.mensagem = "Status dos clientes alterados com sucesso";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
      this.acao_credito = null;
    },

    async cartaCobrancaLote() {
      var clientes = "";
      this.selecionadas.forEach(element => {
        clientes += (clientes ? "," : "") + element.idCliente;
      });

      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente/cartas-cobranca/" + this.idSitePrincipal + "?clientes=" + clientes);

        util.imprimir(response.data);
      } catch (e) {
        this.mensagem = "ERRO: " + e.response.data;
        this.snackErro = true;
      } finally {
        this.acao_visualizar = null;
      }
    },

    async reciboLote(email) {
      var parcelas = "";
      this.selecionadas.forEach(element => {
        parcelas += (parcelas ? "," : "") + element.id;
      });

      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/recibos/" + this.idSitePrincipal + "?parcelas=" + parcelas + (email ? "&email=1" : ""));

        if (!email) {
          util.imprimir(response.data);
        } else {
          this.mensagem = "Recibos direcionados para envio por e-mail";
          this.snackbar = true;
        }
      } catch (e) {
        this.mensagem = "ERRO: " + e.response.data;
        this.snackErro = true;
      } finally {
        if (email) {
          this.acao_email = null;
        } else {
          this.acao_visualizar = null;
        }
      }
    },

    async cobrancaCarteiraLote() {
      var parcelas = "";
      this.selecionadas.forEach(element => {
        parcelas += (parcelas ? "," : "") + element.id;
      });

      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/carteira/" + this.idSitePrincipal + "?parcelas=" + parcelas);

        util.imprimir(response.data);
      } catch (e) {
        this.mensagem = "ERRO: " + e.response.data;
        this.snackErro = true;
      } finally {
        this.acao_visualizar = null;
      }
    },

    async imprimirBoletos() {
      var cabecalho = `${this.$store.state.usuario.id}|${this.acao_visualizar.includes("3") ? "3pp" : this.acao_visualizar.includes("2") ? "2pp" : "1pp"};`;
      var arquivos = "";
      var banco = null;
      var formato = null;
      var erro = false;
      var nnros = [];
      var dtRemessa = null;

      this.selecionadas.forEach(element => {
        if (!nnros.includes(element.nossoNumero)) {
          if (banco && banco != element.bancoNumero) {
            erro = true;
          }
          if (formato && formato != element.cedenteFormato) {
            erro = true;
          }
          banco = element.bancoNumero;
          formato = element.cedenteFormato;
          arquivos += `${element.bancoNumero}/boletos/${element.nossoNumero}.html;`;
          nnros.push(element.nossoNumero);
          if (!dtRemessa) {
            dtRemessa = element.dtRemessa;
          }
        }
      });

      if (erro) {
        this.mensagem = "Selecione boletos de um mesmo banco e mesmo formato";
        this.snackErro = true;
        return;
      }

      //junta os pdfs e apresenta
      this.mensagem = "Juntando boletos. Aguarde...";
      this.snackbar = true;

      try {
        const response = await api.put(process.env.VUE_APP_URL_WEB_API + "fin/parcela/boletosPrint?idSite=" + this.idSitePrincipal, { formato: formato, arquivos: arquivos });

        download(process.env.VUE_APP_URL_WEB_API + response.data.arquivo);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      } finally {
        this.acao_visualizar = null;
      }
    },

    async cartaCobrancaEmail() {
      var clientesCommit = [];
      this.selecionadas.forEach(element => {
        var incluido = false;
        clientesCommit.forEach(item => {
          if (item.idCliente == element.idCliente) {
            incluido = true;
          }
        });
        if (!incluido) {
          var cliente = {
            idSite: this.idSitePrincipal,
            idCliente: element.idCliente,
            origem: element.origem,
            idUsuario: this.$store.state.usuario.id,
            tipo: 1,
            pastaBoleto: null,
            boleto: null,
            pastaNF: null,
            nf: null,
            recibo: null,
            pi: null
          };
          clientesCommit.push(cliente);
        }
      });

      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "adm/log-email/lista?idSite=" + this.idSitePrincipal, clientesCommit);

        this.mensagem = "Cartas cobrança direcionadas para envio por e-mail";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      } finally {
        this.acao_email = null;
      }
    },

    async boletoEmail() {
      var nossosNumeros = [];
      var boletosCommit = [];
      this.selecionadas.forEach(element => {
        if (
          element.status == "ABERTA" &&
          element.nossoNumero &&
          element.formaPagamentoTipo == "BOLETO" &&
          ((element.idLoteFaturamento && parseInt(element.statusCobranca) >= 10 && parseInt(element.statusCobranca) <= 20) || element.payAsaas)
        ) {
          if (!nossosNumeros.includes(element.nossoNumero)) {
            nossosNumeros.push(element.nossoNumero);
            var caminhoBoleto = "";
            if (element.payAsaas) {
              caminhoBoleto = element.payAsaas;
            } else {
              caminhoBoleto = `${this.idSitePrincipal}/boletos/${element.bancoNumero}/boletos`;
            }
            var boleto = {
              idSite: this.idSitePrincipal,
              idCliente: element.idCliente,
              origem: element.origem,
              idUsuario: this.$store.state.usuario.id,
              tipo: 5,
              pastaBoleto: caminhoBoleto,
              boleto: element.nossoNumero,
              pastaNF: null,
              nf: null,
              recibo: null,
              pi: null
            };
            boletosCommit.push(boleto);
          }
        }
      });
      if (boletosCommit.length == 0) {
        this.mensagem = "Não há boleto corrente entre as parcelas selecionadas";
        this.snackErro = true;
        setTimeout(() => {
          this.acao_email = null;
        }, 30);
        return;
      }

      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "adm/log-email/lista?idSite=" + this.idSitePrincipal, boletosCommit);

        this.mensagem = "Boletos direcionados para envio por e-mail";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      } finally {
        this.acao_email = null;
      }
    },

    async nfEmail() {
      var nfs = [];
      var notasCommit = [];
      this.selecionadas.forEach(element => {
        if (
          (element.idNFe && element.statusNFe == "Processada") ||
          (element.idNFSC && element.situacaoNFSC == "N" && element.pdfNFSC == 2) ||
          (element.idNFSe && element.statusNFSe == "Processada")
        ) {
          var caminhoNota = "";
          var numeroNota = "";
          if (element.idNFe) {
            caminhoNota = `${this.idSitePrincipal}/notas-fiscais/nfe/notas/${element.anoMesNFe}`;
            numeroNota = `NFe${element.chaveNFe}`;
          } else if (element.idNFSC) {
            caminhoNota = `${this.idSitePrincipal}/notas-fiscais/nfsc/notas/${element.anoMesNFSC}`;
            numeroNota = element.numeroNFSC.toString().padStart(6, "0");
          } else if (element.idNFSe) {
            caminhoNota = (element.urlImpressao.startsWith("http") ? "" : "http://") + element.urlImpressao;
            numeroNota = element.numeroNFSe;
          }
          if (caminhoNota) {
            if (!nfs.includes(numeroNota)) {
              nfs.push(numeroNota);
              var boleto = {
                idSite: this.idSitePrincipal,
                idCliente: element.idCliente,
                origem: element.origem,
                idUsuario: this.$store.state.usuario.id,
                tipo: 4,
                pastaBoleto: null,
                boleto: null,
                pastaNF: caminhoNota,
                nf: numeroNota,
                recibo: null,
                pi: null
              };
              notasCommit.push(boleto);
            }
          }
        }
      });
      if (notasCommit.length == 0) {
        this.mensagem = "Não há nota fiscal corrente entre as parcelas selecionadas";
        this.snackErro = true;
        setTimeout(() => {
          this.acao_email = null;
        }, 30);
        return;
      }

      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "adm/log-email/lista?idSite=" + this.idSitePrincipal, notasCommit);

        this.mensagem = "Notas fiscais direcionadas para envio por e-mail";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      } finally {
        this.acao_email = null;
      }
    },

    async boletoNfEmail() {
      var nossosNumerosNFs = [];
      var boletosNfCommit = [];
      this.selecionadas.forEach(element => {
        if (
          element.status == "ABERTA" &&
          ((element.idLoteFaturamento && parseInt(element.statusCobranca) >= 10 && parseInt(element.statusCobranca) <= 20) || element.payAsaas) &&
          element.nossoNumero &&
          element.formaPagamentoTipo == "BOLETO" &&
          ((element.idNFe && element.statusNFe == "Processada") ||
            (element.idNFSC && element.situacaoNFSC == "N" && element.pdfNFSC == 2) ||
            (element.idNFSe && element.statusNFSe == "Processada"))
        ) {
          var caminhoBoleto = "";
          if (element.payAsaas) {
            caminhoBoleto = element.payAsaas;
          } else {
            caminhoBoleto = `${this.idSitePrincipal}/boletos/${element.bancoNumero}/boletos`;
          }
          var caminhoNota = "";
          var numeroNota = "";
          if (element.idNFe) {
            caminhoNota = `${this.idSitePrincipal}/notas-fiscais/nfe/notas/${element.anoMesNFe}`;
            numeroNota = `NFe${element.chaveNFe}`;
          } else if (element.idNFSC) {
            caminhoNota = `${this.idSitePrincipal}/notas-fiscais/nfsc/notas/${element.anoMesNFSC}`;
            numeroNota = element.numeroNFSC.toString().padStart(6, "0");
          } else if (element.idNFSe) {
            caminhoNota = (element.urlImpressao.startsWith("http") ? "" : "http://") + element.urlImpressao;
            numeroNota = element.numeroNFSe;
          }
          if (caminhoNota) {
            if (!nossosNumerosNFs.includes(element.nossoNumero + "|" + numeroNota)) {
              nossosNumerosNFs.push(element.nossoNumero + "|" + numeroNota);
              var boletoNF = {
                idSite: this.idSitePrincipal,
                idCliente: element.idCliente,
                origem: element.origem,
                idUsuario: this.$store.state.usuario.id,
                tipo: 6,
                pastaBoleto: caminhoBoleto,
                boleto: element.nossoNumero,
                pastaNF: caminhoNota,
                nf: numeroNota,
                recibo: null,
                pi: null
              };
              boletosNfCommit.push(boletoNF);
            }
          }
        }
      });
      if (boletosNfCommit.length == 0) {
        this.mensagem = "Não há boleto e nota fiscal corrente entre as parcelas selecionadas";
        this.snackErro = true;
        setTimeout(() => {
          this.acao_email = null;
        }, 30);
        return;
      }

      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "adm/log-email/lista?idSite=" + this.idSitePrincipal, boletosNfCommit);

        this.mensagem = "Boletos/Notas Fiscais direcionados para envio por e-mail";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      } finally {
        this.acao_email = null;
      }
    },

    async editarContato(item) {
      this.idParcela = item.id + "";
      this.idAssinatura = "";
      this.idRenovacao = "";
      this.idAnuncio = "";
      this.idPacote = "";
      this.idOperacao = "";
      this.origem = "";
      if (item.idAssinatura) {
        this.idAssinatura = item.idAssinatura.toString();
        this.idRenovacao = item.idRenovacao.toString();
      } else if (item.idAnuncio) {
        this.idAnuncio = item.idAnuncio.toString();
      } else if (item.idPacote) {
        this.idPacote = item.idPacote.toString();
      } else {
        this.idOperacao = item.idOperacao.toString();
        this.origem = item.origem;
      }
      this.idCliente = item.idCliente.toString();
      this.cliente = item.cliente;
      this.tipo = "2"; //cobrança
      this.dialogContato = true;
    },
    closeContato() {
      this.dialogContato = false;
      this.idParcela = "";
      this.idAssinatura = "";
      this.idRenovacao = "";
      this.idAnuncio = "";
      this.idPacote = "";
      this.idOperacao = "";
      this.origem = "";
    },

    async cobrarCartao(item) {
      var itens = "";
      var parcelar = true;
      if (item) {
        itens += item.id;
      } else if (this.selecionadas.length) {
        const valor = this.selecionadas[0].valor + this.selecionadas[0].descAcr;
        this.selecionadas.forEach(element => {
          if (this.$store.state.usuario.site_gateway.includes("ASAAS") && valor != element.valor + element.descAcr) {
            parcelar = false;
          }
          itens += (itens ? "," : "") + element.id;
        });
      }

      if (itens) {
        this.parcelasCartao = itens;
        this.nroParcelasCartao = parcelar ? 1 : 0;
        if (this.$store.state.usuario.site_gateway.includes("ASAAS")) {
          if (parcelar && this.selecionadas && this.selecionadas.length > 1) {
            this.nroParcelasCartao = this.selecionadas.length;
          } else {
            this.nroParcelasCartao = 0;
          }
        }
        this.dialogCartao = true;
      }
    },
    closeCartao() {
      this.dialogCartao = false;
      this.parcelasCartao = "";
      this.nroParcelasCartao = 1;
    },
    async gerarTxt() {
      try {
        //* chama api para gerar o arquivo txt com dados para a gráfica

        var itens = "";
        this.selecionadas.forEach(element => {
          itens += (itens ? "," : "") + element.id;
        });

        const retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "fin/lote-faturamento/txt/0", { parcelas: itens });
        download(process.env.VUE_APP_URL_WEB_API + retorno.data.arquivo);
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      }
    },

    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateUTC(date) {
      return util.formatDateUTC(date);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    },
    valida_data_futura(date) {
      if (date) {
        return util.valida_data_futura_BR(date, true);
      } else {
        return true;
      }
    },
    valida_data_passada(date) {
      if (date) {
        return util.valida_data_passada_BR(date, true, true);
      } else {
        return true;
      }
    },
    formatDateUS(date) {
      if (date && date.length == 10) {
        return util.formatDateUS(date);
      }
    },

    async filtroAvancadoFiltrar(exportar, total) {
      if (this.identificacaoPesquisa && !util.valida_cpf(this.identificacaoPesquisa) && !util.valida_cnpj(this.identificacaoPesquisa)) {
        this.mensagemPesquisa = "Identificação inválida";
        this.snackErro = true;
        return;
      }

      if (total) {
        this.calcular = this.calcular == "sim" ? "nao" : "sim";
        if (!this.registros.length) {
          total = false;
        }
      }
      this.selectAll = false;

      //* Carrega o id do site principal
      this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

      var l = null;
      if (exportar) {
        this.loader = "loadingExp";
        l = this.loader;
        this[l] = !this[l];
        setTimeout(() => (this[l] = false), 5000);
        this.loader = null;
      }

      if (!exportar) {
        this.carregando = true;
        this.mensagemPesquisa = "";
        this.loading = true;
      } else {
        this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR AS PARCELAS...";
      }

      //* Limpa os registros da tabela
      if (!total) {
        this.registros = [];
      }
      this.totalRegistros = 0;
      this.valorTotal = 0;
      this.totalPaginas = 0;

      //* Esconde a dialog do filtro avançado
      this.dialogFiltroAvancado = false;

      //* Filtros avançados da parcela
      var filtroParcelaUrl = this.montaFiltro();

      var camposAnuncio = "";
      if (exportar) {
        if (this.$refs.pesquisas) {
          //* Carrega os filtros da nota fiscal
          camposAnuncio = this.$refs.pesquisas.filtroUrlCampos();
        }
      }

      try {
        //* Consulta os dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/parcela/tipo/" +
            (this.receber ? "1" : "0") +
            "?" +
            filtroParcelaUrl +
            "&campos=" +
            camposAnuncio +
            "&pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem +
            (exportar ? "&exportar=1" : "") +
            (this.calcular == "sim" ? "&calcular=1" : "")
        );

        if (!exportar) {
          //* Carrega os dados de retorno
          if (!total) {
            this.registros = response.data.data;
            this.registros.forEach(element => {
              element.nroNF = element.numeroNFe ? element.numeroNFe : element.numeroNFSC ? element.numeroNFSC : element.numeroNFSe ? element.numeroNFSe : null;
              element.formaQuitada =
                util.isFormaPgtoValorZero(element.formaPagamentoTipo) || element.formaPagamentoTipo.startsWith("PERMUTA") || element.formaPagamentoTipo == "PACOTE";
            });
          }

          this.totalRegistros = response.data.total;
          this.valorTotal = response.data.valorTotal;
          this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
          this.rodape.pageText = this.totalRegistros + " itens" + (this.valorTotal ? " = " + util.formatCurrency(this.valorTotal, this.$store.state.usuario.site_pais, true) : "");

          //* Fecha a mensagem de carregando
          this.carregando = false;

          //* Registra caso algum item não seja encontrado
          this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
        }
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.mensagem =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackErro = true;
        }
      } finally {
        this.loading = false;
      }
    },
    montaFiltro() {
      var filtroParcelaUrl = "";
      if (this.$refs.filtroParcela) {
        //* Carrega os filtros da parcela
        filtroParcelaUrl += this.$refs.filtroParcela.filtroUrlParametros();
      }

      if (this.$refs.filtroCliente) {
        //* Carrega os filtros do cliente
        filtroParcelaUrl += this.$refs.filtroCliente.filtroUrlParametros(this.idSitePrincipal);
      }
      if (this.$refs.filtroAssinatura) {
        //* Carrega os filtros da assinatura
        filtroParcelaUrl += this.$refs.filtroAssinatura.filtroUrlParametros(this.idSitePrincipal);
      }
      if (this.$refs.filtroAnuncio) {
        //* Carrega os filtros do anúncio
        filtroParcelaUrl += this.$refs.filtroAnuncio.filtroUrlParametros(this.idSitePrincipal);
      }

      if (this.$refs.filtroNF) {
        //* Carrega os filtros da nota fiscal
        filtroParcelaUrl += this.$refs.filtroNF.filtroUrlParametros();
      }
      //* Verifica se recebeu o parametro
      if (this.$route.query["idParcela"] && !filtroParcelaUrl.includes("idParcela")) {
        filtroParcelaUrl += "&idParcela=" + this.$route.query["idParcela"];
      }

      if (this.$route.query["idLoteFaturamento"] && !filtroParcelaUrl.includes("idLoteFaturamento")) {
        filtroParcelaUrl += "&idLoteFaturamento=" + this.$route.query["idLoteFaturamento"];
      }

      if (this.$route.query["idNF"] && !filtroParcelaUrl.includes("idNF")) {
        filtroParcelaUrl += "&idNF=" + this.$route.query["idNF"];
      }

      if (this.$route.query["idNFe"] && !filtroParcelaUrl.includes("idNFe")) {
        filtroParcelaUrl += "&n.nfeID=" + this.$route.query["idNFe"] + "&n.nfeStatus=" + this.$route.query["status"];
      }

      if (this.$route.query["idNFSC"] && !filtroParcelaUrl.includes("idNFSC")) {
        filtroParcelaUrl += "&n.nfscID=" + this.$route.query["idNFSC"] + "&n.nfscSituacao=" + this.$route.query["situacao"];
      }

      if (this.$route.query["idNFSe"] && !filtroParcelaUrl.includes("idNFSe")) {
        filtroParcelaUrl += "&n.nfseID=" + this.$route.query["idNFSe"];
        "&n.nfseStatus=" + this.$route.query["status"];
      }

      if (filtroParcelaUrl.includes("idAnuncio")) {
        filtroParcelaUrl = filtroParcelaUrl.replace("&u.idSite=" + this.idSitePrincipal, "");
        filtroParcelaUrl = filtroParcelaUrl.replace("&omitirPacotes=1", "");
      }
      if (this.$route.query["idAnuncio"] && !filtroParcelaUrl.includes("idAnuncio")) {
        filtroParcelaUrl += "&idAnuncio=" + this.$route.query["idAnuncio"];
      }
      if (this.$route.query["idPacote"] && !filtroParcelaUrl.includes("idPacote")) {
        filtroParcelaUrl += "&idPacote=" + this.$route.query["idPacote"];
      }

      if (filtroParcelaUrl.includes("idPacoteAnuncios")) {
        filtroParcelaUrl = filtroParcelaUrl.replace("&omitirPacotes=1", "");
      }
      if (this.$route.query["idPacoteAnuncios"] && !filtroParcelaUrl.includes("idPacoteAnuncios")) {
        filtroParcelaUrl += "&idPacoteAnuncios=" + this.$route.query["idPacoteAnuncios"];
      }

      if (filtroParcelaUrl.includes("idAssinatura")) {
        filtroParcelaUrl = filtroParcelaUrl.replace("&a.idSite=" + this.idSitePrincipal, "");
        filtroParcelaUrl = filtroParcelaUrl.replace("&a.renovacoesAtual=ATUAL", "");
      }
      if (this.$route.query["idAssinatura"] && !filtroParcelaUrl.includes("idAssinatura")) {
        filtroParcelaUrl += "&idAssinatura=" + this.$route.query["idAssinatura"];
      }

      if (this.$route.query["idRenovacao"] && !filtroParcelaUrl.includes("idRenovacao")) {
        filtroParcelaUrl += "&idRenovacao=" + this.$route.query["idRenovacao"];
      }

      if (this.$route.query["idOperacao"] && !filtroParcelaUrl.includes("idOperacao")) {
        filtroParcelaUrl += "&idOperacao=" + this.$route.query["idOperacao"];
      }

      if (
        this.$route.query["idParcela"] ||
        this.$route.query["idLoteFaturamento"] ||
        this.$route.query["idNF"] ||
        this.$route.query["idNFe"] ||
        this.$route.query["idNFSC"] ||
        this.$route.query["idNFSe"] ||
        this.$route.query["idAnuncio"] ||
        this.$route.query["idPacote"] ||
        this.$route.query["idAssinatura"] ||
        this.$route.query["idRenovacao"] ||
        this.$route.query["idOperacao"]
      ) {
        //* executa com o parâmetro específico
      } else if (this.direitoCaixa) {
        this.direitoCaixa = false;
        // status=ABERTA
        // forma de pagamento=A_VISTA
        // vencimento=HOJE
        if (!filtroParcelaUrl.includes("status")) {
          filtroParcelaUrl += "&status=ABERTA";
        }
        if (!filtroParcelaUrl.includes("idFormaPgto")) {
          filtroParcelaUrl += "&idFormaPgto=1";
        }
        if (!filtroParcelaUrl.includes("dtVecto_inicio")) {
          filtroParcelaUrl += "&dtVecto_inicio=" + util.formatDate(new Date());
        }
        if (!filtroParcelaUrl.includes("dtVecto_fim")) {
          filtroParcelaUrl += "&dtVecto_fim=" + util.formatDate(new Date());
        }
      }

      if (!filtroParcelaUrl.includes("idSite")) {
        filtroParcelaUrl += "&idSite=" + this.idSitePrincipal;
      }
      if (this.identificacaoPesquisa) {
        if (filtroParcelaUrl.includes("identificacao")) {
          this.identificacaoPesquisa = "";
        } else {
          filtroParcelaUrl += "&c.identificacao=" + this.identificacaoPesquisa;
        }
      }
      if (this.nomePesquisa) {
        if (filtroParcelaUrl.includes("&c.nome=")) {
          this.nomePesquisa = "";
        } else {
          filtroParcelaUrl += "&c.nome=" + this.nomePesquisa;
          filtroParcelaUrl += "&c.nomeFantasia=" + this.nomePesquisa;
        }
      }
      if (this.idParcelaPesquisa) {
        filtroParcelaUrl += "&idParcela=" + this.idParcelaPesquisa;
      }

      return filtroParcelaUrl;
    },
    filtroAvancadoRetornar() {
      //* Esconde a dialog do filtro avançado
      this.dialogFiltroAvancado = false;
    },
    filtroAvancadoLimpar() {
      //* Limpa os registros da tabela
      this.registros = [];
      this.totalRegistros = 0;
      this.valorTotal = 0;
      this.totalPaginas = 0;
      this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR AS PARCELAS...";

      this.tabAss = false;
      this.tabAnu = false;
      if (this.tab == 2 || this.tab == 3) {
        this.tab = 0;
      }

      //* Limpa os campos dos filtros
      if (this.$refs.filtroCliente) {
        this.$refs.filtroCliente.limpar();
      }
      if (this.$refs.filtroAssinatura) {
        this.$refs.filtroAssinatura.limpar();
      }
      if (this.$refs.filtroAnuncio) {
        this.$refs.filtroAnuncio.limpar();
      }
      if (this.$refs.filtroParcela) {
        this.$refs.filtroParcela.limpar();
      }
      if (this.$refs.filtroNF) {
        this.$refs.filtroNF.limpar();
      }
    },
    marcarDesmarcarTodos(key) {
      var vlrTotal = 0;
      var itens = 0;
      this.registros.forEach(element => {
        if (element.status != "EXCLUÍDA") {
          element.selecionado = key;
          if (element.selecionado) {
            itens++;
            vlrTotal += element.valor + element.descAcr;
          }
        }
      });
      this.rodape.pageText = this.totalRegistros + " itens = " + util.formatCurrency(this.valorTotal, this.$store.state.usuario.site_pais, true);
      if (itens) {
        this.rodape.pageText += " | " + itens + " selecionado" + (itens > 1 ? "s" : "") + " = " + util.formatCurrency(vlrTotal, this.$store.state.usuario.site_pais, true);
      }
    },
    async loteAltCanc() {
      this.loadingExp = true;
      const loteCommit = {
        idSite: this.idSitePrincipal,
        origem: 0,
        pagamento: 0
      };

      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "fin/lote-faturamento/altcanc?idSite=" + this.idSitePrincipal + "&imediata=1", loteCommit);

        this.mensagem = "Lote(s) criado(s) com sucesso";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      } finally {
        this.loadingExp = false;
      }
    },
    async recarregaParametros() {
      this.OCULTAR_MENU();
      if (this.receber) {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_PARCELAS_RECEBER_ACESSO"]);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_PARCELAS_PAGAR_ACESSO"]);
      }

      this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
      this.carregarFormasPgto();
      this.carregarContas();
      if (this.receber) {
        await this.carregarCedentesBoleto();
        await this.carregarCedentesDebitoConta();
        await this.carregarCedenteAsaas();
        await this.carregarConfigNF();
      } else {
        await this.carregarCedentesBRR();
      }

      if (this.cedentesBRR.length) {
        var brr = this.headers.find(dado => dado.text == "BRR");
        if (!this.receber) {
          if (!brr) {
            this.headers.push({ text: "BRR", value: "brrOperacao" });
            this.headersBemRed.push({ text: "BRR", value: "brrOperacao" });
            this.headersExpand.push({ text: "BRR", value: "brrOperacao" });
            this.headersRed.push({ text: "BRR", value: "brrOperacao" });
          }
        } else {
          if (brr) {
            var index = this.headers.indexOf(brr);
            this.headers.splice(index, 1);
            index = this.headersBemRed.indexOf(brr);
            this.headersBemRed.splice(index, 1);
            index = this.headersExpand.indexOf(brr);
            this.headersExpand.splice(index, 1);
            index = this.headersRed.indexOf(brr);
            this.headersRed.splice(index, 1);
          }
        }
      }

      if (this.receber && this.$store.state.usuario.site_protheus) {
        this.headersExpand.push({ text: "Protheus", value: "idProtheus" });
      }

      this.alteracoes = [];
      this.operacoes = [];
      this.cancelamentos = [];
      this.creditos = [];
      this.visualizacoes = [];
      this.emails = [];

      //* Verifica se o usuário logado possui direitos
      this.$store.state.usuario.direitos.forEach(element => {
        var direito = element.modulo + "_" + element.grupo + "_" + element.direito;

        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINANTES_EDITAR") {
          this.direitoAssinante = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CLIENTES_EDITAR") {
          this.direitoCliente = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_FORNECEDORES_EDITAR") {
          this.direitoFornecedor = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINATURAS_EDITAR") {
          this.direitoAssinatura = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANÚNCIOS_EDITAR") {
          this.direitoAnuncio = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_PACOTES_EDITAR") {
          this.direitoPacote = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_LOG_PARCELAS_ACESSO") {
          this.direitoLog = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CLIENTES_ALTERAR_CRÉDITO") {
          this.creditos = ["Bloquear", "Liberar", "Análise"];
        }

        if (this.receber) {
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CONTAS_RECEBER_EDITAR") {
            this.direitoOperacao = true;
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_VENDA_AVULSA_FATURAMENTO_ACESSO") {
            this.direitoVendaAvulsa = true;
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_EDITAR") {
            this.direitoEditar = true;
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_QUITAR") {
            this.direitoQuitar = true;
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_REVERTER_BANCO") {
            this.direitoReverterBanco = true;
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_COBRAR_CARTAO") {
            if (
              this.$store.state.usuario.site_gateway.includes("MUNDIPAGG") ||
              this.$store.state.usuario.site_gateway.includes("CIELO") ||
              this.$store.state.usuario.site_gateway.includes("ASAAS")
            ) {
              this.direitoCobrarCartao = true;
            }
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_CANCELAR") {
            this.direitoCancelar = true;
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_RECIBO") {
            this.direitoRecibo = true;
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_BOLETO") {
            this.direitoBoleto = true;
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_NOTA_FISCAL") {
            this.direitoNotaFiscal = true;
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_CONTATO_CRIAR") {
            this.direitoContato = true;
          }

          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ALTERAR_VENCIMENTO") {
            this.alteracoes.push("Vencimento");
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ALTERAR_FORMA_PAGAMENTO") {
            this.alteracoes.push("Forma de Pagamento");
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ALTERAR_VALOR") {
            this.alteracoes.push("Valor");
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ALTERAR_TEXTO_FATURA") {
            this.alteracoes.push("Texto Fatura");
          }

          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ALTERAR_BLOQUEIO") {
            this.operacoes.push("Bloquear");
          }
          if (this.direitoQuitar) {
            this.operacoes.push("Quitar");
          }
          if (this.direitoCobrarCartao) {
            this.operacoes.push("Cobrar Cartão");
            if (this.cedenteAsaas) {
              this.operacoes.push("Cobrar Asaas");
            }
          }
          if (this.direitoCancelar) {
            this.operacoes.push("Cancelar");
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_REMESSAS_RECEBER_ACESSO") {
            if (this.cedentesBoleto.length || this.cedentesDebitoConta.length) {
              this.direitoLote = true;
              this.operacoes.push("Criar Lote (por Cliente)");
              this.operacoes.push("Criar Lote (por Parcela)");

              setTimeout(() => {
                var carne = false;
                this.cedentesBoleto.forEach(element => {
                  if (element.formato == 1) {
                    carne = true;
                  }
                });
                if (carne) {
                  this.operacoes.push("Emitir 2ª Via Carnê");
                }
              }, 1000);
            }
          }
          if (this.direitoNotaFiscal && this.configNF) {
            this.operacoes.push("Criar Nota Fiscal (por Cliente)");
            this.operacoes.push("Criar Nota Fiscal (por Parcela)");
            if (this.remessaFutura) {
              this.operacoes.push("Criar NFe Remessa Futura (por Cliente)");
            }
          }

          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ALTERAR_BLOQUEIO") {
            this.cancelamentos.push("Bloqueio");
          }
          if (this.direitoCancelar) {
            this.cancelamentos.push("Quitação");
            this.cancelamentos.push("Cancelamento");
          }
          //boleto e débito podem ser cancelados alterando-se a forma de cobrança ou bloqueando a parcela
          // "Boleto",
          // "Débito em Conta",

          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_COBRANÇA") {
            this.visualizacoes.push("Carta-Cobrança");
            this.visualizacoes.push("Cobrança Carteira");
          }
          if (this.direitoRecibo) {
            this.visualizacoes.push("Recibo");
          }
          if (this.direitoBoleto) {
            if (this.cedentesBoleto.length) {
              this.visualizacoes.push("Boleto (1/pag)");
              this.visualizacoes.push("Boleto (2/pag)");
              this.visualizacoes.push("Boleto (3/pag=carnê)");
            }
          }

          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_COBRANÇA") {
            this.emails.push("Carta-Cobrança");
            this.emails.push("Recibo");
            if (this.cedentesBoleto.length || this.cedenteAsaas) {
              this.emails.push("Boleto");
            }
            if (this.configNF || this.cedenteAsaas) {
              this.emails.push("Nota Fiscal");
              this.emails.push("Boleto + Nota Fiscal");
            }
          }
          if (
            // direito == "WEBSUITA_ADMIN_SUITA" ||
            direito == "FINANCEIRO_PARCELAS_RECEBER_CAIXA"
          ) {
            this.direitoCaixa = true;
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_TROCO") {
            this.direitoTroco = true;
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_EXPORTAR") {
            this.direitoExportar = true;
          }
        } else {
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CONTAS_PAGAR_EDITAR") {
            this.direitoOperacao = true;
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_PAGAR_EDITAR") {
            this.direitoEditar = true;
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_PAGAR_EDITAR_CONFIRMADA") {
            this.direitoEditarConfirmada = true;
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_PAGAR_QUITAR") {
            this.direitoQuitar = true;
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_PAGAR_CANCELAR") {
            this.direitoCancelar = true;
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_PAGAR_REVERTER_BANCO") {
            this.direitoReverterBanco = true;
          }

          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_PAGAR_ALTERAR_VENCIMENTO") {
            this.alteracoes.push("Vencimento");
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_PAGAR_ALTERAR_FORMA_PAGAMENTO") {
            this.alteracoes.push("Forma de Pagamento");
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_PAGAR_ALTERAR_VALOR") {
            this.alteracoes.push("Valor");
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_PAGAR_ALTERAR_TEXTO_FATURA") {
            this.alteracoes.push("Texto Fatura");
          }

          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_PAGAR_ALTERAR_BLOQUEIO") {
            this.operacoes.push("Bloquear");
          }
          if (this.direitoQuitar) {
            this.operacoes.push("Quitar");
          }
          if (this.direitoCancelar) {
            this.operacoes.push("Cancelar");
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_REMESSAS_PAGAR_ACESSO") {
            if (this.cedentesBRR.length) {
              this.operacoes.push("Criar Lote");
            }
          }

          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_PAGAR_ALTERAR_BLOQUEIO") {
            this.cancelamentos.push("Bloqueio");
          }
          if (this.direitoCancelar) {
            this.cancelamentos.push("Quitação");
            this.cancelamentos.push("Cancelamento");
          }
          if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_PAGAR_EXPORTAR") {
            this.direitoExportar = true;
          }
        }
      });
    },
    visualizarConciliacao(item) {
      this.idExtratoLancamento = item.idExtratoLancamento + "";
      this.dialogConciliacao = true;
    },
    closeConciliacao() {
      this.dialogConciliacao = false;
      this.idExtratoLancamento = "";
    }
  },

  beforeMount() {
    this.recarregaParametros();
  },

  computed: {
    receber() {
      return this.$route.path.indexOf("/receber") > -1;
    }
  },

  watch: {
    tab(val) {
      if (val == 2) {
        this.tabAss = true;
      }
      if (val == 3) {
        this.tabAnu = true;
      }
    },
    alterarDtVectoItemUS() {
      this.alterarDtVectoItem = util.formatDate(this.alterarDtVectoItemUS);
      this.mnuDtalterarDtVectoItem = false;
    },
    operacaoItemdtQuitacaoUS() {
      this.operacaoItemdtQuitacao = util.formatDate(this.operacaoItemdtQuitacaoUS);
      this.mnuDtoperacaoItemdtQuitacao = false;
    },
    dtInicioUS() {
      this.dtInicio = util.formatDate(this.dtInicioUS);
      this.mnuDtInicio = false;
    },
    $route() {
      //* Tem que recarregar as configurações toda vez que inicia a tela ou muda de rota (parcelas/receber <-> parcelas/pagar)
      this.tabAss = false;
      this.tabAnu = false;
      this.recarregaParametros();
      this.registros = [];
      this.totalRegistros = 0;
      this.valorTotal = 0;
      this.totalPaginas = 0;
      this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR AS PARCELAS...";
    },
    dialogParcela(val) {
      if (val == null) {
        this.filtroAvancadoFiltrar(false);
      }
      val || this.closeParcela();
    },
    atMonetaria(val) {
      this.$emit("update:atMonetaria", val);
    },
    dialogContato(val) {
      if (val == null) {
        this.filtroAvancadoFiltrar(false);
      }
      val || this.closeContato();
    },
    dialogCartao(val) {
      if (val == null) {
        this.filtroAvancadoFiltrar(false);
      }
      val || this.closeCartao();
    },
    dialogFiltroParcela(val) {
      var retorno = JSON.parse(JSON.stringify(val));
      if (retorno.status != null) {
        this.filtroParcela = retorno.parcela;
        retorno = retorno.status;
      } else {
        retorno = val;
      }
      retorno || this.closeFiltroParcela();
    },
    dialogLogAlteracao(val) {
      val || this.closeLogAlteracao();
    },
    dialogLogBanco(val) {
      val || this.closeLogBanco();
    },
    paginaAtual: function() {
      this.filtroAvancadoFiltrar(false);
    },
    dialogConciliacao(val) {
      val || this.closeConciliacao();
    },

    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }

        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }

        //* Verifica se recebeu algum parametro
        if (
          this.$route.query["idParcela"] ||
          this.$route.query["idLoteFaturamento"] ||
          this.$route.query["idNF"] ||
          this.$route.query["idNFe"] ||
          this.$route.query["idNFSC"] ||
          this.$route.query["idNFSe"] ||
          this.$route.query["idAnuncio"] ||
          this.$route.query["idPacote"] ||
          this.$route.query["idAssinatura"] ||
          this.$route.query["idRenovacao"] ||
          this.$route.query["idOperacao"] ||
          this.direitoCaixa
        ) {
          this.inicial = false;
        }
        if (!this.inicial) {
          this.filtroAvancadoFiltrar(false);
        } else {
          this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR AS PARCELAS...";
          this.inicial = false;
        }
      }
    }
  }
};
</script>
