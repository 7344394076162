<template>
  <v-container fluid grid-list-md>
    <v-layout wrap>
      <v-flex xs12 v-if="!roteiro">
        <v-tabs v-model="tab" center-active grow background-color="grey lighten-4">
          <v-tab>Todas</v-tab>
          <v-tab>Download</v-tab>
          <v-tab>PDF</v-tab>
          <v-tab>Remessas</v-tab>
          <v-tab :disabled="!direitoComercial">Comercial</v-tab>
          <v-tab :disabled="!direitoAssinaturas">Assinaturas</v-tab>
          <v-tab :disabled="!direitoCarteira">Carteira</v-tab>
          <v-tab :disabled="!direitoExterno">Externo</v-tab>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <span>* Apresenta mensagens de todos os tipos</span>
            </v-tab-item>
            <v-tab-item>
              <span>* Pesquisas solicitadas para download</span>
            </v-tab-item>
            <v-tab-item>
              <span>* PDFs solicitados para impressão de boletos</span>
            </v-tab-item>
            <v-tab-item>
              <span>* Mensagens sobre processamento de remessas</span>
            </v-tab-item>
            <v-tab-item>
              <span>* Mensagens de erros de importação do Comercial</span>
            </v-tab-item>
            <v-tab-item>
              <span>* Mensagens de erros sobre Assinaturas</span>
            </v-tab-item>
            <v-tab-item>
              <span>* Histórico de relatórios de carteira</span>
            </v-tab-item>
            <v-tab-item>
              <span>* Mensagens de sistemas externos</span>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
        <v-divider></v-divider>
      </v-flex>

      <v-flex xs12 md7>
        <v-checkbox v-model="naoVisualizadas" :label="naoVisualizadas ? 'Somente mensagens não visualizadas' : 'Todas as mensagens'" @change="limpaFiltro"></v-checkbox>
      </v-flex>
      <v-flex xs11 md3>
        <v-menu v-model="mnuDtInicio" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dtInicial"
              :label="roteiro ? 'Tiragem' : 'Data'"
              v-mask="'##/##/####'"
              :rules="[value => valida_data(value) || 'Data inválida']"
              prepend-icon="event"
              v-on="on"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @keyup="dtInicioUS = valida_data(dtInicial) ? formatDateUS(dtInicial) : null"
              @change="limpaFiltro"
              @click:clear="
                dtInicioUS = null;
                limpaFiltro();
              "
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dtInicioUS"
            locale="pt-br"
            no-title
            @input="
              mnuDtInicio = false;
              limpaFiltro();
            "
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs1 offset-md-1 md1>
        <v-btn color="blue lighten-2" fab small>
          <v-icon color="white" @click="carregarMensagens" title="Atualizar">refresh</v-icon>
        </v-btn>
      </v-flex>
      <v-flex xs12>
        <v-data-table mobile-breakpoint="0" :headers="headers" :items="mensagens" hide-default-header hide-default-footer no-data-text="- Não há mensagens -" height="510">
          <template v-slot:[getItemData()]="{ item }">
            <span :class="item.visualizado ? 'grey--text' : ''" v-html="formatDateTime(item.data)"></span>
          </template>
          <template v-slot:[getItemResumo()]="{ item }">
            <span
              :class="item.visualizado ? 'grey--text' : ''"
              v-html="
                '<div style=\'margin-top: 15px;' +
                  (item.resumo.includes('Aguarde') || item.resumo.toUpperCase().includes('ERRO') || item.titulo.includes('Erro') ? ' color: red;' : '') +
                  '\'><h4>' +
                  item.titulo +
                  (item.dtMovimento ? ' ' + formatDate(item.dtMovimento) : '') +
                  '</h4><p>' +
                  item.resumo +
                  '</p>' +
                  (idUsuario ? '' : '<p>' + item.usuario + '</p>')
              "
            ></span>
          </template>
          <template v-slot:[getItemVisualizado()]="{ item }">
            <v-btn icon ripple @click="cliqueMensagem(item)">
              <v-icon :color="item.visualizado ? 'grey' : 'teal darken-2'">{{ mensagemIcone[item.tipo] }}</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-flex>
      <v-flex text-xs-center style="margin-top:20px;padding-bottom:20px">
        <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 11" circle></v-pagination>
      </v-flex>
      <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    </v-layout>
  </v-container>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import download from "downloadjs";
export default {
  props: { roteiro: { type: Boolean, default: false } },

  data() {
    return {
      headers: [
        { text: "", value: "data" },
        { text: "", value: "resumo" },
        { text: "", value: "visualizado" }
      ],

      tab: null,
      dtInicial: null,
      dtInicioUS: null,
      mnuDtInicio: false,

      direitoComercial: false,
      direitoAssinaturas: false,
      direitoCarteira: false,
      direitoExterno: false,

      mensagem: "",
      snackErro: false,

      idUsuario: 0,
      naoVisualizadas: 1,
      mensagemIcone: [],
      mensagens: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1
    };
  },
  methods: {
    getItemData() {
      return "item.data";
    },
    getItemResumo() {
      return "item.resumo";
    },
    getItemVisualizado() {
      return "item.visualizado";
    },

    limpaFiltro() {
      this.paginaAtual = 1;
      this.carregarMensagens();
    },
    carregarMensagens() {
      var urlApiMensagem = process.env.VUE_APP_URL_WEB_API + "adm/mensagem";

      urlApiMensagem += "?pagina=" + this.paginaAtual;

      if (this.naoVisualizadas) {
        urlApiMensagem += "&naoVisualizadas=1";
      }

      var direito = this.$store.state.usuario.direitos[0];
      this.idUsuario = this.$store.state.usuario.id;
      if (direito.modulo + "_" + direito.grupo + "_" + direito.direito == "WEBSUITA_ADMIN_SUITA") {
        this.idUsuario = 0;
      }
      urlApiMensagem += "&idUsuario=" + this.idUsuario;

      if (this.roteiro) {
        urlApiMensagem += "&tipo=ROTEIRO";
        if (this.dtInicioUS) {
          urlApiMensagem += "&dtMovimento=" + this.dtInicioUS;
        }
      } else {
        if (this.dtInicioUS) {
          urlApiMensagem += "&data=" + this.dtInicioUS;
        }
        if (this.tab == 1) {
          urlApiMensagem += "&tipo=DOWNLOAD";
        } else if (this.tab == 2) {
          urlApiMensagem += "&tipo=PDF";
        } else if (this.tab == 3) {
          urlApiMensagem += "&tipo=REMESSA_RETORNO";
        } else if (this.tab == 4) {
          urlApiMensagem += "&tipo=COMERCIAL";
        } else if (this.tab == 5) {
          urlApiMensagem += "&tipo=ASSINATURAS";
        } else if (this.tab == 6) {
          urlApiMensagem += "&tipo=CARTEIRA";
        } else if (this.tab == 7) {
          urlApiMensagem += "&tipo=EXTERNO";
        }
      }

      //* Carrega as mensagens
      api
        .get(urlApiMensagem)
        .then(response => {
          this.mensagens = response.data.data;
          this.mensagens.forEach(element => {
            if (element.tipo.includes("_ROT") || element.tipo.includes("_MOV")) {
              element.tipo = "ROTEIRO";
            }
          });
          this.totalNaoVisualizado = response.data.totalNaoVisualizado;
          this.totalRegistros = response.data.total;
          this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
          this.$emit("update:mensagensCarregadas", this.totalNaoVisualizado);
        })
        .catch(e => {
          if (e.toString().includes("status code 400")) {
            this.mensagem = "SESSÃO EXPIRADA!";
            this.snackErro = true;
            setTimeout(() => {
              this.$store.dispatch("deslogarUsuario");
            }, 2000);
          }
        });
    },
    dialogMensagensAbrir() {},
    async cliqueMensagem(mensagem) {
      try {
        //* Verifica o tipo de mensagem
        if (["DOWNLOAD", "ROTEIRO", "PDF", "CARTEIRA", "IVC1", "IVC2", "IVC3"].includes(mensagem.tipo)) {
          if (!mensagem.resumo.includes("Aguarde")) {
            download(process.env.VUE_APP_URL_WEB_API + mensagem.arquivo);
          }
        } else if (mensagem.tipo == "LINK") {
          window.open(mensagem.link);
        }

        //* Define como item visualizado
        await api.put(process.env.VUE_APP_URL_WEB_API + "adm/mensagem/" + mensagem.id, {
          visualizado: 1
        });

        //* Define como item visualiado
        mensagem.visualizado = 1;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    formatDateUS(date) {
      return util.formatDateUS(date);
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(date) {
      return util.formatDateTime(date).replace(" ", "<br/>");
    },
    valida_data(date) {
      if (date) {
        return util.valida_data_BR(date);
      } else {
        return true;
      }
    }
  },

  mounted() {
    setInterval(() => {
      this.carregarMensagens();
    }, 300000);
  },

  watch: {
    paginaAtual: function() {
      this.carregarMensagens();
    },
    dtInicioUS() {
      this.dtInicial = util.formatDate(this.dtInicioUS);
      this.mnuDtInicio = false;
    },
    tab() {
      this.limpaFiltro();
    },
    roteiro() {
      this.limpaFiltro();
    }
  },

  async created() {
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;

      if (element.modulo == "COMERCIAL" || direito == "WEBSUITA_ADMIN_SUITA") {
        this.direitoComercial = true;
      }
      if (element.modulo == "ASSINATURAS" || direito == "WEBSUITA_ADMIN_SUITA") {
        this.direitoAssinaturas = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINATURAS_CARTEIRA") {
        this.direitoCarteira = true;
      }

      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_MENSAGENS_EXTERNO") {
        this.direitoExterno = true;
      }
    });
    if (!this.direitoComercial || !this.direitoExterno || !this.direitoCarteira) {
      //* Se não tem direitoComercial ou direitoExterno ou direitoCarteira, vai para a tab Download (pois Todos será ocultada)
      this.tab = 1;
    }

    //* Define os icones das mensagens
    this.mensagemIcone["DOWNLOAD"] = "cloud_download";
    this.mensagemIcone["ROTEIRO"] = "cloud_download";
    this.mensagemIcone["CARTEIRA"] = "cloud_download";
    this.mensagemIcone["IVC1"] = "cloud_download";
    this.mensagemIcone["IVC2"] = "cloud_download";
    this.mensagemIcone["IVC3"] = "cloud_download";
    this.mensagemIcone["LINK"] = "link";
    this.mensagemIcone["TEXTO"] = "mail";
    this.mensagemIcone["COMERCIAL"] = "visibility";
    this.mensagemIcone["REMESSA_RETORNO"] = "visibility";
    this.mensagemIcone["PDF"] = "cloud_download";
    this.mensagemIcone["EXTERNO"] = "visibility";

    if (this.roteiro) {
      this.naoVisualizadas = 0;
    }

    //* Carrega as mensagens
    this.carregarMensagens();
  }
};
</script>
