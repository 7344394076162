<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">menu_open</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">
            Fluxo de Caixa
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="contaSelecionada.id && competencia" color="success" fab small @click="incluiSaldo">
            <v-icon color="white" title="Incluir">add</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
          <v-container fluid grid-list-md>
            <v-layout wrap>
              <v-flex xs12 md4>
                <v-autocomplete
                  v-model="contaSelecionada"
                  autofocus
                  :items="contasMovimento"
                  :filter="filtrarObjetos"
                  label="Conta Movimento"
                  item-text="nome"
                  item-value="id"
                  autocomplete="disabled"
                  :name="Math.random()"
                  no-data-text
                  :rules="[value => !!value || 'Obrigatório.']"
                  @change="carregarCompetencias()"
                  return-object
                ></v-autocomplete>
              </v-flex>

              <v-flex xs6 md2>
                <v-autocomplete
                  v-model="competencia"
                  :items="competencias"
                  :filter="filtrarItens"
                  hint="Obrigatório"
                  :rules="[value => !!value || 'Obrigatório.']"
                  label="Competência"
                  :name="Math.random()"
                  no-data-text
                  :disabled="competencias.length == 0"
                ></v-autocomplete>
              </v-flex>

              <v-flex xs1 v-if="contaSelecionada.id && competencia">
                <v-btn color="blue" :loading="loading" :disabled="loading" fab small @click="pesquisaDados(true)">
                  <v-icon color="white" title="Exportar">description</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
        <v-data-table
          mobile-breakpoint="0"
          :headers="headers"
          :items="registros"
          :options.sync="options"
          :no-data-text="mensagemPesquisa"
          :footer-props="rodape"
          :disable-sort="carregando"
          :disable-pagination="carregando"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon color="blue" title="Editar" @click="editaSaldo(item)">edit</v-icon>
          </template>

          <template v-slot:item.data="{ item }">{{ formatCompetencia(item.data) }}</template>
          <template v-slot:item.saldo="{ item }">{{ formatCurrency(item.saldo) }}</template>
        </v-data-table>
        <div class="text-xs-center pt-2 marg-bt">
          <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
        </div>

        <div class="px-4 mt-3">
          <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
          <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        </div>
      </v-card>

      <v-dialog persistent v-model="dialogSaldo" max-width="600px" @keydown.esc="dialogSaldo = false">
        <v-card>
          <v-form ref="formSaldo" v-model="saldoValid" lazy-validation>
            <v-toolbar>
              <span class="headline">Saldo Inicial</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeSaldo">
                <v-icon color="darken-1">cancel</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-container fluid grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 md12> <v-text-field v-model="contaSelecionada.nome" label="Conta Movimento" color="blue-grey lighten-2" disabled></v-text-field></v-flex>
                  <v-flex xs12 md12> <v-text-field v-model="saldoItem.competencia" label="Competência" color="blue-grey lighten-2" disabled></v-text-field></v-flex>

                  <v-flex xs12 md12>
                    <v-currency-field
                      :locale="`pt-${$store.state.usuario.site_pais}`"
                      v-model="saldoItem.saldo"
                      label="Saldo"
                      :allowNegative="true"
                      color="blue-grey lighten-2"
                    ></v-currency-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" @click="closeSaldo">Retornar</v-btn>
              <v-btn color="blue darken-1" @click="saveSaldo">Atualizar</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
import util from "../../util.js";
export default {
  data() {
    return {
      contaSelecionada: {},
      competencia: null,
      contasMovimento: [],
      competencias: [],

      headers: [
        { text: "Ações", value: "actions", width: "80" },
        { text: "ID", value: "id" },
        { text: "Conta", value: "conta" },
        { text: "Competencia", value: "data" },
        { text: "Saldo Inicial", value: "saldo" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      dialogSaldo: false,
      saldoValid: true,
      saldoIndex: -1,
      saldoItem: { id: null, saldo: 0 },
      defaultSaldoItem: { id: null, saldo: 0 },

      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,

      elementoValid: true,
      mensagem: "",
      snackbar: false,
      snackErro: false,

      mensagemPesquisa: "",
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {},
      loader: null,
      loading: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    async pesquisaDados(exportar) {
      var l = null;
      if (exportar) {
        this.loader = "loading";
        const l = this.loader;
        this[l] = !this[l];
      }

      if (!exportar) {
        //* Sinaliza que está carregando
        this.carregando = true;
        this.mensagemPesquisa = "Carregando...";
        this.loading = true;
      } else {
        this.mensagemPesquisa = "";
      }

      try {
        if (!exportar) {
          this.registros = [];

          const response = await api.get(
            process.env.VUE_APP_URL_WEB_API +
              "fin/saldo?idSite=" +
              this.$store.state.usuario.idSitePrincipal +
              (this.contaSelecionada.id ? "&idConta=" + this.contaSelecionada.id : "")
          );

          //seta as variaveis com o retorno
          this.registros = response.data.data;
          this.registros.forEach(element => {
            element.competencia = util.formatDate(element.data).substring(3);
          });
          this.totalRegistros = response.data.total;
          this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
          this.rodape.pageText = this.totalRegistros + " itens";
          //fecha o loading
          this.carregando = false;
          //se não achar registro seta a mensagem senão deixa em branco
          this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
        } else {
          await api.get(
            process.env.VUE_APP_URL_WEB_API +
              "fin/saldo/fluxo?idSite=" +
              this.$store.state.usuario.idSitePrincipal +
              (this.contaSelecionada.id ? "&idConta=" + this.contaSelecionada.id : "") +
              (this.competencia ? "&data=" + util.dateToStringUS(util.parseDate("01/" + this.competencia)) : "")
          );

          this.mensagem = "Fluxo de Caixa exportado, verifique suas mensagens";
          this.snackbar = true;
          this.pesquisaDados(false);
        }
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      } finally {
        this[l] = false;
        this.loader = null;
        this.loading = false;
      }
    },
    async carregarContas() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/conta/movimento");
      if (response.data) {
        this.contasMovimento = response.data;
      }
    },
    async carregarCompetencias() {
      await this.pesquisaDados(false);
      try {
        this.competencias = [];
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/saldo/primeiroSaldo/" + this.contaSelecionada.id);
        if (response.data.dtInicio) {
          var dtInicial = new Date(response.data.dtInicio);
          dtInicial.setDate(1);

          var dtFinal = new Date();
          dtFinal.setDate(1);
          do {
            this.competencias.push(util.formatDate(dtFinal).substring(3));
            dtFinal.setMonth(dtFinal.getMonth() - 1);
          } while (dtFinal > dtInicial);
        } else {
          this.competencias.push(util.formatDate(new Date()).substring(3));
        }
      } catch {
        this.mensagem = "Ocorreu um erro ao carregas as competências";
        this.snackErro = true;
      }
    },

    incluiSaldo() {
      this.saldoIndex = -1;
      this.saldoItem = this.defaultSaldoItem;
      this.saldoItem.idConta = this.contaSelecionada.id;
      this.saldoItem.data = util.dateToStringUS(util.parseDate("01/" + this.competencia));
      this.saldoItem.competencia = this.competencia;
      this.dialogSaldo = true;
    },
    editaSaldo(item) {
      this.saldoIndex = this.registros.indexOf(item);
      this.saldoItem = Object.assign({}, item);
      this.dialogSaldo = true;
    },
    async saveSaldo() {
      try {
        if (this.$refs.formSaldo.validate()) {
          if (!this.saldoItem.id) {
            await api.post(process.env.VUE_APP_URL_WEB_API + "fin/saldo", this.saldoItem);
          } else {
            await api.put(process.env.VUE_APP_URL_WEB_API + "fin/saldo/" + this.saldoItem.id, this.saldoItem);
          }

          this.closeSaldo();
          this.pesquisaDados(false);
        }
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    closeSaldo() {
      this.dialogSaldo = false;
      setTimeout(() => {
        this.saldoIndex = -1;
        this.saldoItem = this.defaultSaldoItem;
        this.$refs.formSaldo.resetValidation();
      }, 300);
    },

    valida_data(date) {
      if (date) {
        return util.valida_data_BR(date);
      } else {
        return true;
      }
    },
    filtrarItens(item, queryText, itemText) {
      return util.filtrarItens(item, queryText, itemText);
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    formatDateUS(date) {
      return util.formatDateUS(date);
    },
    formatCompetencia(date) {
      return util.formatDate(date).substring(3);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_FLUXO_CAIXA_ACESSO"]);

    this.carregarContas();
  }
};
</script>
