<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">ac_unit</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Reparte de Venda Avulsa</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs6>
            <v-text-field v-model="banca" label="Banca" readonly @click:append="visualizarBanca" append-icon="loupe" :disabled="!direitoBanca"></v-text-field>
          </v-flex>

          <v-flex xs12>
            <v-data-table mobile-breakpoint="0" :headers="headers" :items="itens" :search="search" no-data-text="Não existem itens neste reparte" :footer-props="rodape">
              <template v-slot:item.reparte="props">
                <v-edit-dialog v-if="!props.item.idParcela" :return-value.sync="props.item.reparte" @save="save('reparte', props.item)">
                  <v-chip>{{ props.item.reparte }}</v-chip>
                  <template v-slot:input>
                    <v-text-field
                      v-mask="'#####'"
                      v-model="props.item.reparte"
                      label="Reparte"
                      single-line
                      :rules="[v => v != '']"
                      @change="props.item.reparte = props.item.reparte == '' ? 0 : parseInt(props.item.reparte)"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>

                <span v-if="props.item.idParcela">{{ props.item.reparte }}</span>
              </template>
              <template v-slot:item.adicional="props">
                <v-edit-dialog v-if="!props.item.idParcela" :return-value.sync="props.item.adicional" @save="save('adicional', props.item)">
                  <v-chip>{{ props.item.adicional }}</v-chip>
                  <template v-slot:input>
                    <v-text-field
                      v-mask="'#####'"
                      v-model="props.item.adicional"
                      label="Reparte"
                      single-line
                      :rules="[v => v != '']"
                      @change="props.item.adicional = props.item.adicional == '' ? 0 : parseInt(props.item.adicional)"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
                <span v-if="props.item.idParcela">{{ props.item.adicional }}</span>
              </template>
              <template v-slot:item.encalhe="props">
                <v-edit-dialog v-if="!props.item.nossoNumero && props.item.status != 'QUITADA'" :return-value.sync="props.item.encalhe" @save="save('encalhe', props.item)">
                  <v-chip>{{ props.item.encalhe }}</v-chip>
                  <template v-slot:input>
                    <v-text-field
                      v-mask="'#####'"
                      v-model="props.item.encalhe"
                      label="Reparte"
                      single-line
                      :rules="[v => v != '']"
                      @change="props.item.encalhe = props.item.reparte == '' ? 0 : parseInt(props.item.encalhe)"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
                <span v-if="props.item.nossoNumero || props.item.status == 'QUITADA'">{{ props.item.encalhe }}</span>
              </template>

              <template v-slot:item.dtEntrega="{ item }">{{ formatDate(item.dtEntrega) }}</template>

              <template v-slot:item.soma="{ item }">
                <span v-html="(item.reparte ? parseInt(item.reparte) : 0) + (item.adicional ? parseInt(item.adicional) : 0)"></span>
              </template>

              <template v-slot:item.saldo="{ item }">
                <span
                  style="font-weight: bold"
                  v-html="(item.reparte ? parseInt(item.reparte) : 0) + (item.adicional ? parseInt(item.adicional) : 0) - (item.encalhe ? parseInt(item.encalhe) : 0)"
                ></span>
              </template>

              <template v-slot:item.vlrReparte="{ item }">
                <span v-html="formatCurrency((item.reparte ? parseInt(item.reparte) : 0) * item.vlrUnitario)"></span>
              </template>

              <template v-slot:item.vlrAdicional="{ item }">
                <span v-html="formatCurrency((item.adicional ? parseInt(item.adicional) : 0) * item.vlrUnitario)"></span>
              </template>

              <template v-slot:item.vlrSoma="{ item }">
                <span v-html="formatCurrency(((item.reparte ? parseInt(item.reparte) : 0) + (item.adicional ? parseInt(item.adicional) : 0)) * item.vlrUnitario)"></span>
              </template>

              <template v-slot:item.vlrEncalhe="{ item }">
                <span v-html="formatCurrency((item.encalhe ? parseInt(item.encalhe) : 0) * item.vlrUnitario)"></span>
              </template>

              <template v-slot:item.vlrSaldo="{ item }">
                <span
                  style="font-weight: bold"
                  v-html="
                    formatCurrency(
                      ((item.reparte ? parseInt(item.reparte) : 0) + (item.adicional ? parseInt(item.adicional) : 0) - (item.encalhe ? parseInt(item.encalhe) : 0)) *
                        item.vlrUnitario
                    )
                  "
                ></span>
              </template>
            </v-data-table>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/assinaturas/venda-avulsa-repartes" color="orange lighten-2 white" depressed style="color:white">
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackItem" :color="snackColor" :timeout="2000" relative>{{ mensagem }}</v-snackbar>

    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Reparte", value: "dtEntrega" },
        { text: "Prévia Padrão", value: "previaPadrao", align: "end" },
        {
          text: "Prévia Média",
          value: "previaMedia",
          divider: "true",
          align: "end"
        },
        { text: "REPARTE", value: "reparte", align: "end" },
        { text: "ADICIONAL", value: "adicional", align: "end" },
        { text: "Soma", value: "soma", divider: "true", align: "end" },
        { text: "ENCALHE", value: "encalhe", divider: "true", align: "end" },
        { text: "Saldo", value: "saldo", divider: "true", align: "end" },
        { text: "Valor Reparte", value: "vlrReparte", align: "end" },
        { text: "Valor Adicional", value: "vlrAdicional", align: "end" },
        { text: "Valor Soma", value: "vlrSoma", divider: "true", align: "end" },
        {
          text: "Valor Encalhe",
          value: "vlrEncalhe",
          divider: "true",
          align: "end"
        },
        {
          text: "Valor Saldo",
          value: "vlrSaldo",
          divider: "true",
          align: "end"
        },
        { text: "Fatura", value: "idParcela" },
        { text: "Cobrança", value: "nossoNumero" },
        { text: "Nota Fiscal", value: "notaFiscal" }
      ],

      validacao: {
        mensagemErro: false
      },
      itens: [],
      banca: "",

      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        pageText: ""
      },

      idSitePrincipal: null,
      direitoBanca: false,

      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackItem: false,
      snackErro: false,
      snackColor: "success",
      search: "",

      isUpdating: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async save(campo, item) {
      await util.limparObjeto(this.validacao, false);
      var itemCommit = {};
      var reparte = item.reparte ? parseInt(item.reparte) : 0;
      var adicional = item.adicional ? parseInt(item.adicional) : 0;
      var encalhe = item.encalhe ? parseInt(item.encalhe) : 0;
      var url = "ass/venda-avulsa-item/";
      if (campo == "reparte") {
        itemCommit.reparte = reparte;
        url += "reparte/";
      } else if (campo == "adicional") {
        itemCommit.adicional = adicional;
        url += "adicional/";
      } else if (campo == "encalhe") {
        itemCommit.encalhe = encalhe;
        url += "encalhe/";
      }

      try {
        if (reparte + adicional < parseInt(item.reparteMinimo)) {
          throw {
            response: {
              data: [
                {
                  mensagem: "Reparte mínimo desta banca: " + item.reparteMinimo
                }
              ]
            }
          };
        }
        if (reparte + adicional > parseInt(item.reparteMaximo)) {
          throw {
            response: {
              data: [
                {
                  mensagem: "Reparte máximo desta banca: " + item.reparteMaximo
                }
              ]
            }
          };
        }
        if (reparte + adicional < encalhe) {
          throw {
            response: {
              data: [{ mensagem: "Encalhe é superior ao reparte+adicional" }]
            }
          };
        }
        const response = await api.put(process.env.VUE_APP_URL_WEB_API + url + item.id, itemCommit);

        if (response.data) {
          this.mensagem = "Item Atualizado";
          this.snackColor = "success";
          this.snackItem = true;
        }
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackColor = "error";
        this.snackItem = true;
        this.carregarItensReparte();
      }
    },
    async carregarItensReparte() {
      const periodo = this.$route.query["periodo"];
      const dtInicio = periodo.split("-")[0];
      const dtFim = periodo.split("-")[1];

      const response = await api.get(
        process.env.VUE_APP_URL_WEB_API +
          "ass/venda-avulsa-item/banca/" +
          this.id +
          "?idSite=" +
          this.idSitePrincipal +
          "&dtInicio=" +
          util.dateToStringUS(util.parseDate(dtInicio)) +
          "&dtFim=" +
          util.dateToStringUS(util.parseDate(dtFim))
      );
      if (response.data) {
        this.itens = response.data;
        this.rodape.pageText = this.itens.length + " itens";
        this.itens.forEach(element => {
          element.notaFiscal = element.numeroNFe || element.numeroNFSC;
          element.nossoNumero = element.nossoNumero || (element.status == "QUITADA" ? element.status : "");
        });
        this.banca = `${this.itens[0].codAntigoBanca ? `${this.itens[0].codAntigoBanca} - ` : ""}${this.itens[0].banca}`;
      }
    },
    visualizarBanca() {
      window.open("/assinaturas/banca/" + this.id, "_blank");
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    }
  },
  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";

    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_BANCAS_EDITAR") {
        this.direitoBanca = true;
      }
    });

    try {
      //* Se é edição, carrega o item
      if (this.id) {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_VENDA_AVULSA_REPARTE_EDITAR"]);
        this.carregarItensReparte();
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
