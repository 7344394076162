<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">ac_unit</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Reparte de Venda Avulsa</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="lote.idSite"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              hint="Obrigatório"
              :rules="[validacao.idSite == false || validacao.idSite, value => !!value || 'Obrigatório.']"
              label="Site"
              item-text="nome"
              item-value="id"
              disabled
              no-data-text
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12>
            <h4 class="overline font-weight-black font-italic">
              Criação do Reparte
            </h4>
            <v-divider></v-divider>
          </v-flex>

          <v-flex xs12>
            <v-autocomplete
              autofocus
              v-model="lote.municipios"
              :items="municipios"
              :filter="filtrarObjetos"
              :label="$store.state.usuario.site_pais == 'BR' ? 'Município(s)' : 'Concelho(s)'"
              item-text="nome"
              item-value="id"
              no-data-text
              clearable
              multiple
              dense
              :disabled="id != ''"
              :rules="[validacao.municipios == false || validacao.municipios]"
              @change="validacao.municipios = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12>
            <v-autocomplete
              v-model="lote.entregadores"
              :items="entregadores"
              :filter="filtrarObjetos"
              label="Entregador(es)"
              item-text="nome"
              item-value="id"
              no-data-text
              clearable
              multiple
              dense
              :disabled="id != ''"
              :rules="[validacao.entregadores == false || validacao.entregadores]"
              @change="validacao.entregadores = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12>
            <v-autocomplete
              v-model="lote.rotas"
              :items="rotas"
              :filter="filtrarObjetos"
              label="Rota(s)"
              item-text="nome"
              item-value="id"
              no-data-text
              clearable
              multiple
              dense
              :disabled="id != ''"
              :rules="[validacao.rotas == false || validacao.rotas]"
              @change="validacao.rotas = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md3>
            <v-autocomplete
              v-model="lote.idTipoBanca"
              :items="tiposBanca"
              :filter="filtrarObjetos"
              :rules="[validacao.idTipoBanca == false || validacao.idTipoBanca]"
              label="Tipo de Banca"
              item-text="nome"
              item-value="id"
              no-data-text
              clearable
              :disabled="id != ''"
              @change="validacao.idTipoBanca = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md2>
            <v-autocomplete
              v-model="lote.idProduto"
              :items="produtos"
              :filter="filtrarObjetos"
              hint="Obrigatório"
              :rules="[validacao.idProduto == false || validacao.idProduto, value => !!value || 'Obrigatório.']"
              label="Produto"
              item-text="nome"
              item-value="id"
              no-data-text
              @change="
                carregarTabela();
                validacao.idProduto = false;
              "
              :disabled="id != ''"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs6 md2>
            <v-menu v-model="mnuDtEntrega" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="lote.dtEntrega"
                  label="Data da Entrega"
                  v-mask="'##/##/####'"
                  :rules="[validacao.dtEntrega == false || validacao.dtEntrega, value => valida_data(value) || 'Data inválida']"
                  prepend-icon="event"
                  v-on="on"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @keyup="dtEntregaUS = valida_data(lote.dtEntrega) ? formatDateUS(lote.dtEntrega) : null"
                  @change="
                    carregarTabela();
                    validacao.dtEntrega = false;
                  "
                  :disabled="id != ''"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dtEntregaUS"
                locale="pt-br"
                no-title
                @input="
                  mnuDtEntrega = false;
                  carregarTabela();
                "
              ></v-date-picker>
            </v-menu>
          </v-flex>

          <v-flex xs6 md1>
            <v-currency-field :locale="`pt-${$store.state.usuario.site_pais}`" v-model="vlrTabela" label="Valor de Tabela" color="blue-grey lighten-2" readonly></v-currency-field>
          </v-flex>

          <v-flex xs1 md1>
            <v-icon v-if="vlrTabela && direitoTabelaVA" color="blue lighten-2" title="Editar Tabela" @click="editarTabela">chrome_reader_mode</v-icon>
          </v-flex>

          <v-flex xs12 md3 v-if="!id" text-right>
            <v-btn :loading="isUpdating" :disabled="isUpdating" color="green white" class="btn-rsn mt-md-4" style="color:white" depressed @click="criarReparte">
              <v-icon class="icon-rsn" left>arrow_forward</v-icon>Criar Reparte
            </v-btn>
          </v-flex>
          <v-flex xs12 md3 v-if="id">
            <v-text-field v-model="lote.usuario" label="Usuário" disabled color="blue-grey lighten-2"></v-text-field>
          </v-flex>

          <v-flex xs12>
            <h4 class="overline font-weight-black font-italic">
              Operação do Reparte
            </h4>
            <v-divider></v-divider>
          </v-flex>

          <v-flex offset-md8 offset-xs4>
            <v-text-field background-color="white" v-model="search" append-icon="search" label="Digite para pesquisar..." single-line hide-details></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-data-table mobile-breakpoint="0" :headers="headers" :items="itens" :search="search" no-data-text="Não existem itens neste reparte" :footer-props="rodape">
              <template v-slot:item.actions="{ item }">
                <span :class="item.selecionado == 1 ? 'grey lighten-2' : ''">
                  <v-icon v-if="direitoBanca" color="blue" title="Visualizar Banca" @click="visualizarBanca(item)">loupe</v-icon>
                </span>
                <!-- <v-icon
                  :color="
                    item.selecionado ? 'blue lighten-1' : 'blue-grey lighten-3'
                  "
                  :title="
                    (item.selecionado ? 'Desmarcar' : 'Marcar') +
                      ' banca para transferir reparte'
                  "
                  @click="marcarDesmarcarReparte(item)"
                  >forward</v-icon
                >
                <v-icon
                  color="blue-grey lighten-3"
                  title="Transferir reparte para esta banca"
                  @click="transferirReparte(item)"
                  >done_all</v-icon
                > -->
              </template>

              <!-- @cancel="cancel"
                  @open="open"
              @close="close"-->
              <template v-slot:item.reparte="props">
                <!-- v-if="
                    !props.item.notaFiscal && props.item.status != 'QUITADA' - teria que alterar toda a operação 
                  " -->
                <v-edit-dialog v-if="!props.item.idParcela" :return-value.sync="props.item.reparte" @save="save('reparte', props.item)">
                  <v-chip>{{ props.item.reparte }}</v-chip>
                  <template v-slot:input>
                    <v-text-field
                      v-mask="'#####'"
                      v-model="props.item.reparte"
                      label="Reparte"
                      single-line
                      :rules="[v => v != '']"
                      @change="props.item.reparte = props.item.reparte == '' ? 0 : parseInt(props.item.reparte)"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
                <!-- v-if="props.item.notaFiscal || props.item.status == 'QUITADA'" - teria que alterar toda a operação -->
                <span v-if="props.item.idParcela">{{ props.item.reparte }}</span>
              </template>
              <template v-slot:item.adicional="props">
                <v-edit-dialog v-if="!props.item.idParcela" :return-value.sync="props.item.adicional" @save="save('adicional', props.item)">
                  <v-chip>{{ props.item.adicional }}</v-chip>
                  <template v-slot:input>
                    <v-text-field
                      v-mask="'#####'"
                      v-model="props.item.adicional"
                      label="Reparte"
                      single-line
                      :rules="[v => v != '']"
                      @change="props.item.adicional = props.item.adicional == '' ? 0 : parseInt(props.item.adicional)"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
                <span v-if="props.item.idParcela">{{ props.item.adicional }}</span>
              </template>
              <template v-slot:item.encalhe="props">
                <v-edit-dialog v-if="!props.item.nossoNumero && props.item.status != 'QUITADA'" :return-value.sync="props.item.encalhe" @save="save('encalhe', props.item)">
                  <v-chip>{{ props.item.encalhe }}</v-chip>
                  <template v-slot:input>
                    <v-text-field
                      v-mask="'#####'"
                      v-model="props.item.encalhe"
                      label="Reparte"
                      single-line
                      :rules="[v => v != '']"
                      @change="props.item.encalhe = props.item.reparte == '' ? 0 : parseInt(props.item.encalhe)"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
                <span v-if="props.item.nossoNumero || props.item.status == 'QUITADA'">{{ props.item.encalhe }}</span>
              </template>

              <template v-slot:item.soma="{ item }">
                <span v-html="(item.reparte ? parseInt(item.reparte) : 0) + (item.adicional ? parseInt(item.adicional) : 0)"></span>
              </template>

              <template v-slot:item.saldo="{ item }">
                <span
                  style="font-weight: bold"
                  v-html="(item.reparte ? parseInt(item.reparte) : 0) + (item.adicional ? parseInt(item.adicional) : 0) - (item.encalhe ? parseInt(item.encalhe) : 0)"
                ></span>
              </template>

              <template v-slot:item.vlrReparte="{ item }">
                <span v-html="formatCurrency((item.reparte ? parseInt(item.reparte) : 0) * vlrTabela)"></span>
              </template>

              <template v-slot:item.vlrAdicional="{ item }">
                <span v-html="formatCurrency((item.adicional ? parseInt(item.adicional) : 0) * vlrTabela)"></span>
              </template>

              <template v-slot:item.vlrSoma="{ item }">
                <span v-html="formatCurrency(((item.reparte ? parseInt(item.reparte) : 0) + (item.adicional ? parseInt(item.adicional) : 0)) * vlrTabela)"></span>
              </template>

              <template v-slot:item.vlrEncalhe="{ item }">
                <span v-html="formatCurrency((item.encalhe ? parseInt(item.encalhe) : 0) * vlrTabela)"></span>
              </template>

              <template v-slot:item.vlrSaldo="{ item }">
                <span
                  style="font-weight: bold"
                  v-html="
                    formatCurrency(
                      ((item.reparte ? parseInt(item.reparte) : 0) + (item.adicional ? parseInt(item.adicional) : 0) - (item.encalhe ? parseInt(item.encalhe) : 0)) * vlrTabela
                    )
                  "
                ></span>
              </template>
            </v-data-table>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">
              {{ validacao.mensagemErro }}
            </v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn to="/assinaturas/venda-avulsa-repartes" color="orange lighten-2 white" depressed style="color:white">
        <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn :loading="loading" color="blue white" class="btn-rsn" style="color:white" depressed @click="gerarRelatorio">
        <v-icon class="icon-rsn" left>print</v-icon>Relatório
      </v-btn>

      <v-btn v-if="lote.id && direitoEtiquetas" :loading="loading2" color="blue white" class="btn-rsn" style="color:white" depressed @click="gerarEtiquetas">
        <v-icon class="icon-rsn" left>view_module</v-icon>Etiquetas
      </v-btn>

      <v-btn v-if="direitoExportar" :loading="loading3" color="blue white" class="btn-rsn" style="color:white" depressed @click="gerarCSV">
        <v-icon class="icon-rsn" left>description</v-icon>Exportar
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackItem" :color="snackColor" :timeout="2000" relative>{{ mensagem }}</v-snackbar>

    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions", width: "110" },
        { text: "ID", value: "id" },
        { text: "Tipo", value: "tipoBanca" },
        { text: "Código", value: "codAntigoBanca" },
        { text: "Banca", value: "banca", divider: "true" },
        { text: "Prévia Padrão", value: "previaPadrao", align: "end" },
        {
          text: "Prévia Média",
          value: "previaMedia",
          divider: "true",
          align: "end"
        },
        { text: "REPARTE", value: "reparte", align: "end" },
        { text: "ADICIONAL", value: "adicional", align: "end" },
        { text: "Soma", value: "soma", divider: "true", align: "end" },
        { text: "ENCALHE", value: "encalhe", divider: "true", align: "end" },
        { text: "Saldo", value: "saldo", divider: "true", align: "end" },
        { text: "Valor Reparte", value: "vlrReparte", align: "end" },
        { text: "Valor Adicional", value: "vlrAdicional", align: "end" },
        { text: "Valor Soma", value: "vlrSoma", divider: "true", align: "end" },
        {
          text: "Valor Encalhe",
          value: "vlrEncalhe",
          divider: "true",
          align: "end"
        },
        {
          text: "Valor Saldo",
          value: "vlrSaldo",
          divider: "true",
          align: "end"
        },
        { text: "Fatura", value: "idParcela" },
        { text: "Cobrança", value: "nossoNumero" },
        { text: "Nota Fiscal", value: "notaFiscal" }
      ],

      lote: {
        id: "",
        idSite: null,
        idProduto: null,
        idTipoBanca: null,
        dtEntrega: null,
        municipios: [],
        entregadores: [],
        rotas: []
      },
      validacao: {
        idSite: false,
        idProduto: false,
        idTipoBanca: false,
        dtEntrega: false,
        municipios: false,
        entregadores: false,
        rotas: false,
        mensagemErro: false
      },
      tabela: {},
      vlrTabela: 0,

      itens: [],
      tiposBanca: [],

      siteGrupo: [],
      municipios: [],
      entregadores: [],
      rotas: [],
      produtos: [],
      dtEntregaUS: null,
      mnuDtEntrega: false,

      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        pageText: ""
      },

      idSitePrincipal: null,
      direitoBanca: false,
      direitoTabelaVA: false,
      direitoRoteiro: false,
      direitoEtiquetas: false,
      direitoExportar: false,

      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackItem: false,
      snackErro: false,
      snackColor: "success",
      search: "",

      isUpdating: false,
      loader: null,
      loading: false,
      loading2: false,
      loading3: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async save(campo, item) {
      await util.limparObjeto(this.validacao, false);
      var itemCommit = {};
      var reparte = item.reparte ? parseInt(item.reparte) : 0;
      var adicional = item.adicional ? parseInt(item.adicional) : 0;
      var encalhe = item.encalhe ? parseInt(item.encalhe) : 0;
      var url = "ass/venda-avulsa-item/";
      if (campo == "reparte") {
        itemCommit.reparte = reparte;
        url += "reparte/";
      } else if (campo == "adicional") {
        itemCommit.adicional = adicional;
        url += "adicional/";
      } else if (campo == "encalhe") {
        itemCommit.encalhe = encalhe;
        url += "encalhe/";
      }

      try {
        if (reparte + adicional < parseInt(item.reparteMinimo)) {
          throw {
            response: {
              data: [
                {
                  mensagem: "Reparte mínimo desta banca: " + item.reparteMinimo
                }
              ]
            }
          };
        }
        if (reparte + adicional > parseInt(item.reparteMaximo)) {
          throw {
            response: {
              data: [
                {
                  mensagem: "Reparte máximo desta banca: " + item.reparteMaximo
                }
              ]
            }
          };
        }
        if (reparte + adicional < encalhe) {
          throw {
            response: {
              data: [{ mensagem: "Encalhe é superior ao reparte+adicional" }]
            }
          };
        }
        const response = await api.put(process.env.VUE_APP_URL_WEB_API + url + item.id, itemCommit);

        if (response.data) {
          this.mensagem = "Item Atualizado";
          this.snackColor = "success";
          this.snackItem = true;
        }
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackColor = "error";
        this.snackItem = true;
        this.carregarItensReparte();
      }
    },
    async criarReparte() {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      //* Busca as bancas relacionadas no filtro
      var filtro = "dtEntrega=" + this.dtEntregaUS + "&idProduto=" + this.lote.idProduto;
      if (this.lote.municipios != null && this.lote.municipios.length > 0) {
        filtro += "&idMunicipio=" + this.lote.municipios.join(",");
      }
      if (this.lote.entregadores != null && this.lote.entregadores.length > 0) {
        filtro += "&idEntregador=" + this.lote.entregadores.join(",");
      }
      if (this.lote.rotas != null && this.lote.rotas.length > 0) {
        filtro += "&idRota=" + this.lote.rotas.join(",");
      }
      if (this.lote.idTipoBanca) {
        filtro += "&idTipoBanca=" + this.lote.idTipoBanca;
      }

      const responseBancas = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/venda-avulsa-item?" + filtro);
      if (!responseBancas.data) {
        this.mensagem = "Não foram encontradas bancas para o filtro selecionado";
        this.snackErro = true;
        this.isUpdating = false;
        return;
      }
      if (parseFloat(this.vlrTabela) == 0) {
        this.mensagem = "Valor de tabela inválido";
        this.snackErro = true;
        this.isUpdating = false;
        return;
      }
      var itens = [];
      responseBancas.data.forEach(element => {
        itens.push({
          idBanca: element.idBanca,
          vlrUnitario: this.vlrTabela,
          previaPadrao: element.previaPadrao,
          previaMedia: element.previaMedia,
          reparte: (parseInt(element.previaPadrao) + parseInt(element.previaMedia)) / 2
        });
      });

      var reparteCommit = {
        idSite: this.idSitePrincipal,
        idProduto: this.lote.idProduto,
        idTipoBanca: this.lote.idTipoBanca,
        municipios: JSON.stringify(this.lote.municipios),
        entregadores: JSON.stringify(this.lote.entregadores),
        rotas: JSON.stringify(this.lote.rotas),
        dtEntrega: this.dtEntregaUS,
        itens: itens
      };

      try {
        if (!this.id) {
          const retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "ass/venda-avulsa-lote?idSite=" + this.idSitePrincipal, reparteCommit);
          this.id = retorno.data.id;
          this.lote.id = retorno.data.id;
          this.carregarItensReparte();
          this.mensagem = "Dados foram atualizados";
          this.snackbar = true;
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    async carregarMunicipios() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cep/municipios?vendaAvulsa=1");
      if (response.data) {
        this.municipios = response.data;
        this.municipios.forEach(element => {
          element.nome = element.nome + "/" + element.uf;
        });
        if (this.municipios.length == 1) {
          this.lote.municipios = [this.municipios[0].id];
        }
      }
    },
    async carregarEntregadores() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/entregador/lista?idSite=" + this.idSitePrincipal + "&vendaAvulsa=1");
      if (response.data) {
        this.entregadores = response.data;
        if (this.entregadores.length == 1) {
          this.lote.entregadores = [this.entregadores[0].id];
        }
      }
    },
    async carregarRotas() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/rota/lista?idSite=" + this.idSitePrincipal + "&vendaAvulsa=1");
      if (response.data) {
        this.rotas = response.data;
        this.rotas.forEach(element => {
          element.nome = element.nome + " - " + element.municipio + "/" + element.uf;
        });
        if (this.rotas.length == 1) {
          this.lote.rotas = [this.rotas[0].id];
        }
      }
    },
    async carregarTiposBanca() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/tipo-banca/lista");
      if (response.data) {
        this.tiposBanca = response.data;
        if (this.tiposBanca.length == 1) {
          this.lote.idTipoBanca = this.tiposBanca[0].id;
        }
      }
    },
    async carregarProdutos() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/produto/lista?vendaAvulsa=1");
      if (response.data) {
        this.produtos = response.data;
        if (this.produtos.length == 1) {
          this.lote.idProduto = this.produtos[0].id;
        }
      }
    },
    async carregarTabela() {
      this.vlrTabela = 0;
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/venda-avulsa-tabela/produto/" + this.lote.idProduto);
      if (response.data) {
        this.tabela = response.data;
        if (this.dtEntregaUS) {
          var dtEntrega = util.parseDateUS(this.dtEntregaUS);
          if (dtEntrega.getDay() == 0) {
            this.vlrTabela = this.tabela.vlrDom;
          } else if (dtEntrega.getDay() == 1) {
            this.vlrTabela = this.tabela.vlrSeg;
          } else if (dtEntrega.getDay() == 2) {
            this.vlrTabela = this.tabela.vlrTer;
          } else if (dtEntrega.getDay() == 3) {
            this.vlrTabela = this.tabela.vlrQua;
          } else if (dtEntrega.getDay() == 4) {
            this.vlrTabela = this.tabela.vlrQui;
          } else if (dtEntrega.getDay() == 5) {
            this.vlrTabela = this.tabela.vlrSex;
          } else if (dtEntrega.getDay() == 6) {
            this.vlrTabela = this.tabela.vlrSab;
          }
        }
      }
    },
    async carregarItensReparte() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/venda-avulsa-item/lote/" + this.id);
      if (response.data) {
        this.itens = response.data;
        this.rodape.pageText = this.itens.length + " itens";
        this.itens.forEach(element => {
          element.notaFiscal = element.numeroNFe || element.numeroNFSC;
          element.nossoNumero = element.nossoNumero || (element.status == "QUITADA" ? element.status : "");
        });
      }
    },
    visualizarBanca(item) {
      window.open("/assinaturas/banca/" + item.idCliente, "_blank");
    },
    editarTabela() {
      window.open("/assinaturas/venda-avulsa-tabela/" + this.tabela.id, "_blank");
    },
    marcarDesmarcarReparte() {},
    transferirReparte() {},
    async gerarRelatorio() {
      if (!this.lote.idProduto) {
        this.mensagem = "Selecione o produto";
        this.snackErro = true;
        return;
      }
      this.loader = "loading";
      const l = this.loader;
      this[l] = !this[l];
      try {
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "adm/cep/roteiroVA?idSite=" +
            this.idSitePrincipal +
            "&dtRoteiro=" +
            this.dtEntregaUS +
            (this.lote.idProduto ? "&idProduto=" + this.lote.idProduto : "") +
            "&site=" +
            this.$store.state.usuario.site_nome
        );

        util.imprimir(response.data);
      } catch (e) {
        this.mensagem = e.response.data;
        this.snackErro = true;
      } finally {
        this[l] = false;
        this.loader = null;
      }
    },
    async gerarEtiquetas() {
      this.loader = "loading2";
      const l = this.loader;
      this[l] = !this[l];
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/venda-avulsa-lote/etiqueta/" + this.lote.id);

        util.imprimir(response.data);
      } catch (e) {
        this.mensagem = e.response.data;
        this.snackErro = true;
      } finally {
        this[l] = false;
        this.loader = null;
      }
    },
    async gerarCSV() {
      this.loader = "loading3";
      const l = this.loader;
      this[l] = !this[l];

      try {
        await api.get(process.env.VUE_APP_URL_WEB_API + "ass/venda-avulsa-lote/csv?idSite=" + this.idSitePrincipal + "&idLote=" + this.lote.id);
        this.mensagem = "Pesquisa exportada, verifique suas mensagens";
        this.snackbar = true;
      } catch (e) {
        this.mensagem = e.response.data;
        this.snackErro = true;
      } finally {
        this[l] = false;
        this.loader = null;
      }
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    valida_data(date) {
      if (date) {
        return util.valida_data_BR(date);
      } else {
        return true;
      }
    },
    formatDateUS(date) {
      return util.formatDateUS(date);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    }
  },
  watch: {
    dtEntregaUS(val) {
      this.lote.dtEntrega = util.formatDate(val);
      this.nuDtEntrega = false;
    }
  },
  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_BANCAS_EDITAR") {
        this.direitoBanca = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_VENDA_AVULSA_TABELA_EDITAR") {
        this.direitoTabelaVA = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_VENDA_AVULSA_ROTEIRO_ACESSO") {
        this.direitoRoteiro = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_VENDA_AVULSA_REPARTE_ETIQUETAS") {
        this.direitoEtiquetas = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_VENDA_AVULSA_REPARTE_EXPORTAR") {
        this.direitoExportar = true;
      }
    });

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }

    this.carregarMunicipios();
    this.carregarEntregadores();
    this.carregarRotas();
    this.carregarTiposBanca();
    this.carregarProdutos();

    try {
      //* Se é edição, carrega o item
      if (this.id == "") {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_VENDA_AVULSA_REPARTE_CRIAR"]);
        this.lote.idSite = this.idSitePrincipal;
        var dtEntrega = new Date();
        dtEntrega.setDate(dtEntrega.getDate() + 1);
        this.dtEntregaUS = util.dateToStringUS(dtEntrega);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_VENDA_AVULSA_REPARTE_EDITAR"]);
        //* Carrega o item - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/venda-avulsa-lote/" + this.id + "?idSite=" + this.idSitePrincipal);
        if (!response.data) {
          this.$router.push("/assinaturas/venda-avulsa-repartes");
        } else {
          this.lote = response.data;
          this.dtEntregaUS = util.dateToStringUS(this.lote.dtEntrega);
          if (this.lote.municipios) {
            this.lote.municipios = JSON.parse(this.lote.municipios);
          }
          if (this.lote.entregadores) {
            this.lote.entregadores = JSON.parse(this.lote.entregadores);
          }
          if (this.lote.rotas) {
            this.lote.rotas = JSON.parse(this.lote.rotas);
          }
          this.carregarItensReparte();
        }
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  },
  beforeMount() {
    setTimeout(() => {
      this.carregarTabela();
    }, 1000);
  }
};
</script>
