var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('v-app',{attrs:{"id":"inspire"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"#333333"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("mail_outline")]),_c('v-toolbar-title',{staticClass:"font-weight-light",staticStyle:{"color":"white"}},[_vm._v("E-mails")])],1),_c('div',{staticClass:"px-6 mt-4"},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-spacer'),_c('v-flex',{attrs:{"xs12":"","md5":""}},[_c('v-text-field',{staticClass:"mt-2 mb-2 elevation-0",attrs:{"background-color":"white","append-icon":"search","label":"Digite para pesquisar...","placeholder":"Pesquisa pelo nome, e-mail, status(Enviado,Erro)","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.limpaFiltro($event)},"click:append":_vm.limpaFiltro},model:{value:(_vm.filtroPesquisa),callback:function ($$v) {_vm.filtroPesquisa=$$v},expression:"filtroPesquisa"}})],1)],1),_c('v-data-table',{attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.registros,"options":_vm.options,"no-data-text":_vm.mensagemPesquisa,"footer-props":_vm.rodape,"disable-sort":_vm.carregando,"disable-pagination":_vm.carregando},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.direitoCliente)?_c('v-icon',{attrs:{"color":"orange","title":"Cliente"},on:{"click":function($event){return _vm.visualizarCliente(item)}}},[_vm._v("account_circle")]):_vm._e(),(item.processado && item.retorno != 'Enviado')?_c('v-icon',{attrs:{"color":"blue","title":"Reenviar"},on:{"click":function($event){return _vm.reenviarEmail(item)}}},[_vm._v("forward")]):_vm._e()]}},{key:"item.origem",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.origem == "ASS" ? "Assinaturas" : "Financeiro"))]}},{key:"item.tipo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tipo == 0 ? "Recibo" : item.tipo == 1 ? "Cobrança Email" : item.tipo == 2 ? "Cobrança Corte" : item.tipo == 3 ? "Cobrança Cancelamento" : item.tipo == 4 ? "Nota Fiscal" : item.tipo == 5 ? "Boleto" : item.tipo == 6 ? "NF + Boleto" : item.tipo == 7 ? "PI" : item.tipo == 8 ? "Cupom" : item.tipo == 9 ? "Nova Assinatura" : item.tipo == 10 ? "Códigos Promocionais" : "")+" ")]}},{key:"item.dtRegistro",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatDateTime(item.dtRegistro)))]}},{key:"item.dtEnvio",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatDateTime(item.dtEnvio)))]}},{key:"item.processado",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.processado == 1 ? "Sim" : ""))]}},{key:"item.retorno",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.retorno == 'Enviado' ? 'blue--text' : 'red--text'},[_vm._v(_vm._s(item.retorno))])]}}])}),_c('div',{staticClass:"text-xs-center pt-2 marg-bt"},[_c('v-pagination',{attrs:{"length":_vm.totalPaginas,"total-visible":_vm.$vuetify.breakpoint.xsOnly ? 5 : 7,"circle":""},model:{value:(_vm.paginaAtual),callback:function ($$v) {_vm.paginaAtual=$$v},expression:"paginaAtual"}})],1)],1),_c('v-snackbar',{attrs:{"color":"info","timeout":5000,"relative":"","bottom":"","left":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.mensagem))]),_c('v-snackbar',{attrs:{"color":"error","timeout":5000,"relative":"","bottom":"","left":""},model:{value:(_vm.snackErro),callback:function ($$v) {_vm.snackErro=$$v},expression:"snackErro"}},[_vm._v(_vm._s(_vm.mensagem))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }