<template>
  <v-card class="hide-overflow fill-height">
    <v-toolbar height="50" color="#333333">
      <v-icon color="white" class="mr-2">library_books</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light mt-2">Conciliação Bancária</v-toolbar-title>
    </v-toolbar>
    <v-container fluid grid-list-md>
      <v-layout wrap>
        <v-flex xs12 md6>
          <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
            <v-card-title
              ><strong>{{ extrato.cedenteNome }}</strong></v-card-title
            >
            <v-divider></v-divider>
            <v-tabs v-model="tab" right flat height="20" slider-size="1">
              <v-spacer></v-spacer>
              <v-tab>Informações do Banco</v-tab>
              <v-tab>Saldo Inicial</v-tab>
              <v-tab>Saldo Final</v-tab>
            </v-tabs>

            <v-container fluid grid-list-md>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs6>
                        <v-text-field v-model="extrato.bancoNome" label="Banco" disabled color="blue-grey lighten-2"></v-text-field>
                      </v-flex>
                      <v-flex xs2>
                        <v-text-field v-model="extrato.sequencial" label="Sequencial" disabled color="blue-grey lighten-2"></v-text-field>
                      </v-flex>
                      <v-flex xs2>
                        <v-currency-field
                          :locale="`pt-${$store.state.usuario.site_pais}`"
                          v-model="extrato.somaC"
                          label="Soma C"
                          disabled
                          color="blue-grey lighten-2"
                        ></v-currency-field>
                      </v-flex>
                      <v-flex xs2>
                        <v-currency-field
                          :locale="`pt-${$store.state.usuario.site_pais}`"
                          v-model="extrato.somaD"
                          label="Soma D"
                          disabled
                          color="blue-grey lighten-2"
                        ></v-currency-field>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-tab-item>

                <v-tab-item>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs4>
                        <v-text-field v-model="extrato.dtI" label="Data" disabled color="blue-grey lighten-2"></v-text-field>
                      </v-flex>
                      <v-flex xs4>
                        <v-currency-field
                          :locale="`pt-${$store.state.usuario.site_pais}`"
                          v-model="extrato.saldoInicial"
                          label="Valor"
                          disabled
                          color="blue-grey lighten-2"
                        ></v-currency-field>
                      </v-flex>
                      <v-flex xs4>
                        <v-text-field v-model="extrato.stInicial" label="Status" disabled color="blue-grey lighten-2"></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-tab-item>

                <v-tab-item>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs2>
                        <v-text-field v-model="extrato.dtF" label="Data" disabled color="blue-grey lighten-2"></v-text-field>
                      </v-flex>
                      <v-flex xs2>
                        <v-currency-field
                          :locale="`pt-${$store.state.usuario.site_pais}`"
                          v-model="extrato.saldoFinal"
                          label="Valor"
                          disabled
                          color="blue-grey lighten-2"
                        ></v-currency-field>
                      </v-flex>
                      <v-flex xs2>
                        <v-text-field v-model="extrato.stFinal" label="Status" disabled color="blue-grey lighten-2"></v-text-field>
                      </v-flex>

                      <v-flex xs2>
                        <v-currency-field
                          :locale="`pt-${$store.state.usuario.site_pais}`"
                          v-model="extrato.limite"
                          label="Limite"
                          disabled
                          color="blue-grey lighten-2"
                        ></v-currency-field>
                      </v-flex>
                      <v-flex xs2>
                        <v-currency-field
                          :locale="`pt-${$store.state.usuario.site_pais}`"
                          v-model="extrato.bloqueado"
                          label="Bloqueado"
                          disabled
                          color="blue-grey lighten-2"
                        ></v-currency-field>
                      </v-flex>
                      <v-flex xs2>
                        <v-currency-field
                          :locale="`pt-${$store.state.usuario.site_pais}`"
                          v-model="extrato.bloqueado24"
                          label="Bloqueado +24hs"
                          disabled
                          color="blue-grey lighten-2"
                        ></v-currency-field>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-tab-item>
              </v-tabs-items>

              <v-layout wrap>
                <v-spacer></v-spacer>
                <v-flex offset-xs4 xs8>
                  <v-text-field
                    background-color="white"
                    class="mt-2 mb-2 elevation-0"
                    v-model="filtroPesquisa"
                    append-icon="search"
                    label="Digite para pesquisar..."
                    placeholder="Pesquisa pelo valor, código da categoria ou histórico, documento"
                    hide-details
                    @keyup.enter="limpaFiltro"
                    @click:append="limpaFiltro"
                  ></v-text-field>
                </v-flex>

                <v-flex xs8 md4 ml-4>
                  <v-checkbox v-model="selectAll" :label="selectAll ? 'Desmarcar todos' : 'Marcar todos da página'" @change="marcarDesmarcarTodos(selectAll)"></v-checkbox>
                </v-flex>
              </v-layout>

              <v-dialog v-model="dialogConciliacao" max-width="1200px">
                <v-card>
                  <Conciliacao :idExtratoLancamento="idExtratoLancamento" :dialogConciliacao.sync="dialogConciliacao" />
                </v-card>
              </v-dialog>

              <v-data-table
                mobile-breakpoint="0"
                :headers="headers"
                :items="registros"
                :options.sync="options"
                :no-data-text="mensagemPesquisa"
                :footer-props="rodape"
                :disable-sort="carregando"
                :disable-pagination="carregando"
              >
                <template v-slot:[getItemActions()]="{ item }">
                  <v-icon v-if="item.conciliado" color="green" title="Conciliado" @click="visualizarConciliacao(item)">copyright </v-icon>
                  <v-icon v-if="!item.conciliado" color="orange" title="Selecionar" @click="ativarDesativarItem(item)">
                    {{ item.selecionado == 1 ? "check_box" : "check_box_outline_blank" }}
                  </v-icon>
                  <v-icon v-if="item.conciliado && direitoExcluir" class="mr-2" color="red" title="Excluir Conciliação" @click="excluir(item)">delete</v-icon>
                </template>

                <template v-slot:[getItemDtLancamento()]="{ item }">{{ formatDate(item.dtLancamento) }}</template>

                <template v-slot:[getItemValor()]="{ item }">
                  {{ formatCurrency(item.valor) }}
                </template>

                <template v-slot:[getItemCategoria()]="{ item }">{{ item.codCategoria + " - " + item.categoria }}</template>

                <template v-slot:[getItemHistorico()]="{ item }">{{ item.codHistorico + " - " + item.historico }}</template>
              </v-data-table>

              <div class="text-xs-right pt-2 marg-bt">
                <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
              </div>

              <v-card-actions id="flex-wrap" v-if="direitoLancamentos">
                <v-btn color="orange lighten-2 white" style="color:white" @click="aplicar(false)"
                  >Prévia
                  <v-icon right>keyboard_arrow_right</v-icon>
                </v-btn>
                <v-btn color="green lighten-2 white" style="color:white" @click="aplicar(true)"
                  >Aplicar
                  <v-icon right>keyboard_arrow_right</v-icon>
                </v-btn>
              </v-card-actions>

              <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
              <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
            </v-container>
          </v-card>
        </v-flex>

        <v-flex xs12 md6>
          <v-card color="white lighten-1" lighten>
            <v-card-title><strong>Registros</strong></v-card-title>
            <v-divider></v-divider>
          </v-card>

          <v-expansion-panels v-model="painel" focusable>
            <v-expansion-panel dark class="elevation-0">
              <v-expansion-panel-header>
                <strong>Filtro</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap>
                  <v-flex xs4>
                    <v-menu
                      v-model="mnuIntervalo"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                      :disabled="carregandoLanc || carregandoParc"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="intervalo"
                          label="Período"
                          hint="Operação ou Vencimento"
                          persistent-hint
                          prepend-icon="event"
                          v-on="on"
                          color="blue-grey lighten-2"
                          @click:clear="
                            intervaloUS = ['', ''];
                            limpaFiltroReg();
                          "
                          readonly
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="intervaloUS" locale="pt-br" no-title range>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            mnuIntervalo = false;
                            limpaFiltroReg();
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs8></v-flex>

                  <v-flex xs12>
                    <v-autocomplete
                      v-model="categoria"
                      :items="categorias"
                      :filter="filtrarObjetos"
                      label="Categoria"
                      item-text="nome"
                      item-value="id"
                      no-data-text
                      autocomplete="disabled"
                      :name="Math.random()"
                      multiple
                      clearable
                      @change="limpaFiltroReg"
                      :disabled="carregandoLanc || carregandoParc"
                    >
                      <template v-slot:selection="data">
                        <v-avatar size="32" class="accent white--text" left>
                          {{ data.item.id }}
                        </v-avatar>
                        <span class="mr-2">{{ data.item.nomeC }}</span>
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.id"></v-list-item-title>
                          <v-list-item-subtitle v-html="data.item.nomeC"></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12>
                    <v-autocomplete
                      v-model="historico"
                      :items="historicos"
                      :filter="filtrarItens"
                      label="Histórico"
                      no-data-text
                      autocomplete="disabled"
                      :name="Math.random()"
                      multiple
                      clearable
                      @change="limpaFiltroReg"
                      :disabled="carregandoLanc || carregandoParc"
                    >
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs12>
                    <h4 class="overline font-weight-black font-italic">
                      Faixa de Valores
                    </h4>
                  </v-flex>
                  <v-flex xs10 md11>
                    <v-range-slider v-model="faixa" :max="max" :min="min" hide-details class="align-center" :disabled="carregandoLanc || carregandoParc">
                      <template v-slot:prepend>
                        <v-text-field
                          :value="faixa[0]"
                          class="mt-0 pt-0"
                          hide-details
                          single-line
                          type="number"
                          style="width: 60px"
                          @change="$set(faixa, 0, $event)"
                        ></v-text-field>
                      </template>
                      <template v-slot:append>
                        <v-text-field
                          :value="faixa[1]"
                          class="mt-0 pt-0"
                          hide-details
                          single-line
                          type="number"
                          style="width: 60px"
                          @change="$set(faixa, 1, $event)"
                        ></v-text-field>
                      </template>
                    </v-range-slider>
                  </v-flex>
                  <v-flex xs2 md1 text-right>
                    <v-btn color="accent" fab small @click="limpaFiltroReg" :disabled="carregandoLanc || carregandoParc">
                      <v-icon color="white" title="Filtrar">search</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-expansion-panel-header>
                <strong>Transferências</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap>
                  <v-flex offset-xs5 xs6>
                    <v-text-field
                      background-color="white"
                      class="mt-2 mb-2 elevation-0"
                      v-model="filtroPesquisaLanc"
                      append-icon="search"
                      label="Digite para pesquisar..."
                      placeholder="Pesquisa pela descrição"
                      hide-details
                      @keyup.enter="limpaFiltroLanc"
                      @click:append="limpaFiltroLanc"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1>
                    <v-btn color="success" fab small v-if="direitoCriarTransf && this.somaExtrato != 0">
                      <v-icon style="text-decoration: none" color="white" fab small @click="incluirTransf" title="Incluir Transferência">add</v-icon></v-btn
                    ></v-flex
                  >
                  <v-flex xs8 md4 ml-4>
                    <v-checkbox
                      v-model="selectAllLanc"
                      :label="selectAllLanc ? 'Desmarcar todos' : 'Marcar todos da página'"
                      @change="marcarDesmarcarTodosLanc(selectAllLanc)"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>

                <v-dialog persistent v-model="dialogTransferencia" max-width="700px">
                  <v-card>
                    <Transferencia :idTransferencia="idTransferencia" :descricao="descricao" :dialogTransferencia.sync="dialogTransferencia" />
                  </v-card>
                </v-dialog>

                <v-data-table
                  mobile-breakpoint="0"
                  :headers="headersLanc"
                  :items="lancamentos"
                  :options.sync="optionsLanc"
                  :carregando="carregandoLanc"
                  :no-data-text="mensagemPesquisaLanc"
                  :footer-props="rodapeLanc"
                  :disable-sort="carregandoLanc"
                  :disable-pagination="carregandoLanc"
                >
                  <template v-slot:[getItemActions()]="{ item }">
                    <div v-if="!item.conciliado">
                      <v-icon color="orange" title="Selecionar" @click="ativarDesativarLanc(item)">
                        {{ item.selecionado == 1 ? "check_box" : "check_box_outline_blank" }}
                      </v-icon>
                      <v-icon v-if="direitoEditarTransf" style="text-decoration: none" color="blue" @click="editarTransf(item)" title="Editar">edit</v-icon>
                    </div>
                  </template>

                  <template v-slot:[getItemDtOperacao()]="{ item }">
                    <span>{{ formatDate(item.dtOperacao) }}</span>
                  </template>
                  <template v-slot:[getItemVlrBruto()]="{ item }">
                    {{ formatCurrency(item.vlrBruto) }}
                  </template>
                </v-data-table>

                <div class="text-xs-center pt-2 marg-bt">
                  <v-pagination
                    v-model="paginaAtualLanc"
                    :length="totalPaginasLanc"
                    :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7"
                    circle
                    :disabled="carregandoLanc"
                  ></v-pagination>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-expansion-panel-header>
                <strong>Parcelas</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap>
                  <v-flex xs4>
                    <v-menu
                      v-model="mnuIntervaloQuit"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                      :disabled="carregandoLanc || carregandoParc"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="intervaloQuit"
                          label="Período"
                          hint="Quitação"
                          persistent-hint
                          prepend-icon="event"
                          v-on="on"
                          color="blue-grey lighten-2"
                          @click:clear="
                            intervaloQuitUS = ['', ''];
                            limpaFiltroReg();
                          "
                          readonly
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="intervaloQuitUS" locale="pt-br" no-title range>
                        ><v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            mnuIntervaloQuit = false;
                            limpaFiltroReg();
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-flex>

                  <v-flex offset-xs1 xs6>
                    <v-text-field
                      class="mt-2 mb-2 elevation-0"
                      v-model="filtroPesquisaParc"
                      append-icon="search"
                      label="Digite para pesquisar..."
                      placeholder="Pesquisa pelo ID, cliente, valor, forma pagamento!"
                      hint="use '.' para busca por valor (ex: 25.00)"
                      @keyup.enter="limpaFiltroParc"
                      @click:append="limpaFiltroParc"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1>
                    <v-btn color="success" fab small v-if="((tipo == 1 && direitoCriarOperacaoReceber) || (tipo == 0 && direitoCriarOperacaoPagar)) && this.somaExtrato != 0">
                      <v-icon style="text-decoration: none" color="white" fab small @click="incluirOperacao" title="Incluir Operação">add</v-icon></v-btn
                    ></v-flex
                  >
                  <v-flex xs8 md4 ml-4>
                    <v-checkbox
                      v-model="selectAllParc"
                      :label="selectAllParc ? 'Desmarcar todos' : 'Marcar todos da página'"
                      @change="marcarDesmarcarTodosParc(selectAllParc)"
                    ></v-checkbox>
                  </v-flex>

                  <v-flex offset-xs2 xs5>
                    <v-text-field
                      background-color="white"
                      class="mt-2 mb-2 elevation-0"
                      v-model="filtroPesquisaParc2"
                      append-icon="search"
                      label="Digite para pesquisar..."
                      placeholder="Pesquisa pelo texto fatura, número NFe"
                      @keyup.enter="limpaFiltroParc"
                      @click:append="limpaFiltroParc"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-dialog persistent v-model="dialogParcela" max-width="700px">
                  <v-card>
                    <Parcela :id="idParcela" :receber="receber + ''" :dialogParcela.sync="dialogParcela" />
                  </v-card>
                </v-dialog>

                <v-data-table
                  mobile-breakpoint="0"
                  :headers="headersParc"
                  :items="parcelas"
                  :options.sync="optionsParc"
                  :carregando="carregandoParc"
                  :no-data-text="mensagemPesquisaParc"
                  :footer-props="rodapeParc"
                  :disable-sort="carregandoParc"
                  :disable-pagination="carregandoParc"
                >
                  <template v-slot:[getItemActions()]="{ item }">
                    <div v-if="!item.conciliado">
                      <v-icon color="orange" title="Selecionar" @click="ativarDesativarParc(item)">
                        {{ item.selecionado == 1 ? "check_box" : "check_box_outline_blank" }}
                      </v-icon>
                      <v-icon
                        v-if="(item.tipo == 1 && direitoParcelaReceber) || (item.tipo == 0 && direitoParcelaPagar)"
                        style="text-decoration: none"
                        color="blue"
                        @click="editarParcela(item)"
                        title="Editar"
                        >edit</v-icon
                      >
                      <v-icon
                        v-if="item.origem == 'FIN' && ((item.tipo == 1 && direitoOperacaoReceber) || (item.tipo == 0 && direitoOperacaoPagar))"
                        color="blue"
                        title="Operação"
                        @click="visualizarOperacao(item)"
                        >{{ item.tipo == 1 ? "add_circle" : "remove_circle" }}</v-icon
                      >
                      <v-icon v-if="direitoAssinatura && item.origem == 'ASS'" color="red" title="Assinatura" @click="visualizarRenovacao(item)">assignment</v-icon>
                      <v-icon v-if="direitoAnuncio && item.origem == 'ANU'" color="yellow darken-2" title="Anúncio" @click="visualizarAnuncio(item)">{{
                        item.idAnuncio ? "chrome_reader_mode" : "edit"
                      }}</v-icon>
                      <v-icon v-if="direitoAnuncio && item.origem == 'PAC'" color="yellow darken-3" title="Pacote">library_books</v-icon>
                      <v-icon v-if="direitoVendaAvulsa && item.origem == 'AVU'" color="blue" title="Venda Avulsa" @click="visualizarVendaAvulsa(item)">list_alt</v-icon>
                    </div>
                  </template>
                  <template v-slot:[getItemIdOperacao()]="{ item }">
                    <span>
                      {{ item.idAssinatura ? item.idAssinatura + "/" + item.renovacao : item.idAnuncio ? item.idAnuncio : item.idPacote ? item.idPacote : item.idOperacao }}
                    </span>
                  </template>
                  <template v-slot:[getItemStatus()]="{ item }">
                    <span
                      :class="
                        item.status == 'ABERTA'
                          ? 'blue--text'
                          : item.status == 'BLOQUEADA'
                          ? 'orange--text'
                          : item.status == 'CANCELADA'
                          ? 'red--text'
                          : item.status == 'QUITADA'
                          ? 'green--text'
                          : ''
                      "
                      >{{ item.status }}</span
                    >
                  </template>
                  <template v-slot:[getItemDtVecto()]="{ item }">
                    <span>{{ formatDate(item.dtVecto) }}</span>
                  </template>
                  <template v-slot:[getItemValor()]="{ item }">
                    {{ formatCurrency(item.valor + item.descAcr) }}
                  </template>
                </v-data-table>
                <div class="text-xs-center pt-2 marg-bt">
                  <v-pagination
                    v-model="paginaAtualParc"
                    :length="totalPaginasParc"
                    :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7"
                    circle
                    :disabled="carregandoParc"
                  ></v-pagination>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <br />
          <div id="flex-wrap" class="text-right">
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="5" indeterminate></v-progress-linear>

            <h4 class="overline font-weight-black font-italic">
              Soma Itens Extrato...:
              {{ formatCurrency(somaExtrato) }}
            </h4>
            <h4 class="overline font-weight-black font-italic">
              Soma Itens Registros:
              {{ formatCurrency(somaRegistro) }}
            </h4>
            <br />
            <v-divider></v-divider>
            <br />
            <h4 v-if="nossosNumeros.length > 0" class="overline font-weight-black font-italic">
              Número Documentos Quitados:
              {{ nossosNumeros.length }}
            </h4>
            <h4 v-if="totalQuitados > 0" class="overline font-weight-black font-italic">
              Soma Parcelas Quitadas em {{ formatDate(dtQuitados) }}:
              {{ formatCurrency(totalQuitados) }}
            </h4>
            <h4 v-if="taxaCobranca > 0" class="overline font-weight-black font-italic">
              Taxa Cobrança:
              {{ formatCurrency(taxaCobranca) }}
            </h4>
            <h4 v-if="taxaRegistro > 0" class="overline font-weight-black font-italic">
              Taxa Registro:
              {{ formatCurrency(taxaRegistro) }}
            </h4>
            <h5 v-if="msgQuitacao" :class="msgQuitacao.startsWith('ERRO') ? 'red--text' : 'blue--text'">
              {{ msgQuitacao }}
            </h5>
            <br />
            <v-divider></v-divider>
            <br />

            <v-btn v-if="(somaExtrato != 0 && somaExtrato == somaRegistro) || operacoes.length > 0" color="green lighten-2 white" style="color:white" @click="conciliar">
              <v-icon left>arrow_forward</v-icon>Conciliar
            </v-btn>
          </div>
        </v-flex>
      </v-layout>
    </v-container>

    <v-divider></v-divider>
    <v-card-actions id="flex-wrap">
      <v-btn to="/financeiro/extratos" color="orange lighten-2 white" style="color:white"> <v-icon left>keyboard_arrow_left</v-icon>Retornar </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style>
.v__tabextrato .v-tabs__bar {
  margin-top: -25px;
  margin-left: -30px;
  margin-right: -30px;
  padding-right: 30px;
  padding-left: 25px;
}
</style>

<script>
import Parcela from "@/views/fin/Parcela.vue";
import Transferencia from "@/views/fin/Transferencia.vue";
import Conciliacao from "@/views/fin/Conciliacao.vue";
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  components: {
    Parcela,
    Transferencia,
    Conciliacao
  },
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions", width: "100" },
        { text: "ID", value: "id" },
        { text: "Ordem", value: "sequencia" },
        { text: "Data", value: "dtLancamento" },
        { text: "Valor", value: "valor", align: "end" },
        { text: "Categoria", value: "categoria" },
        { text: "Histórico", value: "historico" },
        { text: "Documento", value: "nroDoc" }
      ],
      headersLanc: [
        { text: "Ações", value: "actions", width: "100" },
        { text: "ID", value: "id" },
        { text: "Data", value: "dtOperacao" },
        { text: "Destino/Despesa", value: "contaD" },
        { text: "Origem", value: "contaC" },
        { text: "Descrição", value: "descricao" },
        { text: "Valor", value: "vlrBruto", align: "end" }
      ],
      headersParc: [
        { text: "Ações", value: "actions", width: "110" },
        { text: "ID", value: "id" },
        { text: "Código", value: "idOperacao" },
        { text: "Cliente", value: "cliente" },
        { text: "Titular", value: "identificacao" },
        { text: "Status", value: "status" },
        { text: "Forma Pgto", value: "formaPagamento" },
        { text: "Vencimento", value: "dtVecto" },
        { text: "Valor", value: "valor", align: "end" }
      ],

      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },
      rodapeLanc: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },
      rodapeParc: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50, 100, 300, 500, 1000],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      mensagem: "",
      snackbar: false,
      snackErro: false,
      selectAll: false,
      selectAllLanc: false,
      selectAllParc: false,
      isUpdating: false,

      direitoCriarTransf: false,
      direitoCriarOperacaoReceber: false,
      direitoCriarOperacaoPagar: false,
      direitoEditarTransf: false,
      direitoVendaAvulsa: false,
      direitoParcelaReceber: false,
      direitoParcelaPagar: false,
      direitoOperacaoReceber: false,
      direitoOperacaoPagar: false,
      direitoAssinatura: false,
      direitoAnuncio: false,
      direitoLancamentos: false,
      direitoExcluir: false,

      painel: null,
      somaExtrato: 0,
      somaRegistro: 0,

      //* Filtro
      mnuIntervalo: false,
      intervaloUS: ["", ""],
      categoria: [],
      historico: [],

      mnuIntervaloQuit: false,
      intervaloQuitUS: ["", ""],

      min: 0,
      max: 0,
      faixa: [0, 0],
      tipo: null,

      categorias: [
        { id: 101, nomeC: "Cheques" },
        { id: 102, nomeC: "Encargos" },
        { id: 103, nomeC: "Estornos" },
        { id: 104, nomeC: "Lançamento Avisado" },
        { id: 105, nomeC: "Tarifas" },
        { id: 106, nomeC: "Aplicação" },
        { id: 107, nomeC: "Empréstimo / Financiamento" },
        { id: 108, nomeC: "Câmbio" },
        { id: 109, nomeC: "CPMF" },
        { id: 110, nomeC: "IOF" },
        { id: 111, nomeC: "Imposto de Renda" },
        { id: 112, nomeC: "Pagamento Fornecedores" },
        { id: 113, nomeC: "Pagamento Funcionários" },
        { id: 114, nomeC: "Saque Eletrônico" },
        { id: 115, nomeC: "Ações" },
        { id: 117, nomeC: "Transferência entre Contas" },
        { id: 118, nomeC: "Devolução da Compensação" },
        { id: 119, nomeC: "Devolução de Cheque Depositado" },
        { id: 120, nomeC: "Transferência Interbancária (DOC, TED)" },
        { id: 121, nomeC: "Antecipação a Fornecedores" },
        { id: 122, nomeC: "OC / AEROPS" },
        { id: 201, nomeC: "Depósitos" },
        { id: 202, nomeC: "Líquido de Cobrança" },
        { id: 203, nomeC: "Devolução de Cheques" },
        { id: 204, nomeC: "Estornos" },
        { id: 205, nomeC: "Lançamento Avisado" },
        { id: 206, nomeC: "Resgate de Aplicação" },
        { id: 207, nomeC: "Empréstimo / Financiamento" },
        { id: 208, nomeC: "Câmbio" },
        { id: 209, nomeC: "Transferência Interbancária (DOC, TED)" },
        { id: 210, nomeC: "Ações" },
        { id: 211, nomeC: "Dividendos" },
        { id: 212, nomeC: "Seguro" },
        { id: 213, nomeC: "Transferência entre Contas" },
        { id: 214, nomeC: "Depósitos Especiais" },
        { id: 215, nomeC: "Devolução da Compensação" },
        { id: 216, nomeC: "OCT" }
      ],
      historicos: [],

      mensagemPesquisa: "",
      mensagemPesquisaLanc: "",
      mensagemPesquisaParc: "",
      filtroPesquisa: "",
      filtroPesquisaLanc: "",
      filtroPesquisaParc: "",
      filtroPesquisaParc2: "",

      extrato: {},
      registros: [],
      lancamentos: [],
      parcelas: [],

      idCedente: null,
      nossosNumeros: [],
      taxaCobranca: 0,
      taxaRegistro: 0,
      totalQuitados: 0,
      dtQuitados: null,
      msgQuitacao: "",
      erroQuitacao: false,
      operacoes: [],

      dialogTransferencia: false,
      idTransferencia: "",
      descricao: "",

      dialogParcela: false,
      idParcela: "",
      receber: false,

      dialogConciliacao: false,
      idExtratoLancamento: "",

      tab: null,

      idSitePrincipal: null,
      registrosPagina: 10,
      registrosPaginaLanc: 10,
      registrosPaginaParc: 10,
      totalRegistros: 0,
      totalRegistrosLanc: 0,
      totalRegistrosParc: 0,
      totalPaginas: 0,
      totalPaginasLanc: 0,
      totalPaginasParc: 0,
      paginaAtual: 1,
      paginaAtualLanc: 1,
      paginaAtualParc: 1,
      carregando: true,
      carregandoLanc: true,
      carregandoParc: true,
      ordenacao: "",
      ordenacaoLanc: "",
      ordenacaoParc: "",
      ordem: "",
      ordemLanc: "",
      ordemParc: "",
      options: {},
      optionsLanc: {},
      optionsParc: {}
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO", "OCULTAR_MENU"]),

    getItemActions() {
      return "item.actions";
    },
    getItemDtLancamento() {
      return "item.dtLancamento";
    },
    getItemValor() {
      return "item.valor";
    },
    getItemDtVecto() {
      return "item.dtVecto";
    },
    getItemCategoria() {
      return "item.categoria";
    },
    getItemHistorico() {
      return "item.historico";
    },

    getItemIdOperacao() {
      return "item.idOperacao";
    },
    getItemDtOperacao() {
      return "item.dtOperacao";
    },
    getItemVlrBruto() {
      return "item.vlrBruto";
    },
    getItemStatus() {
      return "item.status";
    },

    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados() {
      this.registros = [];
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/extrato-lancamento/extrato/" +
            this.id +
            "?pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;

        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        this.mensagemPesquisa = "Erro ao buscar dados: " + e;
      }
    },

    limpaFiltroReg() {
      this.paginaAtualLanc = 1;
      this.paginaAtualParc = 1;
      this.pesquisaDadosLanc();
      this.pesquisaDadosParc();
    },
    limpaFiltroLanc() {
      this.paginaAtualLanc = 1;
      this.pesquisaDadosLanc();
    },
    limpaFiltroParc() {
      this.paginaAtualParc = 1;
      this.pesquisaDadosParc();
    },
    async pesquisaDadosLanc(selecionar) {
      //sinaliza que está carregando
      this.carregandoLanc = true;
      this.lancamentos = [];
      this.mensagemPesquisaLanc = "Carregando...";
      try {
        var filtro = "&tipo=2";

        if (this.operacoes.length == 0) {
          var dtInicio = "";
          var dtFim = "";
          if (this.intervaloUS && this.intervaloUS.length > 1) {
            dtInicio = this.intervaloUS[0];
            dtFim = this.intervaloUS[1];
          }
          if (dtInicio) {
            filtro += "&dtInicio=" + dtInicio;
            filtro += "&dtFim=" + dtFim;
          }
          if (this.categoria.length > 0) {
            filtro += "&categorias=" + this.categoria.join(",");
          }
          if (this.historico.length > 0) {
            filtro += "&historicos=" + this.historico.join(",");
          }
          filtro += "&valorInicio=" + this.faixa[0].toFixed(2);
          filtro += "&valorFim=" + this.faixa[1].toFixed(2);

          if (this.filtroPesquisaLanc) {
            filtro += "&filtro=" + this.filtroPesquisaLanc;
          }
        } else {
          filtro += "&ids=" + this.operacoes.join(",");
        }

        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/operacao/tipo/2?idSite=" +
            this.idSitePrincipal +
            "&situacao=1" +
            "&pagina=" +
            this.paginaAtualLanc +
            "&tamanho=" +
            this.registrosPaginaLanc +
            filtro +
            "&ordenacao=" +
            this.ordenacaoLanc +
            "&ordem=" +
            this.ordemLanc
        );
        this.lancamentos = response.data.data;
        if (this.lancamentos.length > 0) {
          this.painel = 1;
        }
        this.totalRegistrosLanc = response.data.total;
        this.totalPaginasLanc = Math.ceil(this.totalRegistrosLanc / this.registrosPaginaLanc);
        this.rodapeLanc.pageText = this.totalRegistrosLanc + " itens";
        //fecha o loading
        this.carregandoLanc = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisaLanc = this.lancamentos.length > 1 ? "" : "Nenhum registro encontrado";

        if (selecionar) {
          setTimeout(() => {
            this.lancamentos.forEach(element => {
              element.selecionado = 1;
            });
          }, 300);
          this.somaRegistro = this.somaExtrato;
        }
      } catch (e) {
        this.carregando = false;
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    async pesquisaDadosParc() {
      //sinaliza que está carregando
      this.carregandoParc = true;
      this.parcelas = [];
      this.mensagemPesquisaParc = "Carregando...";
      try {
        var filtro = "&tipo=" + this.tipo;

        var dtInicio = "";
        var dtFim = "";
        if (this.intervaloUS && this.intervaloUS.length > 1) {
          dtInicio = this.intervaloUS[0];
          dtFim = this.intervaloUS[1];
        }
        if (dtInicio) {
          filtro += "&dtInicio=" + dtInicio;
          filtro += "&dtFim=" + dtFim;
        }
        var dtInicioQuit = "";
        var dtFimQuit = "";
        if (this.intervaloQuitUS && this.intervaloQuitUS.length > 1) {
          dtInicioQuit = this.intervaloQuitUS[0];
          dtFimQuit = this.intervaloQuitUS[1];
        }
        if (dtInicioQuit) {
          filtro += "&dtInicioQuit=" + dtInicioQuit;
          filtro += "&dtFimQuit=" + dtFimQuit;
        }

        if (this.categoria.length > 0) {
          filtro += "&categorias=" + this.categoria.join(",");
        }
        if (this.historico.length > 0) {
          filtro += "&historicos=" + this.historico.join(",");
        }

        filtro += "&valorInicio=" + Math.min(Math.abs(this.faixa[0]), Math.abs(this.faixa[1])).toFixed(2);
        filtro += "&valorFim=" + Math.max(Math.abs(this.faixa[0]), Math.abs(this.faixa[1])).toFixed(2);

        if (this.filtroPesquisaParc) {
          filtro += "&filtro=" + this.filtroPesquisaParc;
        }
        if (this.filtroPesquisaParc2) {
          filtro += "&filtro2=" + this.filtroPesquisaParc2;
        }

        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/parcela/conciliacao?idSite=" +
            this.idSitePrincipal +
            "&pagina=" +
            this.paginaAtualParc +
            "&tamanho=" +
            this.registrosPaginaParc +
            filtro +
            "&ordenacao=" +
            this.ordenacaoParc +
            "&ordem=" +
            this.ordemParc
        );
        this.parcelas = response.data.data;
        if (this.parcelas.length > 0) {
          this.painel = 2;
        }
        this.totalRegistrosParc = response.data.total;
        this.totalPaginasParc = Math.ceil(this.totalRegistrosParc / this.registrosPaginaParc);
        this.rodapeParc.pageText = this.totalRegistrosParc + " itens";
        //fecha o loading
        this.carregandoParc = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisaParc = this.parcelas.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregandoParc = false;
        this.mensagemPesquisaParc = "Erro ao buscar dados: " + e;
      }
    },

    async montaCreditoBoletoDC(lancar, item, forma) {
      try {
        //* busca cedente_conta_historico do cedente
        const responseCCH = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/cedente-conta-historico/cedente/" + this.extrato.idCedente);
        var cchCredito = {};
        var cchDespesa = {};
        responseCCH.data.forEach(element => {
          if (forma == "BOLETO" && element.acao == "CREDITO_BOLETO") {
            cchCredito = element;
          } else if (forma == "DEBITO_CONTA" && element.acao == "CREDITO_DEBITO_CONTA") {
            cchCredito = element;
          } else if (forma == "BOLETO" && element.acao == "TAXA_COBRANCA_BOLETO") {
            cchDespesa = element;
          } else if (forma == "DEBITO_CONTA" && element.acao == "TAXA_COBRANCA_DEBITO_CONTA") {
            //para Sicredi
            cchDespesa = element;
          }
        });

        //* Se é crédito de boletos
        //* busca os boletos quitados no dia útil anterior ao dia do extrato
        var data = util.parseDateUS(item.dtLancamento); //this.extrato.dtFinal
        data.setDate(data.getDate() - 1);
        if (data.getDay() == 0) {
          //domingo, puxa para sexta
          data.setDate(data.getDate() - 2);
        } else if (data.getDay() == 6) {
          //sábado, puxa para sexta
          data.setDate(data.getDate() - 1);
        } else {
          //verifica se é feriado
          const isFeriado = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/feriado/isFeriado?dtFeriado=" + util.dateToStringUS(data));
          if (isFeriado.data) {
            //feriado, puxa para o dia anterior
            data.setDate(data.getDate() - 1);
            if (data.getDay() == 0) {
              //domingo, puxa para sexta
              data.setDate(data.getDate() - 2);
            } else if (data.getDay() == 6) {
              //sábado, puxa para sexta
              data.setDate(data.getDate() - 1);
            }
          }
        }
        data.setDate(data.getDate() - (this.extrato.diasExtrato - 1));
        if (data.getDay() == 0) {
          //domingo, puxa para sexta
          data.setDate(data.getDate() - 2);
        } else if (data.getDay() == 6) {
          //sábado, puxa para sexta
          data.setDate(data.getDate() - 1);
        } else {
          //verifica se é feriado
          const isFeriado = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/feriado/isFeriado?dtFeriado=" + util.dateToStringUS(data));
          if (isFeriado.data) {
            //feriado, puxa para o dia anterior
            data.setDate(data.getDate() - 1);
            if (data.getDay() == 0) {
              //domingo, puxa para sexta
              data.setDate(data.getDate() - 2);
            } else if (data.getDay() == 6) {
              //sábado, puxa para sexta
              data.setDate(data.getDate() - 1);
            }
          }
        }
        this.dtQuitados = data;

        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/parcela/nossosNumeros?forma=" +
            forma +
            "&bancoNumero=" +
            this.extrato.bancoNumero +
            "&data=" +
            util.dateToStringUS(data) +
            "&taxaCobranca=" +
            (forma == "BOLETO" ? this.extrato.taxaCobrancaBoleto : this.extrato.taxaCobrancaDC) +
            "&total=" +
            item.valor
        );
        if (response.data && response.data.data) {
          this.nossosNumeros = response.data.data;
          this.idCedente = response.data.cedente.id;

          this.taxaCobranca = 0;
          if (cchDespesa.id) {
            //* Calcula a taxa de cobrança (taxaCobranca x nossoNumero's)
            this.taxaCobranca = (forma == "BOLETO" ? this.extrato.taxaCobrancaBoleto : this.extrato.taxaCobrancaDC) * this.nossosNumeros.length;
            this.taxaCobranca = parseFloat(this.taxaCobranca.toFixed(2));
          }

          this.totalQuitados = 0;
          this.nossosNumeros.forEach(element => {
            this.totalQuitados += element.liquido;
          });
          this.totalQuitados = parseFloat(this.totalQuitados.toFixed(2));

          //* Compara a soma dos boletos quitados - a taxa de cobrança com o valor do crédito de boletos no extrato
          //* Se der diferente, avisar em vermelho (o botão aplicar não será mostrado, pois precisam editar e corrigir o lançamento da taxa)
          if (this.totalQuitados - this.taxaCobranca == item.valor) {
            this.msgQuitacao = "Dados de quitação estão corretos";
            this.erroQuitacao = false;
          } else {
            this.msgQuitacao =
              "ERRO: Valor creditado não corresponde ao valor quitado em " +
              util.formatDate(data) +
              (this.taxaCobranca ? " menos a taxa de cobrança" : "") +
              ". # " +
              util.formatCurrency(item.valor - this.totalQuitados - this.taxaCobranca);
            this.erroQuitacao = true;
          }

          var operacaoCommit = {};

          if (lancar && cchCredito.id) {
            //* Cria os lançamentos com o valor do crédito de boletos
            operacaoCommit = {
              transferencia: 1,
              idSite: 18,
              tipo: 2,
              dtOperacao: util.dateToStringUS(item.dtLancamento), //this.extrato.dtFinal),
              idUsuario: this.$store.state.usuario.id,
              dtCancelamento: null,
              descricao: item.codCategoria + " - " + item.categoria + " - " + item.codHistorico + " - " + item.historico + " - " + item.nroDoc,
              idCliente: null,
              idDeptoCliente: null,
              origem: "FIN",
              vlrBruto: item.valor,
              vlrLiquido: item.valor,
              vlrTaxado: 0,
              nroParcelas: 0,
              //frete: 9,
              duplicatas: 0,
              situacao: 1,
              mensal: 0,
              confirmada: 1,
              idContaD: cchCredito.idConta,
              idContaC: response.data.cedente.idConta
            };

            const responseLanc1 = await api.post(process.env.VUE_APP_URL_WEB_API + "fin/operacao?idSite=" + this.idSitePrincipal, operacaoCommit);
            this.operacoes.push(responseLanc1.data.id);
            this.mensagem = "Transferência " + responseLanc1.data.id + " incluída automaticamente";
            this.snackbar = true;
          }

          if (lancar && cchDespesa.id) {
            //* Cria os lançamentos com o valor da despesa de boletos
            //* faz o lançamento: 2. D-Despesa com Boletos s/ Liquidação, C-Transação de valores - com a diferença do total dos boletos menos crédito no extrato - que deve ser igual a totalização acima (1xNN)
            operacaoCommit.vlrBruto = this.taxaCobranca;
            operacaoCommit.vlrLiquido = this.taxaCobranca;
            operacaoCommit.idContaD = cchDespesa.idConta;
            operacaoCommit.idContaC = response.data.cedente.idConta;

            const responseLanc2 = await api.post(process.env.VUE_APP_URL_WEB_API + "fin/operacao?idSite=" + this.idSitePrincipal, operacaoCommit);
            this.operacoes.push(responseLanc2.data.id);
            this.mensagem = "Transferência " + responseLanc2.data.id + " incluída automaticamente";
            this.snackbar = true;
          }

          this.pesquisaDadosLanc(lancar);
        }
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    async montaCreditoCartao(lancar, item) {
      try {
        //* busca cedente_conta_historico do cedente
        const responseCCH = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/cedente-conta-historico/cedente/" + this.extrato.idCedente);
        var cchCredito = {};
        responseCCH.data.forEach(element => {
          if (element.acao == "CREDITO_CARTAO") {
            cchCredito = element;
          }
        });

        //* busca a conta da forma de pagamento Cartão de Crédito
        const responseForma = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/forma-pagamento/tipo?tipo=CARTAO_CREDITO");
        if (responseForma.data && responseForma.data.idConta) {
          var operacaoCommit = {};
          if (lancar && cchCredito.id) {
            //* Cria o lançamento de transferência do cartão
            operacaoCommit = {
              transferencia: 1,
              idSite: 18,
              tipo: 2,
              dtOperacao: util.dateToStringUS(this.extrato.dtFinal),
              idUsuario: this.$store.state.usuario.id,
              dtCancelamento: null,
              descricao: item.codCategoria + " - " + item.categoria + " - " + item.codHistorico + " - " + item.historico + " - " + item.nroDoc,
              idCliente: null,
              idDeptoCliente: null,
              origem: "FIN",
              vlrBruto: item.valor,
              vlrLiquido: item.valor,
              vlrTaxado: 0,
              nroParcelas: 0,
              //frete: 9,
              duplicatas: 0,
              situacao: 1,
              mensal: 0,
              confirmada: 1,
              idContaD: cchCredito.idConta,
              idContaC: responseForma.data.idConta
            };

            const responseLanc1 = await api.post(process.env.VUE_APP_URL_WEB_API + "fin/operacao?idSite=" + this.idSitePrincipal, operacaoCommit);
            this.operacoes.push(responseLanc1.data.id);
            this.mensagem = "Lançamento " + responseLanc1.data.id + " incluído automaticamente";
            this.snackbar = true;
          }

          this.pesquisaDadosLanc(lancar);
        }
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    async montaLancamento(lancar, item) {
      try {
        //* busca cedente_conta_historico do cedente
        const responseCCH = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/cedente-conta-historico/cedente/" + this.extrato.idCedente);
        var cchLancamento = {};
        responseCCH.data.forEach(element => {
          if (element.acao == "LANÇAMENTO" && element.codHistorico == item.codHistorico && (element.historico == "" || element.historico == item.historico)) {
            cchLancamento = element;
          }
        });

        if (lancar && cchLancamento.id) {
          var operacaoCommit = {};
          //* Cria o lançamento de transferência banco/conta associada ao histórico
          operacaoCommit = {
            transferencia: 1,
            idSite: 18,
            tipo: 2,
            dtOperacao: util.dateToStringUS(this.extrato.dtFinal),
            idUsuario: this.$store.state.usuario.id,
            dtCancelamento: null,
            descricao: item.codCategoria + " - " + item.categoria + " - " + item.codHistorico + " - " + item.historico + " - " + item.nroDoc,
            idCliente: null,
            idDeptoCliente: null,
            origem: "FIN",
            vlrBruto: Math.abs(item.valor),
            vlrLiquido: Math.abs(item.valor),
            vlrTaxado: 0,
            nroParcelas: 0,
            //frete: 9,
            duplicatas: 0,
            situacao: 1,
            mensal: 0,
            confirmada: 1,
            idContaD: item.valor < 0 ? this.extrato.idConta : cchLancamento.idConta,
            idContaC: item.valor > 0 ? this.extrato.idConta : cchLancamento.idConta
          };
          const responseLanc1 = await api.post(process.env.VUE_APP_URL_WEB_API + "fin/operacao?idSite=" + this.idSitePrincipal, operacaoCommit);
          this.operacoes.push(responseLanc1.data.id);
          this.mensagem = "Lançamento " + responseLanc1.data.id + " incluído automaticamente";
          this.snackbar = true;
        }

        this.pesquisaDadosLanc(lancar);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    ativarDesativarItem(item) {
      if (!item.conciliado) {
        var dadoIndex = this.registros.indexOf(item);
        var dadoItem = Object.assign({}, item);
        dadoItem.selecionado = dadoItem.selecionado == 1 ? 0 : 1;
        Object.assign(this.registros[dadoIndex], dadoItem);
        this.limparDadosFiltro();
      }
    },
    marcarDesmarcarTodos(key) {
      this.registros.forEach(element => {
        if (!element.conciliado) {
          element.selecionado = key;
        }
      });
      this.limparDadosFiltro();
    },
    limparDadosFiltro() {
      this.intervaloUS = ["", ""];
      this.intervaloQuitUS = ["", ""];
      this.categoria = [];
      this.historicos = [];
      this.historico = [];
      this.somaExtrato = 0;
      this.somaRegistro = 0;
      this.min = 0;
      this.max = 0;
      this.faixa = [0, 0];
      this.tipo = null;
      this.painel = null;
    },

    incluirTransf() {
      this.idTransferencia = "novo";
      this.descricao = "";
      this.registros.forEach(element => {
        if (element.selecionado) {
          this.descricao += (this.descricao ? "," : "") + element.id;
        }
      });

      this.dialogTransferencia = true;
    },
    editarTransf(item) {
      this.idTransferencia = item.id + "";
      this.dialogTransferencia = true;
    },
    closeTransferencia() {
      this.dialogTransferencia = false;
      this.idTransferencia = "x";
    },

    incluirOperacao() {
      window.open("/financeiro/operacao/" + (this.tipo == 1 ? "receber/" : "pagar/"), "_blank");
    },
    editarParcela(item) {
      this.receber = item.tipo == 1;
      this.idParcela = item.id + "";
      this.dialogParcela = true;
    },
    closeParcela() {
      this.dialogParcela = false;
      this.idParcela = "";
    },

    visualizarConciliacao(item) {
      this.idExtratoLancamento = item.id + "";
      this.dialogConciliacao = true;
    },
    closeConciliacao() {
      this.dialogConciliacao = false;
      this.idExtratoLancamento = "";
    },

    visualizarOperacao(item) {
      window.open("/financeiro/operacao/" + (item.tipo == 1 ? "receber/" : "pagar/") + item.idOperacao, "_blank");
    },
    visualizarRenovacao(item) {
      window.open("/assinaturas/assinatura/" + item.idAssinatura + "/" + item.idRenovacao, "_blank");
    },
    visualizarAnuncio(item) {
      if (item.idAnuncio) {
        window.open("/comercial/anuncio/" + item.idAnuncio, "_blank");
      } else {
        window.open("/financeiro/operacao/receber/" + item.id, "_blank");
      }
    },
    visualizarVendaAvulsa(item) {
      window.open("/financeiro/operacao/receber/" + item.idOperacao, "_blank");
    },

    ativarDesativarLanc(item) {
      if (!item.conciliado) {
        var dadoIndex = this.lancamentos.indexOf(item);
        var dadoItem = Object.assign({}, item);
        dadoItem.selecionado = dadoItem.selecionado == 1 ? 0 : 1;
        Object.assign(this.lancamentos[dadoIndex], dadoItem);
        this.calcularSomaRegistro();
      }
    },
    marcarDesmarcarTodosLanc(key) {
      this.lancamentos.forEach(element => {
        if (!element.conciliado) {
          element.selecionado = key;
        }
      });
      this.calcularSomaRegistro();
    },

    ativarDesativarParc(item) {
      if (!item.conciliado) {
        var dadoIndex = this.parcelas.indexOf(item);
        var dadoItem = Object.assign({}, item);
        dadoItem.selecionado = dadoItem.selecionado == 1 ? 0 : 1;
        Object.assign(this.parcelas[dadoIndex], dadoItem);
        this.calcularSomaRegistro();
      }
    },
    marcarDesmarcarTodosParc(key) {
      this.parcelas.forEach(element => {
        if (!element.conciliado) {
          element.selecionado = key;
        }
      });
      this.calcularSomaRegistro();
    },

    async aplicar(lancar) {
      this.isUpdating = true;
      this.categoria = [];
      this.historicos = [];
      this.historico = [];
      this.somaExtrato = 0;
      this.somaRegistro = 0;

      this.idCedente = null;
      this.nossosNumeros = [];
      this.taxaCobranca = 0;
      this.taxaRegistro = 0;
      this.totalQuitados = 0;
      this.operacoes = [];
      this.msgQuitacao = "";

      this.registros.forEach(element => {
        if (element.selecionado) {
          if (!this.historicos.includes(element.codHistorico)) {
            this.historicos.push(element.codHistorico + " - " + element.historico + (element.acao ? " | " + element.acao : ""));
            // this.historico.push(element.codHistorico);
          }
          this.somaExtrato += element.valor;
        }
      });
      if (this.historicos.length == 0) {
        this.mensagem = "Selecione pelo menos 1 item do Extrato";
        this.snackErro = true;
        this.isUpdating = false;
        return;
      }

      this.intervaloUS = [util.dateToStringUS(this.extrato.dtFinal), util.dateToStringUS(this.extrato.dtFinal)];

      //* Se valor é positivo, busca nas contas a receber, se negativo, a pagar
      this.tipo = this.somaExtrato > 0 ? 1 : 0;

      this.somaExtrato = Math.abs(this.somaExtrato);
      this.somaExtrato = parseFloat(this.somaExtrato.toFixed(2));

      this.min = 0; //para permitir pegar lançamentos de diferença - parseInt(this.somaExtrato / 2);
      this.max = 10000000; //parseInt(this.somaExtrato * 2);
      this.faixa = [parseInt(this.somaExtrato), parseInt(this.somaExtrato) + 1];
      if (Math.abs(parseInt(this.somaExtrato)) < 1) {
        this.faixa = [0, 1];
        // this.max = 1;
      }
      this.painel = 0;

      this.limpaFiltroReg();

      const processar = this.registros.map(async element => {
        if (element.selecionado) {
          if (element.acao == "CREDITO_BOLETO" || element.acao == "TAXA_COBRANCA_BOLETO") {
            await this.montaCreditoBoletoDC(lancar, element, "BOLETO");
          }
          if (element.acao == "TAXA_REGISTRO_BOLETO") {
            //await this.montaTaxaBoleto(lancar, element);
          }
          if (element.acao == "CREDITO_DEBITO_CONTA" || element.acao == "TAXA_COBRANCA_DEBITO_CONTA") {
            await this.montaCreditoBoletoDC(lancar, element, "DEBITO_CONTA");
          }
          if (element.acao == "CREDITO_CARTAO") {
            await this.montaCreditoCartao(lancar, element);
          }
          if (element.acao == "LANÇAMENTO") {
            await this.montaLancamento(lancar, element);
          }
        }
      });
      await Promise.all(processar);

      this.isUpdating = false;
    },

    calcularSomaRegistro() {
      this.somaRegistro = 0;
      var parcelasSelecionadas = false;
      this.parcelas.forEach(element => {
        if (element.selecionado) {
          parcelasSelecionadas = true;
          this.somaRegistro += element.valor + element.descAcr;
        }
      });
      this.lancamentos.forEach(element => {
        if (element.selecionado) {
          if (parcelasSelecionadas && ((this.tipo && element.contaC.startsWith("1")) || (!this.tipo && element.contaD.startsWith("1")))) {
            //* Deduz o valor, se:
            //selecionada parcela a receber e lançamento está creditando conta movimento
            //ou selecionada parcela a pagar e lançamento está debitando conta movimento
            this.somaRegistro -= element.vlrBruto;
          } else {
            this.somaRegistro += element.vlrBruto;
          }
        }
      });
      this.somaRegistro = parseFloat(this.somaRegistro.toFixed(2));
    },
    async conciliar() {
      var dtLancamento = null;
      var extratoCommit = [];
      this.registros.forEach(element => {
        if (element.selecionado) {
          element.conciliado = 1;
          extratoCommit.push(element.id);
          dtLancamento = element.dtLancamento;
        }
      });
      var lancamentoCommit = [];
      this.lancamentos.forEach(element => {
        if (element.selecionado) {
          element.conciliado = 1;
          lancamentoCommit.push(element.id);
        }
      });
      var parcelaCommit = [];
      this.parcelas.forEach(element => {
        if (element.selecionado) {
          element.conciliado = 1;
          parcelaCommit.push(element.id);
        }
      });
      var nossosNumerosCommit = [];
      this.nossosNumeros.forEach(element => {
        nossosNumerosCommit.push(element.nossoNumero);
      });

      var conciliadoCommit = {
        dtExtrato: this.extrato.dtFinal,
        dtLancamento: dtLancamento,
        idCedente: this.idCedente,
        idConta: this.extrato.idConta,
        extratos: extratoCommit,
        lancamentos: lancamentoCommit,
        parcelas: parcelaCommit,
        nossosNumeros: nossosNumerosCommit
      };

      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "fin/extrato-lancamento/conciliado", conciliadoCommit);
        setTimeout(() => {
          this.registros.forEach(element => {
            if (element.selecionado) {
              element.conciliado = 1;
              element.selecionado = 0;
            }
          });
          this.idCedente = null;
          this.nossosNumeros = [];
          this.taxaCobranca = 0;
          this.taxaRegistro = 0;
          this.totalQuitados = 0;
          this.operacoes = [];
          this.msgQuitacao = "";
          this.limparDadosFiltro();
          this.limpaFiltroReg();
        }, 300);
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    async excluir(item) {
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "fin/extrato-lancamento/cancelar/" + item.id);
        setTimeout(() => {
          this.registros.forEach(element => {
            element.selecionado = 0;
            if (element.id == item.id) {
              element.conciliado = 0;
            }
          });
          this.idCedente = null;
          this.nossosNumeros = [];
          this.taxaCobranca = 0;
          this.taxaRegistro = 0;
          this.totalQuitados = 0;
          this.operacoes = [];
          this.msgQuitacao = "";
          this.limparDadosFiltro();
          this.limpaFiltroReg();
        }, 300);
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    filtrarItens(item, queryText, itemText) {
      return util.filtrarItens(item, queryText, itemText);
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    }
  },

  beforeMount() {
    setTimeout(() => {
      this.OCULTAR_MENU();
    }, 300);
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_EXTRATOS_VISUALIZAR"]);

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;

      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_TRANSFERÊNCIAS_CRIAR") {
        this.direitoCriarTransf = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_TRANSFERÊNCIAS_EDITAR") {
        this.direitoEditarTransf = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_VENDA_AVULSA_FATURAMENTO_ACESSO") {
        this.direitoVendaAvulsa = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_EDITAR") {
        this.direitoParcelaReceber = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_PAGAR_EDITAR") {
        this.direitoParcelaPagar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CONTAS_RECEBER_CRIAR") {
        this.direitoCriarOperacaoReceber = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CONTAS_RECEBER_EDITAR") {
        this.direitoOperacaoReceber = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CONTAS_PAGAR_CRIAR") {
        this.direitoCriarOperacaoPagar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CONTAS_PAGAR_EDITAR") {
        this.direitoOperacaoPagar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINATURAS_EDITAR") {
        this.direitoAssinatura = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANÚNCIOS_EDITAR") {
        this.direitoAnuncio = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_EXTRATOS_LANÇAMENTOS") {
        this.direitoLancamentos = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_EXTRATOS_EXCLUIR_CONCILIAÇÃO") {
        this.direitoExcluir = true;
      }
    });
  },

  computed: {
    intervalo() {
      var retorno = "";
      if (this.intervaloUS && this.intervaloUS.length > 1) {
        this.intervaloUS.forEach(element => {
          if (element) {
            retorno += (retorno ? " - " : "") + util.formatDate(element);
          }
        });
      }
      return retorno;
    },
    intervaloQuit() {
      var retorno = "";
      if (this.intervaloQuitUS && this.intervaloQuitUS.length > 1) {
        this.intervaloQuitUS.forEach(element => {
          if (element) {
            retorno += (retorno ? " - " : "") + util.formatDate(element);
          }
        });
      }
      return retorno;
    }
  },

  watch: {
    dialogTransferencia(val) {
      if (val == null) {
        this.pesquisaDadosLanc();
      }
      val || this.closeTransferencia();
    },
    dialogParcela(val) {
      if (val == null) {
        this.pesquisaDadosParc();
      }
      val || this.closeParcela();
    },
    dialogConciliacao(val) {
      val || this.closeConciliacao();
    },

    paginaAtual() {
      this.pesquisaDados();
    },
    paginaAtualLanc() {
      this.pesquisaDadosLanc();
    },
    paginaAtualParc() {
      this.pesquisaDadosParc();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        this.pesquisaDados();
      }
    },
    optionsLanc: {
      handler() {
        this.ordemLanc = "";
        this.ordenacaoLanc = "";
        if (this.registrosPaginaLanc !== this.optionsLanc.itemsPerPage) {
          this.registrosPaginaLanc = this.optionsLanc.itemsPerPage;
          this.paginaAtualLanc = 1;
        }
        if (this.optionsLanc.sortBy.length > 0) {
          this.ordenacaoLanc = this.optionsLanc.sortBy[0];
          if (this.optionsLanc.sortDesc[0]) {
            this.ordemLanc = "desc";
          } else {
            this.ordemLanc = "asc";
          }
        }
        this.pesquisaDadosLanc();
      }
    },
    optionsParc: {
      handler() {
        this.ordemParc = "";
        this.ordenacaoParc = "";
        if (this.registrosPaginaParc !== this.optionsParc.itemsPerPage) {
          this.registrosPaginaParc = this.optionsParc.itemsPerPage;
          this.paginaAtualParc = 1;
        }
        if (this.optionsParc.sortBy.length > 0) {
          this.ordenacaoParc = this.optionsParc.sortBy[0];
          if (this.optionsParc.sortDesc[0]) {
            this.ordemParc = "desc";
          } else {
            this.ordemParc = "asc";
          }
        }
        this.pesquisaDadosParc();
      }
    }
  },

  async created() {
    this.id = this.$route.params.id ? this.$route.params.id : "";

    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    try {
      if (this.id != "") {
        this.categorias.forEach(element => {
          element.nome = element.id + element.nomeC;
        });
        //* Carrega o extrato
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/extrato/" + this.id);
        if (!response.data) {
          this.$router.push("/financeiro/extratos");
          return;
        }
        this.extrato = response.data;

        this.extrato.dtI = this.formatDate(this.extrato.dtInicial);
        this.extrato.stInicial = this.extrato.statusInicial == "P" ? "Parcial" : this.extrato.statusInicial == "F" ? "Final" : "Intra-Dia";

        this.extrato.dtF = this.formatDate(this.extrato.dtFinal);
        this.extrato.stFinal = this.extrato.statusFinal == "P" ? "Parcial" : this.extrato.statusFinal == "F" ? "Final" : "Intra-Dia";
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
