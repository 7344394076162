<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">mdi-account</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">Notas Fiscais de Serviço de Comunicação</v-toolbar-title>
          <v-spacer></v-spacer>
          <router-link v-if="direitoParcelas && direitoCriar" to="/financeiro/parcelas/receber?idNF=0" style="text-decoration: none">
            <v-btn color="success" fab small>
              <v-icon color="white" title="Criação de Nota Fiscal">add</v-icon>
            </v-btn>
          </router-link>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-layout wrap>
            <v-flex md1 xs3>
              <v-dialog v-model="dialogFiltroAvancado" max-width="900px">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="blue" fab small :disabled="loading || loadingExp">
                    <v-icon color="white" title="Filtro Avançado">search</v-icon>
                  </v-btn>
                  <v-btn
                    color="red lighten-2"
                    style="color:white"
                    class="btn-rsn ml-n2 mb-6"
                    id="btn-limpa-filtro"
                    fab
                    @click="filtroAvancadoLimpar"
                    :disabled="loading || loadingExp"
                  >
                    <v-icon class="icon-rsn" small center title="Limpar Filtro">clear</v-icon>
                  </v-btn>
                </template>

                <template>
                  <v-card>
                    <v-tabs v-model="tab" background-color="blue accent-4" center-active dark>
                      <v-tab>Nota Fiscal</v-tab>
                      <v-tab>Cliente</v-tab>
                      <v-tab :disabled="tabAnu" v-if="$store.state.usuario.site_assinaturas">Assinatura</v-tab>
                      <v-tab :disabled="tabAss" v-if="$store.state.usuario.site_comercial">Anúncio</v-tab>
                      <v-tab>Parcela</v-tab>
                      <v-tab>Pesquisas</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                      <v-tab-item>
                        <FiltroNF ref="filtroNF" prefixo v-bind:nfsc="true" />
                      </v-tab-item>
                      <v-tab-item>
                        <FiltroCliente ref="filtroCliente" prefixo="c." />
                      </v-tab-item>
                      <v-tab-item v-if="$store.state.usuario.site_assinaturas">
                        <FiltroAssinatura ref="filtroAssinatura" prefixo="a." />
                      </v-tab-item>
                      <v-tab-item v-if="$store.state.usuario.site_comercial">
                        <FiltroAnuncio ref="filtroAnuncio" prefixo="u." />
                      </v-tab-item>
                      <v-tab-item>
                        <FiltroParcela ref="filtroParcela" prefixo="p." />
                      </v-tab-item>
                      <v-tab-item>
                        <Pesquisas ref="pesquisas" prefixo="nfe" />
                      </v-tab-item>
                    </v-tabs-items>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="orange lighten-2 white" style="color:white" class="btn-rsn" @click="filtroAvancadoRetornar">
                        <v-icon class="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
                      </v-btn>
                      <v-btn outlined color="red white" style="color:white" class="btn-rsn" @click="filtroAvancadoLimpar">
                        <v-icon class="icon-rsn" left>clear</v-icon>Limpar
                      </v-btn>
                      <v-btn v-if="tab != 5" color="blue white" style="color:white" class="btn-rsn" depressed @click="limpaFiltro">
                        <v-icon class="icon-rsn" left>tune</v-icon>Filtrar
                      </v-btn>
                      <v-btn v-if="direitoExportar" outlined color="blue white" style="color:white" class="btn-rsn" depressed @click="filtroAvancadoFiltrar(true)">
                        <v-icon class="icon-rsn" left>description</v-icon>Exportar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex md1 xs2>
              <v-btn v-if="direitoExportar" color="blue" :loading="loading" :disabled="loading || loadingExp" fab small @click="filtroAvancadoFiltrar(true)">
                <v-icon color="white" title="Exportar">description</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs6 md2>
              <v-text-field
                background-color="white"
                class="mb-2 elevation-0"
                v-model="numeroPesquisa"
                v-mask="'#########'"
                append-icon="search"
                label="Número"
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
                autocomplete="disabled"
                :name="Math.random()"
                :disabled="loading || loadingExp"
              ></v-text-field>
            </v-flex>
            <v-flex xs6 md2>
              <v-text-field
                background-color="white"
                class="mb-2 elevation-0"
                v-model="identificacaoPesquisa"
                v-mask="'##############'"
                append-icon="search"
                label="Identificação"
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
                autocomplete="disabled"
                :name="Math.random()"
                :disabled="loading || loadingExp"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md2>
              <v-text-field
                background-color="white"
                class="mb-2 elevation-0"
                v-model="nomePesquisa"
                append-icon="search"
                label="Nome"
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
                autocomplete="disabled"
                :name="Math.random()"
                :disabled="loading || loadingExp"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon color="blue lighten-2" title="Visualizar Parcelas" @click="parcelas(item)">loupe</v-icon>
              <v-icon
                v-if="direitoCancelar && item.situacao != 'S' && item.pdf != 2 && item.dtLimite > new Date(item.dtEmissao)"
                color="orange"
                title="Liberar"
                @click="liberar(item)"
                >call_made</v-icon
              >
              <v-icon v-if="direitoCancelar && item.situacao == 'N'" color="red" title="Cancelar" @click="acaoCancelar(item)">cancel</v-icon>
            </template>

            <template v-slot:item.situacao="{ item }">
              <span :class="item.situacao == 'S' ? 'orange--text' : item.pdf == 2 ? 'green--text' : ''">
                {{ item.situacao == "S" ? "Cancelada" : item.pdf == 2 ? "Processada" : "Criada" }}
              </span>
            </template>

            <template v-slot:item.valorTotal="{ item }">
              {{ formatCurrency(item.valorTotal) }}
            </template>
            <template v-slot:item.valorBruto="{ item }">
              {{ formatCurrency(item.valorBruto) }}
            </template>
            <template v-slot:item.valorOutros="{ item }">
              {{ formatCurrency(item.valorOutros) }}
            </template>

            <template v-slot:item.actions2="{ item }">
              <v-icon v-if="item.situacao == 'N' && item.pdf == 2" color="teal" title="DANFE" @click="danfe(item)">account_balance</v-icon>
              <v-icon v-if="item.situacao == 'N' && item.pdf == 2" color="teal" title="E-mail" @click="nfEmail(item)">mail_outline</v-icon>
            </template>

            <template v-slot:item.dtEmissao="{ item }">
              <span>{{ formatDateTime(item.dtEmissao) }}</span>
            </template>
            <template v-slot:item.dtCancelamento="{ item }">
              <span>{{ formatDateTime(item.dtCancelamento) }}</span>
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
          </div>
        </div>
        <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
      </v-card>

      <v-dialog persistent v-model="dialogAcao" max-width="500">
        <v-card>
          <v-toolbar>
            <span class="headline">{{ acaoItem.tipoAcao }} Nota Fiscal</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeAcao">
              <v-icon color="darken-1">cancel</v-icon>
            </v-btn>
          </v-toolbar>
          <v-form ref="formAcao" v-model="acaoValid" lazy-validation>
            <v-container>
              <v-layout wrap>
                <v-flex xs12>
                  <v-textarea
                    v-model="acaoItem.texto"
                    label="Informe o Motivo"
                    maxlength="255"
                    rows="5"
                    hint="Obrigatório"
                    :rules="[
                      validacaoAcao.texto == false || validacaoAcao.texto,
                      value => !!value || 'Obrigatório.',
                      value => (value != null && value.length > 15) || 'Preencha o motivo'
                    ]"
                    color="blue-grey lighten-2"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="closeAcao">Retornar</v-btn>
            <v-btn color="blue darken-1" @click="executarAcao">
              {{ acaoItem.tipoAcao }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import FiltroCliente from "@/views/components/FiltroCliente.vue";
import FiltroAssinatura from "@/views/components/FiltroAssinatura.vue";
import FiltroAnuncio from "@/views/components/FiltroAnuncio.vue";
import FiltroParcela from "@/views/components/FiltroParcela.vue";
import FiltroNF from "@/views/components/FiltroNF.vue";
import Pesquisas from "@/views/components/Pesquisas.vue";
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  components: {
    FiltroCliente,
    FiltroAssinatura,
    FiltroAnuncio,
    FiltroParcela,
    FiltroNF,
    Pesquisas
  },
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions", width: 120 },
        { text: "ID", value: "id" },
        { text: "Origem", value: "origem" },
        { text: "Série", value: "serie" },
        { text: "Número", value: "numero" },
        { text: "Emissão", value: "dtEmissao" },
        { text: "Cancelamento", value: "dtCancelamento" },
        { text: "Cliente", value: "cliente" },
        { text: "Situação", value: "situacao" },
        { text: "Total", value: "valorTotal" },
        { text: "Bruto", value: "valorBruto" },
        { text: "Outros", value: "valorOutros" },
        { text: "Forma Pgto Original", value: "formaPgto" },
        { text: "", value: "actions2", width: 80 }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      direitoCriar: false,
      direitoCancelar: false,
      direitoParcelas: false,
      direitoExportar: false,

      tab: null,
      tabAss: false,
      tabAnu: false,

      dialogAcao: false,
      acaoValid: true,
      acaoItem: {
        tipoAcao: null,
        texto: ""
      },
      validacaoAcao: {
        texto: false
      },
      dadoIndex: -1,
      dadoItem: {},

      mensagem: "",
      snackbar: false,
      snackErro: false,

      inicial: true,
      idSitePrincipal: null,
      mensagemPesquisa: "",
      numeroPesquisa: null,
      identificacaoPesquisa: null,
      nomePesquisa: null,
      dialogFiltroAvancado: false,
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {},
      loader: null,
      loading: false,
      loadingExp: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    filtroAvancadoRetornar() {
      //* Esconde a dialog do filtro avançado
      this.dialogFiltroAvancado = false;
    },
    filtroAvancadoLimpar() {
      this.tabAss = false;
      this.tabAnu = false;
      if (this.tab == 2 || this.tab == 3) {
        this.tab = 0;
      }

      //* Limpa os registros da tabela
      this.registros = [];
      this.totalRegistros = 0;
      this.totalPaginas = 0;
      this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR AS NOTAS FISCAIS...";

      //* Limpa os campos dos filtros
      if (this.$refs.filtroNF) {
        this.$refs.filtroNF.limpar();
      }
      if (this.$refs.filtroCliente) {
        this.$refs.filtroCliente.limpar();
      }
      if (this.$refs.filtroAssinatura) {
        this.$refs.filtroAssinatura.limpar();
      }
      if (this.$refs.filtroAnuncio) {
        this.$refs.filtroAnuncio.limpar();
      }
      if (this.$refs.filtroParcela) {
        this.$refs.filtroParcela.limpar();
      }
    },
    limpaFiltro() {
      var pgAnterior = this.paginaAtual;
      this.paginaAtual = 1;
      if (pgAnterior == 1) {
        this.filtroAvancadoFiltrar(false);
      }
    },
    async filtroAvancadoFiltrar(exportar) {
      //* Limpa os registros da tabela
      this.registros = [];
      this.totalRegistros = 0;
      this.totalPaginas = 0;

      if (this.identificacaoPesquisa && !util.valida_cpf(this.identificacaoPesquisa) && !util.valida_cnpj(this.identificacaoPesquisa)) {
        this.mensagem = "Identificação inválida";
        this.snackErro = true;
        return;
      }
      this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

      var l = null;
      if (exportar) {
        this.loader = "loading";
        l = this.loader;
        this[l] = !this[l];
        setTimeout(() => (this[l] = false), 5000);
        this.loader = null;
      }

      if (!exportar) {
        //* Sinaliza que está carregando
        this.carregando = true;
        this.mensagemPesquisa = "Carregando...";
        this.loading = true;
      } else {
        this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR AS NOTAS FISCAIS...";
      }

      //* Esconde a dialog do filtro avançado
      this.dialogFiltroAvancado = false;

      var filtroNFUrl = this.montaFiltro();
      if (!filtroNFUrl.includes("nfscIdSite")) {
        filtroNFUrl += "&nfscIdSite=" + this.idSitePrincipal;
      }
      if (this.numeroPesquisa) {
        if (filtroNFUrl.includes("nfscNumeroInicio") || filtroNFUrl.includes("nfscNumeroFim")) {
          this.numeroPesquisa = "";
        } else {
          filtroNFUrl += "&nfscNumeroInicio=" + this.numeroPesquisa + "&nfscNumeroFim=" + this.numeroPesquisa;
        }
      }
      if (this.identificacaoPesquisa) {
        if (filtroNFUrl.includes("identificacao")) {
          this.identificacaoPesquisa = "";
        } else {
          filtroNFUrl += "&c.identificacao=" + this.identificacaoPesquisa;
        }
      }
      if (this.nomePesquisa) {
        if (filtroNFUrl.includes("&c.nome=")) {
          this.nomePesquisa = "";
        } else {
          filtroNFUrl += "&c.nome=" + this.nomePesquisa;
          filtroNFUrl += "&c.nomeFantasia=" + this.nomePesquisa;
        }
      }

      var camposAnuncio = "";
      if (exportar) {
        if (this.$refs.pesquisas) {
          //* Carrega os filtros da nota fiscal
          camposAnuncio = this.$refs.pesquisas.filtroUrlCampos();
        }
      }

      try {
        //* Consulta os dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/nfsc?" +
            filtroNFUrl +
            "&campos=" +
            camposAnuncio +
            "&pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem +
            (exportar ? "&exportar=1" : "")
        );

        if (!exportar) {
          //* Carrega os dados de retorno
          this.registros = response.data.data;
          this.registros.forEach(element => {
            element.dtLimite = new Date();
            element.dtLimite.setMinutes(element.dtLimite.getMinutes() - 10);
          });

          this.totalRegistros = response.data.total;
          this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
          this.rodape.pageText = this.totalRegistros + " itens";
          //* Fecha a mensagem de carregando
          this.carregando = false;

          //* Registra caso algum item não seja encontrado
          this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
        }
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else if (e.response && e.response.data.length > 0) {
          this.mensagemPesquisa = "ERRO: " + e.response.data[0].mensagem;
          this.mensagem = "ERRO: " + e.response.data[0].mensagem;
          this.snackErro = true;
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
          this.mensagem = "Erro ao buscar dados: " + e;
          this.snackErro = true;
        }
      } finally {
        this.loading = false;
      }
    },
    montaFiltro() {
      var filtroNFUrl = "";
      if (this.$refs.filtroNF) {
        //* Carrega os filtros da nota fiscal
        filtroNFUrl = filtroNFUrl + this.$refs.filtroNF.filtroUrlParametros();
      }

      if (this.$refs.filtroAssinatura) {
        //* Carrega os filtros da assinatura
        filtroNFUrl = filtroNFUrl + this.$refs.filtroAssinatura.filtroUrlParametros(this.idSitePrincipal);
      }

      if (this.$refs.filtroAnuncio) {
        //* Carrega os filtros do anúncio
        filtroNFUrl += this.$refs.filtroAnuncio.filtroUrlParametros(this.idSitePrincipal);
      }

      if (this.$refs.filtroCliente) {
        //* Carrega os filtros do cliente
        filtroNFUrl = filtroNFUrl + this.$refs.filtroCliente.filtroUrlParametros(this.idSitePrincipal);
      }

      if (this.$refs.filtroParcela) {
        //* Carrega os filtros da parcela
        filtroNFUrl += this.$refs.filtroParcela.filtroUrlParametros(this.idSitePrincipal);
      }
      return filtroNFUrl;
    },
    parcelas(nfsc) {
      window.open("/financeiro/parcelas/receber?idNFSC=" + nfsc.id + "&situacao=" + nfsc.situacao, "_blank");
    },
    danfe(item) {
      try {
        var caminhoNota = `/${this.idSitePrincipal}/notas-fiscais/nfsc/notas/${item.anoMes}/${item.numero.toString().padStart(6, "0")}.pdf`;
        window.open(process.env.VUE_APP_URL_WEB_API + "fin/arquivo/pdf?caminho=" + caminhoNota, "_blank");
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    closeAcao() {
      this.dialogAcao = false;
      this.$refs.formAcao.resetValidation();
      this.dadoIndex = -1;
      this.dadoItem = {};
      this.acaoItem.tipoAcao = null;
      this.acaoItem.texto = "";
      this.validacaoAcao.texto = false;
    },
    async acaoCancelar(item) {
      this.dadoIndex = this.registros.indexOf(item);
      this.dadoItem = Object.assign({}, item);

      //Prazo de cancelamento de NFSC: até o último dia do mês da emissão - ag. confirmação da Sefaz/Aurélio
      var dtEmissao = new Date(item.dtEmissao);
      var dtLimite = new Date(dtEmissao.getFullYear(), dtEmissao.getMonth() + 1, 0, 23, 59, 59);

      if (dtEmissao < dtLimite) {
        this.acaoItem.tipoAcao = "Cancelar";
        this.dialogAcao = true;
      } else {
        this.mensagem = "Prazo para cancelamento expirado";
        this.snackErro = true;
      }
    },
    executarAcao() {
      if (this.$refs.formAcao.validate()) {
        if (this.acaoItem.tipoAcao == "Cancelar") {
          this.cancelar();
        }
      }
    },
    async cancelar() {
      try {
        const notaCommit = {
          situacao: "S",
          motivoCancelamento: this.acaoItem.texto
        };
        const response = await api.put(process.env.VUE_APP_URL_WEB_API + "fin/nfsc/cancelar/" + this.dadoItem.id, notaCommit);
        if (response.data) {
          this.dadoItem.situacao = "S";
          this.dadoItem.dtCancelamento = new Date();
          Object.assign(this.registros[this.dadoIndex], this.dadoItem);

          this.mensagem = "Nota fiscal cancelada";
          this.snackbar = true;
        }
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      } finally {
        this.closeAcao();
      }
    },
    async nfEmail(element) {
      var notasCommit = [];
      var caminhoNota = "";
      var numeroNota = "";
      caminhoNota = `${this.idSitePrincipal}/notas-fiscais/nfsc/notas/${element.anoMes}`;
      numeroNota = element.numero.toString().padStart(6, "0");
      var boleto = {
        idSite: this.idSitePrincipal,
        idCliente: element.idCliente,
        origem: element.origem,
        idUsuario: this.$store.state.usuario.id,
        tipo: 4,
        pastaBoleto: null,
        boleto: null,
        pastaNF: caminhoNota,
        nf: numeroNota,
        recibo: null,
        pi: null
      };
      notasCommit.push(boleto);

      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "adm/log-email/lista?idSite=" + this.idSitePrincipal, notasCommit);

        this.mensagem = "Notas fiscais direcionadas para envio por e-mail";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    async liberar(item) {
      try {
        const response = await api.put(process.env.VUE_APP_URL_WEB_API + "fin/nfsc/pdf/" + item.id, { pdf: 2 });
        if (response.data) {
          this.mensagem = "Nota fiscal liberada";
          this.snackbar = true;
          setTimeout(() => {
            this.filtroAvancadoFiltrar(false);
          }, 2000);
        }
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_NFSC_ACESSO"]);
    //* Verifica se o usuário logado possui direito para incluir
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_NFSC_CRIAR") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ACESSO") {
        this.direitoParcelas = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_NFSC_CANCELAR") {
        this.direitoCancelar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_NFSC_EXPORTAR") {
        this.direitoExportar = true;
      }
    });
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
  },

  watch: {
    tab(val) {
      if (val == 2) {
        this.tabAss = true;
      }
      if (val == 3) {
        this.tabAnu = true;
      }
    },
    $route() {
      this.tabAss = false;
      this.tabAnu = false;
    },
    paginaAtual: function() {
      this.filtroAvancadoFiltrar(false);
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        if (!this.inicial) {
          this.filtroAvancadoFiltrar(false);
        } else {
          this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR AS NOTAS FISCAIS...";
          this.inicial = false;
        }
      }
    }
  }
};
</script>
