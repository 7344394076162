<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-form ref="formElemento">
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-expansion-panels v-model="painel" focusable>
            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Dados Básicos</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md6 v-if="!origem || ['CLI', 'REC', 'PAG'].includes(origem)">
                      <v-autocomplete
                        v-model="filtrosSelecionados.origem"
                        :items="itensOrigem"
                        :filter="filtrarObjetos"
                        label="Origem"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        multiple
                        autocomplete="disabled"
                        :name="Math.random()"
                        @dblclick.stop="selectAll('itensOrigem')"
                        hint="Use duplo click para selecionar todos"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md6 v-if="origem && !['CLI', 'REC', 'PAG'].includes(origem)"></v-flex>
                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.status"
                        :items="itensStatus"
                        :filter="filtrarItens"
                        label="Status"
                        no-data-text
                        clearable
                        multiple
                        autocomplete="disabled"
                        :name="Math.random()"
                        @dblclick.stop="selectAll('itensStatus')"
                        hint="Use duplo click para selecionar todos"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md2>
                      <v-text-field v-model="filtrosSelecionados.idParcela" label="ID" v-mask="'########'" color="blue-grey lighten-2" clearable></v-text-field>
                    </v-flex>
                    <v-flex xs12 md2>
                      <v-text-field v-model="filtrosSelecionados.numero" label="Número" v-mask="'###'" color="blue-grey lighten-2" clearable></v-text-field>
                    </v-flex>
                    <v-flex xs12 md2>
                      <v-text-field v-model="filtrosSelecionados.nroParcelas" label="Parcelas" v-mask="'###'" color="blue-grey lighten-2" clearable></v-text-field>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.idFormaPgto"
                        :items="formasPgto"
                        :filter="filtrarObjetos"
                        label="Forma de Pagamento"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        multiple
                        autocomplete="disabled"
                        :name="Math.random()"
                        @dblclick.stop="selectAll('formasPgto')"
                        hint="Use duplo click para selecionar todos"
                      >
                        <template v-slot:append-outer>
                          <v-slide-x-reverse-transition mode="out-in">
                            <v-icon
                              :color="filtrosSelecionados.omitirPacotes ? 'blue' : 'grey'"
                              @click="filtrosSelecionados.omitirPacotes = !filtrosSelecionados.omitirPacotes"
                              :title="filtrosSelecionados.omitirPacotes ? 'Omitindo Pacote' : 'Apresentando Pacote'"
                              >library_books</v-icon
                            >
                          </v-slide-x-reverse-transition>
                        </template>
                      </v-autocomplete>
                    </v-flex>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Valor Bruto
                      </h4>
                      <v-divider></v-divider>
                      <v-layout wrap>
                        <v-flex xs6 md6>
                          <v-currency-field
                            class="ml-4"
                            :locale="`pt-${$store.state.usuario.site_pais}`"
                            v-model="filtrosSelecionados.valorInicio"
                            label="Mínimo"
                            :allowNegative="false"
                            :max="9999999"
                            color="blue-grey lighten-2"
                          ></v-currency-field>
                        </v-flex>
                        <v-flex xs6 md6>
                          <v-currency-field
                            :locale="`pt-${$store.state.usuario.site_pais}`"
                            v-model="filtrosSelecionados.valorFim"
                            label="Máximo"
                            :allowNegative="false"
                            :max="9999999"
                            color="blue-grey lighten-2"
                          ></v-currency-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">Valor Líquido</span>
                          </template>
                          <span>Considera Desconto/Acréscimo</span>
                        </v-tooltip>
                      </h4>
                      <v-divider></v-divider>
                      <v-layout wrap>
                        <v-flex xs6 md6>
                          <v-currency-field
                            class="ml-4"
                            :locale="`pt-${$store.state.usuario.site_pais}`"
                            v-model="filtrosSelecionados.valorLiquidoInicio"
                            label="Mínimo"
                            :allowNegative="false"
                            :max="9999999"
                            color="blue-grey lighten-2"
                          ></v-currency-field>
                        </v-flex>
                        <v-flex xs6 md6>
                          <v-currency-field
                            :locale="`pt-${$store.state.usuario.site_pais}`"
                            v-model="filtrosSelecionados.valorLiquidoFim"
                            label="Máximo"
                            :allowNegative="false"
                            :max="9999999"
                            color="blue-grey lighten-2"
                          ></v-currency-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Nosso Número
                      </h4>
                      <v-divider></v-divider>
                      <v-layout wrap>
                        <v-flex xs6 md6>
                          <v-text-field
                            class="ml-4"
                            v-model="filtrosSelecionados.nossoNumeroInicio"
                            label="Início"
                            v-mask="'#################'"
                            color="blue-grey lighten-2"
                            clearable
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs6 md6>
                          <v-text-field v-model="filtrosSelecionados.nossoNumeroFim" label="Fim" v-mask="'#################'" color="blue-grey lighten-2" clearable></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.statusCobranca"
                        :items="itensStatusCobranca"
                        :filter="filtrarObjetos"
                        label="Cobrança"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        multiple
                        autocomplete="disabled"
                        :name="Math.random()"
                        @dblclick.stop="selectAll('itensStatusCobranca')"
                        hint="Use duplo click para selecionar todos"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md3>
                      <v-autocomplete
                        v-model="filtrosSelecionados.ajustada"
                        :items="itensSimNao"
                        :filter="filtrarObjetos"
                        label="Ajustada"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md3>
                      <v-autocomplete
                        v-model="filtrosSelecionados.conciliado"
                        :items="itensSimNao"
                        :filter="filtrarObjetos"
                        label="Conciliada"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md3>
                      <v-text-field
                        v-model="filtrosSelecionados.idLoteFaturamento"
                        label="Lote Faturamento"
                        v-mask="'#######'"
                        color="blue-grey lighten-2"
                        hint="Entre com 0 para parcelas sem lote"
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3>
                      <v-text-field v-model="filtrosSelecionados.idOperacao" label="ID Operação" v-mask="'#######'" color="blue-grey lighten-2" clearable></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.idCedente"
                        :items="cedentes"
                        :filter="filtrarObjetos"
                        label="Cedente"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        multiple
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md3>
                      <v-text-field
                        v-model="filtrosSelecionados.codAntigoCirculacao"
                        label="Código Circulação®"
                        v-mask="'########'"
                        color="blue-grey lighten-2"
                        clearable
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3>
                      <v-text-field v-model="filtrosSelecionados.codFinanceiro" label="Código Financeiro®" v-mask="'#######'" color="blue-grey lighten-2" clearable></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3 v-if="$store.state.usuario.site_protheus">
                      <v-text-field v-model="filtrosSelecionados.idProtheus" label="ID Protheus" v-mask="'######'" color="blue-grey lighten-2" clearable></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3 v-if="$store.state.usuario.site_gateway.includes('ASAAS')">
                      <v-autocomplete
                        v-model="filtrosSelecionados.asaas"
                        :items="itensSimNao"
                        :filter="filtrarObjetos"
                        label="Asaas"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12 md9>
                      <v-text-field v-model="filtrosSelecionados.obsCobranca" label="Texto para Fatura" maxlength="100" color="blue-grey lighten-2" clearable></v-text-field>
                    </v-flex>
                    <v-flex xs12 md3 v-if="$store.state.usuario.site_gateway.includes('ASAAS')">
                      <v-text-field v-model="filtrosSelecionados.payAsaas" label="Pay Asaas" maxlength="20" color="blue-grey lighten-2" clearable></v-text-field>
                    </v-flex>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Retorno do Banco
                      </h4>
                      <v-divider></v-divider>
                      <v-layout wrap>
                        <v-flex xs12 md3>
                          <v-text-field
                            class="ml-4"
                            v-model="filtrosSelecionados.codRetorno"
                            label="Código Retorno"
                            v-mask="'##'"
                            color="blue-grey lighten-2"
                            clearable
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-text-field v-model="filtrosSelecionados.descRetorno" label="Descrição Retorno" maxlength="100" color="blue-grey lighten-2" clearable></v-text-field>
                        </v-flex>
                        <v-flex xs12 md3>
                          <v-switch v-model="filtrosSelecionados.retorno" :label="`${filtrosSelecionados.retorno == 1 ? 'Todos Retornos' : 'Último Retorno'}`"></v-switch>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Períodos</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Emissão
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 md6>
                          <v-menu v-model="mnfiltroSelDtEmissaoInicioUS" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.dtEmissaoInicio"
                                label="Início"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelDtEmissaoInicioUS = null"
                                @keyup="filtroSelDtEmissaoInicioUS = valida_data(filtrosSelecionados.dtEmissaoInicio) ? formatDateUS(filtrosSelecionados.dtEmissaoInicio) : null"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelDtEmissaoInicioUS" locale="pt-br" no-title @input="mnfiltroSelDtEmissaoInicioUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-menu v-model="mnfiltroSelDtEmissaoFimUS" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.dtEmissaoFim"
                                label="Fim"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelDtEmissaoFimUS = null"
                                @keyup="filtroSelDtEmissaoFimUS = valida_data(filtrosSelecionados.dtEmissaoFim) ? formatDateUS(filtrosSelecionados.dtEmissaoFim) : null"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelDtEmissaoFimUS" locale="pt-br" no-title @input="mnfiltroSelDtEmissaoFimUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-container>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Vencimento Original
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 md6>
                          <v-menu
                            v-model="mnfiltroSelDtVectorOriginalInicioUS"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.dtVectoOriginalInicio"
                                label="Início"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelDtVectorOriginalInicioUS = null"
                                @keyup="
                                  filtroSelDtVectorOriginalInicioUS = valida_data(filtrosSelecionados.dtVectoOriginalInicio)
                                    ? formatDateUS(filtrosSelecionados.dtVectoOriginalInicio)
                                    : null
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelDtVectorOriginalInicioUS" locale="pt-br" no-title @input="mnfiltroSelDtVectorOriginalInicioUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-menu
                            v-model="mnfiltroSelDtVectorOriginalFimUS"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.dtVectoOriginalFim"
                                label="Fim"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelDtVectorOriginalFimUS = null"
                                @keyup="
                                  filtroSelDtVectorOriginalFimUS = valida_data(filtrosSelecionados.dtVectoOriginalFim) ? formatDateUS(filtrosSelecionados.dtVectoOriginalFim) : null
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelDtVectorOriginalFimUS" locale="pt-br" no-title @input="mnfiltroSelDtVectorOriginalFimUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-container>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Vencimento
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 md6>
                          <v-menu v-model="mnfiltroSelDtVectoInicioUS" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.dtVectoInicio"
                                label="Início"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelDtVectoInicioUS = null"
                                @keyup="filtroSelDtVectoInicioUS = valida_data(filtrosSelecionados.dtVectoInicio) ? formatDateUS(filtrosSelecionados.dtVectoInicio) : null"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelDtVectoInicioUS" locale="pt-br" no-title @input="mnfiltroSelDtVectoInicioUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-menu v-model="mnfiltroSelDtVectoFimUS" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.dtVectoFim"
                                label="Fim"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelDtVectoFimUS = null"
                                @keyup="filtroSelDtVectoFimUS = valida_data(filtrosSelecionados.dtVectoFim) ? formatDateUS(filtrosSelecionados.dtVectoFim) : null"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelDtVectoFimUS" locale="pt-br" no-title @input="mnfiltroSelDtVectoFimUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Quitação
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 md6>
                          <v-menu
                            v-model="mnfiltroSelDtQuitacaoInicioUS"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.dtQuitacaoInicio"
                                label="Início"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelDtQuitacaoInicioUS = null"
                                @keyup="filtroSelDtQuitacaoInicioUS = valida_data(filtrosSelecionados.dtQuitacaoInicio) ? formatDateUS(filtrosSelecionados.dtQuitacaoInicio) : null"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelDtQuitacaoInicioUS" locale="pt-br" no-title @input="mnfiltroSelDtQuitacaoInicioUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-menu v-model="mnfiltroSelDtQuitacaoFimUS" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.dtQuitacaoFim"
                                label="Fim"
                                mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelDtQuitacaoFimUS = null"
                                @keyup="filtroSelDtQuitacaoFimUS = valida_data(filtrosSelecionados.dtQuitacaoFim) ? formatDateUS(filtrosSelecionados.dtQuitacaoFim) : null"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelDtQuitacaoFimUS" locale="pt-br" no-title @input="mnfiltroSelDtQuitacaoFimUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Bloqueio
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 md6>
                          <v-menu
                            v-model="mnfiltrosSelDtBloqueioInicioUS"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.dtBloqueioInicio"
                                label="Início"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtrosSelDtBloqueioInicioUS = null"
                                @keyup="
                                  filtrosSelDtBloqueioInicioUS = valida_data(filtrosSelecionados.dtBloqueioInicio) ? formatDateUS(filtrosSelecionados.dtBloqueioInicio) : null
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtrosSelDtBloqueioInicioUS" locale="pt-br" no-title @input="mnfiltrosSelDtBloqueioInicioUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-menu v-model="mnfiltrosSelDtBloqueioFimUS" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.dtBloqueioFim"
                                label="Fim"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtrosSelDtBloqueioFimUS = null"
                                @keyup="filtrosSelDtBloqueioFimUS = valida_data(filtrosSelecionados.dtBloqueioFim) ? formatDateUS(filtrosSelecionados.dtBloqueioFim) : null"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtrosSelDtBloqueioFimUS" locale="pt-br" no-title @input="mnfiltrosSelDtBloqueioFimUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Promessa
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 md6>
                          <v-menu
                            v-model="mnfiltroSelDtPromessaInicioUS"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.dtPromessaInicio"
                                label="Início"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelDtPromessaInicioUS = null"
                                @keyup="filtroSelDtPromessaInicioUS = valida_data(filtrosSelecionados.dtPromessaInicio) ? formatDateUS(filtrosSelecionados.dtPromessaInicio) : null"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelDtPromessaInicioUS" locale="pt-br" no-title @input="mnfiltroSelDtPromessaInicioUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-menu v-model="mnfiltroSelDtPromessaFimUS" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.dtPromessaFim"
                                label="Fim"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelDtPromessaFimUS = null"
                                @keyup="filtroSelDtPromessaFimUS = valida_data(filtrosSelecionados.dtPromessaFim) ? formatDateUS(filtrosSelecionados.dtPromessaFim) : null"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelDtPromessaFimUS" locale="pt-br" no-title @input="mnfiltroSelDtPromessaFimUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-container>

                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Cancelamento
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 md6>
                          <v-menu v-model="mnfiltroSelDtCancelInicioUS" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.dtCancelamentoInicio"
                                label="Início"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelDtCancelInicioUS = null"
                                @keyup="
                                  filtroSelDtCancelInicioUS = valida_data(filtrosSelecionados.dtCancelamentoInicio) ? formatDateUS(filtrosSelecionados.dtCancelamentoInicio) : null
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelDtCancelInicioUS" locale="pt-br" no-title @input="mnfiltroSelDtCancelInicioUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-menu v-model="mnfiltroSelDtCancelFimUS" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.dtCancelamentoFim"
                                label="Fim"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelDtCancelFimUS = null"
                                @keyup="filtroSelDtCancelFimUS = valida_data(filtrosSelecionados.dtCancelamentoFim) ? formatDateUS(filtrosSelecionados.dtCancelamentoFim) : null"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelDtCancelFimUS" locale="pt-br" no-title @input="mnfiltroSelDtCancelFimUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0" v-if="['CLI', 'PAG'].includes(origem)">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Parcelas a Pagar</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider></v-divider>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 md6>
                      <v-text-field v-model="filtrosSelecionados.numeroNFpg" label="Nota Fiscal a Pagar" v-mask="'########'" color="blue-grey lighten-2" clearable></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Emissão NF a Pagar
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-container fluid grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12 md6>
                          <v-menu
                            v-model="mnfiltroSelDtEmissaoNFpgInicioUS"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.dtEmissaoNFpgInicio"
                                label="Início"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelDtEmissaoNFpgInicioUS = null"
                                @keyup="
                                  filtroSelDtEmissaoNFpgInicioUS = valida_data(filtrosSelecionados.dtEmissaoNFpgInicio)
                                    ? formatDateUS(filtrosSelecionados.dtEmissaoNFpgInicio)
                                    : null
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelDtEmissaoNFpgInicioUS" locale="pt-br" no-title @input="mnfiltroSelDtEmissaoNFpgInicioUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex xs12 md6>
                          <v-menu
                            v-model="mnfiltroSelDtEmissaoNFpgFimUS"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="filtrosSelecionados.dtEmissaoNFpgFim"
                                label="Fim"
                                v-mask="'##/##/####'"
                                :rules="[value => valida_data(value) || 'Data inválida']"
                                color="blue-grey lighten-2"
                                clearable
                                prepend-icon="event"
                                v-on="on"
                                autocomplete="disabled"
                                :name="Math.random()"
                                @click:clear="filtroSelDtEmissaoNFpgFimUS = null"
                                @keyup="filtroSelDtEmissaoNFpgFimUS = valida_data(filtrosSelecionados.dtEmissaoNFpgFim) ? formatDateUS(filtrosSelecionados.dtEmissaoNFpgFim) : null"
                              ></v-text-field>
                            </template>
                            <v-date-picker v-model="filtroSelDtEmissaoNFpgFimUS" locale="pt-br" no-title @input="mnfiltroSelDtEmissaoNFpgFimUS = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-container>

                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.mensal"
                        :items="itensSimNao"
                        :filter="filtrarObjetos"
                        label="Mensal"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-autocomplete
                        v-model="filtrosSelecionados.confirmada"
                        :items="itensConfirmada"
                        :filter="filtrarObjetos"
                        label="Confirmada"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12>
                      <v-autocomplete
                        v-model="filtrosSelecionados.tipoServico"
                        :items="tiposServico"
                        :filter="filtrarObjetos"
                        label="Tipo de Serviço"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                        multiple
                        @dblclick.stop="selectAll('itensTipoServico')"
                        hint="Use duplo click para selecionar todos"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12>
                      <v-autocomplete
                        v-model="filtrosSelecionados.formaLancamento"
                        :items="formasLancamento"
                        :filter="filtrarObjetos"
                        label="Forma de Lançamento"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                        multiple
                        @dblclick.stop="selectAll('itensFormaLancamento')"
                        hint="Use duplo click para selecionar todos"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12>
                      <v-autocomplete
                        v-model="filtrosSelecionados.contaD"
                        :items="despesas"
                        :filter="filtrarObjetos"
                        label="Despesa"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12>
                      <v-autocomplete
                        v-model="filtrosSelecionados.idCentroCusto"
                        :items="centrosCusto"
                        :filter="filtrarObjetos"
                        label="Centro de Custo"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
export default {
  props: {
    prefixo: { type: String, default: "" },
    origem: { type: String, default: "" }
  },
  data() {
    return {
      filtrosSelecionados: {
        origem: null,
        status: null,
        idParcela: null,
        idOperacao: null,
        numero: null,
        nroParcelas: null,
        idFormaPgto: null,
        omitirPacotes: true,
        idCedente: null,
        dtEmissaoInicio: null,
        dtEmissaoFim: null,
        dtVectoOriginalInicio: null,
        dtVectoOriginalFim: null,
        dtVectoInicio: null,
        dtVectoFim: null,
        dtQuitacaoInicio: null,
        dtQuitacaoFim: null,
        dtBloqueioInicio: null,
        dtBloqueioFim: null,
        dtPromessaInicio: null,
        dtPromessaFim: null,
        dtCancelamentoInicio: null,
        dtCancelamentoFim: null,
        dtEmissaoNFpgInicio: null,
        dtEmissaoNFpgFim: null,
        valorInicio: 0,
        valorFim: 0,
        valorLiquidoInicio: 0,
        valorLiquidoFim: 0,
        nossoNumeroInicio: null,
        nossoNumeroFim: null,
        statusCobranca: null,
        ajustada: null,
        conciliado: null,
        idLoteFaturamento: null,
        codRetorno: null,
        descRetorno: null,
        retorno: 1,
        codAntigoCirculacao: null,
        codFinanceiro: null,
        idProtheus: null,
        asaas: null,
        payAsaas: null,
        numeroNFpg: null,
        dtEmissaoNFpgInicio: null,
        dtEmissaoNFpgFim: null,
        obsCobranca: null,
        mensal: null,
        confirmada: null,
        tipoServico: null,
        formaLancamento: null,
        contaD: null,
        idCentroCusto: null
      },

      filtroSelDtEmissaoInicioUS: null,
      mnfiltroSelDtEmissaoInicioUS: false,

      filtroSelDtEmissaoFimUS: null,
      mnfiltroSelDtEmissaoFimUS: false,

      filtroSelDtVectorOriginalInicioUS: null,
      mnfiltroSelDtVectorOriginalInicioUS: false,

      filtroSelDtVectorOriginalFimUS: null,
      mnfiltroSelDtVectorOriginalFimUS: false,

      filtroSelDtVectoInicioUS: null,
      mnfiltroSelDtVectoInicioUS: false,

      filtroSelDtVectoFimUS: null,
      mnfiltroSelDtVectoFimUS: false,

      filtroSelDtQuitacaoInicioUS: null,
      mnfiltroSelDtQuitacaoInicioUS: false,

      filtroSelDtQuitacaoFimUS: null,
      mnfiltroSelDtQuitacaoFimUS: false,

      filtrosSelDtBloqueioInicioUS: null,
      mnfiltrosSelDtBloqueioInicioUS: false,

      filtrosSelDtBloqueioFimUS: null,
      mnfiltrosSelDtBloqueioFimUS: false,

      filtroSelDtPromessaInicioUS: null,
      mnfiltroSelDtPromessaInicioUS: false,

      filtroSelDtPromessaFimUS: null,
      mnfiltroSelDtPromessaFimUS: false,

      filtroSelDtCancelInicioUS: null,
      mnfiltroSelDtCancelInicioUS: false,
      filtroSelDtCancelFimUS: null,
      mnfiltroSelDtCancelFimUS: false,

      filtroSelDtEmissaoNFpgInicioUS: null,
      mnfiltroSelDtEmissaoNFpgInicioUS: false,

      filtroSelDtEmissaoNFpgFimUS: null,
      mnfiltroSelDtEmissaoNFpgFimUS: false,

      parcelaDefault: {
        origem: null,
        status: null,
        numero: null,
        nroParcelas: null,
        idParcela: null,
        idOperacao: null,
        idFormaPgto: null,
        omitirPacotes: true,
        idCedente: null,
        dtEmissaoInicio: null,
        dtEmissaoFim: null,
        dtVectoOriginalInicio: null,
        dtVectoOriginalFim: null,
        dtVectoInicio: null,
        dtVectoFim: null,
        dtQuitacaoInicio: null,
        dtQuitacaoFim: null,
        dtBloqueioInicio: null,
        dtBloqueioFim: null,
        dtPromessaInicio: null,
        dtPromessaFim: null,
        dtCancelamentoInicio: null,
        dtCancelamentoFim: null,
        dtEmissaoNFpgInicio: null,
        dtEmissaoNFpgFim: null,
        valorInicio: 0,
        valorFim: 0,
        valorLiquidoInicio: 0,
        valorLiquidoFim: 0,
        nossoNumeroInicio: null,
        nossoNumeroFim: null,
        statusCobranca: null,
        ajustada: null,
        conciliado: null,
        idLoteFaturamento: null,
        codRetorno: null,
        descRetorno: null,
        retorno: 1,
        codAntigoCirculacao: null,
        codFinanceiro: null,
        idProtheus: null,
        asaas: null,
        payAsaas: null,
        numeroNFpg: null,
        dtEmissaoNFpgInicio: null,
        dtEmissaoNFpgFim: null,
        obsCobranca: null,
        mensal: null,
        confirmada: null,
        tipoServico: null,
        formaLancamento: null,
        contaD: null,
        idCentroCusto: null
      },

      itensOrigem: [
        { id: "FIN", nome: "Financeiro" },
        { id: "ANU", nome: "Anúncio" },
        { id: "ASS", nome: "Assinatura" },
        { id: "PAC", nome: "Pacote" },
        { id: "AVU", nome: "Venda Avulsa" }
      ],

      itensStatus: ["PROPOSTA", "ABERTA", "BLOQUEADA", "QUITADA", "CANCELADA", "EXCLUÍDA"],

      itensStatusCobranca: [
        { id: 0, nome: "Não Emitida" },
        { id: 10, nome: "Emitida" },
        { id: 11, nome: "Confirmada no Banco" },
        { id: 20, nome: "Alterada" },
        // { id: 21, nome: "Alterada no Banco" }, volta para 11 para ser possível realterar
        { id: 30, nome: "Cancelada" },
        // { id: 31, nome: "Cancelada no Banco" }, volta para 0 ao ser confirmado o cancelamento - para ser possível cobrar de outra forma
        { id: 40, nome: "Erro" }
      ],
      itensSimNao: [
        { id: 1, nome: "SIM" },
        { id: 0, nome: "NÃO" }
      ],
      itensConfirmada: [
        { id: 0, nome: "Orçamento" },
        { id: 1, nome: "Pagamento" }
      ],

      tiposServico: [
        { id: "01", nome: "01 - Cobrança" },
        { id: "20", nome: "20 - Pagamento de Fornecedor" },
        { id: "22", nome: "22 - Pagamento de Contas, Tributos e Impostos" },
        { id: "23", nome: "23 - Interoperabilidade entre Contas de Instituições de Pagamentos" },
        { id: "30", nome: "30 - Pagamento de Salários" },
        { id: "32", nome: "32 - Pagamento de honorários" },
        { id: "50", nome: "50 - Pagamento de Sinistros Segurados" },
        { id: "60", nome: "60 - Pagamento de Despesas Viajante em Trânsito" },
        { id: "70", nome: "70 - Pagamento Autorizado" },
        { id: "75", nome: "75 - Pagamento de Credenciados" },
        { id: "80", nome: "80 - Pagamento de Representantes / Vendedores Autorizados" },
        { id: "90", nome: "90 - Pagamento de Benefícios - INSS (GPS PJ sem código de barras)" },
        { id: "98", nome: "98 - Pagamentos Diversos" }
      ],

      formasLancamento: [
        { id: "01", nome: "01 - Crédito em Conta Corrente/Salário" },
        { id: "03", nome: "03 - TED" },
        { id: "05", nome: "05 - Crédito em Conta Poupança" },
        { id: "10", nome: "10 - OP à Disposição" },
        { id: "11", nome: "11 - Pagamento de Contas e Tributos (com código de barras)" },
        { id: "16", nome: "16 - Pagamento de Tributo - DARF Normal (sem código de barras)" },
        { id: "17", nome: "17 - Pagamento de Tributo - GPS (Guia da Previdência Social)" },
        { id: "20", nome: "20 - Pagamento com Autenticação" },
        { id: "30", nome: "30 - Pagamento de Títulos Banrisul" },
        { id: "31", nome: "31 - Pagamento de Títulos de Outros Bancos" },
        { id: "41", nome: "41 - TED - Outra Titularidade" },
        { id: "43", nome: "43 - TED - Mesma Titularidade" },
        { id: "45", nome: "45 - Transferências via PIX" }
      ],

      despesas: [],
      centrosCusto: [],

      idSitePrincipal: null,
      formasPgto: [],
      omitirPacotes: true,
      cedentes: [],
      painel: [0, 0, 0],

      autoUpdate: true
    };
  },

  methods: {
    filtroUrlParametros() {
      var filtro = "";

      //* Verifica os campos que foram preenchidos
      if (this.filtrosSelecionados.origem && this.filtrosSelecionados.origem.length) {
        filtro += "&" + this.prefixo + "origem=" + this.filtrosSelecionados.origem.join(",");
      }

      if (this.filtrosSelecionados.status && this.filtrosSelecionados.status.length) {
        filtro += "&" + this.prefixo + "status=" + this.filtrosSelecionados.status.join(",");
      }

      if (this.filtrosSelecionados.idParcela) {
        filtro += "&" + this.prefixo + "idParcela=" + this.filtrosSelecionados.idParcela;
      }
      if (this.filtrosSelecionados.idOperacao) {
        filtro += "&" + this.prefixo + "idOperacao=" + this.filtrosSelecionados.idOperacao;
      }

      if (this.filtrosSelecionados.numero) {
        filtro += "&" + this.prefixo + "numero=" + this.filtrosSelecionados.numero;
      }
      if (this.filtrosSelecionados.nroParcelas) {
        filtro += "&" + this.prefixo + "nroParcelas=" + this.filtrosSelecionados.nroParcelas;
      }

      if (this.filtrosSelecionados.idFormaPgto && this.filtrosSelecionados.idFormaPgto.length) {
        filtro += "&" + this.prefixo + "idFormaPgto=" + this.filtrosSelecionados.idFormaPgto.join(",");
      }

      if (this.filtrosSelecionados.omitirPacotes) {
        filtro += "&" + this.prefixo + "omitirPacotes=1";
      }

      if (this.filtrosSelecionados.idCedente && this.filtrosSelecionados.idCedente.length) {
        filtro += "&" + this.prefixo + "idCedente=" + this.filtrosSelecionados.idCedente.join(",");
      }

      if (this.filtrosSelecionados.dtEmissaoInicio) {
        filtro += "&" + this.prefixo + "dtEmissaoInicio=" + this.filtrosSelecionados.dtEmissaoInicio;
      }

      if (this.filtrosSelecionados.dtEmissaoFim) {
        filtro += "&" + this.prefixo + "dtEmissaoFim=" + this.filtrosSelecionados.dtEmissaoFim;
      }

      if (this.filtrosSelecionados.dtVectoOriginalInicio) {
        filtro += "&" + this.prefixo + "dtVectoOriginalInicio=" + this.filtrosSelecionados.dtVectoOriginalInicio;
      }

      if (this.filtrosSelecionados.dtVectoOriginalFim) {
        filtro += "&" + this.prefixo + "dtVectoOriginalFim=" + this.filtrosSelecionados.dtVectoOriginalFim;
      }

      if (this.filtrosSelecionados.dtVectoInicio) {
        filtro += "&" + this.prefixo + "dtVectoInicio=" + this.filtrosSelecionados.dtVectoInicio;
      }

      if (this.filtrosSelecionados.dtVectoFim) {
        filtro += "&" + this.prefixo + "dtVectoFim=" + this.filtrosSelecionados.dtVectoFim;
      }

      if (this.filtrosSelecionados.dtQuitacaoInicio) {
        filtro += "&" + this.prefixo + "dtQuitacaoInicio=" + this.filtrosSelecionados.dtQuitacaoInicio;
      }

      if (this.filtrosSelecionados.dtQuitacaoFim) {
        filtro += "&" + this.prefixo + "dtQuitacaoFim=" + this.filtrosSelecionados.dtQuitacaoFim;
      }

      if (this.filtrosSelecionados.dtBloqueioInicio) {
        filtro += "&" + this.prefixo + "dtBloqueioInicio=" + this.filtrosSelecionados.dtBloqueioInicio;
      }

      if (this.filtrosSelecionados.dtBloqueioFim) {
        filtro += "&" + this.prefixo + "dtBloqueioFim=" + this.filtrosSelecionados.dtBloqueioFim;
      }

      if (this.filtrosSelecionados.dtPromessaInicio) {
        filtro += "&" + this.prefixo + "dtPromessaInicio=" + this.filtrosSelecionados.dtPromessaInicio;
      }

      if (this.filtrosSelecionados.dtPromessaFim) {
        filtro += "&" + this.prefixo + "dtPromessaFim=" + this.filtrosSelecionados.dtPromessaFim;
      }

      if (this.filtrosSelecionados.dtCancelamentoInicio) {
        filtro += "&" + this.prefixo + "dtCancelamentoInicio=" + this.filtrosSelecionados.dtCancelamentoInicio;
      }
      if (this.filtrosSelecionados.dtCancelamentoFim) {
        filtro += "&" + this.prefixo + "dtCancelamentoFim=" + this.filtrosSelecionados.dtCancelamentoFim;
      }

      if (this.filtrosSelecionados.valorInicio) {
        filtro += "&" + this.prefixo + "valorInicio=" + this.filtrosSelecionados.valorInicio;
      }
      if (this.filtrosSelecionados.valorFim) {
        filtro += "&" + this.prefixo + "valorFim=" + this.filtrosSelecionados.valorFim;
      }

      if (this.filtrosSelecionados.valorLiquidoInicio) {
        filtro += "&" + this.prefixo + "valorLiquidoInicio=" + this.filtrosSelecionados.valorLiquidoInicio;
      }
      if (this.filtrosSelecionados.valorLiquidoFim) {
        filtro += "&" + this.prefixo + "valorLiquidoFim=" + this.filtrosSelecionados.valorLiquidoFim;
      }

      if (this.filtrosSelecionados.nossoNumeroInicio) {
        filtro += "&" + this.prefixo + "nossoNumeroInicio=" + this.filtrosSelecionados.nossoNumeroInicio;
      }

      if (this.filtrosSelecionados.nossoNumeroFim) {
        filtro += "&" + this.prefixo + "nossoNumeroFim=" + this.filtrosSelecionados.nossoNumeroFim;
      }

      if (this.filtrosSelecionados.statusCobranca && this.filtrosSelecionados.statusCobranca.length) {
        filtro += "&" + this.prefixo + "statusCobranca=" + this.filtrosSelecionados.statusCobranca.join(",");
      }

      if (this.filtrosSelecionados.ajustada != null) {
        filtro += "&" + this.prefixo + "ajustada=" + this.filtrosSelecionados.ajustada;
      }

      if (this.filtrosSelecionados.conciliado != null) {
        filtro += "&" + this.prefixo + "conciliado=" + this.filtrosSelecionados.conciliado;
      }

      if (this.filtrosSelecionados.idLoteFaturamento) {
        filtro += "&" + this.prefixo + "idLoteFaturamento=" + this.filtrosSelecionados.idLoteFaturamento;
      }

      if (this.filtrosSelecionados.codRetorno) {
        filtro += "&" + this.prefixo + "codRetorno=" + this.filtrosSelecionados.codRetorno;
      }

      if (this.filtrosSelecionados.descRetorno) {
        filtro += "&" + this.prefixo + "descRetorno=" + this.filtrosSelecionados.descRetorno;
      }
      if (this.filtrosSelecionados.codRetorno || this.filtrosSelecionados.descRetorno) {
        filtro += "&" + this.prefixo + "retorno=" + (this.filtrosSelecionados.retorno ? 1 : 0);
      }

      if (this.filtrosSelecionados.codAntigoCirculacao) {
        filtro += "&" + this.prefixo + "codAntigoCirculacao=" + this.filtrosSelecionados.codAntigoCirculacao;
      }

      if (this.filtrosSelecionados.codFinanceiro) {
        filtro += "&" + this.prefixo + "codFinanceiro=" + this.filtrosSelecionados.codFinanceiro;
      }

      if (this.filtrosSelecionados.idProtheus) {
        filtro += "&" + this.prefixo + "idProtheus=" + this.filtrosSelecionados.idProtheus;
      }
      if (this.filtrosSelecionados.asaas != null) {
        filtro += "&" + this.prefixo + "asaas=" + this.filtrosSelecionados.asaas;
      }
      if (this.filtrosSelecionados.payAsaas) {
        filtro += "&" + this.prefixo + "payAsaas=" + this.filtrosSelecionados.payAsaas;
      }

      if (this.filtrosSelecionados.numeroNFpg) {
        filtro += "&" + this.prefixo + "numeroNFpg=" + this.filtrosSelecionados.numeroNFpg;
      }
      if (this.filtrosSelecionados.dtEmissaoNFpgInicio) {
        filtro += "&" + this.prefixo + "dtEmissaoNFpgInicio=" + this.filtrosSelecionados.dtEmissaoNFpgInicio;
      }
      if (this.filtrosSelecionados.dtEmissaoNFpgFim) {
        filtro += "&" + this.prefixo + "dtEmissaoNFpgFim=" + this.filtrosSelecionados.dtEmissaoNFpgFim;
      }

      if (this.filtrosSelecionados.obsCobranca) {
        filtro += "&" + this.prefixo + "obsCobranca=" + this.filtrosSelecionados.obsCobranca;
      }

      if (this.filtrosSelecionados.mensal != null) {
        filtro += "&" + this.prefixo + "mensal=" + this.filtrosSelecionados.mensal;
      }
      if (this.filtrosSelecionados.confirmada != null) {
        filtro += "&" + this.prefixo + "confirmada=" + this.filtrosSelecionados.confirmada;
      }

      if (this.filtrosSelecionados.tipoServico && this.filtrosSelecionados.tipoServico.length) {
        filtro += "&" + this.prefixo + "tipoServico=" + this.filtrosSelecionados.tipoServico.join(",");
      }
      if (this.filtrosSelecionados.formaLancamento && this.filtrosSelecionados.formaLancamento.length) {
        filtro += "&" + this.prefixo + "formaLancamento=" + this.filtrosSelecionados.formaLancamento.join(",");
      }

      if (this.filtrosSelecionados.contaD) {
        filtro += "&" + this.prefixo + "contaD=" + this.filtrosSelecionados.contaD;
      }
      if (this.filtrosSelecionados.idCentroCusto) {
        filtro += "&" + this.prefixo + "idCentroCusto=" + this.filtrosSelecionados.idCentroCusto;
      }

      if (filtro) {
        filtro = filtro + "&" + this.prefixo + "idSite=" + this.idSitePrincipal;
      }

      return filtro;
    },
    carregarFormasPgto() {
      api.get(process.env.VUE_APP_URL_WEB_API + "adm/forma-pagamento/all?idSite=" + this.idSitePrincipal).then(response => {
        this.formasPgto = response.data;
      });
    },
    async carregarCedentes() {
      const boletos = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/cedente/boleto");
      this.cedentes = boletos.data;
      const debitos = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/cedente/debito");
      Array.prototype.push.apply(this.cedentes, debitos.data);
    },
    async carregarDespesas() {
      //* Busca todas as contas de despesas
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/conta/despesas");
      if (response.data) {
        this.despesas = response.data;
        this.despesas.forEach(element => {
          element.nomeS = element.nome;
          element.nome = element.nome + " - " + element.mae;
        });
      }
    },
    async carregarCentrosCusto() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/centro-custo/lista");
      this.centrosCusto = response.data;
    },

    limpar() {
      this.filtrosSelecionados = JSON.parse(JSON.stringify(this.parcelaDefault));
      this.painel = [0, 0, 0];
      this.filtroSelDtEmissaoInicioUS = null;
      this.filtroSelDtEmissaoFimUS = null;
      this.filtroSelDtVectorOriginalInicioUS = null;
      this.filtroSelDtVectorOriginalFimUS = null;
      this.filtroSelDtVectoInicioUS = null;
      this.filtroSelDtVectoFimUS = null;
      this.filtroSelDtQuitacaoInicioUS = null;
      this.filtroSelDtQuitacaoFimUS = null;
      this.filtrosSelDtBloqueioInicioUS = null;
      this.filtrosSelDtBloqueioFimUS = null;
      this.filtroSelDtPromessaInicioUS = null;
      this.filtroSelDtPromessaFimUS = null;
      this.filtroSelDtCancelInicioUS = null;
      this.filtroSelDtCancelFimUS = null;
    },

    selectAll(element) {
      if (element == "itensStatus") {
        this.filtrosSelecionados.status = this.itensStatus;
      } else if (element == "formasPgto") {
        this.filtrosSelecionados.idFormaPgto = [];
        this.formasPgto.forEach(element => {
          this.filtrosSelecionados.idFormaPgto.push(element.id);
        });
      } else if (element == "itensStatusCobranca") {
        this.filtrosSelecionados.statusCobranca = [];
        this.itensStatusCobranca.forEach(element => {
          this.filtrosSelecionados.statusCobranca.push(element.id);
        });
      } else if (element == "itensOrigem") {
        this.filtrosSelecionados.origem = [];
        this.itensOrigem.forEach(element => {
          this.filtrosSelecionados.origem.push(element.id);
        });
      } else if (element == "itensTipoServico") {
        this.filtrosSelecionados.tipoServico = [];
        this.tiposServico.forEach(element => {
          this.filtrosSelecionados.tipoServico.push(element.id);
        });
      } else if (element == "itensFormaLancamento") {
        this.filtrosSelecionados.formaLancamento = [];
        this.formasLancamento.forEach(element => {
          this.filtrosSelecionados.formaLancamento.push(element.id);
        });
      }
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    filtrarItens(item, queryText, itemText) {
      return util.filtrarItens(item, queryText, itemText);
    },
    valida_data(date) {
      if (date) {
        return util.valida_data_BR(date);
      } else {
        return true;
      }
    },
    formatDateUS(date) {
      if (date && date.length == 10) {
        return util.formatDateUS(date);
      }
    }
  },

  watch: {
    filtroSelDtEmissaoInicioUS(val) {
      this.filtrosSelecionados.dtEmissaoInicio = util.formatDate(val);
      this.mnfiltroSelDtEmissaoInicioUS = false;
    },
    filtroSelDtEmissaoFimUS(val) {
      this.filtrosSelecionados.dtEmissaoFim = util.formatDate(val);
      this.mnfiltroSelDtEmissaoFimUS = false;
    },
    filtroSelDtVectorOriginalInicioUS(val) {
      this.filtrosSelecionados.dtVectoOriginalInicio = util.formatDate(val);
      this.mnfiltroSelDtVectorOriginalInicioUS = false;
    },
    filtroSelDtVectorOriginalFimUS(val) {
      this.filtrosSelecionados.dtVectoOriginalFim = util.formatDate(val);
      this.mnfiltroSelDtVectorOriginalFimUS = false;
    },
    filtroSelDtVectoInicioUS(val) {
      this.filtrosSelecionados.dtVectoInicio = util.formatDate(val);
      this.mnfiltroSelDtVectoInicioUS = false;
    },
    filtroSelDtVectoFimUS(val) {
      this.filtrosSelecionados.dtVectoFim = util.formatDate(val);
      this.mnfiltroSelDtVectoFimUS = false;
    },
    filtroSelDtQuitacaoInicioUS(val) {
      this.filtrosSelecionados.dtQuitacaoInicio = util.formatDate(val);
      this.mnfiltroSelDtQuitacaoInicioUS = false;
    },
    filtroSelDtQuitacaoFimUS(val) {
      this.filtrosSelecionados.dtQuitacaoFim = util.formatDate(val);
      this.mnfiltroSelDtQuitacaoFimUS = false;
    },
    filtrosSelDtBloqueioInicioUS(val) {
      this.filtrosSelecionados.dtBloqueioInicio = util.formatDate(val);
      this.mnfiltrosSelDtBloqueioInicioUS = false;
    },
    filtrosSelDtBloqueioFimUS(val) {
      this.filtrosSelecionados.dtBloqueioFim = util.formatDate(val);
      this.mnfiltrosSelDtBloqueioFimUS = false;
    },
    filtroSelDtPromessaInicioUS(val) {
      this.filtrosSelecionados.dtPromessaInicio = util.formatDate(val);
      this.mnfiltroSelDtPromessaInicioUS = false;
    },
    filtroSelDtPromessaFimUS(val) {
      this.filtrosSelecionados.dtPromessaFim = util.formatDate(val);
      this.mnfiltroSelDtPromessaFimUS = false;
    },
    filtroSelDtCancelInicioUS(val) {
      this.filtrosSelecionados.dtCancelamentoInicio = util.formatDate(val);
      this.mnfiltroSelDtCancelInicioUS = false;
    },
    filtroSelDtCancelFimUS(val) {
      this.filtrosSelecionados.dtCancelamentoFim = util.formatDate(val);
      this.mnfiltroSelDtCancelFimUS = false;
    },
    filtroSelDtEmissaoNFpgInicioUS(val) {
      this.filtrosSelecionados.dtEmissaoNFpgInicio = util.formatDate(val);
      this.mnfiltroSelDtEmissaoNFpgInicioUS = false;
    },
    filtroSelDtEmissaoNFpgFimUS(val) {
      this.filtrosSelecionados.dtEmissaoNFpgFim = util.formatDate(val);
      this.mnfiltroSelDtEmissaoNFpgFimUS = false;
    }
  },

  async created() {
    try {
      this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

      //* Carrega os itens dos selects
      this.carregarFormasPgto();
      this.carregarCedentes();
      if (!this.receber) {
        this.carregarDespesas();
        this.carregarCentrosCusto();
      }
    } catch {
      // this.mensagem = "Ocorreu um erro ao carregar os dados";
      // this.snackErro = true;
    }
  }
};
</script>
