<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">money</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light"
        >Tabela Preço</v-toolbar-title
      >
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear
              :active="isUpdating"
              class="ma-0"
              color="cyan accent-3"
              height="3"
              indeterminate
            ></v-progress-linear>
          </v-flex>

          <v-flex xs12 md12 v-if="siteGrupo.length > 0">
            <v-autocomplete
              v-model="tabelaPreco.idSite"
              :disabled="isUpdating"
              :items="siteGrupo"
              :filter="filtrarObjetos"
              label="Restrito ao Site"
              item-text="nome"
              item-value="id"
              :rules="[validacao.idSite == false || validacao.idSite]"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              clearable
              @change="validacao.idSite = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6>
            <v-autocomplete
              autofocus
              v-model="produtoSelecionado"
              :items="produtos"
              :filter="filtrarObjetos"
              hint="Obrigatório"
              :rules="[value => (!!value && !!value.id) || 'Obrigatório.']"
              label="Selecione o Produto"
              item-text="nome"
              item-value="id"
              :disabled="isUpdating"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              @change="
                tabelaPreco.idCaderno = null;
                tabelaPreco.idFormatoCaderno = null;
                carregarCadernos();
              "
              return-object
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6>
            <v-autocomplete
              v-model="cadernoSelecionado"
              :items="cadernos"
              :filter="filtrarObjetos"
              hint="Obrigatório"
              :rules="[value => !!value || 'Obrigatório']"
              label="Selecione o Caderno"
              item-text="nome"
              item-value="id"
              :disabled="isUpdating"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              return-object
              @change="tabelaPreco.idFormatoCaderno = null"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6>
            <v-autocomplete
              v-model="tabelaPreco.idFormatoCaderno"
              :items="formatos"
              :filter="filtrarObjetos"
              hint="Obrigatório"
              :rules="[
                validacao.idFormatoCaderno == false ||
                  validacao.idFormatoCaderno,
                value => !!value || 'Obrigatório'
              ]"
              label="Selecione o Formato"
              item-text="nome"
              item-value="id"
              :disabled="isUpdating"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              @change="validacao.idFormatoCaderno = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex
            xs12
            md6
            v-if="
              produtoSelecionado.tipo &&
                produtoSelecionado.tipo != 'CLASSIFICADOS'
            "
          >
            <v-autocomplete
              v-model="tabelaPreco.idDeterminacao"
              :items="determinacoes"
              :filter="filtrarObjetos"
              hint="Vazio = tabela única para todas as determinações/colocações do formato"
              persistent-hint
              :rules="[
                validacao.idDeterminacao == false || validacao.idDeterminacao,
                value =>
                  (tabelaPreco.idColocacao && !!value) ||
                  !tabelaPreco.idColocacao ||
                  'Obrigatório'
              ]"
              label="Selecione a Determinação"
              item-text="nome"
              item-value="id"
              :disabled="isUpdating"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              clearable
              @click:clear="tabelaPreco.idColocacao = null"
              @change="validacao.idDeterminacao = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex
            xs12
            md6
            v-if="
              produtoSelecionado.tipo &&
                produtoSelecionado.tipo != 'CLASSIFICADOS'
            "
          >
            <v-autocomplete
              v-model="tabelaPreco.idColocacao"
              :items="colocacoes"
              :filter="filtrarObjetos"
              hint="Vazio = tabela única para todas as determinações/colocações do formato"
              persistent-hint
              :rules="[
                validacao.idColocacao == false || validacao.idColocacao,
                value =>
                  (tabelaPreco.idDeterminacao && !!value) ||
                  !tabelaPreco.idDeterminacao ||
                  'Obrigatório'
              ]"
              label="Selecione a Colocação"
              item-text="nome"
              item-value="id"
              :disabled="isUpdating"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              clearable
              @click:clear="tabelaPreco.idDeterminacao = null"
              @change="validacao.idColocacao = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md6>
            <v-text-field
              v-model="tabelaPreco.nome"
              label="Nome da Tabela"
              maxlength="50"
              hint="Obrigatório"
              :rules="[
                validacao.nome == false || validacao.nome,
                value => !!value || 'Obrigatório.',
                value => value.length > 2 || 'Preencha o nome da tabela'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                tabelaPreco.nome = pLetra(tabelaPreco.nome);
                validacao.nome = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md3>
            <v-autocomplete
              v-model="tabelaPreco.tipoCobranca"
              :disabled="isUpdating"
              :items="tiposCobranca"
              :filter="filtrarObjetos"
              hint="Obrigatório"
              :rules="[
                validacao.tipoCobranca == false || validacao.tipoCobranca,
                value => !!value || 'Obrigatório'
              ]"
              label="Selecione o Tipo de Cobrança"
              item-text="nome"
              item-value="id"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              @change="validacao.tipoCobranca = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 md3>
            <v-menu
              ref="mnuDtInicio"
              v-model="mnuDtInicio"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dtInicio"
                  label="Data Inicial"
                  v-mask="'##/##/####'"
                  hint="Obrigatório"
                  :rules="[
                    validacao.dtInicio == false || validacao.dtInicio,
                    value => valida_data(value) || 'Data inválida',
                    value => !!value || 'Obrigatório.'
                  ]"
                  :disabled="isUpdating"
                  prepend-icon="event"
                  v-on="on"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @keyup="
                    dtInicioUS = valida_data(dtInicio)
                      ? formatDateUS(dtInicio)
                      : null
                  "
                  @change="validacao.dtInicio = false"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dtInicioUS"
                locale="pt-br"
                no-title
                @input="mnuDtInicio = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>

          <v-flex xs12>
            <h4 class="overline font-weight-black font-italic">
              Preços
            </h4>
            <v-divider></v-divider>
          </v-flex>

          <v-flex xs6 md3 v-if="cadernoSelecionado.publicaDom">
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="tabelaPreco.vlrDom"
              label="Domingo"
              :decimalLength="produtoSelecionado.tipo == 'ONLINE' ? 4 : 2"
              :allowNegative="false"
              :max="9999"
              :rules="[
                validacao.vlrDom == false || validacao.vlrDom,
                value => !!value || 'Obrigatório.',
                value => (value && value.length < 9) || 'Verifique o valor'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.vlrDom = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3 v-if="cadernoSelecionado.publicaSeg">
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="tabelaPreco.vlrSeg"
              label="Segunda-Feira"
              :decimalLength="produtoSelecionado.tipo == 'ONLINE' ? 4 : 2"
              :allowNegative="false"
              :max="9999"
              :rules="[
                validacao.vlrSeg == false || validacao.vlrSeg,
                value => !!value || 'Obrigatório.',
                value => (value && value.length < 9) || 'Verifique o valor'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.vlrSeg = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3 v-if="cadernoSelecionado.publicaTer">
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="tabelaPreco.vlrTer"
              label="Terça-Feira"
              :decimalLength="produtoSelecionado.tipo == 'ONLINE' ? 4 : 2"
              :allowNegative="false"
              :max="9999"
              :rules="[
                validacao.vlrTer == false || validacao.vlrTer,
                value => !!value || 'Obrigatório.',
                value => (value && value.length < 9) || 'Verifique o valor'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.vlrTer = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3 v-if="cadernoSelecionado.publicaQua">
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="tabelaPreco.vlrQua"
              label="Quarta-Feira"
              :decimalLength="produtoSelecionado.tipo == 'ONLINE' ? 4 : 2"
              :allowNegative="false"
              :max="9999"
              :rules="[
                validacao.vlrQua == false || validacao.vlrQua,
                value => !!value || 'Obrigatório.',
                value => (value && value.length < 9) || 'Verifique o valor'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.vlrQua = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3 v-if="cadernoSelecionado.publicaQui">
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="tabelaPreco.vlrQui"
              label="Quinta-Feira"
              :decimalLength="produtoSelecionado.tipo == 'ONLINE' ? 4 : 2"
              :allowNegative="false"
              :max="9999"
              :rules="[
                validacao.vlrQui == false || validacao.vlrQui,
                value => !!value || 'Obrigatório.',
                value => (value && value.length < 9) || 'Verifique o valor'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.vlrQui = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3 v-if="cadernoSelecionado.publicaSex">
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="tabelaPreco.vlrSex"
              label="Sexta-Feira"
              :decimalLength="produtoSelecionado.tipo == 'ONLINE' ? 4 : 2"
              :allowNegative="false"
              :max="9999"
              :rules="[
                validacao.vlrSex == false || validacao.vlrSex,
                value => !!value || 'Obrigatório.',
                value => (value && value.length < 9) || 'Verifique o valor'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.vlrSex = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3 v-if="cadernoSelecionado.publicaSab">
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="tabelaPreco.vlrSab"
              label="Sábado"
              :decimalLength="produtoSelecionado.tipo == 'ONLINE' ? 4 : 2"
              :allowNegative="false"
              :max="9999"
              :rules="[
                validacao.vlrSab == false || validacao.vlrSab,
                value => !!value || 'Obrigatório.',
                value => (value && value.length < 9) || 'Verifique o valor'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              @change="validacao.vlrSab = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{
              validacao.mensagemErro
            }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <v-card-actions id="flex-wrap">
      <v-btn
        to="/comercial/anuncio-tabelas"
        id="btn-rsn"
        color="orange lighten-2 white"
        style="color:white"
      >
        <v-icon class="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="blue white"
        style="color:white"
        id="btn-rsn"
        depressed
        @click="salvar('false')"
      >
        <v-icon id="icon-rsn" left>update</v-icon>Salvar
      </v-btn>
      <v-btn
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="orange white"
        style="color:white"
        id="btn-rsn"
        outlined
        @click="salvar('retornar')"
      >
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="blue white"
        style="color:white"
        id="btn-rsn"
        outlined
        @click="salvar('true')"
        v-if="direitoCriar"
      >
        <v-icon id="icon-rsn" left>autorenew</v-icon>Salvar e Novo
      </v-btn>
      <v-btn
        v-if="direitoExcluir && tabelaPreco.id"
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="red lighten-1"
        style="color:white"
        id="btn-rsn"
        outlined
        @click="dialogConfirm = true"
      >
        <v-icon left>cancel</v-icon>Excluir
      </v-btn>
    </v-card-actions>
    <v-snackbar
      v-model="snackbar"
      color="info"
      :timeout="5000"
      relative
      bottom
      left
      >{{ mensagem }}</v-snackbar
    >
    <v-snackbar
      v-model="snackErro"
      color="error"
      :timeout="5000"
      relative
      bottom
      left
      >{{ mensagem }}</v-snackbar
    >

    <v-dialog v-model="dialogConfirm" persistent max-width="290">
      <v-card>
        <v-toolbar>
          <span class="headline">Confirmação</span>
        </v-toolbar>
        <br />
        <v-card-text
          ><span
            style="color: black;"
            v-html="
              'Tem certeza que deseja EXCLUIR? (esta operação não pode ser desfeita)'
            "
          ></span
        ></v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="dialogConfirm = false"
            >Não</v-btn
          >
          <v-btn color="green darken-1" text @click="excluir">Sim</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      tabelaPreco: {
        id: "",
        idSite: null,
        idFormatoCaderno: null,
        idDeterminacao: null,
        idColocacao: null,
        nome: "",
        tipoCobranca: null,
        dtInicio: null,
        vlrDom: 0,
        vlrSeg: 0,
        vlrTer: 0,
        vlrQua: 0,
        vlrQui: 0,
        vlrSex: 0,
        vlrSab: 0
      },

      validacao: {
        id: false,
        idSite: false,
        idFormatoCaderno: false,
        idDeterminacao: false,
        idColocacao: false,
        nome: false,
        tipoCobranca: false,
        dtInicio: false,
        vlrDom: false,
        vlrSeg: false,
        vlrTer: false,
        vlrQua: false,
        vlrQui: false,
        vlrSex: false,
        vlrSab: false,
        mensagemErro: false
      },

      siteGrupo: [],

      produtos: [],
      cadernos: [],
      formatos: [],
      determinacoes: [],
      colocacoes: [],

      tiposCobranca: [
        { id: 1, nome: "Centímetro x Coluna" },
        { id: 2, nome: "Preço Fixo" },
        { id: 3, nome: "Largura x Altura" },
        { id: 4, nome: "Número de Palavras" },
        { id: 5, nome: "Número de Caracteres" }
      ],

      dtInicio: null,
      dtInicioUS: null,
      mnuDtInicio: false,

      idSitePrincipal: null,
      produtoSelecionado: { id: null, tipo: null },
      cadernoSelecionado: { id: null },

      dialogConfirm: false,
      direitoCriar: false,
      direitoExcluir: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,
      isUpdating: false,
      bloqueiaBotoes: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem =
          "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      if (!this.produtoSelecionado.tipo) {
        this.validacao.formato = "Obrigatório";
        this.mensagem =
          "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }
      if (this.produtoSelecionado.tipo != "CLASSIFICADOS") {
        if (this.tabelaPreco.idDeterminacao && !this.tabelaPreco.idColocacao) {
          this.validacao.idColocacao =
            "Obrigatória se determinação foi selecionada";
          this.mensagem =
            "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
          this.$refs.formElemento.validate();
          return;
        }
        if (!this.tabelaPreco.idDeterminacao && this.tabelaPreco.idColocacao) {
          this.validacao.idDeterminacao =
            "Obrigatória se colocação foi selecionada";
          this.mensagem =
            "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
          this.$refs.formElemento.validate();
          return;
        }
      }
      if (!this.cadernoSelecionado.publicaSeg) {
        this.tabelaPreco.vlrSeg = 0;
      }
      if (!this.cadernoSelecionado.publicaTer) {
        this.tabelaPreco.vlrTer = 0;
      }
      if (!this.cadernoSelecionado.publicaQua) {
        this.tabelaPreco.vlrQua = 0;
      }
      if (!this.cadernoSelecionado.publicaQui) {
        this.tabelaPreco.vlrQui = 0;
      }
      if (!this.cadernoSelecionado.publicaSex) {
        this.tabelaPreco.vlrSex = 0;
      }
      if (!this.cadernoSelecionado.publicaSab) {
        this.tabelaPreco.vlrSab = 0;
      }
      if (!this.cadernoSelecionado.publicaDom) {
        this.tabelaPreco.vlrDom = 0;
      }

      if (this.dtInicio != null) {
        if (util.valida_data(util.formatDateUS(this.dtInicio))) {
          this.tabelaPreco.dtInicio = util.formatDateUS(this.dtInicio);
        } else {
          this.validacao.dtInicio = "Data inválida";
          this.mensagem =
            "Verifique os campos indicados em vermelho neste formulário"; //não deveria cair aqui
          this.snackErro = true;
          return;
        }
      } else {
        this.mensagem =
          "Verifique os campos indicados em vermelho neste formulário"; //não deveria cair aqui
        this.snackErro = true;
        return;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        if (this.id) {
          retorno = await api.put(
            process.env.VUE_APP_URL_WEB_API + "com/anuncio-tabela/" + this.id,
            this.tabelaPreco
          );
        } else {
          retorno = await api.post(
            process.env.VUE_APP_URL_WEB_API +
              "com/anuncio-tabela?idSite=" +
              this.idSitePrincipal,
            this.tabelaPreco
          );
          this.id = retorno.data.id;
          this.tabelaPreco.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "true") {
          this.$router.push("/comercial/anuncio-tabela");
          this.$router.go("/comercial/anuncio-tabela");
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/comercial/anuncio-tabelas");
          }, 2000);
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem =
            "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem =
            "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    async carregarProdutos() {
      try {
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "com/produto/lista" +
            (this.tabelaPreco.idProduto
              ? "?inativo=" + this.tabelaPreco.idProduto
              : "")
        );
        if (response.data) {
          this.produtos = response.data;
          this.produtos.forEach(element => {
            if (!element.situacao) {
              element.nome = "INATIVO: " + element.nome;
            }
            if (element.id == this.tabelaPreco.idProduto) {
              this.produtoSelecionado = element;
            }
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de produtos";
        this.snackErro = true;
      }
    },
    async carregarCadernos() {
      try {
        this.cadernos = [];
        this.formatos = [];
        this.tabelaPreco.idProduto = this.produtoSelecionado.id;
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "com/caderno/produto/" +
            this.tabelaPreco.idProduto +
            (this.tabelaPreco.idCaderno
              ? "?inativo=" + this.tabelaPreco.idCaderno
              : "")
        );
        if (response.data) {
          this.cadernos = response.data;
          this.cadernos.forEach(element => {
            if (!element.situacao) {
              element.nome = "INATIVO: " + element.nome;
            }
            if (element.id == this.tabelaPreco.idCaderno) {
              this.cadernoSelecionado = element;
            }
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os cadernos do produto";
        this.snackErro = true;
      }
    },
    async carregarFormatos() {
      try {
        this.formatos = [];
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "com/formato-caderno/caderno/" +
            this.tabelaPreco.idCaderno +
            (this.tabelaPreco.idFormatoCaderno
              ? "?inativo=" + this.tabelaPreco.idFormatoCaderno
              : "")
        );
        if (response.data) {
          this.formatos = response.data;
          this.formatos.forEach(element => {
            if (!element.formatoSituacao) {
              element.nome = "INATIVO: " + element.nome;
            }
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os formatos do caderno";
        this.snackErro = true;
      }
    },
    async carregarDeterminacoes() {
      try {
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "com/determinacao/lista" +
            (this.tabelaPreco.idDeterminacao
              ? "?inativo=" + this.tabelaPreco.idDeterminacao
              : "")
        );
        if (response.data) {
          this.determinacoes = response.data;
          this.determinacoes.forEach(element => {
            if (!element.situacao) {
              element.nome = "INATIVA: " + element.nome;
            }
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de determinações";
        this.snackErro = true;
      }
    },
    async carregarColocacoes() {
      try {
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "com/colocacao/lista" +
            (this.tabelaPreco.idColocacao
              ? "?inativo=" + this.tabelaPreco.idColocacao
              : "")
        );
        if (response.data) {
          this.colocacoes = response.data;
          this.colocacoes.forEach(element => {
            if (!element.situacao) {
              element.nome = "INATIVO: " + element.nome;
            }
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de colocações";
        this.snackErro = true;
      }
    },
    async excluir() {
      try {
        this.isUpdating = true;
        await api.delete(
          process.env.VUE_APP_URL_WEB_API +
            "com/anuncio-tabela/" +
            this.tabelaPreco.id
        );

        this.mensagem = "Tabela de Preços Excluída";
        this.snackbar = true;
        setTimeout(() => {
          this.$router.push("/comercial/anuncio-tabelas");
        }, 2000);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
        this.isUpdating = false;
      } finally {
        this.dialogConfirm = false;
      }
    },

    pLetra(text) {
      return util.pLetra(text);
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    valida_data(date) {
      if (date) {
        return util.valida_data_BR(date);
      } else {
        return true;
      }
    },
    formatDateUS(date) {
      return util.formatDateUS(date);
    }
  },

  watch: {
    dtInicioUS() {
      this.dtInicio = util.formatDate(this.dtInicioUS);
      this.mnuDtInicio = false;
    },
    cadernoSelecionado(val) {
      this.tabelaPreco.idCaderno = val.id;
      this.carregarFormatos();
    }
  },

  async created() {
    this.isUpdating = false;
    this.bloqueiaBotoes = true;
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito =
        element.modulo + "_" + element.grupo + "_" + element.direito;
      if (
        direito == "WEBSUITA_ADMIN_SUITA" ||
        direito == "COMERCIAL_TABELAS_PREÇO_CRIAR"
      ) {
        this.direitoCriar = true;
      }
      if (
        direito == "WEBSUITA_ADMIN_SUITA" ||
        direito == "COMERCIAL_TABELAS_PREÇO_EXCLUIR"
      ) {
        this.direitoExcluir = true;
      }
    });

    //* Verifica se o site do usuário está em GRUPO - OBS: ou ele compartilha a informação ou deixa exclusiva para o seu site
    var idGrupoSite = this.$store.state.usuario.site_idGrupo;
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    if (idGrupoSite != null) {
      this.siteGrupo = [
        {
          id: this.idSitePrincipal,
          nome: this.$store.state.usuario.site_nome
        }
      ];
    }

    //* Se é edição, carrega o item
    if (this.id == "") {
      await this.carregarProdutos();
      await this.carregarDeterminacoes();
      await this.carregarColocacoes();

      this.POSSUI_DIREITO([
        "WEBSUITA_ADMIN_SUITA",
        "COMERCIAL_TABELAS_PREÇO_CRIAR"
      ]);
      this.dtInicioUS = util.dateToStringUS(new Date());
    } else {
      this.POSSUI_DIREITO([
        "WEBSUITA_ADMIN_SUITA",
        "COMERCIAL_TABELAS_PREÇO_EDITAR"
      ]);
      try {
        //* Carrega o item - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API + "com/anuncio-tabela/" + this.id
        );
        if (!response.data) {
          this.$router.push("/comercial/anuncio-tabelas");
        } else {
          this.tabelaPreco = response.data;
          this.tabelaPreco.vlrSeg = this.tabelaPreco.vlrSeg.toString().replace(".", ",");
          this.tabelaPreco.vlrTer = this.tabelaPreco.vlrTer.toString().replace(".", ",");
          this.tabelaPreco.vlrQua = this.tabelaPreco.vlrQua.toString().replace(".", ",");
          this.tabelaPreco.vlrQui = this.tabelaPreco.vlrQui.toString().replace(".", ",");
          this.tabelaPreco.vlrSex = this.tabelaPreco.vlrSex.toString().replace(".", ",");
          this.tabelaPreco.vlrSab = this.tabelaPreco.vlrSab.toString().replace(".", ",");
          this.tabelaPreco.vlrDom = this.tabelaPreco.vlrDom.toString().replace(".", ",");
          setTimeout(() => {
            this.tabelaPreco.vlrSeg = parseFloat(this.tabelaPreco.vlrSeg.toString().replace(",", "."));
            this.tabelaPreco.vlrTer = parseFloat(this.tabelaPreco.vlrTer.toString().replace(",", "."));
            this.tabelaPreco.vlrQua = parseFloat(this.tabelaPreco.vlrQua.toString().replace(",", "."));
            this.tabelaPreco.vlrQui = parseFloat(this.tabelaPreco.vlrQui.toString().replace(",", "."));
            this.tabelaPreco.vlrSex = parseFloat(this.tabelaPreco.vlrSex.toString().replace(",", "."));
            this.tabelaPreco.vlrSab = parseFloat(this.tabelaPreco.vlrSab.toString().replace(",", "."));
            this.tabelaPreco.vlrDom = parseFloat(this.tabelaPreco.vlrDom.toString().replace(",", "."));
          }, 1000);
          await this.carregarProdutos();
          await this.carregarDeterminacoes();
          await this.carregarColocacoes();
          await this.carregarCadernos();
          this.dtInicioUS = util.dateToStringUS(this.tabelaPreco.dtInicio);

        }
      } catch {
        this.isUpdating = true;
        this.mensagem =
          "Ocorreu um erro ao carregar os dados da tabela de preço";
        this.snackErro = true;
      }
    }
    setTimeout(() => {
      this.bloqueiaBotoes = false;
    }, 1000);
  }
};
</script>
