var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('v-app',{attrs:{"id":"inspire"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"#333333"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("format_list_numbered")]),_c('v-toolbar-title',{staticClass:"font-weight-light",staticStyle:{"color":"white"}},[_vm._v(" Editorias ")]),_c('v-spacer'),(_vm.direitoCriar)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/editorial/editoria/null/null"}},[(_vm.direitoCriar)?_c('v-btn',{attrs:{"color":"success","fab":"","small":""}},[_c('v-icon',{attrs:{"color":"white","title":"Incluir"}},[_vm._v("add")])],1):_vm._e()],1):_vm._e()],1),_c('div',{staticClass:"px-4 mt-3"},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-spacer'),_c('v-flex',{attrs:{"xs12":"","md5":""}},[_c('v-text-field',{staticClass:"mt-2 mb-2 elevation-0",attrs:{"background-color":"white","append-icon":"search","label":"Digite para pesquisar...","placeholder":"Pesquisa pelo nome","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.limpaFiltro($event)},"click:append":_vm.limpaFiltro},model:{value:(_vm.filtroPesquisa),callback:function ($$v) {_vm.filtroPesquisa=$$v},expression:"filtroPesquisa"}})],1)],1),_c('v-data-table',{attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.registros,"options":_vm.options,"no-data-text":_vm.mensagemPesquisa,"footer-props":_vm.rodape,"disable-sort":_vm.carregando,"disable-pagination":_vm.carregando},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:_vm.getItemActions(),fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":'/editorial/editoria/' + item.idSuperior + '/' + item.id}},[_c('v-icon',{attrs:{"color":"blue","title":"Editar"}},[_vm._v("edit")])],1),(_vm.direitoExcluir && !item.filhas)?_c('v-icon',{attrs:{"color":"error","title":"Remover"},on:{"click":function($event){return _vm.excluir(item)}}},[_vm._v("delete")]):_vm._e()]}},{key:_vm.getItemNumero(),fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.editoria)}})]}},{key:_vm.getItemActions2(),fn:function(ref){
var item = ref.item;
return [(_vm.direitoCriar)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":'/editorial/editoria/' + item.id}},[_c('v-icon',{attrs:{"color":"success","title":"Incluir"}},[_vm._v("add_circle")])],1):_vm._e()]}},{key:_vm.getItemTipo(),fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(
                item.tipo == 1
                  ? 'Internet'
                  : item.tipo == 2
                  ? 'Lixeira'
                  : item.tipo == 3
                  ? 'Produção'
                  : 'Edição'
              )}})]}},{key:_vm.getItemSalvaLimpeza(),fn:function(ref){
              var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(
                item.salvaLimpeza == 1
                  ? 'Limpeza'
                  : item.salvaLimpeza == 0
                  ? '<strong>Não Limpar</strong>'
                  : ''
              )}})]}},{key:_vm.getItemSituacao(),fn:function(ref){
              var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(
                item.situacao == 1
                  ? 'Ativo'
                  : item.situacao == 0
                  ? '<strong>Inativo</strong>'
                  : ''
              )}})]}}],null,true)}),_c('div',{staticClass:"text-xs-center pt-2 marg-bt"},[_c('v-pagination',{attrs:{"length":_vm.totalPaginas,"total-visible":_vm.$vuetify.breakpoint.xsOnly ? 5 : 7,"circle":""},model:{value:(_vm.paginaAtual),callback:function ($$v) {_vm.paginaAtual=$$v},expression:"paginaAtual"}})],1)],1),_c('v-snackbar',{attrs:{"color":"info","timeout":5000,"relative":"","bottom":"","left":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.mensagem))]),_c('v-snackbar',{attrs:{"color":"error","timeout":5000,"relative":"","bottom":"","left":""},model:{value:(_vm.snackErro),callback:function ($$v) {_vm.snackErro=$$v},expression:"snackErro"}},[_vm._v(_vm._s(_vm.mensagem))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }