var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('v-app',{attrs:{"id":"inspire"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"#333333"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v(" offline_bolt ")]),_c('v-toolbar-title',{staticClass:"font-weight-light",staticStyle:{"color":"white"}},[_vm._v("Recepções Celesc")]),_c('v-spacer')],1),_c('div',{staticClass:"px-6 mt-4"},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-spacer'),_c('v-flex',{attrs:{"xs12":"","md5":""}},[_c('v-text-field',{staticClass:"mt-2 mb-2 elevation-0",attrs:{"background-color":"white","append-icon":"search","label":"Digite para pesquisar...","placeholder":"Pesquisa pela competência(MM/AAAA) ou sigla do site","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.limpaFiltro($event)},"click:append":_vm.limpaFiltro},model:{value:(_vm.filtroPesquisa),callback:function ($$v) {_vm.filtroPesquisa=$$v},expression:"filtroPesquisa"}})],1)],1),_c('v-data-table',{attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.registros,"options":_vm.options,"no-data-text":_vm.mensagemPesquisa,"footer-props":_vm.rodape},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:_vm.getItemActions(),fn:function(ref){
var item = ref.item;
return [(_vm.direitoTitulos && item.status != 'EXCLUÍDO')?_c('v-icon',{attrs:{"color":"blue","title":"Visualizar Títulos"},on:{"click":function($event){return _vm.visualizarTitulos(item)}}},[_vm._v("add_circle_outline")]):_vm._e(),(item.status == 'CORRENTE')?_c('v-icon',{attrs:{"color":"red lighten-3","title":"Excluir Lote"},on:{"click":function($event){return _vm.excluiLote(item)}}},[_vm._v("cancel")]):_vm._e()]}},{key:"item.sigla",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.sigla.toUpperCase()))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.status == 'EXCLUÍDO' ? 'red--text' : 'blue--text'},[_vm._v(_vm._s(item.status))])]}},{key:"item.dtCriacao",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.dtCriacao)))])]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatCurrency(item.valor)))])]}},{key:"item.qtde",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNumber(item.qtde)))])]}},{key:"item.dtExclusao",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateTime(item.dtExclusao)))])]}}],null,true)}),_c('div',{staticClass:"text-xs-center pt-2 marg-bt"},[_c('v-pagination',{attrs:{"length":_vm.totalPaginas,"total-visible":_vm.$vuetify.breakpoint.xsOnly ? 5 : 11,"circle":""},model:{value:(_vm.paginaAtual),callback:function ($$v) {_vm.paginaAtual=$$v},expression:"paginaAtual"}})],1)],1),_c('v-snackbar',{attrs:{"color":"error","timeout":5000,"relative":"","bottom":"","left":""},model:{value:(_vm.snackErro),callback:function ($$v) {_vm.snackErro=$$v},expression:"snackErro"}},[_vm._v(_vm._s(_vm.mensagem))])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.dialogConfirm),callback:function ($$v) {_vm.dialogConfirm=$$v},expression:"dialogConfirm"}},[_c('v-card',[_c('v-toolbar',[_c('span',{staticClass:"headline"},[_vm._v("Confirmação")])]),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.msgConfirm)}}),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.naoConfirmado}},[_vm._v("Não")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.confirmado}},[_vm._v("Sim")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }