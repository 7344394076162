var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"hide-overflow fill-height",attrs:{"color":"white lighten-1","lighten":""}},[_c('v-toolbar',{attrs:{"color":"#333333"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("open_with")]),_c('v-toolbar-title',{staticClass:"font-weight-light",staticStyle:{"color":"white"}},[_vm._v("Faturamento de Venda Avulsa")]),_c('v-spacer')],1),_c('v-form',{ref:"formElemento",attrs:{"lazy-validation":""},model:{value:(_vm.elementoValid),callback:function ($$v) {_vm.elementoValid=$$v},expression:"elementoValid"}},[_c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-progress-linear',{staticClass:"ma-0",attrs:{"active":_vm.isUpdating,"color":"cyan accent-3","height":"3","indeterminate":""}})],1),_c('v-flex',{attrs:{"xs12":"","mb-4":""}},[_c('h4',{staticClass:"overline font-weight-black font-italic"},[_vm._v(" Filtro ")]),_c('v-divider')],1),_c('v-flex',{attrs:{"xs11":"","md6":""}},[_c('v-autocomplete',{attrs:{"autofocus":"","items":_vm.bancas,"filter":_vm.filtrarObjetos,"label":"Banca","item-text":"nome","item-value":"id","hint":"Obrigatório","rules":[_vm.validacao.idCliente == false || _vm.validacao.idCliente],"no-data-text":"","dense":""},on:{"change":function($event){_vm.limpaFiltro(true);
              _vm.validacao.idCliente = false;}},model:{value:(_vm.operacao.idCliente),callback:function ($$v) {_vm.$set(_vm.operacao, "idCliente", $$v)},expression:"operacao.idCliente"}})],1),_c('v-flex',{staticClass:"text-left",attrs:{"xs1":""}},[_c('v-spacer'),(_vm.direitoBanca && _vm.operacao.idCliente != null)?_c('v-icon',{attrs:{"color":"orange","title":"Editar Banca"},on:{"click":_vm.editarBanca}},[_vm._v("account_circle")]):_vm._e()],1),_c('v-flex',{attrs:{"xs12":"","md5":""}},[_c('v-autocomplete',{attrs:{"items":_vm.produtos,"filter":_vm.filtrarObjetos,"label":"Produto","item-text":"nome","item-value":"id","hint":"Obrigatório","rules":[_vm.validacao.idProduto == false || _vm.validacao.idProduto, function (value) { return !!value || 'Obrigatório.'; }],"no-data-text":"","dense":""},on:{"change":function($event){_vm.limpaFiltro(true);
              _vm.validacao.idProduto = false;}},model:{value:(_vm.idProduto),callback:function ($$v) {_vm.idProduto=$$v},expression:"idProduto"}})],1),_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('v-autocomplete',{attrs:{"disabled":_vm.isUpdating,"items":_vm.entregadores,"filter":_vm.filtrarObjetos,"hint":"Obrigatório","rules":[_vm.validacao.idEntregador == false || _vm.validacao.idEntregador],"label":"Entregador","item-text":"nome","item-value":"id","autocomplete":"disabled","name":Math.random(),"no-data-text":""},on:{"change":function($event){_vm.limpaFiltro(true);
              _vm.validacao.idEntregador = false;}},model:{value:(_vm.idEntregador),callback:function ($$v) {_vm.idEntregador=$$v},expression:"idEntregador"}})],1),_c('v-flex',{attrs:{"xs12":"","offset-md1":"","md5":""}},[_c('v-autocomplete',{attrs:{"disabled":_vm.isUpdating,"items":_vm.tiposBanca,"filter":_vm.filtrarObjetos,"hint":"Obrigatório","rules":[_vm.validacao.idTipoBanca == false || _vm.validacao.idTipoBanca],"label":"Tipo de Banca","item-text":"nome","item-value":"id","autocomplete":"disabled","name":Math.random(),"no-data-text":""},on:{"change":function($event){_vm.limpaFiltro(true);
              _vm.validacao.idTipoBanca = false;}},model:{value:(_vm.idTipoBanca),callback:function ($$v) {_vm.idTipoBanca=$$v},expression:"idTipoBanca"}})],1),_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('v-text-field',{attrs:{"label":"Descrição da Operação","maxlength":"180","hint":"Obrigatório","rules":[
              _vm.validacao.descricao == false || _vm.validacao.descricao,
              function (value) { return _vm.valida_nome(value) || 'Descrição inválida'; },
              function (value) { return value.length > 2 || 'Preencha a descrição da operação'; }
            ],"color":"blue-grey lighten-2","autocomplete":"disabled","name":Math.random()},on:{"change":function($event){_vm.operacao.descricao = _vm.pLetra(_vm.operacao.descricao);
              _vm.validacao.descricao = false;}},model:{value:(_vm.operacao.descricao),callback:function ($$v) {_vm.$set(_vm.operacao, "descricao", $$v)},expression:"operacao.descricao"}})],1),_c('v-flex',{attrs:{"xs12":"","offset-md1":"","md3":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Período do Reparte","prepend-icon":"event","color":"blue-grey lighten-2","clearable":"","readonly":""},on:{"click:clear":function($event){_vm.intervaloReparteUS = ['', ''];
                  _vm.limpaFiltro(false);}},model:{value:(_vm.intervaloReparte),callback:function ($$v) {_vm.intervaloReparte=$$v},expression:"intervaloReparte"}},on))]}}]),model:{value:(_vm.mnuReparte),callback:function ($$v) {_vm.mnuReparte=$$v},expression:"mnuReparte"}},[_c('v-date-picker',{attrs:{"locale":"pt-br","no-title":"","range":""},model:{value:(_vm.intervaloReparteUS),callback:function ($$v) {_vm.intervaloReparteUS=$$v},expression:"intervaloReparteUS"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.mnuReparte = false;
                  _vm.limpaFiltro(false);}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-flex',{attrs:{"xs6":"","md1":""}},[_c('v-autocomplete',{attrs:{"items":_vm.simnao,"filter":_vm.filtrarObjetos,"label":"Encalhe","item-text":"nome","item-value":"id","no-data-text":"","clearable":""},on:{"change":function($event){return _vm.limpaFiltro(false)}},model:{value:(_vm.encalhe),callback:function ($$v) {_vm.encalhe=$$v},expression:"encalhe"}})],1),_c('v-flex',{attrs:{"xs6":"","md1":"","text-right":""}},[_c('v-btn',{staticClass:"btn-rsn mt-4",staticStyle:{"color":"white"},attrs:{"rounded":"","outlined":"","loading":_vm.isUpdating,"color":"blue white","depressed":""}},[_c('v-icon',{staticClass:"icon-rsn",attrs:{"title":"Filtrar"},on:{"click":function($event){return _vm.limpaFiltro(false)}}},[_vm._v("search")])],1)],1),_c('v-flex',{attrs:{"xs8":"","md3":"","ml-4":""}},[_c('v-checkbox',{attrs:{"label":_vm.selectAll ? 'Desmarcar todos' : 'Marcar todos da página'},on:{"change":_vm.marcarDesmarcarTodos},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.registros,"options":_vm.options,"no-data-text":_vm.mensagemPesquisa,"footer-props":_vm.rodape,"disable-sort":_vm.carregando,"disable-pagination":_vm.carregando},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [(item.id && !item.idParcela)?_c('v-icon',{attrs:{"color":"blue","title":"Selecionar"},on:{"click":function($event){return _vm.ativarDesativarItem(item)}}},[_vm._v(" "+_vm._s(item.selecionado == 1 ? "check_box" : "check_box_outline_blank")+" ")]):_vm._e()]}},{key:"item.dtEntrega",fn:function(ref){
                  var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatDate(item.dtEntrega))}})]}},{key:"item.soma",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s((item.reparte ? parseInt(item.reparte) : 0) + (item.adicional ? parseInt(item.adicional) : 0))+" ")]}},{key:"item.saldo",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"bold"},domProps:{"innerHTML":_vm._s((item.reparte ? parseInt(item.reparte) : 0) + (item.adicional ? parseInt(item.adicional) : 0) - (item.encalhe ? parseInt(item.encalhe) : 0))}})]}},{key:"item.vlrReparte",fn:function(ref){
                  var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatCurrency(item.reparte * item.vlrUnitario))}})]}},{key:"item.vlrAdicional",fn:function(ref){
                  var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatCurrency(item.adicional * item.vlrUnitario))}})]}},{key:"item.vlrSoma",fn:function(ref){
                  var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatCurrency((item.reparte + item.adicional) * item.vlrUnitario))}})]}},{key:"item.vlrEncalhe",fn:function(ref){
                  var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatCurrency(item.encalhe * item.vlrUnitario))}})]}},{key:"item.vlrSaldo",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"bold"},domProps:{"innerHTML":_vm._s(_vm.formatCurrency((item.reparte + item.adicional - item.encalhe) * item.vlrUnitario))}})]}}])}),_c('div',{staticClass:"text-xs-center pt-2 marg-bt"},[_c('v-pagination',{attrs:{"length":_vm.totalPaginas,"total-visible":_vm.$vuetify.breakpoint.xsOnly ? 5 : 7,"circle":""},model:{value:(_vm.paginaAtual),callback:function ($$v) {_vm.paginaAtual=$$v},expression:"paginaAtual"}})],1)],1),(_vm.validacao.mensagemErro)?_c('v-flex',{attrs:{"xs12":""}},[_c('v-sheet',{staticClass:"red--text",attrs:{"value":true}},[_vm._v(" "+_vm._s(_vm.validacao.mensagemErro)+" ")])],1):_vm._e()],1)],1)],1),_c('v-divider'),_c('v-card-actions',{attrs:{"id":"flex-wrap"}},[_c('v-btn',{staticStyle:{"color":"white"},attrs:{"to":"/assinaturas/venda-avulsa-repartes","color":"orange lighten-2 white","depressed":""}},[_c('v-icon',{attrs:{"id":"icon-rsn","left":""}},[_vm._v("keyboard_arrow_left")]),_vm._v("Retornar ")],1),_c('v-btn',{staticClass:"btn-rsn",staticStyle:{"color":"white"},attrs:{"loading":_vm.isUpdating,"color":"blue white","depressed":""},on:{"click":_vm.faturamento}},[_c('v-icon',{staticClass:"icon-rsn",attrs:{"left":""}},[_vm._v("account_balance")]),_vm._v("Faturamento ")],1)],1),_c('v-snackbar',{attrs:{"color":"info","timeout":5000,"relative":"","bottom":"","left":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.mensagem))]),_c('v-snackbar',{attrs:{"color":"error","timeout":5000,"relative":"","bottom":"","left":""},model:{value:(_vm.snackErro),callback:function ($$v) {_vm.snackErro=$$v},expression:"snackErro"}},[_vm._v(_vm._s(_vm.mensagem))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }