<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">mdi-account</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Operação {{ receber ? "a Receber" : "a Pagar" }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 :class="id == '' ? 'md6' : 'md12'">
            <v-autocomplete
              autofocus
              v-model="contaCSelecionada"
              :disabled="id != ''"
              :items="receitas"
              :filter="filtrarObjetos"
              :label="receber ? 'Receita' : 'Destino'"
              item-text="nome"
              item-value="id"
              hint="Obrigatório"
              :rules="[validacao.idContaC == false || validacao.idContaC, value => (!!value && !!value.id) || 'Obrigatório.']"
              autocomplete="disabled"
              :name="Math.random()"
              clearable
              no-data-text
              return-object
              @change="validacao.idContaC = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12 :class="id == '' ? 'md6' : 'md12'">
            <v-autocomplete
              v-model="contaDSelecionada"
              :disabled="id != '' || receber"
              :items="despesas"
              :filter="filtrarObjetos"
              label="Origem"
              item-text="nome"
              item-value="id"
              hint="Obrigatório"
              :rules="[validacao.idContaD == false || validacao.idContaD, value => (!!value && !!value.id) || 'Obrigatório.']"
              autocomplete="disabled"
              :name="Math.random()"
              clearable
              no-data-text
              return-object
              @change="validacao.idContaD = false"
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.nomeS }}</v-list-item-title>
                  <v-list-item-subtitle>{{ data.item.mae }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-flex>

          <v-flex xs11 v-if="!semFornecedor">
            <v-autocomplete
              v-model="clienteSelecionado"
              :items="clientes"
              hint="Obrigatório"
              :rules="[validacao.idCliente == false || validacao.idCliente, value => (!!value && !!value.id) || 'Obrigatório.']"
              :filter="filtrarObjetos"
              :label="receber ? 'Cliente' : 'Fornecedor'"
              item-text="nome"
              item-value="id"
              :disabled="id != ''"
              no-data-text
              placeholder="Pesquisa pelos primeiros 50 registros que coincidirem com o nome digitado"
              :loading="loadingClientes"
              :search-input.sync="search"
              @keyup.enter="buscarCliente"
              return-object
              @change="validacao.idCliente = false"
            >
              <template v-slot:append v-if="operacao.idCliente == null">
                <v-btn color="blue" fab small>
                  <v-icon color="white" @click="buscarCliente" title="Busca pelo nome ou identificação">search</v-icon>
                </v-btn>
              </template>
              <template v-slot:append-outer v-if="direitoFornecedor">
                <router-link to="/financeiro/fornecedor" style="text-decoration: none" target="_blank">
                  <v-btn color="blue" fab small :disabled="id != ''">
                    <v-icon color="white" title="Adicionar Fornecedor" style="position:relative;">add</v-icon>
                  </v-btn>
                </router-link>
              </template>
            </v-autocomplete>
          </v-flex>
          <v-flex xs1 v-if="!semFornecedor && operacao.idCliente != null">
            <v-spacer></v-spacer>
            <v-icon
              v-if="direitoCliente || direitoAssinante || direitoAnunciante || direitoBanca || direitoFornecedor"
              color="orange"
              @click="visualizarCliente"
              :title="
                'Editar ' +
                  (operacao.origem == 'ASS'
                    ? 'Assinante'
                    : operacao.origem == 'ANU' || operacao.origem == 'PAC'
                    ? 'Anunciante'
                    : operacao.origem == 'AVU'
                    ? 'Banca'
                    : receber
                    ? 'Cliente'
                    : 'Fornecedor')
              "
              >account_circle</v-icon
            >
          </v-flex>

          <v-flex xs12 md12 v-if="!semFornecedor && departamentosCliente.length > 0">
            <v-autocomplete
              v-model="operacao.idDeptoCliente"
              :items="departamentosCliente"
              :filter="filtrarObjetos"
              label="Departamento do Cliente"
              item-text="departamento"
              item-value="id"
              :rules="[validacao.idDeptoCliente == false || validacao.idDeptoCliente]"
              :disabled="id != ''"
              no-data-text
              @change="validacao.idDeptoCliente = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex xs12>
            <v-text-field
              v-model="operacao.descricao"
              ref="focusDescricao"
              label="Descrição da Operação"
              maxlength="180"
              hint="Obrigatório"
              :rules="[
                validacao.descricao == false || validacao.descricao,
                value => !!value || 'Obrigatório.',
                value => valida_nome(value) || 'Descrição inválida',
                value => value.length > 2 || 'Preencha a descrição da operação'
              ]"
              :disabled="!nfNaoEmitida"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                operacao.descricao = pLetra(operacao.descricao);
                validacao.descricao = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-menu ref="mnuDtOperacao" v-model="mnuDtOperacao" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dtOperacao"
                  label="Data da Operação"
                  v-mask="'##/##/####'"
                  return-v-masked-value
                  hint="Obrigatório"
                  prepend-icon="event"
                  v-on="on"
                  :rules="[
                    validacao.dtOperacao == false || validacao.dtOperacao,
                    value => valida_data(value) || 'Data inválida',
                    value => !!value || 'Obrigatório.',
                    value => (value != null && value.length == 10) || 'Preencha a data da operação'
                  ]"
                  :disabled="id != '' || !direitoCancelar"
                  color="blue-grey lighten-2"
                  autocomplete="disabled"
                  :name="Math.random()"
                  @keyup="dtOperacaoUS = valida_data(dtOperacao) ? formatDateUS(dtOperacao) : null"
                  @change="validacao.dtOperacao = false"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dtOperacaoUS" locale="pt-br" no-title @input="mnuDtOperacao = false"></v-date-picker>
            </v-menu>
          </v-flex>

          <v-flex md3 xs6>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="operacao.vlrBruto"
              label="Valor Bruto"
              :allowNegative="false"
              :max="9999999"
              hint="Obrigatório"
              :rules="[
                validacao.vlrBruto == false || validacao.vlrBruto,
                value => !!value || 'Obrigatório.',
                value => (value && parseFloat(value.replace(',', '.')) > 0 && value.length < 13) || 'Verifique o Valor Bruto'
              ]"
              :disabled="!nfNaoEmitida || (id != '' && operacao.confirmada == 1)"
              autocomplete="disabled"
              :name="Math.random()"
              color="blue-grey lighten-2"
              @change="
                operacao.vlrLiquido = operacao.vlrLiquido ? operacao.vlrLiquido : operacao.vlrBruto;
                validacao.vlrBruto = false;
              "
            ></v-currency-field>
          </v-flex>

          <v-flex md3 xs6>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="operacao.vlrLiquido"
              label="Valor Líquido"
              :allowNegative="false"
              :max="9999999"
              hint="Obrigatório"
              :rules="[validacao.vlrLiquido == false || validacao.vlrLiquido, value => (value && value.length < 13) || 'Verifique o valor líquido']"
              :disabled="parcelas.length != 0"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.vlrLiquido = false"
            ></v-currency-field>
          </v-flex>

          <v-flex md3 xs6>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-if="vlrPermutado"
              v-model="vlrPermutado"
              label="Valor Permutado"
              :allowNegative="false"
              disabled
              color="blue-grey lighten-2"
            ></v-currency-field>
          </v-flex>

          <v-flex md3 xs6>
            <v-text-field
              v-model="operacao.nroParcelas"
              label="Parcelas"
              v-mask="'###'"
              hint="Obrigatório"
              :rules="[
                validacao.nroParcelas == false || validacao.nroParcelas,
                value => !!value || 'Obrigatório.',
                value => parseInt(value) != 0 || 'Verifique o número de parcelas'
              ]"
              :disabled="parcelas.length != 0"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.nroParcelas = false"
            ></v-text-field>
          </v-flex>

          <v-flex md3 xs6 v-if="parcelas.length == 0">
            <v-menu ref="mnuDtVecto" v-model="mnuDtVecto" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dtVecto"
                  label="Vencimento 1ª Parcela"
                  v-mask="'##/##/####'"
                  return-v-masked-value
                  hint="Obrigatório"
                  prepend-icon="event"
                  v-on="on"
                  :rules="[
                    validacao.dtVecto == false || validacao.dtVecto,
                    value => valida_data_vecto(value) || 'Data de vencimento não pode ser inferior a da operação',
                    value => !!value || 'Obrigatório.',
                    value => (value != null && value.length == 10) || 'Preencha a data de vencimento da primeira parcela'
                  ]"
                  color="blue-grey lighten-2"
                  @change="
                    dtVectoUS = valida_data(dtVecto) ? formatDateUS(dtVecto) : null;
                    validacao.dtVecto = false;
                  "
                ></v-text-field>
              </template>
              <v-date-picker v-model="dtVectoUS" locale="pt-br" no-title @input="mnuDtVecto = false"></v-date-picker>
            </v-menu>
          </v-flex>

          <v-flex md3 xs6 v-if="receber">
            <v-autocomplete
              v-model="operacao.frete"
              :items="fretes"
              :filter="filtrarObjetos"
              label="Frete"
              item-text="nome"
              item-value="id"
              :rules="[validacao.frete == false || validacao.frete]"
              :disabled="!nfNaoEmitida"
              autocomplete="disabled"
              :name="Math.random()"
              no-data-text
              @change="validacao.frete = false"
            ></v-autocomplete>
          </v-flex>

          <v-flex md3 xs12>
            <v-text-field v-model="operacao.usuario" label="Usuário" disabled color="blue-grey lighten-2"></v-text-field>
          </v-flex>
          <v-flex xs12 v-if="!receber"> </v-flex>

          <v-flex xs12 v-if="receber">
            <v-switch v-model="operacao.duplicatas" :label="`${operacao.duplicatas == 1 ? 'Duplicatas na nota' : 'Sem duplicatas na nota'}`" :disabled="!nfNaoEmitida"></v-switch>
          </v-flex>

          <v-flex xs6 md3 v-if="!receber">
            <v-text-field v-model="nroNF" label="Nº NF" v-mask="'########'" color="blue-grey lighten-2" autocomplete="disabled" :name="Math.random()"></v-text-field>
          </v-flex>
          <v-flex xs6 md3 v-if="!receber">
            <v-text-field
              v-model="dtEmissaoNF"
              label="Emissão NF"
              v-mask="'##/##/####'"
              :rules="[value => valida_data(value) || 'Data inválida']"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 md3 v-if="!receber">
            <v-switch
              v-model="operacao.mensal"
              :label="`${operacao.mensal == 1 ? (id == '' ? 'Mensal - um lançamento por parcela' : 'Mensal') : id == '' ? 'Individual - lançamento único' : 'Individual'}`"
              :disabled="id != ''"
            ></v-switch>
          </v-flex>
          <v-flex xs12 md3 v-if="!receber">
            <v-switch
              v-model="operacao.confirmada"
              :label="`${operacao.confirmada == 1 ? 'Pagamento' : 'Orçamento'}`"
              :disabled="isUpdating || (id != '' && operacao.confirmada == 1 && emCobranca)"
              @change="confirmar"
            ></v-switch>
          </v-flex>

          <v-flex xs12>
            <v-alert v-if="operacao.situacao" :value="true" color="success" icon="check_circle" outlined>Operação ATIVA</v-alert>
            <v-alert
              v-if="!operacao.situacao"
              :value="true"
              color="error"
              icon="priority_high"
              outlined
              v-html="
                'Esta operação foi cancelada em ' +
                  formatDateTime(operacao.dtCancelamento) +
                  (operacao.usuarioCancelamento ? ', por ' + operacao.usuarioCancelamento : '') +
                  (operacao.motivoCancelamento ? '<br />Justificativa: ' + operacao.motivoCancelamento : '')
              "
            >
            </v-alert>
          </v-flex>
          <v-flex xs12>
            <v-alert v-if="!nfNaoEmitida" :value="true" color="blue" icon="check_circle" outlined>Nota Fiscal Emitida</v-alert>
          </v-flex>
        </v-layout>

        <v-layout wrap>
          <v-expansion-panels v-model="painel">
            <v-expansion-panel v-if="receber && operacao.origem != 'AVU'" dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.agentes ? 'red--text' : ''">
                <strong>Agentes/Agências</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogAgente" max-width="700px">
                        <template v-slot:activator="{ on }">
                          <v-btn :disabled="!nfNaoEmitida" color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formAgente" v-model="agenteValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Agente/Agência</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeAgente">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs10>
                                    <v-autocomplete
                                      autofocus
                                      v-model="agenteSelecionado"
                                      :items="agentesOrigem"
                                      :rules="[validacao.agente == false || validacao.agente, value => (!!value && !!value.id) || 'Obrigatório.']"
                                      hint="Obrigatório"
                                      :filter="filtrarObjetosIdentificacao"
                                      label="Selecione o Agente/Agência"
                                      item-text="agente"
                                      item-value="idAgente"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                      no-data-text
                                      return-object
                                      @change="validacao.agente = false"
                                    ></v-autocomplete>
                                  </v-flex>
                                  <v-flex xs2>
                                    <v-currency-field
                                      :locale="`pt-${$store.state.usuario.site_pais}`"
                                      v-model="agenteItem.comissao"
                                      label="Comissão"
                                      :allowNegative="false"
                                      :max="100"
                                      suffix="%"
                                      hint="Obrigatório"
                                      :rules="[
                                        validacao.comissao == false || validacao.comissao,
                                        value => !value || parseFloat(value.replace('.', '').replace(',', '.')) <= 99.99 || 'Verifique a comissão'
                                      ]"
                                      color="blue-grey lighten-2"
                                      @change="validacao.comissao = false"
                                    ></v-currency-field>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeAgente">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveAgente">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerAgentes"
                      :items="agentes"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50],
                        pageText: ''
                      }"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon v-if="nfNaoEmitida" class="mr-2" color="red" title="Remover Agente/Agência" @click="removerAgente(item)">delete</v-icon>
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.agentes" :value="true" color="error" icon="priority_high" outlined>{{ validacao.agentes }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="permutas.length > 0" dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.permutas ? 'red--text' : ''">
                <strong>Permutas</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap>
                  <v-flex xs8 md3 ml-4>
                    <v-checkbox
                      v-if="id == ''"
                      v-model="selectAll"
                      :label="selectAll ? 'Desmarcar todos' : 'Marcar todos da página'"
                      @change="marcarDesmarcarTodos(selectAll)"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-data-table mobile-breakpoint="0" :headers="headerPermutas" :items="permutas" no-data-text no-results-text :footer-props="rodapePermutas">
                      <template v-slot:item.actions="{ item }">
                        <v-icon v-if="item.id && id == ''" color="orange" title="Selecionar" @click="ativarDesativarItem(item)">
                          {{ item.selecionado == 1 ? "check_box" : "check_box_outline_blank" }}
                        </v-icon>
                        <v-icon v-if="direitoEditarOpPermuta && item.id && id != ''" color="blue" title="Operação" @click="visualizarOperacaoPermuta(item)">{{
                          item.tipo == 1 ? "add_circle" : "remove_circle"
                        }}</v-icon>
                      </template>
                      <template v-slot:item.dtVecto="{ item }">
                        <span>{{ formatDate(item.dtVecto) }}</span>
                      </template>
                      <template v-slot:item.valor="{ item }">
                        <span>{{ formatCurrency(item.valor) }}</span>
                      </template>
                    </v-data-table>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="operacao.vlrLiquido - vlrPermutado > 0 || (id != '' && operacao.confirmada == 1)" dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.parcelas ? 'red--text' : ''">
                <strong>Parcelas</strong>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-layout wrap>
                  <v-flex xs12 md6 v-if="formaPagamentoSelecionar || parcelas.length == 0">
                    <v-autocomplete
                      v-model="formaPagamentoSelecionada"
                      autofocus
                      :items="formasPgto"
                      :filter="filtrarObjetos"
                      label="Selecione a Forma de Pagamento"
                      hint="Obrigatório"
                      :rules="[value => (!!value && !!value.id) || 'Obrigatório.']"
                      item-text="nome"
                      item-value="id"
                      autocomplete="disabled"
                      :name="Math.random()"
                      no-data-text
                      return-object
                      clearable
                      @change="recriaParcelas"
                      @click:clear="recriaParcelas"
                      :disabled="isUpdating"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12 md6 v-if="parcelas.length == 0">
                    <v-btn color="success" :loading="isUpdating" style="color:white" depressed @click="gerarParcelas"> <v-icon left>library_add</v-icon>Gerar Parcelas </v-btn>
                  </v-flex>
                  <v-flex xs12>
                    <v-alert v-if="validacao.parcelas" :value="true" color="error" icon="priority_high" outlined>{{ validacao.parcelas }}</v-alert>
                  </v-flex>

                  <v-flex xs12 md12 v-if="parcelas.length > 0">
                    <v-card>
                      <v-spacer></v-spacer>

                      <v-dialog persistent v-model="dialogAjuste" max-width="600px">
                        <v-card>
                          <v-form ref="formAjuste" v-model="ajusteValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Ajustar Parcela</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeAjuste">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs6>
                                    <v-currency-field
                                      :locale="`pt-${$store.state.usuario.site_pais}`"
                                      v-model="ajusteItem.valor"
                                      label="Novo Valor"
                                      :allowNegative="false"
                                      :max="9999999"
                                      :rules="[validacao.ajuste_valor == false || validacao.ajuste_valor, value => (value && value.length < 13) || 'Verifique o valor da parcela']"
                                      color="blue-grey lighten-2"
                                      autocomplete="disabled"
                                      :name="Math.random()"
                                    ></v-currency-field>
                                  </v-flex>
                                  <v-flex xs6>
                                    <v-menu
                                      ref="mnuAjusteItemDtVectoO"
                                      v-model="mnuAjusteItemDtVectoO"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      max-width="290px"
                                      min-width="290px"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                          v-model="ajusteItemDtVectoO"
                                          label="Novo Vencimento"
                                          v-mask="'##/##/####'"
                                          prepend-icon="event"
                                          v-on="on"
                                          return-v-masked-value
                                          hint="Obrigatório"
                                          :rules="[
                                            validacao.ajuste_vecto == false || validacao.ajuste_vecto,
                                            value => valida_data_vecto(value) || 'Data de vencimento não pode ser inferior a da operação',
                                            value => !!value || 'Obrigatório.',
                                            value => (value != null && value.length == 10) || 'Preencha a data de vencimento da parcela'
                                          ]"
                                          color="blue-grey lighten-2"
                                          @keyup="ajusteItemDtVectoOUS = valida_data_vecto(ajusteItemDtVectoO) ? formatDateUS(ajusteItemDtVectoO) : null"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker v-model="ajusteItemDtVectoOUS" locale="pt-br" no-title @input="mnuAjusteItemDtVectoO = false"></v-date-picker>
                                    </v-menu>
                                  </v-flex>
                                  <v-flex xs6>
                                    <v-switch v-model="ajusteItem.liquido" :label="`${ajusteItem.liquido == 1 ? 'Manter Valor Líquido' : 'Recalcular Valor Líquido'}`"></v-switch>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeAjuste">Retornar</v-btn>
                              <v-btn color="blue darken-1" @click="saveAjuste">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>

                      <v-dialog persistent v-model="dialogParcela" max-width="600px">
                        <v-card>
                          <Parcela :id="idParcela" :receber="receber + ''" :dialogParcela.sync="dialogParcela" />
                        </v-card>
                      </v-dialog>
                      <v-data-table
                        mobile-breakpoint="0"
                        :headers="headerParcelas"
                        :items="parcelas"
                        no-data-text
                        no-results-text
                        :footer-props="{
                          itemsPerPageText: 'Registros por página',
                          itemsPerPageOptions: [12, 25, 50],
                          pageText: ''
                        }"
                      >
                        <template v-slot:item.actions="{ item }">
                          <v-icon
                            v-if="!isUpdating && item.id && direitoParcelas && (receber || !operacao.confirmada || direitoEditarConfirmada)"
                            class="mr-2"
                            color="blue lighten-2"
                            title="Editar"
                            @click="editarParcela(item)"
                            >edit</v-icon
                          >
                          <v-icon v-if="!item.id && parcelas.length > 1" class="mr-2" color="blue lighten-2" title="Editar" @click="editarAjuste(item)">edit</v-icon>
                        </template>

                        <template v-slot:item.dtVectoOriginal="{ item }">
                          {{ formatDate(item.dtVectoOriginal) }}
                        </template>

                        <template v-slot:item.dtPromessa="{ item }">
                          {{ formatDate(item.dtPromessa) }}
                        </template>

                        <template v-slot:item.valor="{ item }">
                          {{ formatCurrency(item.valor + item.descAcr) }}
                        </template>
                      </v-data-table>

                      <v-alert v-if="validacao.parcelas" :value="true" color="error" icon="priority_high" outlined>{{ validacao.parcelas }}</v-alert>
                    </v-card>
                  </v-flex>

                  <v-flex xs12 md3 v-if="parcelas.length > 0 && parcelas[0].id && direitoParcelas">
                    <v-icon color="blue lighten-2" title="Abrir no Financeiro" @click="visualizarParcelas">add_circle_outline</v-icon>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header :class="validacao.centrosCusto ? 'red--text' : ''">
                <strong>Centros de Custo</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-toolbar>
                      <v-spacer></v-spacer>
                      <v-dialog persistent v-model="dialogCentroCusto" max-width="600px">
                        <template v-slot:activator="{ on }">
                          <v-btn :disabled="!editarCC" color="success" fab small v-on="on">
                            <v-icon color="white" title="Incluir">add</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="formCentroCusto" v-model="centroCustoValid" lazy-validation>
                            <v-toolbar>
                              <span class="headline">Centro de Custo</span>
                              <v-spacer></v-spacer>
                              <v-btn icon @click="closeCentroCusto">
                                <v-icon color="darken-1">cancel</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container grid-list-md>
                                <v-layout wrap>
                                  <v-flex xs12 md6>
                                    <v-autocomplete
                                      autofocus
                                      v-model="centroCustoSelecionado"
                                      :items="centrosCusto"
                                      :rules="[value => (!!value && !!value.id) || 'Obrigatório.']"
                                      :filter="filtrarObjetos"
                                      label="Centro de Custo"
                                      item-text="nome"
                                      item-value="id"
                                      no-data-text
                                      return-object
                                    ></v-autocomplete>
                                  </v-flex>

                                  <v-flex xs12 md6>
                                    <v-currency-field
                                      :locale="`pt-${$store.state.usuario.site_pais}`"
                                      v-model="centroCustoItem.percentual"
                                      label="Percentual"
                                      :allowNegative="false"
                                      :max="100"
                                      :rules="[value => !!value || 'Obrigatório.', value => (value && parseFloat(value) > 0 && parseFloat(value) <= 100) || 'Percentual inválido']"
                                      color="blue-grey lighten-2"
                                    ></v-currency-field>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" @click="closeCentroCusto">Retornar</v-btn>
                              <v-btn v-if="centroCustoItem.id == null" color="blue darken-1" @click="saveCentroCusto">Atualizar</v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerCentrosCusto"
                      :items="centrosCustoOperacao"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50],
                        pageText: ''
                      }"
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon v-if="editarCC" class="mr-2" color="blue lighten-2" title="Editar" @click="editarCentroCusto(item)">edit</v-icon>
                        <v-icon v-if="editarCC" class="mr-2" color="red" title="Remover Centro de Custo" @click="removerCentroCusto(item)">delete</v-icon>
                      </template>
                      <template v-slot:item.percentual="{ item }">
                        {{ formatCurrency4(item.percentual) }}
                      </template>
                    </v-data-table>
                    <v-alert v-if="validacao.centrosCusto" :value="true" color="error" icon="priority_high" outlined>{{ validacao.centrosCusto }}</v-alert>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="operacao.origem == 'AVU'" dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Repartes Venda Avulsa</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-flex xs12 md12>
                  <v-card>
                    <v-data-table
                      mobile-breakpoint="0"
                      :headers="headerItensVA"
                      :items="itensVA"
                      no-data-text
                      no-results-text
                      :footer-props="{
                        itemsPerPageText: 'Registros por página',
                        itemsPerPageOptions: [10, 25, 50],
                        pageText: ''
                      }"
                    >
                      <template v-slot:item.dtEntrega="{ item }">
                        {{ formatDate(item.dtEntrega) }}
                      </template>
                      <template v-slot:item.vlrUnitario="{ item }">
                        {{ formatCurrency(item.vlrUnitario) }}
                      </template>
                      <template v-slot:item.soma="{ item }">
                        {{ item.reparte + item.adicional }}
                      </template>
                      <template v-slot:item.saldo="{ item }">
                        {{ item.reparte + item.adicional - item.encalhe }}
                      </template>
                      <template v-slot:item.vlrReparte="{ item }">
                        {{ formatCurrency(item.reparte * item.vlrUnitario) }}
                      </template>
                      <template v-slot:item.vlrAdicional="{ item }">
                        {{ formatCurrency(item.adicional * item.vlrUnitario) }}
                      </template>
                      <template v-slot:item.vlrSoma="{ item }">
                        {{ formatCurrency((item.reparte + item.adicional) * item.vlrUnitario) }}
                      </template>
                      <template v-slot:item.vlrEncalhe="{ item }">
                        {{ formatCurrency(item.encalhe * item.vlrUnitario) }}
                      </template>
                      <template v-slot:item.vlrSaldo="{ item }">
                        {{ formatCurrency((item.reparte + item.adicional - item.encalhe) * item.vlrUnitario) }}
                      </template>
                    </v-data-table>
                  </v-card>
                </v-flex>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="lancamentos.length > 0" dark class="elevation-0">
              <v-divider></v-divider>
              <v-expansion-panel-header>
                <strong>Lançamentos</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-layout wrap>
                  <v-flex xs12 md12>
                    <v-data-table mobile-breakpoint="0" :headers="headerLancamentos" :items="lancamentos" no-data-text no-results-text hide-default-footer>
                      <template v-slot:item.dtLancamento="{ item }">{{ formatDate(item.dtLancamento) }}</template>
                      <template v-slot:item.valor="{ item }">{{ formatCurrency(item.valor) }}</template>
                    </v-data-table>
                  </v-flex>
                </v-layout>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <v-card-actions id="flex-wrap">
      <v-btn :to="'/financeiro/operacoes/' + (receber ? 'receber' : 'pagar')" color="orange lighten-2 white" style="color:white" id="btn-rsn">
        <v-icon left>keyboard_arrow_left</v-icon>Retornar
      </v-btn>
      <v-btn
        v-if="nfNaoEmitida && operacao.situacao && editar && (receber || id == '' || !operacao.confirmada || direitoEditarConfirmada)"
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="blue white"
        style="color:white"
        id="btn-rsn"
        depressed
        @click="salvar('false')"
      >
        <v-icon left>update</v-icon>Salvar
      </v-btn>

      <v-btn
        v-if="nfNaoEmitida && operacao.situacao && editar && (receber || id == '' || !operacao.confirmada || direitoEditarConfirmada)"
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="orange white"
        style="color:white"
        id="btn-rsn"
        outlined
        @click="salvar('retornar')"
      >
        <v-icon id="icon-rsn" left>update</v-icon>Salvar e Retornar
      </v-btn>
      <v-btn
        v-if="direitoCriar && nfNaoEmitida && operacao.situacao && editar && (receber || id == '' || !operacao.confirmada || direitoEditarConfirmada)"
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="blue white"
        style="color:white"
        id="btn-rsn"
        outlined
        @click="salvar('true')"
      >
        <v-icon left>autorenew</v-icon>Salvar e Nova
      </v-btn>
      <v-btn
        v-if="id != '' && direitoCancelar && operacao.situacao && editar"
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="red lighten-1"
        style="color:white"
        id="btn-rsn"
        outlined
        @click="dialogConfirmCanc = true"
      >
        <v-icon left>cancel</v-icon>Cancelar
      </v-btn>
      <v-btn
        v-if="direitoCancelar && !operacao.situacao && editar"
        :loading="isUpdating"
        :disabled="bloqueiaBotoes"
        color="green lighten-1"
        style="color:white"
        id="btn-rsn"
        depressed
        @click="reverter"
      >
        <v-icon left>undo</v-icon>Reverter
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackRefresh" color="info" :timeout="1" relative bottom left></v-snackbar>
    <v-dialog persistent v-model="dialogConfirm" max-width="600">
      <v-card>
        <v-toolbar>
          <span class="headline">Confirmação</span>
        </v-toolbar>
        <br />
        <v-card-text>Tem certeza que deseja confirmar esta operação?</v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="naoConfirmado">Não</v-btn>
          <v-btn color="green darken-1" text @click="confirmado">Sim</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialogConfirmCanc" max-width="600">
      <v-card>
        <v-form ref="formConfirmCanc" v-model="confirmCancValid" lazy-validation>
          <v-toolbar>
            <span class="headline">Cancelamento da Operação</span>
          </v-toolbar>
          <br />
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-layout wrap>
                <v-flex xs12 md12>
                  <v-textarea
                    autofocus
                    v-model="motivoCancelamento"
                    label="Justificativa"
                    maxlength="255"
                    counter="255"
                    rows="4"
                    hint="Obrigatório"
                    :rules="[
                      value => !!value || 'Obrigatório.',
                      value => (value != null && value.length >= 10 && value.length <= 255) || 'Texto da justificativa deve ter entre 10 e 255 caracteres'
                    ]"
                    color="blue-grey lighten-2"
                    autocomplete="disabled"
                    :name="Math.random()"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="red darken-1" text @click="naoCancelar">Retornar</v-btn>
            <v-btn color="green darken-1" text @click="cancelar">Cancelar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import Parcela from "@/views/fin/Parcela.vue";
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  components: {
    Parcela
  },
  data() {
    return {
      operacao: {
        id: "",
        idSite: this.$store.state.usuario.idSitePrincipal,
        tipo: 1,
        dtOperacao: null,
        dtC: null,
        idUsuario: this.$store.state.usuario.id,
        usuario: this.$store.state.usuario.nome,
        dtCancelamento: null,
        usuarioCancelamento: null,
        motivoCancelamento: null,
        descricao: "",
        idCliente: null,
        idDeptoCliente: null,
        origem: "FIN",
        vlrBruto: 0,
        vlrLiquido: 0,
        vlrTaxado: 0,
        nroParcelas: 0,
        frete: 9,
        duplicatas: 1,
        situacao: 1,
        mensal: 0,
        confirmada: 1,
        idContaC: null,
        idContaD: null
      },

      nroNF: "",
      dtEmissaoNF: null,

      contaDSelecionada: {},
      contaCSelecionada: {},
      semFornecedor: false,

      idClienteEndereco: null,
      idClienteCartao: null,
      idClienteConta: null,
      idClienteCelesc: null,
      idClientePix: null,

      dtOperacao: null,
      dtOperacaoUS: null,
      mnuDtOperacao: false,

      validacao: {
        id: false,
        idSite: false,
        dtOperacao: false,
        idUsuario: false,
        usuario: false,
        dtCancelamento: false,
        descricao: false,
        idCliente: false,
        idDeptoCliente: false,
        origem: false,
        vlrBruto: false,
        vlrLiquido: false,
        nroParcelas: false,
        frete: false,
        duplicatas: false,
        mensal: false,
        confirmada: false,
        situacao: false,
        dtVecto: false,
        agente: false,
        comissao: false,
        agentes: false,
        permutas: false,
        parcelas: false,
        centrosCusto: false,
        ajuste_valor: false,
        ajuste_vecto: false,
        idContaC: false,
        idContaD: false,
        mensagemErro: false
      },
      agentes: [],
      permutas: [],
      selectAll: false,
      vlrPermutado: 0,
      parcelas: [],
      centrosCusto: [],
      centrosCustoOperacao: [],
      lancamentos: [],

      receitas: [],
      despesas: [],
      clientes: [],
      departamentosCliente: [],
      agentesOrigem: [],
      fretes: [
        { id: 0, nome: "0-Emitente" },
        { id: 1, nome: "1-Destinatário" },
        { id: 2, nome: "2-Terceiros" },
        { id: 3, nome: "3-Próprio Emitente" },
        { id: 9, nome: "9-Sem Frete" }
      ],
      formasPgto: [],
      formaPagamentoSelecionar: false,
      formaPagamentoSelecionada: { id: null, nome: "", tipo: "" },
      clienteSelecionado: {},
      dtVecto: null,
      dtVectoUS: null,
      mnuDtVecto: false,

      //* Dados para o componente DialogParcela
      dialogParcela: false,
      idParcela: "",

      agenteSelecionado: {},
      dialogAgente: false,
      headerAgentes: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Agente/Agência", value: "agente" },
        { text: "Comissão(%)", value: "comissao", align: "end" }
      ],
      headerPermutas: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Índice", value: "indice" },
        { text: "Parcela", value: "numero" },
        { text: "Vencimento", value: "dtVecto" },
        { text: "Valor", value: "valor", align: "end" }
      ],
      headerLancamentos: [
        { text: "ID", value: "id" },
        { text: "Data", value: "dtLancamento" },
        { text: "Débito", value: "nomeCompletoD" },
        { text: "Crédito", value: "nomeCompletoC" },
        { text: "Valor", value: "valor", align: "end" },
        { text: "Parcela", value: "idParcela" }
      ],
      rodapePermutas: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50, 100]
      },
      agenteValid: true,
      agenteIndex: -1,
      agenteItem: {
        id: null,
        idAgente: null,
        agente: "",
        comissao: 0
      },
      defaultAgenteItem: {
        id: null,
        idAgente: null,
        agente: "",
        comissao: 0
      },

      dialogAjuste: false,
      ajusteValid: true,
      ajusteIndex: -1,
      ajusteItem: {
        valor: 0,
        dtVectoOriginal: null,
        dtVectoO: null, //* para apresentação
        liquido: 1
      },

      ajusteItemDtVectoO: null,
      ajusteItemDtVectoOUS: null,
      mnuAjusteItemDtVectoO: false,

      headerParcelas: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Parcela", value: "numero" },
        { text: "Status", value: "status" },
        { text: "Forma de Pagamento", value: "formaPagamento" },
        { text: "Vencimento Original", value: "dtVectoOriginal" },
        { text: "Promessa", value: "dtPromessa" },
        { text: "Valor", value: "valor", align: "end" }
      ],

      editarCC: true,
      centroCustoSelecionado: {},
      dialogCentroCusto: false,
      headerCentrosCusto: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Centro de Custo", value: "nome" },
        { text: "Percentual", value: "percentual" }
      ],
      centroCustoValid: true,
      centroCustoIndex: -1,
      centroCustoItem: {
        id: null,
        idCentroCusto: null,
        centroCusto: "",
        percentual: 100
      },
      defaultCentroCustoItem: {
        id: null,
        idCentroCusto: null,
        centroCusto: "",
        percentual: 100
      },

      itensVA: [],
      headerItensVA: [
        // { text: "Ações", value: "actions", width: "110" },
        { text: "ID", value: "idLote" },
        { text: "Entrega", value: "dtEntrega" },
        { text: "Tipo", value: "tipoBanca" },
        { text: "Banca", value: "banca" },
        { text: "Valor Tabela", value: "vlrUnitario", divider: "true" },
        { text: "Reparte", value: "reparte", align: "end" },
        { text: "Adicional", value: "adicional", align: "end" },
        { text: "Soma", value: "soma", divider: "true", align: "end" },
        { text: "Encalhe", value: "encalhe", divider: "true", align: "end" },
        { text: "Saldo", value: "saldo", divider: "true", align: "end" },
        { text: "Valor Reparte", value: "vlrReparte", align: "end" },
        { text: "Valor Adicional", value: "vlrAdicional", align: "end" },
        { text: "Valor Soma", value: "vlrSoma", divider: "true", align: "end" },
        {
          text: "Valor Encalhe",
          value: "vlrEncalhe",
          divider: "true",
          align: "end"
        },
        {
          text: "Valor Saldo",
          value: "vlrSaldo",
          divider: "true",
          align: "end"
        },
        { text: "Fatura", value: "idParcela" },
        { text: "Cobrança", value: "nossoNumero" },
        { text: "Nota Fiscal", value: "notaFiscal" }
      ],

      dialogConfirm: false,

      dialogConfirmCanc: false,
      confirmCancValid: true,
      motivoCancelamento: "",

      emCobranca: false,

      idSitePrincipal: null,
      editar: true,
      painel: [0],
      search: "",
      nfNaoEmitida: true,
      loadingClientes: false,
      direitoEditarOpPermuta: false,
      direitoCriar: false,
      direitoCancelar: false,
      direitoParcelas: false,
      direitoEditarConfirmada: false,
      direitoFornecedor: false,
      direitoCliente: false,
      direitoAssinante: false,
      direitoAnunciante: false,
      direitoBanca: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,
      snackRefresh: false,

      isUpdating: false,
      bloqueiaBotoes: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    async salvar(novo) {
      await util.limparObjeto(this.validacao, false);

      var erro = false;
      if (this.agentes.length > 2) {
        erro = true;
        this.validacao.agentes = "Máximo de agentes permitidos: 2";
      }

      if (this.parcelas.length == 0 && this.id == "" && this.vlrPermutado && this.vlrPermutado < parseFloat(this.operacao.vlrLiquido)) {
        //* Se tem permuta e esta não consumiu o valor total, solicita parcelamento do restante
        this.validacao.parcelas = "Selecione a forma de pagamento e o parcelamento para gerar as parcelas do valor restante não permutado";
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }
      if (!this.receber && this.operacao.confirmada && this.$store.state.usuario.site_confirmaOpPagar && (!this.nroNF || !this.dtEmissaoNF)) {
        this.mensagem = "Para Pagamento, preencha o Número e Data de Emissão da Nota Fiscal";
        this.operacao.confirmada = 0;
        this.snackErro = true;
        return;
      }

      if (this.vlrPermutado) {
        if (this.operacao.mensal) {
          this.mensagem = "Não é possível gerar lançamentos MENSAIS para PERMUTA";
          this.snackErro = true;
          return;
        }
        if (!this.operacao.confirmada) {
          this.mensagem = "Não é possível registrar PERMUTA como ORÇAMENTO";
          this.snackErro = true;
          return;
        }
        if ((this.receber && this.formaPagamentoSelecionada.tipo == "PERMUTA_RECEBER") || (!this.receber && this.formaPagamentoSelecionada.tipo == "PERMUTA_PAGAR")) {
          this.formaPagamentoSelecionada = { id: null, nome: "", tipo: "" };
          this.mensagem = "Defina o valor líquido igual ao total permutado ou selecione outra forma de pagamento para liquidar o valor restante";
          this.snackErro = true;
          return;
        }
      }

      if (this.id == "" && this.vlrPermutado) {
        var existePP = false;
        this.parcelas.forEach(element => {
          if ((this.receber && element.formaPagamentoTipo == "PERMUTA_RECEBER") || (!this.receber && element.formaPagamentoTipo == "PERMUTA_PAGAR")) {
            existePP = true;
          }
        });
        if (!existePP) {
          //* Cria a parcela para PERMUTA A PAGAR
          var dtVecto = new Date();
          var vlrPm = this.vlrPermutado > parseFloat(this.operacao.vlrLiquido) ? parseFloat(this.operacao.vlrLiquido) : this.vlrPermutado;
          var parcela = {
            id: null,
            idOperacao: null,
            indice: null,
            numero: this.parcelas.length + 1,
            status: "QUITADA",
            idFormaPgto: this.receber ? 17 : 16,
            formaPagamento: "Permuta a Receber",
            formaPagamentoTipo: this.receber ? "PERMUTA_RECEBER" : "PERMUTA_PAGAR",
            dtVectoOriginal: dtVecto,
            dtVecto: dtVecto,
            dtVectoO: util.formatDate(dtVecto),
            dtQuitacao: dtVecto,
            idClienteCartao: null,
            idClienteConta: null,
            idClienteEndereco: null,
            idClienteCelesc: null,
            idClientePix: null,
            valor: vlrPm,
            liquido: util.formatCurrency(this.vlrPm),
            descAcr: 0,
            obsCobranca: util.removeEspeciais(util.removeAcentos(this.operacao.descricao)),
            ajustada: 0
          };
          this.parcelas.push(parcela);
        }
      }
      if (this.parcelas.length == 0) {
        erro = true;
        this.validacao.parcelas = "Inclua pelo menos 1 parcela";
      }

      var soma = 0;
      this.centrosCustoOperacao.forEach(element => {
        soma += parseFloat(element.percentual);
      });
      if (!this.id && soma != 100) {
        erro = true;
        this.validacao.centrosCusto = "Soma dos percentuais dos centros de custo deve ser 100";
      }

      //* Prepara os agentes da operacao para envio a base
      var agentesCommit = [];
      var agentesVal = [];
      var agenteDuplicado = "";
      this.agentes.forEach(element => {
        if (agentesVal.includes(element.agente)) {
          agenteDuplicado = element.agente;
        }
        agentesVal.push(element.agente);
        agentesCommit.push({
          id: element.id,
          idOperacao: this.id,
          idAgente: element.idAgente,
          comissao: element.comissao
        });
      });
      if (agenteDuplicado) {
        this.validacao.agentes = "Agente/Agência duplicado [" + agenteDuplicado + "]";
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      var vlrTotal = 0;
      this.parcelas.forEach(element => {
        var vlr = parseFloat(element.valor);
        vlr = vlr.toFixed(2); //deixa vlr como string
        if (!util.isFormaPgtoValorZero(this.formaPagamentoSelecionada.tipo) && parseFloat(vlr) <= 0) {
          erro = true;
          this.validacao.parcelas = "Não pode haver parcela com valor zero";
        }
        vlrTotal += parseFloat(vlr); //converte vlr para float
        vlrTotal = vlrTotal.toFixed(2); //deixa só 2 decimais após a soma
        vlrTotal = parseFloat(vlrTotal); //converte novamente para float, pois toFixed deixa como string
        if (!util.isFormaPgtoValorZero(this.formaPagamentoSelecionada.tipo)) {
          if (this.operacao.confirmada && element.status == "BLOQUEADA") {
            element.status = "ABERTA";
          }
          if (!this.operacao.confirmada && element.status == "ABERTA") {
            element.status = "BLOQUEADA";
          }
        }
      });
      if (vlrTotal != parseFloat(this.operacao.vlrLiquido)) {
        erro = true;
        this.validacao.parcelas = "Soma das parcelas deve ser igual ao valor líquido";
      }

      //* Prepara os centros de custo da operacao para envio a base
      var centrosCustoCommit = [];
      var centrosCustoVal = [];
      var centroCustoDuplicado = "";
      this.centrosCustoOperacao.forEach(element => {
        if (centrosCustoVal.includes(element.nome)) {
          centroCustoDuplicado = element.nome;
        }
        centrosCustoVal.push(element.nome);
        centrosCustoCommit.push({
          id: element.id,
          idCentroCusto: element.idCentroCusto,
          percentual: element.percentual
        });
      });
      if (!this.id && centroCustoDuplicado) {
        this.validacao.centrosCusto = "Centro de Custo duplicado [" + centroCustoDuplicado + "]";
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      if (!this.$refs.formElemento.validate() || erro) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      if (!this.receber) {
        if (this.nroNF || this.dtEmissaoNF) {
          if (this.nroNF) {
            this.operacao.numeroNFpg = this.nroNF;
          }
          if (this.dtEmissaoNF) {
            this.operacao.dtEmissaoNFpg = util.formatDateUS(this.dtEmissaoNF);
          }

          this.parcelas.forEach(element => {
            if (this.nroNF && !element.numeroNFpg) {
              element.numeroNFpg = this.nroNF;
            }
            if (this.dtEmissaoNF && !element.dtEmissaoNFpg) {
              element.dtEmissaoNFpg = util.formatDateUS(this.dtEmissaoNF);
            }
          });
        }
      }
      this.operacao.dtOperacao = this.dtOperacaoUS;
      this.operacao.agentes = agentesCommit;
      this.operacao.parcelas = this.parcelas;
      this.operacao.centrosCusto = centrosCustoCommit;
      if (!this.receber) {
        this.operacao.duplicatas = 0;
      } else {
        this.operacao.confirmada = 1;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "fin/operacao/" + this.id, this.operacao);
        } else {
          var permutasCommit = [];
          if (this.vlrPermutado) {
            this.operacao.vlrPermutado = this.vlrPermutado > parseFloat(this.operacao.vlrLiquido) ? this.operacao.vlrLiquido : this.vlrPermutado;

            const vlr = parseFloat(this.operacao.vlrLiquido);
            var vlrP = 0;
            this.permutas.forEach(element => {
              if (element.selecionado) {
                if (vlrP < vlr) {
                  permutasCommit.push({ idParcela: element.id });
                }
                vlrP += element.valor + element.descAcr;
              }
            });
          }
          this.operacao.permutas = permutasCommit;
          this.operacao.nroParcelas = this.parcelas.length;

          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "fin/operacao" + (this.operacao.mensal ? "/mensal" : "") + "?idSite=" + this.idSitePrincipal, this.operacao);
          if (retorno.data.erro) {
            this.mensagem = "Esta operação já foi cadastrada. Recarregando seus dados...";
            this.snackErro = true;
            setTimeout(() => {
              this.$router.push("/financeiro/operacao/" + (this.receber ? "receber" : "pagar") + "/" + retorno.data.id);
            }, 3000);
            return;
          }
          this.id = retorno.data.id;
          this.operacao.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
        if (novo == "false") {
          this.$router.push("/financeiro/operacao/" + (this.receber ? "receber/" : "pagar/") + this.id);
          this.$router.go("/financeiro/operacao/" + (this.receber ? "receber/" : "pagar/") + this.id);
        } else if (novo == "true") {
          this.$router.push("/financeiro/operacao/" + (this.receber ? "receber" : "pagar"));
          this.$router.go("/financeiro/operacao/" + (this.receber ? "receber" : "pagar"));
        } else if (novo == "retornar") {
          setTimeout(() => {
            this.$router.push("/financeiro/operacoes/" + (this.receber ? "receber" : "pagar"));
          }, 2000);
        } else {
          this.carregarAgentesOperacao();
          this.carregarParcelasOperacao();
          this.carregarCentrosCustoOperacao();
        }
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    recriaParcelas() {
      this.parcelas = [];
      this.operacao.recriar = true;
    },

    confirmar() {
      if (this.id) {
        this.dialogConfirm = true;
      }
    },
    async confirmado() {
      this.dialogConfirm = false;
      this.salvar("false");
    },
    naoConfirmado() {
      this.operacao.confirmada = 0;
      this.dialogConfirm = false;
    },

    naoCancelar() {
      this.dialogConfirmCanc = false;
    },
    async cancelar() {
      if (!this.$refs.formConfirmCanc.validate()) {
        return;
      }
      this.dialogConfirmCanc = false;
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "fin/operacao/cancelar/" + this.id, {
          idUsuarioCancelamento: this.$store.state.usuario.id,
          motivoCancelamento: this.motivoCancelamento
        });
        this.operacao.situacao = 0;
        this.operacao.dtCancelamento = new Date();
        this.operacao.motivoCancelamento = this.motivoCancelamento;
        this.operacao.usuarioCancelamento = this.$store.state.usuario.nome;

        this.parcelas.forEach(element => {
          element.status = "EXCLUÍDA";
        });
        this.mensagem = "Operação cancelada com sucesso";
        this.snackbar = true;
      } catch (e) {
        if (e.response.data) {
          this.mensagem =
            "ERRO: " +
            (e.toString().includes("status code 400")
              ? "SESSÃO EXPIRADA"
              : e.response && e.response.data && e.response.data.length > 0
              ? e.response.data[0].mensagem
              : "Indeterminado, verifique sua conexão");
          this.snackErro = true;
        }
      }
    },
    async reverter() {
      try {
        await api.put(process.env.VUE_APP_URL_WEB_API + "fin/operacao/reverter/" + this.id);
        this.operacao.situacao = 1;
        this.mensagem = "Operação revertida com sucesso";
        this.snackbar = true;
        setTimeout(() => {
          this.$router.push("/financeiro/operacao/" + (this.receber ? "receber" : "pagar") + "/" + this.id);
          this.$router.go("/financeiro/operacao/" + (this.receber ? "receber" : "pagar") + "/" + this.id);
        }, 500);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    async gerarParcelas() {
      if (this.vlrPermutado) {
        if (this.vlrPermutado >= parseFloat(this.operacao.vlrLiquido)) {
          //* Se valor foi todo consumido por permuta, não gera parcelas
          return;
        }
      }
      if (this.formaPagamentoSelecionada) {
        if ((this.receber && this.formaPagamentoSelecionada.tipo == "PERMUTA_PAGAR") || (!this.receber && this.formaPagamentoSelecionada.tipo == "PERMUTA_RECEBER")) {
          this.formaPagamentoSelecionada = { id: null, nome: "", tipo: "" };
          this.mensagem = "Selecione os itens a serem permutados na aba Permutas";
          this.snackErro = true;
          return;
        }
      }

      if (this.vlrPermutado) {
        if ((this.receber && this.formaPagamentoSelecionada.tipo == "PERMUTA_RECEBER") || (!this.receber && this.formaPagamentoSelecionada.tipo == "PERMUTA_PAGAR")) {
          this.formaPagamentoSelecionada = { id: null, nome: "", tipo: "" };
          this.mensagem = "Defina o valor líquido igual ao total permutado ou selecione outra forma de pagamento para liquidar o valor restante";
          this.snackErro = true;
          return;
        }
      }

      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }

      try {
        var vlrTotal = parseFloat(this.operacao.vlrLiquido);
        if (this.vlrPermutado) {
          vlrTotal = vlrTotal - this.vlrPermutado;
          if (vlrTotal < 0) {
            //* Não deverá cair aqui se não sobrou valor a parcelar (é tudo permuta)
            vlrTotal = 0;
          }
        }

        var nroParcelas = parseInt(this.operacao.nroParcelas);
        var dtVecto = util.parseDate(this.dtVecto);
        const dia = dtVecto.getDate();

        if (util.isFormaPgtoValorZero(this.formaPagamentoSelecionada.tipo)) {
          if (vlrTotal != 0) {
            this.mensagem = "Valor líquido deve ser 0,00 para CORTESIA, BONIFICAÇÃO ou COMPENSAÇÃO";
            this.snackErro = true;
            return;
          }
          if (parseInt(this.operacao.nroParcelas) != 1) {
            this.mensagem = "Número de parcelas deve ser 1 para CORTESIA, BONIFICAÇÃO ou COMPENSAÇÃO";
            this.snackErro = true;
            return;
          }

          //* Cria a parcela para CORTESIA, BONIFICAÇÃO ou COMPENSAÇÃO
          var parcela = {
            id: null,
            idOperacao: null,
            indice: null,
            numero: 1,
            status: "QUITADA",
            idFormaPgto: this.formaPagamentoSelecionada.id,
            formaPagamento: this.formaPagamentoSelecionada.nome,
            formaPagamentoTipo: this.formaPagamentoSelecionada.tipo,
            dtVectoOriginal: dtVecto,
            dtVecto: dtVecto,
            dtQuitacao: dtVecto,
            idClienteCartao: this.idClienteCartao,
            idClienteConta: this.idClienteConta,
            idClienteEndereco: this.idClienteEndereco,
            idClienteCelesc: this.idClienteCelesc,
            idClientePix: this.idClientePix,
            valor: 0,
            descAcr: 0,
            obsCobranca: util.removeEspeciais(util.removeAcentos(this.operacao.descricao)),
            ajustada: 0
          };
          this.parcelas.push(parcela);
        } else {
          if (vlrTotal / nroParcelas < 0.01) {
            throw "O valor líquido é incompatível com o número de parcelas";
          }
          if (util.isFormaPgto1Parcela(this.formaPagamentoSelecionada.tipo)) {
            if (parseInt(this.operacao.nroParcelas) != 1) {
              this.mensagem = "Número de parcelas deve ser 1 para esta forma de pagamento";
              this.snackErro = true;
              return;
            }
            dtVecto = new Date();
          }

          var vlrRestante = vlrTotal;
          for (let index = 1; index <= nroParcelas; index++) {
            var vlr = vlrTotal / nroParcelas;
            vlr = vlr.toFixed(2);
            if (index == nroParcelas) {
              vlr = vlrRestante;
            } else {
              vlrRestante -= parseFloat(vlr);
              vlrRestante = vlrRestante.toFixed(2);
            }

            const dtVencimento = new Date(dtVecto.getFullYear(), dtVecto.getMonth(), dtVecto.getDate());
            var parcela2 = {
              id: null,
              idOperacao: null,
              indice: null,
              numero: index,
              status: this.operacao.confirmada ? "ABERTA" : "BLOQUEADA",
              idFormaPgto: this.formaPagamentoSelecionada.id,
              formaPagamento: this.formaPagamentoSelecionada.nome,
              formaPagamentoTipo: this.formaPagamentoSelecionada.tipo,
              dtVectoOriginal: dtVencimento,
              dtVecto: dtVencimento,
              dtQuitacao: null,
              idClienteCartao: this.idClienteCartao,
              idClienteConta: this.idClienteConta,
              idClienteEndereco: this.idClienteEndereco,
              idClienteCelesc: this.idClienteCelesc,
              idClientePix: this.idClientePix,
              valor: vlr,
              descAcr: 0,
              obsCobranca: util.removeEspeciais(util.removeAcentos(this.operacao.descricao)),
              ajustada: 0
            };
            this.parcelas.push(parcela2);
            var dtUltDiaProxMes = new Date(dtVecto.getFullYear(), dtVecto.getMonth() + 2, 0);
            dtVecto.setMonth(dtVecto.getMonth() + 1);
            if (dia <= dtUltDiaProxMes.getDate()) {
              dtVecto.setDate(dia);
            }
            if (dia > dtUltDiaProxMes.getDate()) {
              dtVecto.setDate(dtVecto.getDate() - (dia - dtUltDiaProxMes.getDate()));
            }
          }
        }
      } catch (e) {
        this.mensagem = "Erro ao gerar as parcelas: " + e;
        this.snackErro = true;
      }
    },
    async carregarReceitas() {
      try {
        //* Busca todas as contas de receitas de venda
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/conta/receitas?idSite=" + this.idSitePrincipal);
        if (response.data) {
          this.receitas = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as contas de receita";
        this.snackErro = true;
      }
    },
    async carregarOrigem() {
      try {
        //* Busca a conta CLIENTE
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/conta/tipo?tipo=CLIENTE");
        this.despesas = response.data;
        this.contaDSelecionada = response.data[0];
        this.despesas.forEach(element => {
          element.nomeS = element.nome;
          element.nome = element.nome + " - " + element.mae;
        });
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a conta CLIENTES";
        this.snackErro = true;
      }
    },
    async carregarDespesas() {
      try {
        //* Busca todas as contas de despesas
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/conta/despesas?idSite=" + this.idSitePrincipal);
        if (response.data) {
          this.despesas = response.data;
          this.despesas.forEach(element => {
            element.nomeS = element.nome;
            element.nome = element.nome + " - " + element.mae;
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as contas de despesas";
        this.snackErro = true;
      }
    },
    async carregarDestino() {
      try {
        //* Busca a conta FORNECEDOR
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API + "fin/conta/tipo?tipo=CONTA_PAGAR" //FORNECEDOR
        );
        this.receitas = [];
        this.contaCSelecionada = {};
        response.data.forEach(element => {
          if (!["PERMUTA_PAGAR"].includes(element.tipo)) {
            //"CREDITO", descontinuado
            this.receitas.push(element);
          }
          if (!this.contaCSelecionada.id && element.tipo == "FORNECEDOR") {
            this.contaCSelecionada = element;
          }
        });
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a conta FORNECEDORES";
        this.snackErro = true;
      }
    },
    async carregarCentrosCusto() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/centro-custo/lista");
        if (response.data) {
          this.centrosCusto = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de centros de custo";
        this.snackErro = true;
      }
    },
    async carregarLancamentos() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/lancamento/operacao/" + this.id + "?completa=1");
        this.lancamentos = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os lançamentos da operação";
        this.snackErro = true;
      }
    },
    async carregarPermutasCliente() {
      try {
        this.permutas = [];
        if (this.id) {
          const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/operacao-permuta/operacao/" + this.operacao.id);
          if (response.data) {
            this.permutas = response.data;
          }
        } else {
          this.vlrPermutado = 0;
          var permitePP = false;
          this.formasPgto.forEach(element => {
            if ((this.receber && element.tipo == "PERMUTA_PAGAR") || (!this.receber && element.tipo == "PERMUTA_RECEBER")) {
              permitePP = true;
            }
          });
          if (permitePP) {
            //* Se é conta a pagar, busca permutas a receber do cliente ainda não quitadas (e vice-versa)
            const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/permutas/" + this.operacao.idCliente + (!this.receber ? "?receber=1" : ""));
            if (response.data) {
              this.permutas = response.data;
              this.selectAll = false;
            }
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as permutas";
        this.snackErro = true;
      }
    },

    async buscarCliente() {
      if (this.loadingClientes || !this.search) {
        return;
      }

      this.loadingClientes = true;

      var filtro = "";
      if (util.valida_cpf(this.search) || util.valida_cnpj(this.search)) {
        filtro = "identificacao=" + this.search;
      } else {
        filtro = "nome=" + this.search + "&nomeFantasia=" + this.search;
      }

      const response = await api.get(
        process.env.VUE_APP_URL_WEB_API + "adm/cliente?" + filtro + (this.receber ? "&tela=cliente" : "&tela=fornecedor") + "&ordenacao=nome&ordem=asc&pagina=1&tamanho=50"
      );
      if (response.data.data) {
        this.clientes = response.data.data;
        if (this.clientes.length == 1) {
          this.operacao.idCliente = this.clientes[0].id;
          this.recarregarItensCliente(this.clientes[0]);
        }
      }

      this.loadingClientes = false;
    },

    async carregarDepartamentosCliente() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-departamento/cliente/" + this.operacao.idCliente + "?ativos=1");
        if (response.data) {
          this.departamentosCliente = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os departamentos do cliente";
        this.snackErro = true;
      }
    },

    recarregarItensCliente(item) {
      this.clienteSelecionado = item;
      this.operacao.idCliente = this.clienteSelecionado.id;
      this.carregarDepartamentosCliente();
      this.carregarPermutasCliente();
      if (!this.id && this.formaPagamentoSelecionada.id) {
        const formaPgto = this.formaPagamentoSelecionada;
        this.formaPagamentoSelecionada = null;
        this.recriaParcelas();
        this.formaPagamentoSelecionada = formaPgto;
      }
    },

    async carregarFormasPgto() {
      try {
        //* Busca todas as formas de cobrança ativas (a receber ou a pagar) - para aplicação nas parcelas - alteração será feita na própria parcela depois
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "adm/forma-pagamento/lista?idSite=" +
            this.idSitePrincipal +
            "&idUsuario=" +
            this.$store.state.usuario.id +
            (this.receber ? "" : "&pagar=1")
        );
        if (response.data) {
          response.data.forEach(element => {
            if (!["PACOTE"].includes(element.tipo)) {
              this.formasPgto.push(element);
            }
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de formas de pagamento";
        this.snackErro = true;
      }
    },

    async carregarAgentes() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-agente/lista?idSite=" + this.idSitePrincipal);
        if (response.data) {
          this.agentesOrigem = response.data;
          this.agentesOrigem.forEach(element => {
            element.agente = element.nome;
            element.idAgente = element.id;
          });
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de agentes";
        this.snackErro = true;
      }
    },

    async carregarReceitaDespesaOperacao() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/lancamento/operacao/" + this.operacao.id + "?completa=1");
        if (response.data && response.data.length > 0) {
          const lancamento = response.data[0];
          if (lancamento.idContaD && lancamento.situacaoD) {
            this.despesas = [
              {
                id: lancamento.idContaD,
                nomeS: lancamento.nomeD,
                nome: lancamento.nomeCompletoD,
                idCentroCusto: lancamento.idCentroCustoD
              }
            ];
            this.contaDSelecionada = {
              id: lancamento.idContaD,
              nome: lancamento.nomeD,
              idCentroCusto: lancamento.idCentroCustoD
            };
          }
          if (lancamento.idContaC && lancamento.situacaoC) {
            this.receitas = [
              {
                id: lancamento.idContaC,
                nomeS: lancamento.nomeC,
                nome: lancamento.nomeCompletoC,
                idCentroCusto: lancamento.idCentroCustoC,
                tipo: lancamento.tipoC
              }
            ];
            this.contaCSelecionada = {
              id: lancamento.idContaC,
              nome: lancamento.nomeC,
              idCentroCusto: lancamento.idCentroCustoC,
              tipo: lancamento.tipoC
            };
          }
          this.snackRefresh = true;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os lançamentos da operação";
        this.snackErro = true;
      }
    },

    async carregarAgentesOperacao() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/operacao-agente/operacao/" + this.operacao.id);
        if (response.data) {
          this.agentes = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os agentes da operação";
        this.snackErro = true;
      }
    },

    async carregarParcelasOperacao() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/operacao/" + this.operacao.id);
        if (response.data) {
          this.parcelas = response.data;
          if (this.parcelas.length > 0) {
            var parcela = this.parcelas[0];
            this.formaPagamentoSelecionada.id = parcela.idFormaPgto;
            this.formaPagamentoSelecionada.nome = parcela.formaPagamento;
            this.formaPagamentoSelecionada.tipo = parcela.formaPagamentoTipo;

            var nfs = [];
            this.parcelas.forEach(element => {
              if (element.numeroNFpg) {
                if (!nfs.length) {
                  nfs.push({
                    nro: element.numeroNFpg
                  });
                  this.nroNF = element.numeroNFpg;
                  this.dtEmissaoNF = util.formatDateUTC(element.dtEmissaoNFpg);
                }
              }
            });
          }
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as parcelas da operação";
        this.snackErro = true;
      }
    },

    async carregarCentrosCustoOperacao() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/lancamento-centro-custo/operacao/" + this.operacao.id);
        if (response.data) {
          this.centrosCustoOperacao = response.data;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os centros de custo da operação";
        this.snackErro = true;
      }
    },

    async carregarEnderecos() {
      try {
        //* Carrega os endereços de cobrança do cliente
        const responseEnderecosCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-endereco/lista?idCliente=" + this.operacao.idCliente + "&cobranca=1");
        const enderecosCliente = responseEnderecosCliente.data;
        if (enderecosCliente.length > 0) {
          this.idClienteEndereco = enderecosCliente[0].id;
        }
      } catch (e) {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os endereços de cobrança do cliente: " + e;
        this.snackErro = true;
      }
    },
    async carregarCartoes() {
      try {
        //* Carrega os cartões de crédito do cliente
        const responseCartoesCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-cartao/lista?idCliente=" + this.operacao.idCliente);
        const cartoesCliente = responseCartoesCliente.data;
        if (cartoesCliente.length > 0) {
          this.idClienteCartao = cartoesCliente[0].id;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os cartões de crédito do cliente";
        this.snackErro = true;
      }
    },
    async carregarCelescs() {
      try {
        //* Carrega as unidades consumidoras do cliente
        const responseCelescsCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-celesc/lista?idCliente=" + this.operacao.idCliente);
        const celescsCliente = responseCelescsCliente.data;
        if (celescsCliente.length > 0) {
          this.idClienteCelesc = celescsCliente[0].id;
        }
      } catch (e) {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as unidades consumidoras do cliente: " + e;
        this.snackErro = true;
      }
    },
    async carregarContas() {
      try {
        //* Carrega as contas correntes do cliente
        const responseContasCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-conta/lista?idCliente=" + this.operacao.idCliente);
        const contasCliente = responseContasCliente.data;
        if (contasCliente.length > 0) {
          this.idClienteConta = contasCliente[0].id;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as contas correntes do cliente";
        this.snackErro = true;
      }
    },
    async carregarPixs() {
      try {
        //* Carrega as chaves pix do cliente
        const responsePixsCliente = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-pix/lista?idCliente=" + this.operacao.idCliente);
        const pixsCliente = responsePixsCliente.data;
        if (pixsCliente.length > 0) {
          this.idClientePix = pixsCliente[0].id;
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar as chaves pix do cliente";
        this.snackErro = true;
      }
    },
    async carregarItensReparte() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/venda-avulsa-item/operacao/" + this.id);
        if (response.data) {
          this.itensVA = response.data;
          this.itensVA.forEach(element => {
            element.notaFiscal = element.numeroNFe || element.numeroNFSC;
            element.nossoNumero = element.nossoNumero || (element.status == "QUITADA" ? element.status : "");
          });
        }
      } catch (e) {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os itens do reparte da venda avulsa: " + e;
        this.snackErro = true;
      }
    },
    async defineCentroCusto(centroCusto) {
      if (!this.id) {
        //* Carrega o centro de custo padrão da conta, se houver - definindo-o para 100% e bloqueando edição
        this.centrosCustoOperacao = [];
        this.editarCC = true;
        this.snackRefresh = true;
        if (centroCusto.idCentroCusto) {
          this.centrosCustoOperacao.push(centroCusto);
          this.editarCC = false;
          this.snackRefresh = true;
        }
      } else {
        this.editarCC = true;
        if (this.operacao.confirmada) {
          this.editarCC = false;
        }
      }
    },
    async existeParcelaEmCobranca() {
      try {
        const responseCobranca = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/emCobranca/" + this.operacao.id);
        if (responseCobranca.data && responseCobranca.data.id) {
          this.emCobranca = true;
        }
      } catch (e) {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao verificar parcelas em cobrança: " + e;
        this.snackErro = true;
      }
    },
    visualizarCliente() {
      if (this.receber) {
        if (this.operacao.origem == "ASS" && this.direitoAssinante) {
          window.open("/assinaturas/assinante/" + this.operacao.idCliente, "_blank");
        } else if (this.operacao.origem == "ANU" && this.direitoAnunciante) {
          window.open("/assinaturas/assinante/" + this.operacao.idCliente, "_blank");
        } else if (this.operacao.origem == "AVU" && this.direitoBanca) {
          window.open("/assinaturas/banca/" + this.operacao.idCliente, "_blank");
        } else {
          window.open("/financeiro/cliente/" + this.operacao.idCliente, "_blank");
        }
      } else {
        window.open("/financeiro/fornecedor/" + this.operacao.idCliente, "_blank");
      }
    },
    visualizarOperacaoPermuta(item) {
      window.open("/financeiro/operacao/" + (this.receber ? "pagar/" : "receber/") + item.idOperacao, "_blank");
    },
    visualizarParcelas() {
      if (this.direitoParcelas) {
        window.open("/financeiro/parcelas/" + (this.receber ? "receber" : "pagar") + "?idOperacao=" + this.operacao.id, "_blank");
      }
    },

    // * Métodos para controle dos itens
    // editarAgente(item) {
    //   this.agenteIndex = this.agentes.indexOf(item);
    //   this.agenteItem = Object.assign({}, item);
    //   this.agenteSelecionado = {
    //     idAgente: item.idAgente,
    //     agente: item.agente,
    //     nome: item.nome
    //   };
    //   this.dialogAgente = true;
    // },
    removerAgente(item) {
      this.agenteIndex = this.agentes.indexOf(item);
      this.agenteItem = Object.assign({}, item);
      this.agentes.splice(this.agenteIndex, 1);
      this.agenteItem = Object.assign({}, this.defaultAgenteItem);
      this.agenteIndex = -1;
      this.agenteSelecionado = {};
    },
    closeAgente() {
      this.dialogAgente = false;
      setTimeout(() => {
        this.agenteItem = Object.assign({}, this.defaultAgenteItem);
        this.agenteIndex = -1;
        this.agenteSelecionado = {};
        this.$refs.formAgente.resetValidation();
      }, 300);
    },
    saveAgente() {
      if (this.$refs.formAgente.validate()) {
        var incluido = false;
        this.agenteItem.idAgente = this.agenteSelecionado.idAgente;
        this.agentes.forEach(element => {
          if (this.agenteItem.idAgente == element.idAgente) {
            incluido = true;
            this.validacao.agente = "Agente/Agência já foi incluído";
          }
        });
        if (this.agentes.length == 2) {
          this.validacao.agente = "Máximo 2 agentes de venda por operação (1º Agência, 2º Agente)";
          incluido = true;
        }

        if (!incluido) {
          if (this.agenteIndex > -1) {
            Object.assign(this.agentes[this.agenteIndex], this.agenteItem);
          } else {
            this.agentes.push(this.agenteItem);
          }
          this.closeAgente();
          this.agenteItem = Object.assign({}, this.defaultAgenteItem);
          this.agenteIndex = -1;
          this.agenteSelecionado = {};
          this.$refs.formAgente.resetValidation();
          this.validacao.agentes = false;
        }
      }
    },

    editarAjuste(item) {
      this.ajusteIndex = this.parcelas.indexOf(item);
      this.ajusteItem = Object.assign({}, item);

      this.ajusteItemDtVectoOUS = util.dateToStringUS(this.ajusteItem.dtVectoOriginal);

      this.ajusteItem.liquido = 1;
      this.validacao.ajuste_valor = false;
      this.validacao.ajuste_vecto = false;
      this.dialogAjuste = true;
    },
    closeAjuste() {
      this.dialogAjuste = false;
    },
    saveAjuste() {
      this.validacao.ajuste_valor = false;
      this.validacao.ajuste_vecto = false;
      this.validacao.parcelas = false;

      var vlrOperacao = parseFloat(this.operacao.vlrLiquido);
      if (this.vlrPermutado) {
        vlrOperacao = vlrOperacao - this.vlrPermutado;
        if (vlrOperacao < 0) {
          //* Não deverá cair aqui se não sobrou valor a parcelar (é tudo permuta)
          vlrOperacao = 0;
        }
      }

      this.ajusteItem.dtVectoOriginal = util.parseDate(this.ajusteItemDtVectoO);
      if (this.ajusteItem.liquido) {
        if (parseFloat(this.ajusteItem.valor) > vlrOperacao) {
          this.validacao.ajuste_valor = "Valor da parcela não pode ser superior ao valor da operação";
          return;
        }
      }
      if (this.ajusteIndex > 0) {
        var dtAnt = this.parcelas[this.ajusteIndex - 1].dtVectoOriginal;
        var dtV = this.ajusteItem.dtVectoOriginal;
        if (dtV < dtAnt) {
          this.validacao.ajuste_vecto = "Vencimento deve ser posterior ao vencimento da parcela anterior";
          return;
        }
      }

      var parcelasTemp = [];
      this.parcelas.forEach(element => {
        parcelasTemp.push(Object.assign({}, element));
      });
      if (this.$refs.formAjuste.validate()) {
        if (this.ajusteIndex > -1) {
          Object.assign(parcelasTemp[this.ajusteIndex], this.ajusteItem);
        } else {
          parcelasTemp.push(this.ajusteItem);
        }
      }

      var nroParcelas = parcelasTemp.length - 1;
      var vlrRestante = vlrOperacao;
      var vlrAjuste = vlrOperacao;
      if (this.ajusteItem.liquido) {
        for (var x = 0; x <= nroParcelas; x++) {
          var vlr = 0;
          if (parseInt(this.ajusteIndex) >= x) {
            vlr = parseFloat(parcelasTemp[x].valor);
            vlrRestante -= vlr;
          } else {
            if (x < nroParcelas) {
              vlr = vlrRestante / (nroParcelas - this.ajusteIndex);
              vlr = vlr.toFixed(2);
              parcelasTemp[x].valor = vlr;
            } else {
              parcelasTemp[x].valor = vlrAjuste;
            }
          }
          vlrAjuste -= parseFloat(vlr);
          vlrAjuste = vlrAjuste.toFixed(2);
        }
      }
      var vlrTotal = 0;
      parcelasTemp.forEach(element => {
        var vlr = parseFloat(element.valor);
        vlr = vlr.toFixed(2);
        if (parseFloat(vlr) <= 0) {
          this.validacao.ajuste_valor = "Não pode haver parcela com valor zero";
        }
        dtAnt = element.dtVectoOriginal;
        vlrTotal += parseFloat(vlr);
        vlrTotal = vlrTotal.toFixed(2);
        vlrTotal = parseFloat(vlrTotal);
      });
      if (this.validacao.ajuste_valor) {
        return;
      }
      if (this.ajusteItem.liquido) {
        if (vlrTotal != vlrOperacao) {
          this.validacao.ajuste_valor = "Soma das parcelas deve ser igual ao valor líquido";
          return;
        }
      } else {
        this.operacao.vlrLiquido = vlrTotal + this.vlrPermutado;
      }
      if (this.$refs.formAjuste.validate()) {
        this.parcelas = [];
        parcelasTemp.forEach(element => {
          this.parcelas.push(Object.assign({}, element));
        });
        this.closeAjuste();
        this.$refs.formAjuste.resetValidation();
      }
    },

    editarParcela(item) {
      this.idParcela = item.id + "";
      this.dialogParcela = true;
    },
    closeParcela() {
      this.dialogParcela = false;
      this.idParcela = "";
    },

    editarCentroCusto(item) {
      this.centroCustoIndex = this.centrosCustoOperacao.indexOf(item);
      this.centroCustoItem = Object.assign({}, item);
      this.centroCustoSelecionado = {
        id: item.idCentroCusto,
        nome: item.nome
      };
      this.dialogCentroCusto = true;
    },
    removerCentroCusto(item) {
      this.centroCustoIndex = this.centrosCustoOperacao.indexOf(item);
      this.centroCustoItem = Object.assign({}, item);
      this.centrosCustoOperacao.splice(this.centroCustoIndex, 1);
      this.centroCustoItem = Object.assign({}, this.defaultCentroCustoItem);
      this.centroCustoIndex = -1;
      this.centroCustoSelecionado = {};
    },
    closeCentroCusto() {
      this.dialogCentroCusto = false;
      setTimeout(() => {
        this.centroCustoItem = Object.assign({}, this.defaultCentroCustoItem);
        this.centroCustoIndex = -1;
        this.centroCustoSelecionado = {};
        this.$refs.formCentroCusto.resetValidation();
      }, 300);
    },
    saveCentroCusto() {
      if (this.$refs.formCentroCusto.validate()) {
        var soma = 0;
        if (this.centroCustoIndex > -1) {
          Object.assign(this.centrosCustoOperacao[this.centroCustoIndex], this.centroCustoItem);
          this.centrosCustoOperacao.forEach(element => {
            soma += parseFloat(element.percentual);
            if (soma > 100) {
              element.percentual = element.percentual + (100 - soma);
            }
          });
        } else {
          soma = parseFloat(this.centroCustoItem.percentual);
          this.centrosCustoOperacao.forEach(element => {
            soma += parseFloat(element.percentual);
          });
          if (soma > 100) {
            var vlr = 100 / (this.centrosCustoOperacao.length + 1);
            soma = vlr;
            this.centroCustoItem.percentual = vlr;
            vlr = parseFloat(vlr.toFixed(4));
            this.centrosCustoOperacao.forEach(element => {
              soma += vlr;
              element.percentual = vlr;
            });
          }

          if (soma > 100) {
            this.centroCustoItem.percentual = this.centroCustoItem.percentual + (100 - soma);
          }
          this.centrosCustoOperacao.push(this.centroCustoItem);
        }
        this.closeCentroCusto();
        this.centroCustoItem = Object.assign({}, this.defaultCentroCustoItem);
        this.centroCustoIndex = -1;
        this.centroCustoSelecionado = {};
        this.$refs.formCentroCusto.resetValidation();
        this.validacao.centrosCusto = false;
      }
    },

    marcarDesmarcarTodos(key) {
      const vlr = parseFloat(this.operacao.vlrLiquido);

      if (key == 1 && this.vlrPermutado >= vlr) {
        key = 0;
        this.mensagem = "Valor permutado já é suficiente para quitar o valor da operação";
        this.snackErro = true;
        return;
      }

      this.vlrPermutado = 0;
      var itens = 0;
      this.permutas.forEach(element => {
        if (key == 1 && this.vlrPermutado >= vlr) {
          element.selecionado = 0;
          this.mensagem = "Valor permutado já é suficiente para quitar o valor da operação";
          this.snackErro = true;
        } else {
          element.selecionado = key;
          if (element.selecionado) {
            itens++;
            this.vlrPermutado += element.valor + element.descAcr;
          }
        }
      });
      if (itens) {
        this.rodapePermutas.pageText = itens + " selecionado" + (itens > 1 ? "s" : "") + " = " + util.formatCurrency(this.vlrPermutado);
      } else {
        this.rodapePermutas.pageText = "";
      }
      //* incluiu ou excluiu permuta, parcelas devem ser recriadas, pois serão de valores diferentes
      this.parcelas = [];
      this.formaPagamentoSelecionada = { id: null, nome: "", tipo: "" };
      this.validacao.permutas = false;
    },
    ativarDesativarItem(item) {
      if (item.selecionado == 0 && this.vlrPermutado >= parseFloat(this.operacao.vlrLiquido)) {
        this.mensagem = "Valor permutado já é suficiente para quitar o valor do anúncio";
        this.snackErro = true;
        return;
      }
      var dadoIndex = this.permutas.indexOf(item);
      var dadoItem = Object.assign({}, item);
      dadoItem.selecionado = dadoItem.selecionado == 1 ? 0 : 1;
      Object.assign(this.permutas[dadoIndex], dadoItem);

      this.vlrPermutado = 0;
      var itens = 0;
      this.permutas.forEach(element => {
        if (element.selecionado) {
          itens++;
          this.vlrPermutado += element.valor + element.descAcr;
        }
      });
      if (itens) {
        this.rodapePermutas.pageText = itens + " selecionado" + (itens > 1 ? "s" : "") + " = " + util.formatCurrency(this.vlrPermutado);
      } else {
        this.rodapePermutas.pageText = "";
      }
      //* incluiu ou excluiu permuta, parcelas devem ser recriadas, pois serão de valores diferentes
      this.parcelas = [];
      this.formaPagamentoSelecionada = { id: null, nome: "", tipo: "" };
      this.validacao.permutas = false;
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    filtrarObjetosIdentificacao(item, queryText, itemText) {
      return util.filtrarObjetosIdentificacao(item, queryText, itemText);
    },
    pLetra(text) {
      return util.pLetra(text);
    },
    valida_nome(text) {
      return util.valida_nome(text);
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    },
    formatCurrency4(valor) {
      return util.formatCurrency4(valor);
    },
    valida_data(date) {
      if (date) {
        return util.valida_data_BR(date);
      } else {
        return true;
      }
    },
    formatDateUS(date) {
      if (date && date.length == 10) {
        return util.formatDateUS(date);
      }
    },
    valida_data_vecto(date) {
      if (!this.dtOperacaoUS) {
        return false;
      }
      if (date) {
        var retorno = util.valida_data_BR(date);
        if (!retorno) {
          return false;
        }
        const dtOp = util.parseDate(this.dtOperacao);
        const dtV = util.parseDate(date);
        if (dtV < dtOp) {
          return false;
        }
        return true;
      } else {
        return true;
      }
    }
  },
  computed: {
    receber() {
      return this.$route.path.indexOf("/receber") > 1;
    }
  },
  watch: {
    clienteSelecionado(val) {
      if (val) {
        this.recarregarItensCliente(val);
      } else {
        this.clientes = [];
        this.operacao.idCliente = null;
      }
    },
    contaDSelecionada(val) {
      this.operacao.idContaD = val ? val.id : null;
      this.defineCentroCusto({
        id: null,
        idCentroCusto: val ? val.idCentroCusto : null,
        nome: val ? val.centroCusto : null,
        percentual: 100
      });
    },
    async contaCSelecionada(val) {
      this.semFornecedor = false;
      if (
        !this.receber &&
        val &&
        (!val.tipo ||
          ![
            "FORNECEDOR",
            "COMISSAO",
            "PERMUTA_PAGAR",
            // "CREDITO", descontinuado
            "PACOTE"
          ].includes(val.tipo))
      ) {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente/site");
        if (response.data) {
          this.clientes.push({
            id: response.data.id,
            nome: response.data.nome,
            diaVencimento: 0
          });
          this.clienteSelecionado = {
            id: response.data.id,
            nome: response.data.nome,
            diaVencimento: 0
          };
          this.semFornecedor = true;
        } else {
          this.contaCSelecionada = {};
          this.mensagem = "Cadastre um fornecedor com a identificação do Jornal";
          this.snackErro = true;
          return;
        }
      }
      this.operacao.idContaC = val ? val.id : null;
      this.defineCentroCusto({
        id: null,
        idCentroCusto: val ? val.idCentroCusto : null,
        nome: val ? val.centroCusto : null,
        percentual: 100
      });
    },
    agenteSelecionado(val) {
      this.validacao.agente = "";
      this.agenteItem.idAgente = val.idAgente;
      this.agenteItem.agente = val.agente;
    },
    centroCustoSelecionado(val) {
      this.centroCustoItem.idCentroCusto = val.id;
      this.centroCustoItem.nome = val.nome;
    },
    dialogParcela(val) {
      if (val == null) {
        this.carregarParcelasOperacao();
      }
      val || this.closeParcela();
    },
    dialogCentroCusto(val) {
      val || this.closeCentroCusto();
    },
    dtOperacaoUS() {
      this.dtOperacao = util.formatDate(this.dtOperacaoUS);
      this.mnuDtOperacao = false;
    },
    dtVectoUS() {
      this.dtVecto = util.formatDate(this.dtVectoUS);
      this.mnuDtVecto = false;
    },
    ajusteItemDtVectoOUS() {
      this.ajusteItemDtVectoO = util.formatDate(this.ajusteItemDtVectoOUS);
      this.mnuAjusteItemDtVectoO = false;
    },
    formaPagamentoSelecionada(val) {
      if (val) {
        this.idClienteEndereco = null;
        this.idClienteCartao = null;
        this.idClienteConta = null;
        this.idClienteCelesc = null;
        this.idClientePix = null;
        if (val.tipo == "BOLETO" || val.tipo == "CARTEIRA") {
          this.carregarEnderecos();
        }
        if (val.tipo == "CARTAO_CREDITO") {
          this.carregarCartoes();
        }
        if (val.tipo == "CELESC" || val.tipo == "SAMAE") {
          this.carregarCelescs();
        }
        if (val.tipo == "DEBITO_CONTA") {
          this.carregarContas();
        }
        if (val.tipo == "PIX") {
          this.carregarPixs();
        }
        this.validacao.parcelas = false;
      }
    }
  },
  async created() {
    this.isUpdating = false;
    this.bloqueiaBotoes = true;
    this.id = this.$route.params.id ? this.$route.params.id : "";

    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (this.receber) {
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CONTAS_RECEBER_CRIAR") {
          this.direitoCriar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ACESSO") {
          this.direitoParcelas = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CONTAS_PAGAR_EDITAR") {
          this.direitoEditarOpPermuta = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CONTAS_RECEBER_CANCELAR") {
          this.direitoCancelar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CLIENTES_EDITAR") {
          this.direitoCliente = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_ASSINANTES_EDITAR") {
          this.direitoAssinante = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "COMERCIAL_ANUNCIANTES_EDITAR") {
          this.direitoAnunciante = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_BANCAS_EDITAR") {
          this.direitoBanca = true;
        }
      } else {
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CONTAS_PAGAR_CRIAR") {
          this.direitoCriar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_PAGAR_ACESSO") {
          this.direitoParcelas = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_PAGAR_EDITAR_CONFIRMADA") {
          this.direitoEditarConfirmada = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CONTAS_RECEBER_EDITAR") {
          this.direitoEditarOpPermuta = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_CONTAS_PAGAR_CANCELAR") {
          this.direitoCancelar = true;
        }
        if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_FORNECEDORES_EDITAR") {
          this.direitoFornecedor = true;
        }
      }
    });

    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    await this.carregarFormasPgto();
    await this.carregarCentrosCusto();

    if (this.receber) {
      await this.carregarAgentes();
    }

    //* Se é edição, carrega o item
    if (this.id == "") {
      if (this.receber) {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_CONTAS_RECEBER_CRIAR"]);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_CONTAS_PAGAR_CRIAR"]);
      }
      this.formaPagamentoSelecionar = true;
      this.operacao.tipo = this.receber ? 1 : 0;
      this.dtVectoUS = util.dateToStringUS(new Date());
      this.dtOperacaoUS = util.dateToStringUS(new Date());

      if (this.receber) {
        await this.carregarReceitas();
        await this.carregarOrigem();
      } else {
        await this.carregarDespesas();
        await this.carregarDestino();
      }
    } else {
      if (this.receber) {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_CONTAS_RECEBER_EDITAR"]);
      } else {
        this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_CONTAS_PAGAR_EDITAR"]);
      }
      try {
        //* Carrega o item - somente se for público (idSite=null) ou for do site do usuário logado
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/operacao/" + this.id + "?idSite=" + this.idSitePrincipal);
        if (!response.data) {
          this.$router.push("/financeiro/operacoes/" + (this.receber ? "receber" : "pagar"));
        } else {
          this.operacao = response.data;
          if (this.operacao.tipo == 2) {
            this.$router.push("/financeiro/operacoes/" + (this.receber ? "receber" : "pagar"));
          } else if ((this.receber && this.operacao.tipo == 0) || (!this.receber && this.operacao.tipo == 1)) {
            this.$router.push("/financeiro/operacoes/" + (this.receber ? "receber" : "pagar"));
          }
          if (!this.receber && !this.operacao.confirmada && this.direitoCancelar) {
            this.formaPagamentoSelecionar = true;
          }

          if (this.operacao.idAnuncio || this.operacao.idPacote || this.operacao.idRenovacao) {
            this.editar = false;
          }
          this.vlrPermutado = this.operacao.vlrPermutado;

          this.dtOperacaoUS = this.operacao.dtOperacao;
          if (this.operacao.tipo == 0 && this.operacao.confirmada) {
            await this.existeParcelaEmCobranca();
          }
          await this.carregarParcelasOperacao();
          if (this.receber) {
            await this.carregarAgentesOperacao();
          } else {
            this.dtVectoUS = util.dateToStringUS(this.parcelas[0].dtVecto);
          }
          await this.carregarReceitaDespesaOperacao();
          await this.carregarCentrosCustoOperacao();
          await this.carregarLancamentos();

          if (this.operacao.origem == "AVU") {
            await this.carregarItensReparte();
          }

          this.clientes.push({
            id: this.operacao.idCliente,
            nome: this.operacao.cliente,
            diaVencimento: this.operacao.diaVencimento
          });
          this.clienteSelecionado = {
            id: this.operacao.idCliente,
            nome: this.operacao.cliente,
            diaVencimento: this.operacao.diaVencimento
          };

          //* Se NF de parcela desta operação foi emitida, não permitir alterar os campos que estavam liberados para alteração (inclusive agentes)
          const responseNF = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/parcela/comNF/" + this.id + "?idSite=" + this.idSitePrincipal);
          if (responseNF.data && responseNF.data.idOperacao) {
            this.nfNaoEmitida = false;
          }

          this.$refs.focusDescricao.focus();
        }
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os dados da operação";
        this.snackErro = true;
      }
    }
    setTimeout(() => {
      this.bloqueiaBotoes = false;
    }, 1000);
  }
};
</script>
