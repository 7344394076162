<template>
  <div class="app-box" id="app">
    <v-app id="inspire">
      <v-card>
        <v-flex xs12>
          <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
        </v-flex>
        <v-row>
          <v-col cols="12">
            <!-- Conteúdo da coluna da direita com rolagem -->
            <div style="height: 50vh; overflow-y: auto" ref="scrollContainer">
              <v-card>
                <v-toolbar color="#333333" dark>
                  <v-icon class="mr-2">format_list_numbered</v-icon>
                  <v-toolbar-title class="font-weight-light"> Artigos {{ nomeEditoriaSelecionada }} </v-toolbar-title>
                  <v-spacer></v-spacer>

                  <v-btn v-if="direitoCriar && liberaAdd" color="success" @click="criarArtigo()" fab small>
                    <v-icon title="Incluir">add</v-icon>
                  </v-btn>
                </v-toolbar>

                <div class="px-6 mt-4">
                  <v-container fluid grid-list-md>
                    <v-layout wrap>
                      <v-flex md6 xs12>
                        <v-row>
                          <v-col cols="auto">
                            <v-autocomplete
                              ref="editoriaAutocomplete"
                              v-model="selectedEditoria"
                              :items="registroEditorias"
                              item-text="nome"
                              :item-value="(editoria) => ({ id: editoria.id, nome: editoria.nome, tipo: editoria.tipo })"
                              label="Editoria"
                              no-data-text="Nenhuma editoria disponível"
                              @change="carregarArtigos"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="auto">
                            <v-btn color="blue" fab small v-if="selectedEditoria != null" @click="pesquisaDados(true)">
                              <v-icon color="white" title="Atualizar">refresh</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-flex>

                      <v-flex md6 xs12>
                        <v-text-field
                          background-color="white"
                          class="mt-2 mb-2 elevation-0"
                          v-model="filtroPesquisa"
                          append-icon="search"
                          label="Digite para pesquisar..."
                          placeholder="Pesquisa pelo nome"
                          hide-details
                          @keyup.enter="limpaFiltro"
                          @click:append="limpaFiltro"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-container>

                  <v-data-table
                    mobile-breakpoint="0"
                    :headers="headers"
                    :items="registros"
                    :options.sync="options"
                    :no-data-text="mensagemPesquisa"
                    :footer-props="rodape"
                    :disable-sort="carregando"
                    show-expand
                  >
                    <!--      :disable-pagination="carregando" -->

                    <template v-slot:item="{ item, index }">
                      <tr
                        @click="selecionarItem(item)"
                        :class="['text-left', index % 2 === 0 ? 'linha-clara linha-destaque' : 'linha-escura linha-destaque']"
                        class="text-left principal"
                      >
                        <td v-if="direitoEditar && idArtigoaberto === item.idMateria" class="btns-acoes">
                          <v-icon class="v-icon-list" v-if="direitoEditar && idArtigoaberto === item.idMateria" @click="toggleExpand(item.idMateria)">
                            {{ isExpanded(item.idMateria) ? "mdi-chevron-up" : "mdi-chevron-down" }}
                          </v-icon>

                          <v-icon
                            class="v-icon-list"
                            v-if="direitoEditar && idArtigoaberto === item.idMateria && !edtLixeiraControl"
                            color="blue"
                            title="Editar"
                            @click="editarArtigo(item.idMateria)"
                            >edit</v-icon
                          >
                          <v-icon
                            class="v-icon-list"
                            v-if="direitoExcluir && idArtigoaberto === item.idMateria && !edtLixeiraControl"
                            color="error"
                            title="Remover"
                            @click="dialogConfirmacao = true"
                            >delete</v-icon
                          >
                          <v-icon
                            class="v-icon-list"
                            v-if="direitoExcluir && idArtigoaberto === item.idMateria && !edtLixeiraControl"
                            color="blue"
                            title="Histórico"
                            @click="mostrarModalHistorico"
                            >history</v-icon
                          >
                          <v-icon
                            class="v-icon-list"
                            v-if="direitoExcluir && !item.filhas && idArtigoaberto === item.idMateria && !edtLixeiraControl"
                            color="blue"
                            title="Copiar Artigo"
                            @click="mostrarModalCopiar = true"
                            >content_copy</v-icon
                          >

                          <!-- EDITORIA LIXEIRA -->
                          <v-icon class="v-icon-list" v-if="direitoExcluir && edtLixeiraControl" color="blue" title="Restaurar Artigo" @click="fnRestaurarArtigo(item.idMateria)"
                            >replay</v-icon
                          >
                        </td>
                        <td>{{ item.nomeMateria }}</td>
                        <td>{{ item.versao }}</td>
                        <td>{{ item.autorMateria }}</td>
                        <td>{{ item.dataCriacao }}</td>
                        <td>{{ item.dataPublicacao }}</td>
                      </tr>
                      <!-- Renderiza a tabela expandida apenas se a linha estiver expandida -->
                      <tr v-if="isExpanded(item.idMateria)" class="sub-tabela text-left">
                        <td colspan="12">
                          <!-- Tabela interna (Expandida) -->
                          <v-data-table :headers="subHeaders" :items="getExpandedData(item.idMateria)" dense hide-default-footer class="mt-3">
                            <template v-slot:item="{ item }">
                              <tr @click="selecionarSubItem(item.idConteudo)">
                                <td>
                                  <v-icon
                                    v-if="direitoEditar && idArtigoaberto === item.idMateria && !edtLixeiraControl"
                                    color="blue"
                                    title="Editar"
                                    @click="editarArtigoVersao(item.idMateria, item.idConteudo)"
                                    >edit</v-icon
                                  >
                                </td>
                                <td>{{ item.dataVersao }}</td>
                                <td>{{ item.dataCriacao }}</td>
                              </tr>
                            </template>
                          </v-data-table>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>
                <div class="text-center pt-2 marg-bt">
                  <!--        <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination> -->
                </div>

                <v-snackbar v-model="snackbar" color="info" :timeout="5000" bottom left>{{ mensagem }}</v-snackbar>
                <v-snackbar v-model="snackErro" color="error" :timeout="5000" bottom left>{{ mensagem }}</v-snackbar>
              </v-card>
            </div>

            <v-divider></v-divider>

            <!-- Modal Copiar Artigo -->
            <v-dialog v-model="mostrarModalCopiar" max-width="800px">
              <v-card>
                <v-card-title class="headline">Copiar Artigo</v-card-title>
                <v-card-text>
                  <v-alert v-if="tituloErro" type="error" prominent icon="mdi-alert-circle-outline">
                    {{ mensagem }}
                  </v-alert>
                  <v-form>
                    <v-text-field v-model="newArtigoCopia.novoNome" label="Nome do Artigo"></v-text-field>
                    <v-autocomplete
                      v-model="selectedEditoriaCopia"
                      :items="filteredEditoriasCopia"
                      item-text="nome"
                      :item-value="(copiaEditoria) => ({ id: copiaEditoria.id, nome: copiaEditoria.nome, tipo: copiaEditoria.tipo })"
                      label="Editoria"
                      no-data-text="Nenhuma editoria disponível"
                      @change="newArtigoEditoriaId"
                    ></v-autocomplete>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="mostrarModalCopiar = false" color="orange lighten-2 white" id="btn-rsn" style="color: white">
                    <v-icon id="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
                  </v-btn>

                  <v-btn :loading="isUpdating" color="blue white" id="btn-rsn" style="color: white" depressed @click="salvarCopiaArtigo()">
                    <v-icon id="icon-rsn" left>update</v-icon>Copiar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Modal Copiar Artigo -->

            <!-- Modal Histórico -->
            <v-dialog v-model="mostrarHistorico" max-width="800px">
              <v-card>
                <v-card-title class="headline">Histórico</v-card-title>
                <v-card-text>
                  <v-data-table :headers="headersHistorico" :items="registroVisualizarHistorico">
                    <template v-slot:item.nomeOriginal="{ item }">
                      {{ item.nomeOriginal }}
                    </template>

                    <template v-slot:item.novoNome="{ item }">
                      {{ item.novoNome }}
                    </template>
                  </v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" dark @click="mostrarHistorico = false">Fechar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Modal Histórico -->

            <!-- MODAL AVISOS -->
            <v-dialog v-model="dialogAviso" persistent max-width="500px">
              <v-card>
                <v-card-title class="headline">Aviso</v-card-title>
                <v-card-text>
                  {{ mensagemAviso }}
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialogAviso = false">OK</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- MODAL AVISOS -->

            <!-- MODAL CONFIRMA LIXEIRA -->
            <v-dialog v-model="dialogConfirmacao" max-width="500px">
              <v-card>
                <v-card-title class="headline">Confirmação</v-card-title>
                <v-card-text> Deseja mover esse artigo para a lixeira? </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialogConfirmacao = false">Cancelar</v-btn>
                  <v-btn color="red" text @click="moverParaLixeira">Sim</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- MODAL CONFIRMA LIXEIRA -->

            <div>
              <div class="output-area pa-6">
                <div v-html="htmlContent" class="output-field"></div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
import util from "../../util.js";

export default {
  data() {
    return {
      headers: [
        { text: "", value: "data-table-expand" },
        { text: "Nome da Matéria", value: "nomeMateria" },
        { text: "Versão", value: "versao" },
        { text: "Autor", value: "autorMateria" },
        { text: "Data Criação", value: "dataCriacao" },
        { text: "Data Publicação", value: "dataPublicacao" }
      ],

      /* sub tabela */
      subHeaders: [
        { text: "Ações", value: "actions" },
        { text: "Data Versão", value: "dataVersao" },
        { text: "Data de Criação", value: "dataCriacao" }
      ],
      registrosConteudoVersoes: [],
      expandedRow: null, // Controla a linha expandida, // Para controlar as linhas expandidas
      /* sub tabela */

      headersHistorico: [
        { text: "Operação", value: "operacao" },
        { text: "Nome Original", value: "nomeOriginal" },
        { text: "Editoria Origem", value: "idEditoriaOrigem" },
        { text: "Novo Nome", value: "novoNome" },
        { text: "Nova Editoria", value: "editoriaDestino" },
        { text: "Data Operação", value: "dataOperacao" },
        { text: "Realizado por", value: "realizadoPor" }
      ],

      rodape: {
        itemsPerPageText: "Registros por página:",
        itemsPerPageOptions: [100],
        pageText: `{0}-{1} de {2}` // Texto do rodapé mostrando a paginação
      },
      validacao: {
        id: false,
        idSite: false,
        idSuperior: false,
        ordem: false,
        nome: false,
        aliasInternet: false,
        tempoLimpeza: false,
        tipo: false,
        situacao: false,
        mensagemErro: false
      },
      nameEditoria: "",
      direitoCriar: false,
      direitoEditar: false,
      direitoExcluir: false,
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 50,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      mensagem: "",
      direitoCriar: false,
      elementoValid: true,
      id: "",
      snackbar: false,
      snackErro: false,
      isUpdating: false,
      options: {},
      mostrarHistorico: false, // Controla a visibilidade do modal

      registroEditorias: [],
      selectedEditoria: null,
      registroEditoriasFilt: [],
      registroEditoriasCopia: [],

      mostrarModalCopiar: false, //Modal copiar
      edtLixeiraControl: false,
      tituloErro: false,
      idArtigoaberto: "",

      dialogAviso: false,
      mensagemAviso: "",
      itemIdSelecionado: null, // ID do item selecionado
      registrosIndex: [], // Array para armazenar os índices dos registros

      idEditoriaSelecionada: "",
      nomeEditoriaSelecionada: "",
      tipoEditoriaSelecionada: "",
      liberaAdd: false,
      registroVisualizarTexto: "",
      registroVisualizarHistorico: [],

      htmlContent: "",

      dialogConfirmacao: false, // Variável para controlar o diálogo

      selectedEditoriaCopia: null,
      idEditoriaDestinoTipo: "",
      newArtigoCopia: {
        idMateria: "0",
        idEditoriaDestino: "",
        idUsuario: this.$store.state.usuario.id,
        novoNome: "",
        origemCopia: 0,
        altura: "0"
      },
      isUpdating: false,
      idSitePrincipal: "41", // TODO AJUSTAR ID SITE - ID USUÁRIO

      registrosPorPagina: 10, // Número inicial de registros por página
      hasMore: true // Indica se há mais dados para carregar
    };
  },

  async mounted() {
    this.carregarEditorias();

    console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&");
    console.log(this.registroEditorias.length);
    this.$nextTick(() => {
      this.$refs.editoriaAutocomplete.focus(); // Foco no autocomplete editorias
    });
    this.$nextTick(() => {
      this.$refs.scrollContainer.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeDestroy() {
    this.$refs.scrollContainer.removeEventListener("scroll", this.handleScroll);
  },

  computed: {
    //FILTRA SITUAÇÃO == 0 EDITORIA
    filteredEditoriasCopia() {
      return this.registroEditorias.filter((editoria) => editoria.tipo != 2);
    }
    //FILTRA SITUAÇÃO == 0 EDITORIA
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    getItemActions() {
      return "item.actions";
    },
    getItemNumero() {
      return "item.numero";
    },
    getItemActions2() {
      return "item.actions2";
    },
    getItemTipo() {
      return "item.tipo";
    },
    getItemSituacao() {
      return "item.situacao";
    },
    getItemExpand() {
      return "item.data-table-expand";
    },
    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados(true);
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },

    /* sub tabela */
    // Filtra os dados da tabela expandida com base no idMateria
    getExpandedData(idMateriaExpand) {
      console.log("1111111111111111121212");
      return this.registrosConteudoVersoes.filter((registros) => registros.idMateria === idMateriaExpand);
    },
    // Verifica se a linha está expandida
    isExpanded(idMateriaExpand) {
      return this.expandedRow === idMateriaExpand;
    },
    // Alterna a expansão da linha
    toggleExpand(idMateriaExpand) {
      console.log("33333333333");

      this.expandedRow = this.expandedRow === idMateriaExpand ? null : idMateriaExpand;
    },
    /* sub tabela */

    temSinalELetraMaiuscula() {
      const regex = /<([A-Z])/;
      return regex.test(this.htmlContent);
    },

    async salvarCopiaArtigo() {
      await util.limparObjeto(this.validacao, false);
      if (this.selectedEditoriaCopia == null) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        this.isUpdating = false;
        return;
      }

      console.log(this.newArtigoCopia), console.log("@@@@@@@@@@@@@@@@@@@@@@@@@");

      console.log(this.idEditoriaDestinoTipo);
      console.log("##################");
      console.log(this.htmlContent);

      if (this.idEditoriaDestinoTipo == 3) {
        //Se for de produção entra aqui
        console.log("PRODUÇÃOOOOOOOOOOOOOOOOOOOOOOOOO");

        console.log(this.htmlContent);

        if (this.htmlContent.includes("&lt;") || this.htmlContent.includes("&gt;")) {
          console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");
          console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");

          console.log(" EXISTE <> PRODUÇÃOOOOOOOOOOOOOOOOOOOOOOOOO");
          this.isUpdating = true;
          try {
            var retorno = "";
            retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "edt/editorial/enviaMateriaProducao", this.newArtigoCopia);

            if (!retorno.data.erro) {
              console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&");
              console.log(retorno.data);
              console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&");
              this.mensagem = "Artigo foi copiado";
              this.snackbar = true;
              this.mostrarModalCopiar = false;
            } else {
              console.log("##########################");
              console.log(retorno.data.descricao);
              console.log("#########################");
              this.tituloErro = true;
              this.mensagem = retorno.data.descricao;
            }
          } catch (e) {
            console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
            console.log(e);
            if (e.toString().includes("status code 400")) {
              this.mensagem = "SESSÃO EXPIRADA";
              this.snackErro = true;
            } else if (e.response && e.response.data) {
              util.validaErros(this.validacao, e.response.data);
              this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
              this.snackErro = true;
            } else {
              this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
              this.snackErro = true;
            }
          } finally {
            this.isUpdating = false;
          }
        } else {
          console.log("NÂO TEMMMMMMMMMMMMMMMMMMMMMMMMM <><><><><><<<");
          this.mensagemAviso = "Falta estilo nessa matéria, ela não pode ser enviada para produção.";
          this.dialogAviso = true;
        }
      } else {
        //Editorias que não são de produção
        console.log("OUTROSSSSSSSSSSSSSSSSSS");
        this.isUpdating = true;
        try {
          var retorno = "";
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "edt/editorial/copiaMateria", this.newArtigoCopia);

          if (!retorno.data.erro) {
            console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&");
            console.log(retorno.data);
            console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&");
            this.mensagem = "Artigo foi copiado";
            this.snackbar = true;
            this.mostrarModalCopiar = false;
          } else {
            console.log("##########################");
            console.log(retorno.data.descricao);
            console.log("#########################");
            this.tituloErro = true;
            this.mensagem = retorno.data.descricao;
          }
        } catch (e) {
          console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
          console.log(e);
          if (e.toString().includes("status code 400")) {
            this.mensagem = "SESSÃO EXPIRADA";
            this.snackErro = true;
          } else if (e.response && e.response.data) {
            util.validaErros(this.validacao, e.response.data);
            this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
            this.snackErro = true;
          } else {
            this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
            this.snackErro = true;
          }
        } finally {
          this.isUpdating = false;
        }
      } //Editoria não produção
    },

    async newArtigoEditoriaId(selectEditoriaCopia) {
      if (selectEditoriaCopia != null) {
        console.log("ID enviado:", selectEditoriaCopia.id, selectEditoriaCopia.nome, selectEditoriaCopia.tipo, this.selectedEditoriaCopia);
        this.newArtigoCopia.idEditoriaDestino = selectEditoriaCopia.id;
        this.idEditoriaDestinoTipo = selectEditoriaCopia.tipo;
      }
    },

    // ... outras funções ...
    selecionarItem(item) {
      this.pesquisaVersoes();

      this.visualizarTexto(item.idMateria);

      if (item.idMateria != this.itemIdSelecionado) {
        this.expandedRow = null;
      }

      console.log(item);
      console.log("#######################################################" + item.nomeMateria);
      this.itemIdSelecionado = item.idMateria;
      this.newArtigoCopia.novoNome = item.nomeMateria;
      this.newArtigoCopia.idMateria = item.idMateria;
      this.registrosIndex = this.registros.map((reg, index) => ({ id: reg.idMateria, index }));
    },

    selecionarSubItem(idConteudo) {
      this.visualizarTextoVersao(idConteudo);
      console.log(idConteudo);
      console.log("SUB ITEMMMMMMMMMMMMMM" + idConteudo);
      /*       this.itemIdSelecionado = item.idMateria;
      this.newArtigoCopia.novoNome = item.nomeMateria;
      this.newArtigoCopia.idMateria = item.idMateria;
      this.registrosIndex = this.registros.map((reg, index) => ({ id: reg.idMateria, index })); */
    },
    // ... outras funções ...

    //  ------------------  HISTORICO DATALHES  ------------------------------
    async mostrarModalHistorico() {
      console.log(this.idArtigoaberto);
      const bodyHistorico = {
        idMateria: this.idArtigoaberto
      };
      //Organiza e exibie as editorias na lateral
      this.registroVisualizarHistorico = []; // Limpa o array antes de buscar os dados
      try {
        // Faz a pesquisa no banco de dados
        const response = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editorial/buscaRegistrosMateria", bodyHistorico);
        // Seta as variáveis com o retorno
        this.registroVisualizarHistorico = response.data.registros; // Atribui os dados ao array

        console.log(response.data.registros, "@@@@@@@@@@@@@@@@@@@");
        this.mostrarHistorico = true; // Mostra o modal
      } catch (error) {
        console.error("Erro ao buscar editorias:", error);
        // Tratar o erro (exibir mensagem ao usuário, etc.)
      }
    },

    async visualizarTexto(idArtigo) {
      const body = {
        idMateria: idArtigo,
        conteudoAcervo: "0"
      };

      //Organiza e exibie as editorias na lateral
      this.registroVisualizarTexto = ""; // Limpa o array antes de buscar os dados
      try {
        // Faz a pesquisa no banco de dados
        const response = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editorial/buscaConteudoMateria", body);
        // Seta as variáveis com o retorno
        this.htmlContent = response.data.conteudo.conteudo;

        this.idArtigoaberto = idArtigo; //atualiza o ID do artigo que foi aberto - usado para mostraro histórico
        console.log(response.data.conteudo);
      } catch (error) {
        console.error("Erro ao buscar editorias:", error);
        // Tratar o erro (exibir mensagem ao usuário, etc.)
      }
      // Converte e exibe texto plano
    },

    async visualizarTextoVersao(idVersao) {
      const body = {
        idConteudo: idVersao
      };

      //Organiza e exibie as editorias na lateral
      this.registroVisualizarTexto = ""; // Limpa o array antes de buscar os dados
      try {
        // Faz a pesquisa no banco de dados
        const response = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editorial/buscaConteudoVersao", body);
        // Seta as variáveis com o retorno
        this.htmlContent = response.data.conteudo.conteudo;

        console.log(response.data.conteudo);
      } catch (error) {
        console.error("Erro ao buscar editorias:", error);
        // Tratar o erro (exibir mensagem ao usuário, etc.)
      }
      // Converte e exibe texto plano
    },

    async carregarEditorias() {
      //Organiza e exibie as editorias na lateral
      this.registroEditorias = []; // Limpa o array antes de buscar os dados

      try {
        // Faz a pesquisa no banco de dados
        const response = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editoria/all");
        // Seta as variáveis com o retorno
        this.registroEditorias = response.data; // Atribui os dados ao array
        console.log(response.data);
        console.log("!!!!!!!!!!");
        // console.log(JSON.stringify(response.data.data) + "@@@@@@@@@@@@@@@@@@@");
      } catch (error) {
        console.error("Erro ao buscar editorias:", error);
        // Tratar o erro (exibir mensagem ao usuário, etc.)
      }
      //Organiza e exibie as editorias na lateral
    },

    criarArtigo() {
      window.open("/editorial/artigo/" + this.idEditoriaSelecionada + "/null/null", "_blank");
      //para listar as parcelas do retorno, teria que buscar as parcelas com join em log_parcela.idParcela e onde log_parcela.idLoteFaturamento = item.id - porém pode ficar muito lento com este join a mais
    },

    editarArtigo(idArtigoEdit) {
      window.open("/editorial/artigo/" + this.idEditoriaSelecionada + "/" + idArtigoEdit + "/null", "_blank");
      //para listar as parcelas do retorno, teria que buscar as parcelas com join em log_parcela.idParcela e onde log_parcela.idLoteFaturamento = item.id - porém pode ficar muito lento com este join a mais
    },

    editarArtigoVersao(idArtigoEdit, idVersaoEdit) {
      window.open("/editorial/artigo/" + this.idEditoriaSelecionada + "/" + idArtigoEdit + "/" + idVersaoEdit, "_blank");
      //para listar as parcelas do retorno, teria que buscar as parcelas com join em log_parcela.idParcela e onde log_parcela.idLoteFaturamento = item.id - porém pode ficar muito lento com este join a mais
    },

    async pesquisaDados(reset = false) {
      if (reset) {
        this.registros = []; // Limpa a lista se reset for true
        this.htmlContent = "";
        this.toggleExpand(null);
      } else {
        console.log("TAMANHO ARRAYYYYYYYYYY");
        console.log(this.registros.length);
        console.log(this.registros);
      }

      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";
      // console.log(this.idEditoriaSelecionada + " $$$$$$$$$$ " + this.nomeEditoriaSelecionada);

      // Verifica se a editoria é de lixeira
      let edtLixeira = 0;
      this.edtLixeiraControl = false;
      if (this.tipoEditoriaSelecionada == 2) {
        edtLixeira = 1;
        console.log("LIXEIRAAAAAAAAAAAAAAAAAAAAAAAAAA");
        this.edtLixeiraControl = true;
      }
      console.log("this.filtroPesquisa ");
      console.log(this.filtroPesquisa);
      console.log("TAMANHO ARRAYYYYYYYYYY");
      console.log(this.registros.length);

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "edt/editorial/buscaMaterias?idEditoria=" +
            this.idEditoriaSelecionada +
            "&excluida=" +
            edtLixeira +
            "&campoOrdenar=1&ordem=1&versoes=0&buscaQnt=0&inicio=" +
            this.registros.length +
            "&limite=" +
            this.registrosPorPagina +
            "&nomePesquisa=" +
            this.filtroPesquisa
        );

        //seta as variaveis com o retorno

        if (response.data.materias.length > 0) {
          this.hasMore = response.data.materias.length >= this.registrosPorPagina; // Verifica ANTES da filtragem

          // Filtra antes de adicionar
          const novosRegistros = response.data.materias.filter((novoItem) => !this.registros.some((existingItem) => existingItem.idMateria === novoItem.idMateria));
          this.registros.push(...novosRegistros);

          console.log(response.data.materias);
          console.log(response.data.materias[0].idMateria);

          if (reset) {
            this.idArtigoaberto = response.data.materias[0].idMateria;
            this.itemIdSelecionado = response.data.materias[0].idMateria;
            this.visualizarTexto(response.data.materias[0].idMateria);
            this.selecionarItem(response.data.materias[0]);
            console.log("AQUIIIIIIIIIIIIIIII AQUIIIIIIIIIIIIIIIIIIIIIIIIIII");
            console.log(response.data.materias[0]);
          }

          this.totalRegistros = registros.length;
          this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
          this.rodape.pageText = this.totalRegistros + " itens";
          //fecha o loading
          this.carregando = false;
        } else {
          this.hasMore = false;
        }
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },

    handleScroll() {
      const element = this.$refs.scrollContainer;
      if (element.scrollTop + element.clientHeight >= element.scrollHeight - 50 && this.hasMore) {
        this.pesquisaDados(false);
      }
    },

    async pesquisaVersoes() {
      this.registrosConteudoVersoes = [];
      //sinaliza que está carregando

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "edt/editorial/buscaMaterias?idMateria=" +
            this.itemIdSelecionado + //id artigo selecionado
            "&excluida=0&campoOrdenar=0&ordem=0&versoes=1"
        );

        //seta as variaveis com o retorno

        if (response.data.materias.length > 0) {
          // Converte o nomeMateria de Base64 para texto legível
          this.registrosConteudoVersoes = response.data.materias;
          // Converte o nomeMateria de Base64 para texto legível
        }
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },

    //Função executado assim que uma editoria é selecionada
    async carregarArtigos(selectEditoria) {
      if (selectEditoria && selectEditoria.id) {
        console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@:");
        console.log("ID enviado:", selectEditoria.id, selectEditoria.nome, selectEditoria.tipo);

        this.idEditoriaSelecionada = selectEditoria.id;
        this.nomeEditoriaSelecionada = selectEditoria.nome;
        this.tipoEditoriaSelecionada = selectEditoria.tipo;

        if (selectEditoria.tipo == 2 || selectEditoria.tipo == 3) {
          this.liberaAdd = false;
        } else {
          this.liberaAdd = true;
        }
        this.pesquisaDados(true);
      }
    },
    //Função ao fechar modal de copiar - limpa campos

    //Mover para lixeira
    async moverParaLixeira() {
      console.log("%%%%%%%%%%%");
      console.log(this.idArtigoaberto);

      // Se o usuário clicar em "OK", execute a lógica de mover para lixeira
      const bodyLixeira = {
        idMateria: this.idArtigoaberto,
        idUsuario: this.newArtigoCopia.idUsuario
      };
      //Organiza e exibie as editorias na lateral

      try {
        // Transfere para editoria lixeira
        const response = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editorial/enviaMateriaLixeira", bodyLixeira);

        console.log(response.data, "@@@@@@@@@@@@@@@@@@@");

        this.mensagemAviso = "Artigo movido para lixeira";
        this.dialogConfirmacao = false; // Fecha o diálogo
        this.dialogAviso = true;
        this.pesquisaDados(true);
      } catch (error) {
        console.error("Erro ao buscar editorias:", error);
        // Tratar o erro (exibir mensagem ao usuário, etc.)
      }
    },
    //Mover para lixeira

    //Função restaurar artigos
    async fnRestaurarArtigo(idArtigoRestaura) {
      console.log("%%%%%%%%%%%");
      console.log(idArtigoRestaura);

      // Se o usuário clicar em "OK", execute a lógica de mover para lixeira
      const bodyRestaura = {
        idMateria: idArtigoRestaura
      };
      //Organiza e exibie as editorias na lateral

      try {
        // Transfere para editoria lixeira
        const response = await api.post(process.env.VUE_APP_URL_WEB_API + "edt/editorial/restauraMateriaLixeira", bodyRestaura);

        console.log(response.data, "@@@@@@@@@@@@@@@@@@@");

        this.mensagemAviso = "Artigo foi restaurado!";
        this.dialogConfirmacao = false; // Fecha o diálogo
        this.dialogAviso = true;
        this.pesquisaDados(true);
      } catch (error) {
        console.error("Erro ao buscar editorias:", error);
        // Tratar o erro (exibir mensagem ao usuário, etc.)
      }
    }
    //Função restaurar artigos
  },

  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "EDITORIAL_EDITORIAS_ACESSO"]);
    //* Verifica se o usuário logado possui direito para incluir
    this.$store.state.usuario.direitos.forEach((element) => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "EDITORIAL_EDITORIAS_CRIAR") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "EDITORIAL_EDITORIAS_EDITAR") {
        this.direitoEditar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "EDITORIAL_EDITORIAS_EXCLUIR") {
        this.direitoExcluir = true;
      }
    });
  },

  watch: {
    mostrarModalCopiar() {
      //altera as variáveis quando altera o valor de mostrarModalCopiar
      if (!this.mostrarModalCopiar) this.newArtigoCopia.novoNome = "";
      this.selectedEditoriaCopia = null;
      this.tituloErro = false;
    },
    $route() {
      this.paginaAtual = 1;
      //this.pesquisaDados();
    },
    paginaAtual() {
      //this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        // this.pesquisaDados();
      }
    }
  }
};
</script>

<style>
td.btns-acoes {
  display: flex;
  margin-top: 7px !important;
}
.v-application p {
  margin-bottom: 0px !important;
}
img.img-artigo {
  max-width: 320px !important;
  width: 100% !important;
  height: auto !important;
}

.treeview-container {
  height: calc(100vh - 64px); /* Ajuste a altura descontando a toolbar */
  overflow-y: auto;
}
.app-box {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.v-toolbar__title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Estilo para as linhas principais */
.linha-clara {
  background-color: #f9f9f9; /* Cor clara para linhas ímpares */
}

.linha-escura {
  background-color: #ececec; /* Cor escura para linhas pares */
}

.linha-destaque:hover {
  background-color: #d1e7dd; /* Cor ao passar o mouse sobre a linha */
}

/* Estilo para os ícones */
.v-icon-list {
  margin-right: 5px;
  cursor: pointer;
}

.v-icon-list:hover {
  transform: scale(1.1); /* Efeito de aumento ao passar o mouse */
}

/* Estilo para a sub-tabela expandida */
.sub-tabela {
  background-color: #ffffff; /* Cor de fundo diferenciada para sub-tabela */
  border-top: 2px solid #007bff; /* Linha superior para destaque */
  padding: 10px; /* Espaçamento para sub-tabela */
}

/* Estilo para as linhas da sub-tabela */
.sub-tabela tr {
  background-color: #fff; /* Fundo branco para linhas da sub-tabela */
}

/* Estilo para as células da sub-tabela */
.sub-tabela td {
  padding: 8px 12px; /* Espaçamento interno das células */
  border-bottom: 1px solid #ddd; /* Linha divisória */
}
.principal td {
  padding: 6px !important; /* Ajuste o valor conforme necessário */
}
tr.sub-tabela td div {
  margin-bottom: 20px !important;
  margin-top: -10px;
}
</style>
