<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">mdi-account</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">Notas Fiscais Eletrônicas</v-toolbar-title>
          <v-spacer></v-spacer>
          <router-link v-if="direitoParcelas && direitoCriar" to="/financeiro/parcelas/receber?idNF=0" style="text-decoration: none">
            <v-btn color="success" fab small>
              <v-icon color="white" title="Criação de Nota Fiscal">add</v-icon>
            </v-btn>
          </router-link>
          <v-btn
            v-if="direitoExportar"
            class="ml-2"
            color="blue"
            fab
            small
            @click="
              dialogXML = true;
              carregarCompetencias();
            "
          >
            <v-icon color="white" title="Download XML's">cloud_download</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-dialog persistente v-model="dialogXML" max-width="400px">
            <v-card>
              <v-toolbar>
                <span class="headline">Download XML's</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialogXML = false">
                  <v-icon color="darken-1">cancel</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
                    </v-flex>
                    <v-flex xs12>
                      <v-autocomplete
                        v-model="competencia"
                        :items="competencias"
                        :filter="filtrarObjetos"
                        label="Selecione a Competência"
                        item-text="nome"
                        item-value="anoMes"
                        no-data-text
                        autocomplete="disabled"
                        :name="Math.random()"
                        return-object
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 v-if="mensagem">
                      <v-sheet :value="true" class="red--text">{{ mensagem }}</v-sheet>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn color="red darken-1" text @click="dialogXML = false">Retornar</v-btn>
                <v-btn color="green darken-1" text @click="downloadXML" :disabled="isUpdating">Download</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-layout wrap>
            <v-flex md1 xs3>
              <v-dialog v-model="dialogFiltroAvancado" max-width="900px">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" color="blue" fab small :disabled="loading || loadingExp">
                    <v-icon color="white" title="Filtro Avançado">search</v-icon>
                  </v-btn>
                  <v-btn
                    color="red lighten-2"
                    style="color:white"
                    class="btn-rsn ml-n2 mb-6"
                    id="btn-limpa-filtro"
                    fab
                    @click="filtroAvancadoLimpar"
                    :disabled="loading || loadingExp"
                  >
                    <v-icon class="icon-rsn" small center title="Limpar Filtro">clear</v-icon>
                  </v-btn>
                </template>
                <template>
                  <v-card>
                    <v-tabs v-model="tab" background-color="blue accent-4" center-active dark>
                      <v-tab>Nota Fiscal</v-tab>
                      <v-tab>Cliente</v-tab>
                      <v-tab v-if="$store.state.usuario.site_assinaturas">Assinatura</v-tab>
                      <v-tab v-if="$store.state.usuario.site_comercial">Anúncio</v-tab>
                      <v-tab>Parcela</v-tab>
                      <v-tab>Pesquisas</v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                      <v-tab-item>
                        <FiltroNF ref="filtroNF" prefixo v-bind:nfe="true" />
                      </v-tab-item>
                      <v-tab-item>
                        <FiltroCliente ref="filtroCliente" prefixo="c." />
                      </v-tab-item>
                      <v-tab-item :disabled="tabAnu" v-if="$store.state.usuario.site_assinaturas">
                        <FiltroAssinatura ref="filtroAssinatura" prefixo="a." />
                      </v-tab-item>
                      <v-tab-item :disabled="tabAss" v-if="$store.state.usuario.site_comercial">
                        <FiltroAnuncio ref="filtroAnuncio" prefixo="u." />
                      </v-tab-item>
                      <v-tab-item>
                        <FiltroParcela ref="filtroParcela" prefixo="p." />
                      </v-tab-item>
                      <v-tab-item>
                        <Pesquisas ref="pesquisas" prefixo="nfe" />
                      </v-tab-item>
                    </v-tabs-items>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="orange lighten-2 white" style="color:white" class="btn-rsn" @click="filtroAvancadoRetornar">
                        <v-icon class="icon-rsn" left>keyboard_arrow_left</v-icon>Retornar
                      </v-btn>
                      <v-btn outlined color="red white" style="color:white" class="btn-rsn" @click="filtroAvancadoLimpar">
                        <v-icon class="icon-rsn" left>clear</v-icon>Limpar
                      </v-btn>
                      <v-btn v-if="tab != 5" color="blue white" style="color:white" class="btn-rsn" depressed @click="limpaFiltro">
                        <v-icon class="icon-rsn" left>tune</v-icon>Filtrar
                      </v-btn>
                      <v-btn v-if="direitoExportar" outlined color="blue white" style="color:white" class="btn-rsn" depressed @click="filtroAvancadoFiltrar(true)">
                        <v-icon class="icon-rsn" left>description</v-icon>Exportar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex md1 xs2>
              <v-btn v-if="direitoExportar" color="blue" :loading="loading" :disabled="loading || loadingExp" fab small @click="filtroAvancadoFiltrar(true)">
                <v-icon color="white" title="Exportar">description</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs6 md2>
              <v-text-field
                background-color="white"
                class="mb-2 elevation-0"
                v-model="numeroPesquisa"
                v-mask="'#########'"
                append-icon="search"
                label="Número"
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
                autocomplete="disabled"
                :name="Math.random()"
                :disabled="loading || loadingExp"
              ></v-text-field>
            </v-flex>
            <v-flex xs6 md2>
              <v-text-field
                background-color="white"
                class="mb-2 elevation-0"
                v-model="identificacaoPesquisa"
                v-mask="'##############'"
                append-icon="search"
                label="Identificação"
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
                autocomplete="disabled"
                :name="Math.random()"
                :disabled="loading || loadingExp"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md2>
              <v-text-field
                background-color="white"
                class="mb-2 elevation-0"
                v-model="nomePesquisa"
                append-icon="search"
                label="Nome"
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
                autocomplete="disabled"
                :name="Math.random()"
                :disabled="loading || loadingExp"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon color="blue lighten-2" title="Visualizar Parcelas" @click="parcelas(item)">loupe</v-icon>
              <v-icon v-if="item.status != 'Emitir'" color="blue lighten-2" title="Visualizar Retorno" @click="visualizarRetorno(item)">find_replace</v-icon>
              <v-icon v-if="item.status == 'Erro' && !item.chaveRef" color="blue lighten-2" title="Reenviar" @click="reenviar(item)">call_made</v-icon>
              <!-- nota de baixa de remessa futura com erro: corrigir no xml e recoloca-lo para reenvio, senão vai pegar o valor atual da parcela -->
              <v-icon
                v-if="direitoCancelar && item.status == 'Processada' && item.protocolo && item.rf == 0 && item.chaveRef == null"
                color="pink darken-1"
                title="Corrigir"
                @click="acaoCorrigir(item)"
                >reply</v-icon
              >
              <v-icon
                v-if="direitoCancelar && item.status == 'Processada' && item.protocolo && item.rf == 0 && item.chaveRef == null"
                color="pink darken-1"
                title="Devolver"
                @click="devolver(item)"
                >undo</v-icon
              >
              <v-icon
                v-if="direitoCancelar && item.status == 'Processada' && item.protocolo && item.rf == 0 && item.chaveRef == null"
                color="pink darken-1"
                title="Estornar"
                @click="estornar(item)"
                >clear</v-icon
              >
              <v-icon v-if="direitoCancelar && item.status == 'Processada' && item.protocolo && item.chaveRef == null" color="red" title="Cancelar" @click="acaoCancelar(item)"
                >cancel</v-icon
              >
              <!-- item.rf == 0 && - a verificação se é remessa futura (ainda não baixada) será feita na api -->
            </template>

            <template v-slot:item.rf="{ item }">
              <span :class="item.rf == 'Remessa Futura' ? 'green--text' : 'blue--text'">{{ item.rf ? "Remessa Futura" : "Venda" }}</span>
            </template>

            <template v-slot:item.dtEmissao="{ item }">
              <span>{{ formatDateTime(item.dtEmissao) }}</span>
            </template>

            <template v-slot:item.status="{ item }">
              <span
                :class="
                  item.status == 'Processada'
                    ? 'green--text'
                    : item.status == 'Cancelada' || item.status == 'Denegada'
                    ? 'orange--text'
                    : item.status.startsWith('Erro')
                    ? 'red--text'
                    : item.status == 'Devolução' || item.status == 'Estorno'
                    ? 'blue--text'
                    : ''
                "
                >{{ item.status }}</span
              >
            </template>

            <template v-slot:item.bruto="{ item }">
              {{ formatCurrency(item.bruto) }}
            </template>
            <template v-slot:item.desconto="{ item }">
              {{ formatCurrency(item.desconto) }}
            </template>
            <template v-slot:item.liquido="{ item }">
              {{ formatCurrency(item.liquido) }}
            </template>

            <template v-slot:item.numeroRef="{ item }">
              <span>{{ item.numeroRef || item.ref }}</span>
            </template>

            <template v-slot:item.actions2="{ item }">
              <v-icon v-if="item.status == 'Processada' || item.status == 'Devolução' || item.status == 'Estorno'" color="teal" title="DANFE" @click="danfe(item)"
                >account_balance</v-icon
              >
              <v-icon v-if="item.status == 'Processada'" color="teal" title="E-mail" @click="nfEmail(item)">mail_outline</v-icon>
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 11" circle></v-pagination>
          </div>
        </div>
        <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
      </v-card>

      <v-dialog persistent v-model="dialogAcao" max-width="500">
        <v-card>
          <v-toolbar>
            <span class="headline">{{ acaoItem.tipoAcao }} Nota Fiscal {{ dadoItem.numero }}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeAcao">
              <v-icon color="darken-1">cancel</v-icon>
            </v-btn>
          </v-toolbar>
          <v-form ref="formAcao" v-model="acaoValid" lazy-validation>
            <v-container>
              <v-layout wrap>
                <v-flex xs12>
                  <v-textarea
                    v-model="acaoItem.texto"
                    :label="'Informe o ' + (acaoItem.tipoAcao == 'Cancelar' ? 'Motivo' : 'Texto da Correção')"
                    maxlength="255"
                    rows="5"
                    hint="Obrigatório"
                    :rules="[
                      validacaoAcao.texto == false || validacaoAcao.texto,
                      value => !!value || 'Obrigatório.',
                      value => (value != null && value.length > 15) || 'Campo não pode ficar em branco'
                    ]"
                    color="blue-grey lighten-2"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="closeAcao">Retornar</v-btn>
            <v-btn color="blue darken-1" @click="executarAcao">{{ acaoItem.tipoAcao }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogRetorno" max-width="500">
        <v-card>
          <v-toolbar>
            <span class="headline">Retorno da Nota Fiscal {{ dadoItem.numero }}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeRetorno">
              <v-icon color="darken-1">cancel</v-icon>
            </v-btn>
          </v-toolbar>
          <v-container>
            <v-layout wrap>
              <v-flex xs12>
                <v-textarea v-model="dadoItem.retorno" label="Descrição do Retorno" rows="5" color="blue-grey lighten-2" readonly></v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="closeRetorno">Retornar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import FiltroCliente from "@/views/components/FiltroCliente.vue";
import FiltroAssinatura from "@/views/components/FiltroAssinatura.vue";
import FiltroAnuncio from "@/views/components/FiltroAnuncio.vue";
import FiltroParcela from "@/views/components/FiltroParcela.vue";
import FiltroNF from "@/views/components/FiltroNF.vue";
import Pesquisas from "@/views/components/Pesquisas.vue";
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
import download from "downloadjs";
export default {
  components: {
    FiltroCliente,
    FiltroAssinatura,
    FiltroAnuncio,
    FiltroParcela,
    FiltroNF,
    Pesquisas
  },
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions", width: 180 },
        { text: "ID", value: "id" },
        { text: "Origem", value: "origem" },
        { text: "Tipo", value: "rf" },
        { text: "Série", value: "serie" },
        { text: "Número", value: "numero" },
        { text: "Emissão", value: "dtEmissao" },
        { text: "Chave", value: "chave" },
        { text: "Cliente", value: "cliente" },
        { text: "Status", value: "status" },
        { text: "Bruto", value: "bruto" },
        { text: "Desconto", value: "desconto" },
        { text: "Líquido", value: "liquido" },
        { text: "Ref.", value: "numeroRef" },
        { text: "Lote", value: "idNFeLote" },
        { text: "", value: "actions2", width: 80 }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      direitoCriar: false,
      direitoCancelar: false,
      direitoParcelas: false,
      direitoExportar: false,

      tab: null,
      tabAss: false,
      tabAnu: false,

      dialogAcao: false,
      acaoValid: true,
      acaoItem: {
        tipoAcao: null,
        texto: ""
      },
      validacaoAcao: {
        texto: false
      },
      dadoIndex: -1,
      dadoItem: {},

      dialogRetorno: false,

      dialogXML: false,
      competencia: null,
      competencias: [],

      isUpdating: false,
      mensagem: "",
      snackbar: false,
      snackErro: false,

      inicial: true,
      idSitePrincipal: null,
      mensagemPesquisa: "",
      numeroPesquisa: null,
      identificacaoPesquisa: null,
      nomePesquisa: null,
      dialogFiltroAvancado: false,
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {},
      loader: null,
      loading: false,
      loadingExp: false
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    filtroAvancadoRetornar() {
      //* Esconde a dialog do filtro avançado
      this.dialogFiltroAvancado = false;
    },
    filtroAvancadoLimpar() {
      this.tabAss = false;
      this.tabAnu = false;
      if (this.tab == 2 || this.tab == 3) {
        this.tab = 0;
      }

      //* Limpa os registros da tabela
      this.registros = [];
      this.totalRegistros = 0;
      this.totalPaginas = 0;
      this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR AS NOTAS FISCAIS...";

      //* Limpa os campos dos filtros
      if (this.$refs.filtroNF) {
        this.$refs.filtroNF.limpar();
      }
      if (this.$refs.filtroCliente) {
        this.$refs.filtroCliente.limpar();
      }
      if (this.$refs.filtroAssinatura) {
        this.$refs.filtroAssinatura.limpar();
      }
      if (this.$refs.filtroAnuncio) {
        this.$refs.filtroAnuncio.limpar();
      }
      if (this.$refs.filtroParcela) {
        this.$refs.filtroParcela.limpar();
      }
    },

    limpaFiltro() {
      var pgAnterior = this.paginaAtual;
      this.paginaAtual = 1;
      if (pgAnterior == 1) {
        this.filtroAvancadoFiltrar(false);
      }
    },
    async filtroAvancadoFiltrar(exportar) {
      //* Limpa os registros da tabela
      this.registros = [];
      this.totalRegistros = 0;
      this.totalPaginas = 0;

      if (this.identificacaoPesquisa && !util.valida_cpf(this.identificacaoPesquisa) && !util.valida_cnpj(this.identificacaoPesquisa)) {
        this.mensagem = "Identificação inválida";
        this.snackErro = true;
        return;
      }

      this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

      var l = null;
      if (exportar) {
        this.loader = "loading";
        l = this.loader;
        this[l] = !this[l];
        setTimeout(() => (this[l] = false), 5000);
        this.loader = null;
      }

      if (!exportar) {
        //* Sinaliza que está carregando
        this.carregando = true;
        this.mensagemPesquisa = "Carregando...";
        this.loading = true;
      } else {
        this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR AS NOTAS FISCAIS...";
      }

      //* Esconde a dialog do filtro avançado
      this.dialogFiltroAvancado = false;

      var filtroNFUrl = this.montaFiltro();
      if (!filtroNFUrl.includes("nfeIdSite")) {
        filtroNFUrl += "&nfeIdSite=" + this.idSitePrincipal;
      }
      if (this.numeroPesquisa) {
        if (filtroNFUrl.includes("nfeNumeroInicio") || filtroNFUrl.includes("nfeNumeroFim")) {
          this.numeroPesquisa = "";
        } else {
          filtroNFUrl += "&nfeNumeroInicio=" + this.numeroPesquisa + "&nfeNumeroFim=" + this.numeroPesquisa;
        }
      }
      if (this.identificacaoPesquisa) {
        if (filtroNFUrl.includes("identificacao")) {
          this.identificacaoPesquisa = "";
        } else {
          filtroNFUrl += "&c.identificacao=" + this.identificacaoPesquisa;
        }
      }
      if (this.nomePesquisa) {
        if (filtroNFUrl.includes("&c.nome=")) {
          this.nomePesquisa = "";
        } else {
          filtroNFUrl += "&c.nome=" + this.nomePesquisa;
          filtroNFUrl += "&c.nomeFantasia=" + this.nomePesquisa;
        }
      }

      var camposAnuncio = "";
      if (exportar) {
        if (this.$refs.pesquisas) {
          //* Carrega os filtros da nota fiscal
          camposAnuncio = this.$refs.pesquisas.filtroUrlCampos();
        }
      }

      try {
        //* Consulta os dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/nfe?" +
            filtroNFUrl +
            "&campos=" +
            camposAnuncio +
            "&pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem +
            (exportar ? "&exportar=1" : "")
        );

        if (!exportar) {
          //* Carrega os dados de retorno
          this.registros = response.data.data;
          this.totalRegistros = response.data.total;
          this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
          this.rodape.pageText = this.totalRegistros + " itens";
          //* Fecha a mensagem de carregando
          this.carregando = false;

          //* Registra caso algum item não seja encontrado
          this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
        }
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else if (e.response && e.response.data.length > 0) {
          this.mensagemPesquisa = "ERRO: " + e.response.data[0].mensagem;
          this.mensagem = "ERRO: " + e.response.data[0].mensagem;
          this.snackErro = true;
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
          this.mensagem = "Erro ao buscar dados: " + e;
          this.snackErro = true;
        }
      } finally {
        this.loading = false;
      }
    },
    montaFiltro() {
      var filtroNFUrl = "";
      if (this.$refs.filtroNF) {
        //* Carrega os filtros da nota fiscal
        filtroNFUrl = filtroNFUrl + this.$refs.filtroNF.filtroUrlParametros();
      }

      if (this.$refs.filtroAssinatura) {
        //* Carrega os filtros da assinatura
        filtroNFUrl = filtroNFUrl + this.$refs.filtroAssinatura.filtroUrlParametros(this.idSitePrincipal);
      }

      if (this.$refs.filtroAnuncio) {
        //* Carrega os filtros do anúncio
        filtroNFUrl += this.$refs.filtroAnuncio.filtroUrlParametros(this.idSitePrincipal);
      }

      if (this.$refs.filtroCliente) {
        //* Carrega os filtros do cliente
        filtroNFUrl = filtroNFUrl + this.$refs.filtroCliente.filtroUrlParametros(this.idSitePrincipal);
      }

      if (this.$refs.filtroParcela) {
        //* Carrega os filtros da parcela
        filtroNFUrl += this.$refs.filtroParcela.filtroUrlParametros(this.idSitePrincipal);
      }
      return filtroNFUrl;
    },
    parcelas(nfe) {
      window.open("/financeiro/parcelas/receber?idNFe=" + nfe.id + "&status=" + nfe.status, "_blank");
    },
    danfe(item) {
      try {
        var caminhoNota = `/${this.idSitePrincipal}/notas-fiscais/nfe/notas/${item.anoMes}/NFe${item.chave}.pdf`;
        window.open(process.env.VUE_APP_URL_WEB_API + "fin/arquivo/pdf?caminho=" + caminhoNota, "_blank");
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    closeAcao() {
      this.dialogAcao = false;
      this.$refs.formAcao.resetValidation();
      this.dadoIndex = -1;
      this.dadoItem = {};
      this.acaoItem.tipoAcao = null;
      this.acaoItem.texto = "";
      this.validacaoAcao.texto = false;
    },
    closeRetorno() {
      this.dialogRetorno = false;
      this.dadoIndex = -1;
      this.dadoItem = {};
    },
    async acaoCancelar(item) {
      this.dadoIndex = this.registros.indexOf(item);
      this.dadoItem = Object.assign({}, item);

      //* Verifica se nota ainda pode ser cancelada
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/config-nfe/site/" + this.idSitePrincipal);
      if (response.data) {
        var config = response.data;

        var dtEmissao = new Date(item.dtEmissao);
        var dtLimite = new Date();
        dtLimite.setHours(dtLimite.getHours() - parseInt(config.hsCanc));

        if (dtEmissao > dtLimite) {
          this.acaoItem.tipoAcao = "Cancelar";
          this.mensagem = "";
          this.dialogAcao = true;
        } else {
          this.mensagem = "Prazo para cancelamento expirado";
          this.snackErro = true;
        }
      } else {
        this.mensagem = "Ocorreu um erro ao carregar os dados da configuração da NFe";
        this.snackErro = true;
      }
    },
    async acaoCorrigir(item) {
      this.dadoIndex = this.registros.indexOf(item);
      this.dadoItem = Object.assign({}, item);

      this.acaoItem.tipoAcao = "Corrigir";
      this.mensagem = "";
      this.dialogAcao = true;
    },
    executarAcao() {
      if (this.$refs.formAcao.validate()) {
        if (this.acaoItem.texto.length < 15) {
          this.mensagem = "Justificativa deve ter pelo menos 15 caracteres";
          this.snackErro = true;
          return;
        }
        if (this.acaoItem.tipoAcao == "Cancelar") {
          this.cancelar();
        }
        if (this.acaoItem.tipoAcao == "Corrigir") {
          this.corrigir();
        }
      }
    },

    //falta inutilização de NFe

    visualizarRetorno(item) {
      this.dadoIndex = this.registros.indexOf(item);
      this.dadoItem = Object.assign({}, item);
      this.dialogRetorno = true;
    },
    async reenviar(item) {
      const notaCommit = {
        idSite: this.idSitePrincipal,
        idNFe: item.id,
        numero: item.numero,
        codigo: item.codigo,
        rf: item.rf,
        agrupamento: 2,
        parcelas: []
      };
      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "fin/nota-fiscal?idSite=" + this.idSitePrincipal, notaCommit);
        this.mensagem = "Nota Fiscal reenviada com sucesso";
        this.snackbar = true;
        item.status = "Emitir";
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    async cancelar() {
      try {
        const loteCommit = {
          idSite: this.idSitePrincipal,
          motivo: this.acaoItem.texto,
          idNFe: this.dadoItem.id,
          chave: this.dadoItem.chave
        };
        const response = await api.post(process.env.VUE_APP_URL_WEB_API + "fin/nfe-lote/cancelar/" + this.dadoItem.id + "?idSite=" + this.idSitePrincipal, loteCommit);
        if (response.data) {
          this.dadoItem.status = "Cancelar";
          Object.assign(this.registros[this.dadoIndex], this.dadoItem);

          this.mensagem = "Nota fiscal enviada para cancelamento";
          this.snackbar = true;
        }
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      } finally {
        this.closeAcao();
      }
    },
    async corrigir() {
      try {
        const loteCommit = {
          idSite: this.idSitePrincipal,
          motivo: this.acaoItem.texto,
          idNFe: this.dadoItem.id,
          chave: this.dadoItem.chave
        };
        const response = await api.post(process.env.VUE_APP_URL_WEB_API + "fin/nfe-lote/corrigir/" + this.dadoItem.id + "?idSite=" + this.idSitePrincipal, loteCommit);
        if (response.data) {
          this.dadoItem.status = "Corrigir";
          Object.assign(this.registros[this.dadoIndex], this.dadoItem);

          this.mensagem = "Nota fiscal enviada para correção";
          this.snackbar = true;
        }
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      } finally {
        this.closeAcao();
      }
    },
    async devolver(item) {
      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "fin/nfe-lote/devolver/" + item.id + "?idSite=" + this.idSitePrincipal);
        this.mensagem = "Nota Fiscal enviada para devolução com sucesso";
        this.snackbar = true;
        setTimeout(() => {
          this.filtroAvancadoFiltrar(false);
        }, 500);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },
    async estornar(item) {
      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "fin/nfe-lote/estornar/" + item.id + "?idSite=" + this.idSitePrincipal);
        this.mensagem = "Nota Fiscal enviada para estorno com sucesso";
        this.snackbar = true;
        setTimeout(() => {
          this.filtroAvancadoFiltrar(false);
        }, 500);
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    async nfEmail(element) {
      var notasCommit = [];
      var caminhoNota = "";
      var numeroNota = "";
      caminhoNota = `${this.idSitePrincipal}/notas-fiscais/nfe/notas/${element.anoMes}`;
      numeroNota = `NFe${element.chave}`;
      var boleto = {
        idSite: this.idSitePrincipal,
        idCliente: element.idCliente,
        origem: element.origem,
        idUsuario: this.$store.state.usuario.id,
        tipo: 4,
        pastaBoleto: null,
        boleto: null,
        pastaNF: caminhoNota,
        nf: numeroNota,
        recibo: null,
        pi: null
      };
      notasCommit.push(boleto);

      try {
        await api.post(process.env.VUE_APP_URL_WEB_API + "adm/log-email/lista?idSite=" + this.idSitePrincipal, notasCommit);

        this.mensagem = "Notas fiscais direcionadas para envio por e-mail";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      }
    },

    async carregarCompetencias() {
      this.mensagem = false;
      if (this.competencias.length == 0) {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/nfe/competencias?idSite=" + this.idSitePrincipal);
        if (response.data) {
          this.competencias = response.data;
        }
      }
    },
    async downloadXML() {
      this.mensagem = "";
      if (this.competencia) {
        this.isUpdating = true;
        try {
          const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/nfe/xmls?idSite=" + this.idSitePrincipal + "&competencia=" + this.competencia.anoMes);
          if (response.data) {
            const ano_mes = "20" + this.competencia.anoMes.substring(0, 2) + "_" + this.competencia.anoMes.substring(2);

            var caminhoArquivo = `arquivos/${this.idSitePrincipal}/notas-fiscais/nfe/xmls/${ano_mes}.zip`;

            setTimeout(() => {
              this.isUpdating = false;
              download(process.env.VUE_APP_URL_WEB_API + caminhoArquivo);
            }, 3000);
          }
        } catch (e) {
          this.isUpdating = false;
          this.mensagem = "Ocorreu um erro ao gerar os ZIP com os XMLs: " + e.response.data;
          this.snackErro = true;
        }
      } else {
        this.mensagem = "Selecione a competência";
      }
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_NFE_ACESSO"]);
    //* Verifica se o usuário logado possui direito para incluir
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_NFE_CRIAR") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_PARCELAS_RECEBER_ACESSO") {
        this.direitoParcelas = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_NFE_CANCELAR") {
        this.direitoCancelar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "FINANCEIRO_NFE_EXPORTAR") {
        this.direitoExportar = true;
      }
    });
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
  },

  watch: {
    tab(val) {
      if (val == 2) {
        this.tabAss = true;
      }
      if (val == 3) {
        this.tabAnu = true;
      }
    },
    $route() {
      this.tabAss = false;
      this.tabAnu = false;
    },
    paginaAtual() {
      this.filtroAvancadoFiltrar(false);
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        if (!this.inicial) {
          this.filtroAvancadoFiltrar(false);
        } else {
          this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR AS NOTAS FISCAIS...";
          this.inicial = false;
        }
      }
    }
  }
};
</script>
