<template>
  <v-card class="hide-overflow fill-height" color="white lighten-1" lighten>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">mdi-account</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Configuração - Nota Fiscal de Serviço de Comunicação</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="formElemento" v-model="elementoValid" lazy-validation>
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-progress-linear :active="isUpdating" class="ma-0" color="cyan accent-3" height="3" indeterminate></v-progress-linear>
          </v-flex>

          <v-flex xs12 md3>
            <v-switch
              v-model="configNFSC.financeiro"
              :label="`${configNFSC.financeiro == 1 ? 'Emite Notas com Parcelas do Financeiro' : 'Não emite notas com parcelas do Financeiro'}`"
            ></v-switch>
          </v-flex>

          <v-flex xs12 md3>
            <v-switch
              v-model="configNFSC.assinatura"
              :label="`${configNFSC.assinatura == 1 ? 'Emite Notas com Parcelas de Assinatura' : 'Não emite notas com parcelas de Assinatura'}`"
            ></v-switch>
          </v-flex>

          <v-flex xs12 md3>
            <v-switch
              v-model="configNFSC.vendaAvulsa"
              :label="`${configNFSC.vendaAvulsa == 1 ? 'Emite Notas com Parcelas de Venda Avulsa' : 'Não emite notas com parcelas de Venda Avulsa'}`"
            ></v-switch>
          </v-flex>

          <v-flex xs12 md3>
            <v-switch
              v-model="configNFSC.anuncio"
              :label="`${configNFSC.anuncio == 1 ? 'Emite Notas com Parcelas de Anúncio' : 'Não emite notas com parcelas de Anúncio'}`"
            ></v-switch>
          </v-flex>

          <v-flex xs6 md3>
            <v-text-field
              v-model="configNFSC.serie"
              autofocus
              label="Série"
              maxlength="3"
              hint="Obrigatório"
              :rules="[validacao.serie == false || validacao.serie, value => validaSerie(value) || 'Obrigatório.']"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="
                configNFSC.serie = configNFSC.serie.toUpperCase();
                validacao.serie = false;
              "
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-text-field
              v-model="configNFSC.ultimoNumero"
              label="Último Número"
              v-mask="'#########'"
              color="blue-grey lighten-2"
              hint="Obrigatório"
              :rules="[validacao.ultimoNumero == false || validacao.ultimoNumero, value => parseInt(value) >= 0 || 'Obrigatório.']"
              @change="validacao.ultimoNumero = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md2>
            <v-text-field
              v-model="configNFSC.codICMS"
              label="Código ICMS"
              v-mask="'##'"
              hint="Obrigatório. Use 00 para SIMPLES"
              :rules="[
                validacao.codICMS == false || validacao.codICMS,
                value => !!value || 'Obrigatório.',
                value => value.length == 2 || 'Preencha o Código do ICMS (para SPED) com 2 dígitos numéricos'
              ]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.codICMS = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-currency-field
              :locale="`pt-${$store.state.usuario.site_pais}`"
              v-model="configNFSC.icms"
              label="Alíquota ICMS"
              :allowNegative="false"
              :max="99"
              :rules="[validacao.icms == false || validacao.icms]"
              color="blue-grey lighten-2"
              @change="validacao.icms = false"
            ></v-currency-field>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch v-model="configNFSC.simples" :label="`${configNFSC.simples == 1 ? 'Empresa do SIMPLES' : 'Não é empresa do SIMPLES'}`"></v-switch>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch v-model="configNFSC.mostrarAgente" :label="`${configNFSC.mostrarAgente == 1 ? 'Dados do Agente/Agência' : 'Sem dados do agente/agência'}`"></v-switch>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch v-model="configNFSC.mostrarDesc" :label="`${configNFSC.mostrarDesc == 1 ? 'Desconto Padrão' : 'Sem desconto padrão'}`"></v-switch>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch v-model="configNFSC.mostrarLaser" :label="`${configNFSC.mostrarLaser == 1 ? 'Mensagem Impressão a Laser' : 'Sem mensagem impressão a laser'}`"></v-switch>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch v-model="configNFSC.mostrarProdutos" :label="`${configNFSC.mostrarProdutos == 1 ? 'Nomes dos Produtos' : 'Sem nomes dos produtos'}`"></v-switch>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch v-model="configNFSC.mostrarTributos" :label="`${configNFSC.mostrarTributos == 1 ? 'Mostrar Tributos' : 'Sem mensagem dos tributos'}`"></v-switch>
          </v-flex>

          <v-flex xs6 md3>
            <v-switch v-model="configNFSC.textoEstendido" :label="`${configNFSC.textoEstendido == 1 ? 'Utilizar Texto Estendido' : 'Sem texto estendido'}`"></v-switch>
          </v-flex>

          <v-flex xs6 md3 v-if="configNFSC.textoEstendido">
            <v-switch
              v-model="configNFSC.incluirPIest"
              :label="`${configNFSC.incluirPIest == 1 ? 'Incluir PI no Texto Estendido' : 'Não incluir PI no texto estendido'}`"
            ></v-switch>
          </v-flex>

          <v-flex xs6 md3>
            <v-text-field
              v-model="configNFSC.ttd"
              label="TTD"
              maxlength="15"
              hint="Preencha com o código do Tratamento Tributário Diferenciado, se aplicável"
              :rules="[validacao.ttd == false || validacao.ttd]"
              :disabled="isUpdating"
              color="blue-grey lighten-2"
              autocomplete="disabled"
              :name="Math.random()"
              @change="validacao.ttd = false"
            ></v-text-field>
          </v-flex>

          <v-flex xs12 v-if="validacao.mensagemErro">
            <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <div>
      <span>Instruções Adesão Convênio ICMS 115/2003: </span>
      <a
        href="https://www.sef.sc.gov.br/servicos/servico/21/Conv%C3%AAnio_ICMS_115_2003_(Nota_Fiscal_em_via_%C3%BAnica_-Comunica%C3%A7%C3%A3o,_Telecomunica%C3%A7%C3%A3o_e_Energia_El%C3%A9trica)"
        target="_blank"
        >Portal Sefaz-SC</a
      >
    </div>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn to="/financeiro/desktop" color="orange lighten-2 white" style="color:white"> <v-icon left>keyboard_arrow_left</v-icon>Retornar </v-btn>
      <v-btn v-if="direitoEditar" :loading="isUpdating" color="blue white" style="color:white" depressed @click="salvar"> <v-icon left>update</v-icon>Salvar </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
    <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
  </v-card>
</template>
<script>
import util from "../../util.js";
import { api } from "@/services/api";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      configNFSC: {
        id: "",
        idSite: null,
        financeiro: 0,
        assinatura: 0,
        vendaAvulsa: 0,
        anuncio: 0,
        ultimoNumero: null,
        serie: "",
        simples: 0,
        codICMS: "",
        icms: 0,
        mostrarAgente: 0,
        mostrarDesc: 0,
        mostrarLaser: 0,
        mostrarProdutos: 0,
        mostrarTributos: 0,
        textoEstendido: 0,
        ttd: "",
        incluirPIest: 0
      },

      validacao: {
        id: false,
        idSite: false,
        financeiro: false,
        assinatura: false,
        vendaAvulsa: false,
        anuncio: false,
        ultimoNumero: false,
        serie: false,
        simples: false,
        codICMS: false,
        icms: false,
        mostrarAgente: false,
        mostrarDesc: false,
        mostrarLaser: false,
        mostrarProdutos: false,
        mostrarTributos: false,
        textoEstendido: false,
        ttd: false,
        incluirPIest: false,
        mensagemErro: false
      },

      idSitePrincipal: null,

      direitoEditar: false,
      elementoValid: true,
      id: "",
      mensagem: "",
      snackbar: false,
      snackErro: false,

      isUpdating: false
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    async salvar() {
      await util.limparObjeto(this.validacao, false);
      if (!this.$refs.formElemento.validate()) {
        this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
        this.snackErro = true;
        return;
      }
      if (!this.configNFSC.textoEstendido) {
        this.configNFSC.incluirPIest = 0;
      }

      this.isUpdating = true;
      try {
        var retorno = "";
        //se tiver id é um update
        if (this.id) {
          retorno = await api.put(process.env.VUE_APP_URL_WEB_API + "fin/config-nfsc/" + this.id, this.configNFSC);
        } else {
          retorno = await api.post(process.env.VUE_APP_URL_WEB_API + "fin/config-nfsc?idSite=" + this.idSitePrincipal, this.configNFSC);
          this.id = retorno.data.id;
          this.configNFSC.id = retorno.data.id;
        }
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          util.validaErros(this.validacao, e.response.data);
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.isUpdating = false;
      }
    },
    validaSerie(value) {
      var retorno = true;
      if (!value || value.length > 3 || value == "0") {
        retorno = "Série inválida";
      }
      if (value != "U" && !Number.isInteger(parseInt(value))) {
        retorno = "Série inválida";
      }
      return retorno;
    }
  },

  async created() {
    //* Verifica se o usuário logado possui direitos
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA") {
        this.direitoEditar = true;
      }
    });

    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;
    try {
      this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_CONFIG_NFSC_ACESSO"]);
      //* Carrega a configuração para o site, se existir
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "fin/config-nfsc/site/" + this.idSitePrincipal);
      if (!response.data) {
        this.id = "";
        this.configNFSC.idSite = this.idSitePrincipal;
      } else {
        this.id = response.data.id;
        this.configNFSC = response.data;
      }
    } catch {
      this.mensagem = "Ocorreu um erro ao carregar os dados";
      this.snackErro = true;
    }
  }
};
</script>
