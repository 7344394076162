<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">ac_unit</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">Repartes de Venda Avulsa</v-toolbar-title>
          <v-spacer></v-spacer>
          <router-link v-if="direitoCriar" to="/assinaturas/venda-avulsa-reparte" style="text-decoration: none">
            <v-btn color="success" fab small>
              <v-icon color="white" title="Incluir">add</v-icon>
            </v-btn>
          </router-link>
        </v-toolbar>

        <div class="px-6 mt-4">
          <v-container fluid grid-list-md>
            <v-layout wrap>
              <v-flex xs12 md6>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Itens do Reparte
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-autocomplete
                        autofocus
                        v-model="idTipoBancaItem"
                        :items="tiposBanca"
                        :filter="filtrarObjetos"
                        label="Tipo de Banca"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        @change="limpaFiltro(true)"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md8>
                      <v-autocomplete
                        v-model="idBanca"
                        :items="bancas"
                        :filter="filtrarObjetos"
                        label="Banca"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        @change="limpaFiltro(true)"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md5>
                      <v-autocomplete
                        autofocus
                        v-model="idEntregador"
                        :items="entregadores"
                        :filter="filtrarObjetos"
                        label="Entregador"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        @change="limpaFiltro(true)"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md7>
                      <v-autocomplete
                        v-model="idRota"
                        :items="rotas"
                        :filter="filtrarObjetos"
                        label="Rota"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        @change="limpaFiltro(true)"
                        @dblclick.stop="selectAll"
                        multiple
                      >
                        <template v-slot:selection="data">
                          <span>{{ (idRota.length > 3 ? abreviaNome(data.item.nome) : data.item.nome) + (idRota.length > 1 ? ",⠀" : "") }}</span>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-flex>

              <v-flex xs12 md6>
                <v-container fluid grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      <h4 class="overline font-weight-black font-italic">
                        Reparte
                      </h4>
                      <v-divider></v-divider>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-autocomplete
                        autofocus
                        v-model="idTipoBanca"
                        :items="tiposBanca"
                        :filter="filtrarObjetos"
                        label="Tipo de Banca"
                        item-text="nome"
                        item-value="id"
                        no-data-text
                        clearable
                        @change="limpaFiltro(true)"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-menu v-model="mnuReparte" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="intervaloReparte"
                            label="Período do Reparte"
                            hint="Para Mapas, selecione até 7 dias"
                            persistent-hint
                            prepend-icon="event"
                            v-on="on"
                            color="blue-grey lighten-2"
                            clearable
                            @click:clear="
                              intervaloReparteUS = ['', ''];
                              limpaFiltro(false);
                            "
                            readonly
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="intervaloReparteUS" locale="pt-br" no-title range
                          ><v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              mnuReparte = false;
                              limpaFiltro(false);
                            "
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-btn v-if="direitoExportar" color="blue" :loading="loading" :disabled="loading" fab small @click="exportar()">
                        <v-icon color="white" title="Exportar Relatório">description</v-icon>
                      </v-btn>
                      <v-btn v-if="direitoExportar" color="blue lighten-2" :loading="loading" :disabled="loading" fab small @click="exportar(0)">
                        <v-icon color="white" title="Exportar Movimento">description</v-icon>
                      </v-btn>
                      <v-btn class="ml-2" v-if="direitoExportar" color="orange lighten-2" :loading="loading" :disabled="loading" fab small @click="exportar(1)">
                        <v-icon color="white" title="Exportar Mapa Recolhimento">description</v-icon>
                      </v-btn>
                      <v-btn v-if="direitoExportar" color="orange" :loading="loading" :disabled="loading" fab small @click="exportar(2)">
                        <v-icon color="white" title="Exportar Mapa Reparte">description</v-icon>
                      </v-btn>
                      <v-btn v-if="direitoExportar" color="orange darken-2" :loading="loading" :disabled="loading" fab small @click="exportar(3)">
                        <v-icon color="white" title="Exportar Mapa Desempenho">description</v-icon>
                      </v-btn>
                      <v-btn class="ml-12" v-if="direitoEditar" color="blue" :loading="loading" :disabled="loading" fab small @click="editar()">
                        <v-icon color="white" title="Editar Repartes da Banca">ac_unit</v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        background-color="white"
                        class="mb-2 elevation-0"
                        v-model="filtroPesquisa"
                        append-icon="search"
                        label="Digite para pesquisar..."
                        placeholder="Pesquisa pelo ID"
                        hide-details
                        @keyup.enter="limpaFiltro(false)"
                        @click:append="limpaFiltro(false)"
                        autocomplete="disabled"
                        :name="Math.random()"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-flex>
            </v-layout>
          </v-container>

          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
          >
            <template v-slot:item.actions="{ item }">
              <router-link v-if="direitoEditar" :to="'/assinaturas/venda-avulsa-reparte/' + item.id" target="_blank" style="text-decoration: none">
                <v-icon color="blue" title="Editar">edit</v-icon>
              </router-link>
            </template>

            <template v-slot:item.dtCriacao="{ item }">{{ formatDateTime(item.dtCriacao) }}</template>
            <template v-slot:item.dtEntrega="{ item }">{{ formatDate(item.dtEntrega) }}</template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
          </div>
          <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
          <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        </div>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Tipo de Banca", value: "tipoBanca" },
        { text: "Produto", value: "produto" },
        { text: "Criação", value: "dtCriacao" },
        { text: "Reparte", value: "dtEntrega" },
        { text: "", value: "entregadores" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      idSitePrincipal: null,
      tiposBanca: [],
      idTipoBanca: null,
      idTipoBancaItem: null,
      idEntregador: null,
      idRota: null,

      bancas: [],
      entregadores: [],
      rotas: [],
      idBanca: null,
      mnuReparte: false,
      intervaloReparteUS: ["", ""],

      snackbar: false,
      snackErro: false,
      mensagem: "",
      direitoCriar: false,
      direitoEditar: false,
      direitoExportar: false,
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {},
      loader: null,
      loading: false,
      inicial: true
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    limpaFiltro(aguardar) {
      this.paginaAtual = 1;
      this.pesquisaDados(aguardar);
    },
    async pesquisaDados(aguardar) {
      this.registros = [];

      var filtro = "&idSite=" + this.idSitePrincipal;

      if (this.filtroPesquisa && parseInt(this.filtroPesquisa) > 0) {
        filtro += "&idLote=" + this.filtroPesquisa;
      }
      if (this.idTipoBanca) {
        filtro += "&idTipoBanca=" + this.idTipoBanca;
      }

      if (this.idTipoBancaItem) {
        filtro += "&idTipoBancaItem=" + this.idTipoBancaItem;
      }
      if (this.idBanca) {
        filtro += "&idBanca=" + this.idBanca;
      }
      if (this.idEntregador) {
        filtro += "&idEntregador=" + this.idEntregador;
      }
      if (this.idRota && this.idRota.length) {
        filtro += "&idRota=" + this.idRota.join(",");
      }

      var dtRepInicio = "";
      var dtRepFim = "";
      if (this.intervaloReparteUS && this.intervaloReparteUS.length > 1 && util.valida_data(this.intervaloReparteUS[0]) && util.valida_data(this.intervaloReparteUS[1])) {
        dtRepInicio = this.intervaloReparteUS[0];
        dtRepFim = this.intervaloReparteUS[1];
        filtro += "&dtInicio=" + dtRepInicio + "&dtFim=" + dtRepFim;
      } else {
        if (!aguardar) {
          this.mensagem = "Selecione o período";
          this.snackErro = true;
        }
        return;
      }

      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";
      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "ass/venda-avulsa-lote?pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            filtro +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;
        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },
    async exportar(mapa) {
      var filtro = "?idSite=" + this.idSitePrincipal;

      if (this.idTipoBanca) {
        filtro += "&idTipoBanca=" + this.idTipoBanca;
      }

      if (this.idTipoBancaItem) {
        filtro += "&idTipoBancaItem=" + this.idTipoBancaItem;
      }
      if (this.idBanca) {
        filtro += "&idBanca=" + this.idBanca;
      }
      if (this.idEntregador) {
        filtro += "&idEntregador=" + this.idEntregador;
      }
      if (this.idRota) {
        filtro += "&idRota=" + this.idRota;
      }

      if (mapa == 1) {
        filtro += "&recolhimento=1";
      } else if (mapa == 3) {
        filtro += "&desempenho=1";
      } else if (mapa == 0) {
        filtro += "&movimento=1";
      }

      var dtRepInicio = "";
      var dtRepFim = "";
      if (this.intervaloReparteUS && this.intervaloReparteUS.length > 1 && util.valida_data(this.intervaloReparteUS[0]) && util.valida_data(this.intervaloReparteUS[1])) {
        dtRepInicio = this.intervaloReparteUS[0];
        dtRepFim = this.intervaloReparteUS[1];
        filtro += "&dtInicio=" + dtRepInicio + "&dtFim=" + dtRepFim;
      } else {
        this.mensagem = "Selecione o período";
        this.snackErro = true;
        return;
      }

      this.loader = "loading";
      const l = this.loader;
      this[l] = !this[l];
      try {
        if (mapa) {
          const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/venda-avulsa-lote/mapa" + filtro);
          if (response.data.status) {
            this.mensagem = "Pesquisa exportada, verifique suas mensagens";
            this.snackbar = true;
          } else {
            util.imprimir(response.data);
          }
        } else {
          await api.get(process.env.VUE_APP_URL_WEB_API + "ass/venda-avulsa-lote/csv" + filtro);
          this.mensagem = "Pesquisa exportada, verifique suas mensagens";
          this.snackbar = true;
        }
      } catch (e) {
        this.mensagem = e.response.data;
        this.snackErro = true;
      } finally {
        this[l] = false;
        this.loader = null;
      }
    },
    async carregarTiposBanca() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/tipo-banca/lista?idSite=" + this.idSitePrincipal);
      if (response.data) {
        this.tiposBanca = response.data;
      }
    },
    async carregarBancas() {
      const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-banca/lista?idSite=" + this.idSitePrincipal);
      if (response.data) {
        this.bancas = response.data;
      }
    },
    async carregarEntregadores() {
      try {
        //* Busca entregadores ativos, pois se está inativo, não deveria estar associado a um CEP
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/entregador/lista?vendaAvulsa=1");
        this.entregadores = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de Entregadores";
        this.snackErro = true;
      }
    },

    async carregarRotas() {
      try {
        //* Busca rotas ativas, pois se está inativa, não deveria estar associada a um CEP
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/rota/lista?vendaAvulsa=1");
        this.rotas = response.data;
        this.rotas.forEach(element => {
          element.nome = element.nome + " - " + element.municipio + "/" + element.uf;
        });
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de Rotas";
        this.snackErro = true;
      }
    },

    selectAll() {
      this.idRota = [];
      this.rotas.forEach(element => {
        this.idRota.push(element.id);
      });
    },
    editar() {
      if (!this.idBanca) {
        this.mensagem = "Selecione a banca";
        this.snackErro = true;
        return;
      }
      var filtro = "";
      if (this.intervaloReparteUS && this.intervaloReparteUS.length > 1 && util.valida_data(this.intervaloReparteUS[0]) && util.valida_data(this.intervaloReparteUS[1])) {
        filtro = "?periodo=" + util.formatDate(this.intervaloReparteUS[0]) + "-" + util.formatDate(this.intervaloReparteUS[1]);
      } else {
        this.mensagem = "Selecione o período";
        this.snackErro = true;
        return;
      }
      this.$router.push("/assinaturas/venda-avulsa-reparte-banca/" + this.idBanca + filtro);
    },
    abreviaNome(nome) {
      return util.abreviaNome(nome);
    },
    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(datetime) {
      return util.formatDateTime(datetime);
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_VENDA_AVULSA_REPARTE_ACESSO"]);
    //* Verifica se o usuário logado possui direito para incluir
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_VENDA_AVULSA_REPARTE_CRIAR") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_VENDA_AVULSA_REPARTE_EDITAR") {
        this.direitoEditar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_VENDA_AVULSA_REPARTE_EXPORTAR") {
        this.direitoExportar = true;
      }
    });
    this.idSitePrincipal = this.$store.state.usuario.idSitePrincipal;

    this.carregarTiposBanca();
    this.carregarBancas();
    this.carregarEntregadores();
    this.carregarRotas();
  },
  computed: {
    intervaloReparte() {
      var retorno = "";
      if (this.intervaloReparteUS && this.intervaloReparteUS.length > 1) {
        this.intervaloReparteUS.forEach(element => {
          if (element) {
            retorno += (retorno ? " - " : "") + util.formatDate(element);
          }
        });
      }
      return retorno;
    }
  },
  watch: {
    paginaAtual() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        if (!this.inicial) {
          this.pesquisaDados();
        } else {
          this.mensagemPesquisa = "MONTE SEU FILTRO PARA VISUALIZAR OS REPARTES...";
          this.inicial = false;
        }
      }
    }
  }
};
</script>
