var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('v-app',{attrs:{"id":"inspire"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"#333333"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("account_balance")]),_c('v-toolbar-title',{staticClass:"font-weight-light",staticStyle:{"color":"white"}},[_vm._v("Cedentes")]),_c('v-spacer'),(_vm.direitoCriar)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/financeiro/cedente"}},[_c('v-btn',{attrs:{"color":"success","fab":"","small":""}},[_c('v-icon',{attrs:{"color":"white","title":"Incluir"}},[_vm._v("add")])],1)],1):_vm._e()],1),_c('div',{staticClass:"px-6 mt-4"},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-spacer'),_c('v-flex',{attrs:{"xs12":"","md5":""}},[_c('v-text-field',{staticClass:"mt-2 mb-2 elevation-0",attrs:{"background-color":"white","append-icon":"search","label":"Digite para pesquisar...","placeholder":"Pesquisa pelo nome, forma, número do banco","hide-details":"","autocomplete":"disabled","name":Math.random()},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.limpaFiltro($event)},"click:append":_vm.limpaFiltro},model:{value:(_vm.filtroPesquisa),callback:function ($$v) {_vm.filtroPesquisa=$$v},expression:"filtroPesquisa"}})],1)],1),_c('v-data-table',{attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.registros,"options":_vm.options,"no-data-text":_vm.mensagemPesquisa,"footer-props":_vm.rodape},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.direitoEditar)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":'/financeiro/cedente/' + item.id}},[_c('v-icon',{attrs:{"color":"blue","title":"Editar"}},[_vm._v("edit")])],1):_vm._e(),(item.situacao == 1 && item.forma == 'DEBITO_CONTA')?_c('v-icon',{attrs:{"color":item.online == 1 ? 'blue lighten-1' : 'blue-grey lighten-3',"title":item.online == 1 ? 'Desmarcar ONLINE' : 'Marcar como ONLINE'},on:{"click":function($event){return _vm.marcarDesmarcarOnline(item)}}},[_vm._v(" "+_vm._s(item.online == 1 ? "wb_cloudy" : "cloud_off")+" ")]):_vm._e(),(_vm.direitoEditar)?_c('v-icon',{attrs:{"color":item.principal == 1 ? 'blue lighten-2' : 'grey',"title":item.principal == 1 ? 'Desmarcar Principal' : 'Marcar como Principal'},on:{"click":function($event){return _vm.marcarDesmarcarPrincipal(item)}}},[_vm._v(" "+_vm._s(item.situacao == 1 ? "radio_button_checked" : item.situacao == 0 ? "radio_button_unchecked" : "")+" ")]):_vm._e(),_c('v-icon',{attrs:{"color":"blue","title":"Visualizar Log"},on:{"click":function($event){return _vm.visualizarLog(item)}}},[_vm._v("info")])]}},{key:"item.remessaPastaUnica",fn:function(ref){
var item = ref.item;
return [(item.forma == 'BOLETO' || item.forma == 'DEBITO_CONTA')?_c('span',{domProps:{"innerHTML":_vm._s(item.remessaPastaUnica == 1 ? '/remessas' : '/' + item.bancoNumero + '/remessas')}}):_vm._e()]}},{key:"item.loteCancBoleto",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.loteCancBoleto == 1 ? 'Sim' : '')}})]}},{key:"item.loteInclUnico",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.loteInclUnico == 1 ? 'Sim' : '')}})]}},{key:"item.loteAltCancUnico",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.loteAltCancUnico == 1 ? 'Sim' : '')}})]}},{key:"item.situacao",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.situacao == 1 ? 'Ativo' : '<strong>Inativo</strong>')}})]}}])}),_c('div',{staticClass:"text-xs-center pt-2 marg-bt"},[_c('v-pagination',{attrs:{"length":_vm.totalPaginas,"total-visible":_vm.$vuetify.breakpoint.xsOnly ? 5 : 7,"circle":""},model:{value:(_vm.paginaAtual),callback:function ($$v) {_vm.paginaAtual=$$v},expression:"paginaAtual"}}),_c('v-snackbar',{attrs:{"color":"info","timeout":5000,"relative":"","bottom":"","left":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.mensagem))])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"1200px"},model:{value:(_vm.dialogLogAlteracao),callback:function ($$v) {_vm.dialogLogAlteracao=$$v},expression:"dialogLogAlteracao"}},[_c('v-card',[_c('LogAlteracao',{attrs:{"idCedente":_vm.idCedente,"dialogLogAlteracao":_vm.dialogLogAlteracao},on:{"update:dialogLogAlteracao":function($event){_vm.dialogLogAlteracao=$event},"update:dialog-log-alteracao":function($event){_vm.dialogLogAlteracao=$event}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }