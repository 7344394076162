<template>
  <v-card>
    <v-toolbar color="#333333">
      <v-icon color="white" class="mr-2">mdi-account</v-icon>
      <v-toolbar-title style="color:white;" class="font-weight-light">Conciliação</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="retornar">
        <v-icon color="white">cancel</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="px-6 mt-4">
      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex xs6 md1 v-if="idExtrato">
            <v-text-field v-model="idExtrato" label="Extrato" disabled></v-text-field>
          </v-flex>
          <v-flex xs6 md1 v-if="idExtrato">
            <v-text-field v-model="idExtratoLancamento" label="Lançamento" disabled></v-text-field>
          </v-flex>
          <v-flex xs12 md3 v-if="idExtrato">
            <v-text-field v-model="banco" label="Banco" disabled></v-text-field>
          </v-flex>
          <v-flex xs12 md2>
            <v-text-field v-model="dtRegistro" label="Data de Registro" disabled></v-text-field>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 md5>
            <v-text-field
              background-color="white"
              v-model="filtroPesquisa"
              append-icon="search"
              label="Digite para pesquisar..."
              placeholder="Pesquisa pela transferência, parcela, código, cliente"
              hide-details
              @keyup.enter="limpaFiltro"
              @click:append="limpaFiltro"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-container>

      <v-data-table mobile-breakpoint="0" :headers="headers" :items="registros" :options.sync="options" :no-data-text="mensagemPesquisa" :footer-props="rodape">
        <template v-slot:[getItemDtOperacao()]="{ item }">
          <span>
            {{ formatDate(item.dtOperacao) }}
          </span>
        </template>
        <template v-slot:[getItemVlrLiquido()]="{ item }">
          <span>
            {{ item.vlrLiquido ? formatCurrency(item.vlrLiquido) : "" }}
          </span>
        </template>

        <template v-slot:[getItemIdOperacao()]="{ item }">
          <span>
            {{ item.idAssinatura ? item.idAssinatura + "/" + item.renovacao : item.idAnuncio ? item.idAnuncio : item.idPacote ? item.idPacote : item.idOperacao }}
          </span>
        </template>
        <template v-slot:[getItemDtQuitacao()]="{ item }">
          <span>
            {{ formatDate(item.dtQuitacao) }}
          </span>
        </template>
        <template v-slot:[getItemVlrParcela()]="{ item }">
          <span>
            {{ item.vlrParcela ? formatCurrency(item.vlrParcela) : "" }}
          </span>
        </template>
      </v-data-table>
      <div class="text-xs-center pt-2 marg-bt">
        <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
      </div>

      <v-divider></v-divider>

      <v-card-actions class="card-btn">
        <v-spacer></v-spacer>

        <v-btn class="sub-btn" color="blue darken-1" @click="retornar">Retornar</v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  props: {
    idExtratoLancamento: String
  },

  data() {
    return {
      headers: [
        { text: "Transferência", value: "idTransferencia" },
        { text: "Operação", value: "dtOperacao" },
        { text: "Valor", value: "vlrLiquido", align: "end" },
        { text: "Destino/Despesa", value: "contaD" },
        { text: "Origem", value: "contaC", divider: "true" },
        { text: "Parcela", value: "id" },
        { text: "Código", value: "idOperacao" },
        { text: "Cliente", value: "cliente" },
        { text: "Identificação", value: "identificacao" },
        { text: "Quitação", value: "dtQuitacao" },
        { text: "Valor", value: "vlrParcela", align: "end" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      idExtrato: null,
      banco: null,
      dtRegistro: "",
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {}
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    getItemDtOperacao() {
      return "item.dtOperacao";
    },
    getItemVlrLiquido() {
      return "item.vlrLiquido";
    },
    getItemIdOperacao() {
      return "item.idOperacao";
    },
    getItemDtQuitacao() {
      return "item.dtQuitacao";
    },
    getItemVlrParcela() {
      return "item.vlrParcela";
    },

    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados() {
      this.registros = [];
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "fin/extrato-conciliacao/lancamento/" +
            this.idExtratoLancamento +
            "?pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;
        if (this.registros.length > 0) {
          if (this.$route.path.includes("parcelas")) {
            this.idExtrato = this.registros[0].idExtrato;
            this.banco = this.registros[0].banco;
          }
          this.dtRegistro = util.formatDateTime(this.registros[0].dtRegistro);
        }

        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens" + (response.data.somaParcelas ? " - Parcelas: " + util.formatCurrency(response.data.somaParcelas) : "");
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },
    retornar() {
      this.$emit("update:dialogConciliacao", false);
    },
    formatDate(date) {
      return util.formatDate(date);
    },
    formatDateTime(date) {
      return util.formatDateTime(date);
    },
    formatCurrency(valor) {
      return util.formatCurrency(valor);
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "FINANCEIRO_EXTRATOS_VISUALIZAR"]);
  },

  watch: {
    idExtratoLancamento(val) {
      if (val) {
        this.paginaAtual = 1;
        this.pesquisaDados();
      }
    },
    paginaAtual() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }
        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }
        this.pesquisaDados();
      }
    }
  }
};
</script>
