<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">track_changes</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">
            IVC
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container fluid grid-list-md>
          <v-layout wrap>
            <v-flex xs6 md2>
              <v-menu v-model="mnuMeses" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px" :disabled="loading">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="competencia"
                    label="Competência"
                    prepend-icon="event"
                    v-on="on"
                    color="blue-grey lighten-2"
                    @click:clear="competenciaUS = ''"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker v-model="competenciaUS" locale="pt-br" type="month" no-title @input="mnuMeses = false"> </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs8 md2>
              <v-autocomplete
                :disabled="isUpdating"
                v-model="idFormaPgto"
                :items="formasPgto"
                :filter="filtrarObjetos"
                label="Forma de Pagamento Padrão"
                persistent-hint
                hint="Somente para Relatório Assinaturas"
                item-text="nome"
                item-value="id"
                autocomplete="disabled"
                :name="Math.random()"
                no-data-text
              >
              </v-autocomplete>
            </v-flex>
            <v-flex offset-md2 md2 xs4 class="text-right">
              <v-btn color="orange darken-2" :loading="loading" :disabled="loading" fab small @click="outros('assinantes')">
                <v-icon color="white" title="Relatório Assinantes">track_changes</v-icon>
              </v-btn>
              <v-btn class="ml-2" color="orange darken-2" :loading="loading" :disabled="loading" fab small @click="outros('assinaturas')">
                <v-icon color="white" title="Relatório Assinaturas">track_changes</v-icon>
              </v-btn>
              <v-btn class="ml-2" color="orange" :loading="loading" :disabled="loading" fab small @click="outros('atacado')">
                <v-icon color="white" title="Relatório Atacado">track_changes</v-icon>
              </v-btn>
              <v-btn class="ml-2" color="orange lighten-2" :loading="loading" :disabled="loading" fab small @click="outros('municipio')">
                <v-icon color="white" title="Relatório Municípios">track_changes</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs12></v-flex>

            <v-flex xs10 md4>
              <v-autocomplete
                v-model="tipo"
                :items="tipos"
                :filter="filtrarObjetos"
                label="Tipo"
                persistent-hint
                hint="Somente para Relatório IVC"
                item-text="nome"
                item-value="id"
                no-data-text
                :rules="[validacao.tipo == false || validacao.tipo, value => value >= 0 || 'Obrigatório.']"
                @change="validacao.tipo = false"
              ></v-autocomplete>
            </v-flex>
            <v-flex xs10 md2 class="d-flex justify-end">
              <v-switch hint="Somente para Relatório IVC" persistent-hint v-model="sobrepor" :label="`${sobrepor == 1 ? 'Gerar novamente' : 'Não gerar novamente'}`"></v-switch>
            </v-flex>

            <v-flex md2 xs2 class="text-right">
              <v-btn color="blue" :loading="loading" :disabled="loading" fab small @click="ivc">
                <v-icon color="white" title="Relatório IVC">track_changes</v-icon>
              </v-btn>
            </v-flex>

            <v-flex xs12>
              <v-progress-linear v-model="value" :active="loading" class="ma-0" color="cyan accent-3" height="16" :indeterminate="value > 100"
                >Montando relatório. Aguarde...</v-progress-linear
              >
            </v-flex>
          </v-layout>
        </v-container>
        <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
      </v-card>
    </v-app>
  </div>
</template>
<script>
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
import util from "../../../util.js";
export default {
  data() {
    return {
      competenciaUS: "",
      mnuMeses: false,
      tipo: null,
      sobrepor: 0,
      idFormaPgto: null,

      validacao: { competencia: false, tipo: false },
      tipos: [
        { id: 1, nome: "1-Física - Venda Avulsa e Impresso" },
        { id: 2, nome: "2-Física - Digital (SEM Sobreposição)" },
        { id: 3, nome: "3-Física - Digital e Impresso (COM Sobreposição)" }
      ],
      formasPgto: [],

      mensagem: "",
      snackbar: false,
      snackErro: false,

      loading: false,
      value: 0
    };
  },

  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    async ivc() {
      if (!this.competenciaUS || !util.parseDateUS(this.competenciaUS + "-01")) {
        this.mensagem = "Verifique a competência";
        this.snackErro = true;
        return false;
      }
      if (!this.tipo) {
        this.mensagem = "Selecione o tipo";
        this.snackErro = true;
        return false;
      }

      this.value = 0;
      clearInterval(this.interval);
      this.interval = setInterval(
        () => {
          this.value += 1;
        },
        this.tipo == 1 ? 2500 : 5000
      );
      this.loading = true;

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            `adm/cep/ivc?idSite=${this.$store.state.usuario.idSitePrincipal}&mesAno=${this.competencia}&tipo=${this.tipo}${this.sobrepor ? "&sobrepor=1" : ""}${
              this.atualizar ? "&atualizar=1" : ""
            }`
        );
        this.atualizar = false;
        if (response.data) {
          util.imprimir(response.data);
        }
        this.loading = false;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
        this.loading = false;
        this.atualizar = false;
      }
    },

    async outros(tipo) {
      if (!this.competenciaUS || !util.parseDateUS(this.competenciaUS + "-01")) {
        this.mensagem = "Verifique a competência";
        this.snackErro = true;
        return;
      }

      this.value = 0;
      clearInterval(this.interval);
      this.interval = setInterval(
        () => {
          this.value += 1;
        },
        tipo == "atacado" ? 100 : 3000
      );
      this.loading = true;

      var filtro = "";
      if (tipo == "assinaturas" && this.idFormaPgto) {
        filtro = "&idFormaPgto=" + this.idFormaPgto;
      }

      try {
        //faz a pesquisa no banco de dados
        await api.get(process.env.VUE_APP_URL_WEB_API + `adm/cep/${tipo}?idSite=${this.$store.state.usuario.idSitePrincipal}&mesAno=${this.competencia}${filtro}`);
        this.loading = false;
        this.mensagem = "Relatório gerado - verifique suas mensagens";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
        this.loading = false;
      }
    },

    async carregarFormasPgto() {
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/forma-pagamento/lista");
        this.formasPgto = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de formas de pagamento";
        this.snackErro = true;
      }
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    }
  },

  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_RELATÓRIOS_IVC"]);
    this.carregarFormasPgto();
  },
  computed: {
    competencia() {
      var retorno = "";
      if (this.competenciaUS) {
        retorno = util.formatMMAAAA(this.competenciaUS);
      }
      return retorno;
    }
  },
  watch: {
    competenciaUS() {
      this.atualizar = true;
    }
  }
};
</script>
