<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">format_list_numbered</v-icon>
          <v-toolbar-title style="color: white" class="font-weight-light">Ordenação do Roteiro</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-container fluid grid-list-md>
            <v-layout wrap>
              <v-flex xs12 md3>
                <v-autocomplete
                  v-model="idEntregador"
                  :items="entregadores"
                  :filter="filtrarObjetos"
                  hint="Obrigatório"
                  label="Selecione o Entregador"
                  item-text="nome"
                  item-value="id"
                  autocomplete="disabled"
                  :name="Math.random()"
                  no-data-text
                  @change="pesquisaDados"
                  clearable
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12 md3>
                <v-autocomplete
                  v-model="idRota"
                  :items="rotas"
                  :filter="filtrarObjetos"
                  hint="Obrigatório"
                  label="Selecione a Rota"
                  item-text="nome"
                  item-value="id"
                  autocomplete="disabled"
                  :name="Math.random()"
                  no-data-text
                  @change="pesquisaDados"
                  clearable
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12 md1>
                <v-btn v-if="registros.length" class="mt-4" :loading="isUpdating" color="blue white" style="color: white" depressed @click="salvar">
                  <v-icon left>update</v-icon>Salvar
                </v-btn>
              </v-flex>

              <v-flex xs12>
                <ol>
                  <li>Selecione o Entregador e a Rota;</li>
                  <li>Selecione os itens na ordem desejada;</li>
                  <li>Arraste para a posição desejada.</li>
                </ol>
              </v-flex>
            </v-layout>
          </v-container>

          <v-data-table mobile-breakpoint="0" :headers="headers" :items="registros" :no-data-text="mensagemPesquisa" disable-pagination :footer-props="rodape">
            <!-- <template #item="{ item, index }">
              <tr draggable="true" @dragstart="onDragStart($event, index)" @dragover.prevent @drop="onDrop($event, index)">
                <td style="cursor: grab; text-align: center">::</td>
                <td>{{ item.ordem }}</td>
                <td>{{ item.bairro }}</td>
                <td>{{ item.tipo + " " + item.logradouro }}</td>
                <td>{{ item.numero }}</td>
                <td>{{ item.faixa }}</td>
              </tr>
            </template> -->

            <template #item="{ item, index }">
              <tr
                :class="{ selected: selectedItems.includes(item) }"
                draggable="true"
                @dragstart="onDragStart($event, index)"
                @dragover.prevent
                @drop="onDrop($event, index)"
                @click="toggleSelection(item)"
              >
                <td>{{ item.ordem }}</td>
                <td>{{ item.bairro }}</td>
                <td>{{ item.tipo + " " + item.logradouro }}</td>
                <td>{{ item.numero }}</td>
                <td>{{ item.faixa }}</td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <v-snackbar v-model="snackbar" color="info" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
        <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>
      </v-card>
    </v-app>
  </div>
</template>

<style>
/* Adiciona estilos para itens selecionados */
tr.selected {
  background-color: NavajoWhite;
  color: #000;
}
tr[draggable="true"] {
  cursor: move;
}
</style>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "Ordem", value: "ordem", sortable: false },
        { text: "Bairro", value: "bairro" },
        { text: "Logradouro", value: "logradouro", sortable: false },
        { text: "Número", value: "numero", sortable: false },
        { text: "Faixa", value: "faixa", sortable: false }
      ],
      rodape: {
        itemsPerPageText: "",
        itemsPerPageOptions: [],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },

      idEntregador: null,
      entregadores: [],
      idRota: null,
      rotas: [],

      isUpdating: false,
      mensagem: "",
      snackbar: false,
      snackErro: false,
      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      carregando: true,
      draggedItemIndex: null,
      selectedItems: [],
      draggedItems: []
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),

    async pesquisaDados() {
      if (!this.idEntregador || !this.idRota) {
        return;
      }
      this.registros = [];
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "adm/cliente-endereco/ordem/" + this.idEntregador + "/" + this.idRota);

        //seta as variaveis com o retorno
        this.registros = response.data;
        this.rodape.pageText = "Total: " + this.registros.length + " endereços";

        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },

    async salvar() {
      var dadosCommit = [];
      this.registros.forEach((element) => {
        dadosCommit.push(element.ids);
      });

      try {
        this.isUpdating = true;
        await api.put(process.env.VUE_APP_URL_WEB_API + "adm/cliente-endereco/ordem", dadosCommit);
        this.mensagem = "Dados foram atualizados";
        this.snackbar = true;
      } catch (e) {
        this.mensagem =
          "ERRO: " +
          (e.toString().includes("status code 400")
            ? "SESSÃO EXPIRADA"
            : e.response && e.response.data && e.response.data.length > 0
            ? e.response.data[0].mensagem
            : "Indeterminado, verifique sua conexão");
        this.snackErro = true;
      } finally {
        this.isUpdating = false;
      }
    },

    async carregarEntregadores() {
      try {
        //* Busca entregadores ativos, pois se está inativo, não deveria estar associado a um CEP
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/entregador/lista");
        this.entregadores = response.data;
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de Entregadores";
        this.snackErro = true;
      }
    },

    async carregarRotas() {
      try {
        //* Busca rotas ativas, pois se está inativa, não deveria estar associada a um CEP
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/rota/lista");
        this.rotas = response.data;
        this.rotas.forEach((element) => {
          element.nome = element.nome + " - " + element.municipio + "/" + element.uf;
        });
      } catch {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar a lista de Rotas";
        this.snackErro = true;
      }
    },

    // onDragStart(event, index) {
    //   this.draggedItemIndex = index;
    //   event.dataTransfer.effectAllowed = "move";
    // },
    // onDrop(event, targetIndex) {
    //   const draggedIndex = this.draggedItemIndex;
    //   if (draggedIndex !== null) {
    //     const draggedItem = this.registros.splice(draggedIndex, 1)[0];
    //     this.registros.splice(targetIndex, 0, draggedItem);
    //     this.draggedItemIndex = null;
    //   }
    // },

    toggleSelection(item) {
      // Adiciona ou remove itens da seleção
      const index = this.selectedItems.indexOf(item);
      if (index === -1) {
        this.selectedItems.push(item);
      } else {
        this.selectedItems.splice(index, 1);
      }
    },
    onDragStart(event, index) {
      // Define quais itens estão sendo arrastados
      if (this.selectedItems.length === 0) {
        this.draggedItems = [this.items[index]];
      } else {
        this.draggedItems = [...this.selectedItems];
      }
      event.dataTransfer.effectAllowed = "move";
    },
    onDrop(event, targetIndex) {
      // Remove os itens arrastados da lista original
      const remainingItems = this.registros.filter((item) => !this.draggedItems.includes(item));
      // Insere os itens arrastados na nova posição
      this.registros = [...remainingItems.slice(0, targetIndex), ...this.draggedItems, ...remainingItems.slice(targetIndex)];
      // Limpa a seleção e os itens arrastados
      this.selectedItems = [];
      this.draggedItems = [];
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_ENTREGADORES_ACESSO"]);

    this.carregarEntregadores();
    this.carregarRotas();
  }
};
</script>
