var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('v-app',{attrs:{"id":"inspire"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"#333333"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("sort")]),_c('v-toolbar-title',{staticClass:"font-weight-light",staticStyle:{"color":"white"}},[_vm._v(" Balancete ")]),_c('v-spacer')],1),_c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs6":"","md3":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"autofocus":"","label":"Período","prepend-icon":"event","color":"blue-grey lighten-2","readonly":""},on:{"click:clear":function($event){_vm.dtInicioUS = ['', '']}},model:{value:(_vm.dtInicio),callback:function ($$v) {_vm.dtInicio=$$v},expression:"dtInicio"}},on))]}}]),model:{value:(_vm.mnuDtInicio),callback:function ($$v) {_vm.mnuDtInicio=$$v},expression:"mnuDtInicio"}},[_c('v-date-picker',{attrs:{"locale":"pt-br","no-title":"","range":""},model:{value:(_vm.dtInicioUS),callback:function ($$v) {_vm.dtInicioUS=$$v},expression:"dtInicioUS"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.mnuDtInicio = false}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-flex',{attrs:{"xs1":""}},[_c('v-btn',{attrs:{"color":"accent","loading":_vm.loading,"disabled":_vm.loading || _vm.loadingExp,"fab":"","small":""},on:{"click":function($event){return _vm.pesquisaDados(false)}}},[_c('v-icon',{attrs:{"color":"white","title":"Filtrar"}},[_vm._v("search")])],1)],1),_c('v-flex',{attrs:{"xs1":""}},[(_vm.direitoExportar)?_c('v-btn',{attrs:{"color":"blue","loading":_vm.loadingExp,"disabled":_vm.loading || _vm.loadingExp,"fab":"","small":""},on:{"click":function($event){return _vm.pesquisaDados(true)}}},[_c('v-icon',{attrs:{"color":"white","title":"Exportar"}},[_vm._v("description")])],1):_vm._e()],1)],1)],1),_c('div',{staticClass:"px-4 mt-3"},[_c('v-data-table',{attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.registros,"no-data-text":_vm.mensagemPesquisa,"hide-default-footer":"","disable-sort":"","disable-pagination":""},scopedSlots:_vm._u([{key:_vm.getItemNumero(),fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(
                '&emsp;'.repeat(item.numero.split('.').length - 2) +
                  item.numero +
                  ' - ' +
                  (item.nome != 'Disponível' ||
                  (item.nome == 'Disponível' &&
                    (_vm.$store.state.usuario.site_planoContas || _vm.direitoAdmin))
                    ? item.nome
                    : 'Caixa & Bancos')
              )}})]}},{key:_vm.getItemMovimentoD(),fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.movimentoD))+" ")]}},{key:_vm.getItemMovimentoC(),fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.movimentoC))+" ")]}},{key:_vm.getItemSaldoD(),fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.saldoD))+" ")]}},{key:_vm.getItemSaldoC(),fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.saldoC))+" ")]}}],null,true)}),(_vm.dtInicio)?_c('v-footer',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_vm._v("Movimento: "+_vm._s(_vm.dtInicio)+"."),_c('br'),_vm._v(" Saldo: tudo até "+_vm._s(_vm.dtInicio.substr(13))+".")])],1):_vm._e(),_c('v-snackbar',{attrs:{"color":"info","timeout":5000,"relative":"","bottom":"","left":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.mensagem))]),_c('v-snackbar',{attrs:{"color":"error","timeout":5000,"relative":"","bottom":"","left":""},model:{value:(_vm.snackErro),callback:function ($$v) {_vm.snackErro=$$v},expression:"snackErro"}},[_vm._v(_vm._s(_vm.mensagem))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }