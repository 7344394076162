var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('v-app',{attrs:{"id":"inspire"}},[_c('v-card',{staticClass:"hide-overflow fill-height"},[_c('v-toolbar',{attrs:{"height":"50","color":"#333333"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("compare_arrows")]),_c('v-toolbar-title',{staticClass:"font-weight-light",staticStyle:{"color":"white"}},[_vm._v("Transferências")]),_c('v-spacer'),(_vm.direitoCriar)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/financeiro/transferencia"}},[_c('v-btn',{attrs:{"color":"success","fab":"","small":""}},[_c('v-icon',{attrs:{"color":"white","title":"Incluir"}},[_vm._v("add")])],1)],1):_vm._e()],1),_c('div',{staticClass:"px-6 mt-4"},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-spacer'),_c('v-flex',{attrs:{"xs12":"","md5":""}},[_c('v-text-field',{staticClass:"mt-2 mb-2 elevation-0",attrs:{"background-color":"white","append-icon":"search","label":"Digite para pesquisar...","placeholder":"Pesquisa pela descrição (ou 1=conciliado/0=não conciliado)","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.limpaFiltro($event)},"click:append":_vm.limpaFiltro},model:{value:(_vm.filtroPesquisa),callback:function ($$v) {_vm.filtroPesquisa=$$v},expression:"filtroPesquisa"}})],1)],1),_c('v-data-table',{attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.registros,"options":_vm.options,"no-data-text":_vm.mensagemPesquisa,"footer-props":_vm.rodape,"disable-sort":_vm.carregando,"disable-pagination":_vm.carregando},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:_vm.getItemActions(),fn:function(ref){
var item = ref.item;
return [(_vm.direitoEditar)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":'/financeiro/transferencia/' + item.id}},[_c('v-icon',{attrs:{"color":"blue","title":"Editar"}},[_vm._v("edit")])],1):_vm._e(),(item.conciliado)?_c('v-icon',{attrs:{"color":"green","title":"Conciliada"}},[_vm._v("copyright ")]):_vm._e()]}},{key:_vm.getItemDtRegistro(),fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDateTime(item.dtRegistro)))])]}},{key:_vm.getItemDtOperacao(),fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.dtOperacao)))])]}},{key:_vm.getItemSituacao(),fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.situacao == null ? '' : item.situacao == 1 ? 'Ativa' : '<strong>Cancelada</strong>')}})]}}],null,true)}),_c('div',{staticClass:"text-xs-center pt-2 marg-bt"},[_c('v-pagination',{attrs:{"length":_vm.totalPaginas,"total-visible":_vm.$vuetify.breakpoint.xsOnly ? 5 : 7,"circle":""},model:{value:(_vm.paginaAtual),callback:function ($$v) {_vm.paginaAtual=$$v},expression:"paginaAtual"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }