<template>
  <div id="app">
    <v-app id="inspire">
      <v-card>
        <v-toolbar color="#333333">
          <v-icon color="white" class="mr-2">dashboard</v-icon>
          <v-toolbar-title style="color:white;" class="font-weight-light">Planos</v-toolbar-title>
          <v-spacer></v-spacer>
          <router-link v-if="direitoCriar" to="/assinaturas/plano" style="text-decoration: none">
            <v-btn color="success" fab small>
              <v-icon color="white" title="Incluir">add</v-icon>
            </v-btn>
          </router-link>
        </v-toolbar>
        <div class="px-6 mt-4">
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-flex xs12 md5>
              <v-text-field
                v-model="filtroPesquisa"
                append-icon="search"
                label="Digite para pesquisar..."
                placeholder="Pesquisa pelo ID, nome, modalidade, migrar"
                hide-details
                background-color="white"
                class="mt-2 mb-2 elevation-0"
                @keyup.enter="limpaFiltro"
                @click:append="limpaFiltro"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-data-table
            mobile-breakpoint="0"
            :headers="headers"
            :items="registros"
            :options.sync="options"
            :no-data-text="mensagemPesquisa"
            :footer-props="rodape"
            :disable-sort="carregando"
            :disable-pagination="carregando"
          >
            <template v-slot:item.actions="{ item }">
              <router-link v-if="direitoEditar" :to="'/assinaturas/plano/' + item.id" style="text-decoration: none">
                <v-icon color="blue" title="Editar">edit</v-icon>
              </router-link>
            </template>

            <template v-slot:item.validarEndereco="{ item }">
              <span v-html="item.validarEndereco == 1 ? 'Sim' : ''"></span>
            </template>

            <template v-slot:item.renovacaoAutomatica="{ item }">
              <span v-html="item.renovacaoAutomatica == 1 ? 'Sim' : ''"></span>
            </template>

            <template v-slot:item.cartaoIntegral="{ item }">
              <span v-html="item.cartaoIntegral == 1 ? 'TODAS' : 'Próxima'"></span>
            </template>

            <template v-slot:item.renovacaoTabela="{ item }">
              <span v-html="item.renovacaoTabela == 1 ? 'Sim' : ''"></span>
            </template>

            <template v-slot:item.situacao="{ item }">
              <span v-html="item.situacao == 1 ? 'Ativo' : item.situacao == 0 ? '<strong>Inativo</strong>' : ''"></span>
            </template>
            <template v-slot:item.nomeMigrar="{ item }">
              {{ item.nomeMigrar }}
              <v-icon v-if="!item.nomeMigrar && item.situacao == 1" color="red" title="Editar" @click="migracao(item)">forward</v-icon>
            </template>
          </v-data-table>
          <div class="text-xs-center pt-2 marg-bt">
            <v-pagination v-model="paginaAtual" :length="totalPaginas" :total-visible="$vuetify.breakpoint.xsOnly ? 5 : 7" circle></v-pagination>
          </div>
        </div>
      </v-card>

      <v-snackbar v-model="snackErro" color="error" :timeout="5000" relative bottom left>{{ mensagem }}</v-snackbar>

      <v-dialog persistent v-model="dialogConfirm" max-width="400">
        <v-card>
          <v-toolbar>
            <span class="headline">Confirmação</span>
          </v-toolbar>
          <v-card-text class="mt-4" v-html="msgConfirm"></v-card-text>
          <v-card-actions>
            <v-btn color="red darken-1" text @click="dialogConfirm = false">Não</v-btn>
            <v-btn color="green darken-1" text @click="migrar(true)">Sim</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="dialogMigrar" max-width="500">
        <v-card>
          <v-toolbar>
            <span class="headline">Migração de Plano</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeMigrar">
              <v-icon color="darken-1">cancel</v-icon>
            </v-btn>
          </v-toolbar>
          <v-form ref="formMigrar" v-model="migrarValid" lazy-validation>
            <v-container>
              <v-layout wrap>
                <v-flex xs12 md10>
                  <v-text-field v-model="itemMigrar.nome" label="Plano Atual" disabled color="blue-grey lighten-2" autocomplete="disabled" :name="Math.random()"></v-text-field>
                </v-flex>

                <v-flex xs12>
                  <v-autocomplete
                    autofocus
                    v-model="planoMigrarSelecionado"
                    :items="planosMigrar"
                    hint="Obrigatório"
                    :rules="[value => (!!value && !!value.id) || 'Obrigatório.']"
                    :filter="filtrarObjetos"
                    label="Migrar para"
                    item-text="nome"
                    item-value="id"
                    filled
                    autocomplete="disabled"
                    :name="Math.random()"
                    no-data-text
                    return-object
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12>
                  <v-subheader>
                    <span style="color:#000000">Ao migrar, o plano atual será bloqueado para edição e suas assinaturas passarão para o novo plano na próxima renovação.</span>
                  </v-subheader>
                </v-flex>

                <v-flex xs12 md12 v-if="migrando">
                  <v-progress-linear color="blue-grey" height="25" indeterminate></v-progress-linear>
                </v-flex>

                <v-flex xs12 v-if="validacao.mensagemErro">
                  <v-sheet :value="true" class="red--text">{{ validacao.mensagemErro }}</v-sheet>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="orange lighten-2 white" @click="closeMigrar">Retornar</v-btn>
            <v-btn color="blue darken-1" @click="migrar(false)" :disabled="migrando">Migrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import util from "../../util.js";
import { api } from "@/services/api.js";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "Ações", value: "actions" },
        { text: "ID", value: "id" },
        { text: "Nome", value: "nome" },
        { text: "Modalidade", value: "modalidade" },
        { text: "Produtos", value: "produtos" },
        { text: "Validar Endereço", value: "validarEndereco" },
        { text: "Renovação Automática", value: "renovacaoAutomatica" },
        { text: "Cobrança Cartão", value: "cartaoIntegral" },
        { text: "Renovação Tabela", value: "renovacaoTabela" },
        { text: "Situação", value: "situacao" },
        { text: "Migrar para", value: "nomeMigrar" }
      ],
      rodape: {
        itemsPerPageText: "Registros por página",
        itemsPerPageOptions: [10, 25, 50],
        prevIcon: "",
        nextIcon: "",
        pageText: ""
      },
      direitoCriar: false,
      direitoEditar: false,

      validacao: { mensagemErro: false },

      itemMigrar: {},
      dialogMigrar: false,
      migrarValid: true,
      planoMigrarSelecionado: {},
      planosMigrar: [],
      migrando: false,

      dialogConfirm: false,
      msgConfirm: "",

      mensagem: "",
      snackErro: false,

      mensagemPesquisa: "",
      filtroPesquisa: "",
      registros: [],
      registrosPagina: 10,
      totalRegistros: 0,
      totalPaginas: 0,
      paginaAtual: 1,
      carregando: true,
      ordenacao: "",
      ordem: "",
      options: {}
    };
  },
  methods: {
    ...mapMutations(["POSSUI_DIREITO"]),
    limpaFiltro() {
      this.paginaAtual = 1;
      this.pesquisaDados();
    },
    async pesquisaDados() {
      this.registros = [];
      //sinaliza que está carregando
      this.carregando = true;
      this.mensagemPesquisa = "Carregando...";

      try {
        //faz a pesquisa no banco de dados
        const response = await api.get(
          process.env.VUE_APP_URL_WEB_API +
            "ass/plano?idSite=" +
            this.$store.state.usuario.idSitePrincipal +
            "&pagina=" +
            this.paginaAtual +
            "&tamanho=" +
            this.registrosPagina +
            "&filtro=" +
            this.filtroPesquisa +
            "&ordenacao=" +
            this.ordenacao +
            "&ordem=" +
            this.ordem
        );

        //seta as variaveis com o retorno
        this.registros = response.data.data;
        this.totalRegistros = response.data.total;
        this.totalPaginas = Math.ceil(this.totalRegistros / this.registrosPagina);
        this.rodape.pageText = this.totalRegistros + " itens";
        //fecha o loading
        this.carregando = false;
        //se não achar registro seta a mensagem senão deixa em branco
        this.mensagemPesquisa = this.registros.length > 1 ? "" : "Nenhum registro encontrado";
      } catch (e) {
        this.carregando = false;
        if (e.toString().includes("status code 400")) {
          this.$router.push(this.$route.path);
          this.$router.go(this.$route.path);
          this.mensagemPesquisa = "SESSÃO EXPIRADA";
        } else {
          this.mensagemPesquisa = "Erro ao buscar dados: " + e;
        }
      }
    },

    async carregarPlanosMigrar() {
      //* Não carrega planos já marcados para migração
      try {
        const response = await api.get(process.env.VUE_APP_URL_WEB_API + "ass/plano/lista?idSite=" + this.$store.state.usuario.idSitePrincipal + "&migrar=1");
        if (response.data) {
          this.planosMigrar = response.data;
        }
      } catch (e) {
        this.isUpdating = true;
        this.mensagem = "Ocorreu um erro ao carregar os planos de assinatura para migração: " + e;
        this.snackErro = true;
      }
    },

    migracao(item) {
      this.itemMigrar = item;
      this.planoMigrarSelecionado = {};
      this.validacao.mensagemErro = false;
      this.dialogMigrar = true;
    },
    async migrar(migrar) {
      //migrar = false - não salva, apenas apresenta os problemas encontrados
      //migrar = true - salva idPlanoMigrar no plano (falta fazer na api)
      try {
        if (!this.$refs.formMigrar.validate()) {
          this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
          this.snackErro = true;
          return;
        }

        this.migrando = true;
        this.validacao.mensagemErro = false;

        var migrarCommit = { idPlanoMigrar: this.planoMigrarSelecionado.id };
        if (migrar) {
          migrarCommit.migrar = 1;
        }

        await api.put(process.env.VUE_APP_URL_WEB_API + "ass/plano/migrar/" + this.itemMigrar.id + "?idSite=" + this.$store.state.usuario.idSitePrincipal, migrarCommit);
        this.dialogConfirm = false;
        this.dialogMigrar = false;
        this.pesquisaDados();
      } catch (e) {
        if (e.toString().includes("status code 400")) {
          this.mensagem = "SESSÃO EXPIRADA";
          this.snackErro = true;
        } else if (e.response && e.response.data) {
          if (e.response.data.confirmacao) {
            this.tipoConfirm = "MIGRAR_PLANO";
            this.msgConfirm = e.response.data.confirmacao + "<br /><p style='color:red'><b>Tem certeza que deseja configurar a migração e bloquear este plano?</b></p>";
            this.dialogConfirm = true;
          } else {
            util.validaErros(this.validacao, e.response.data);
            this.mensagem = "Verifique os campos indicados em vermelho neste formulário";
            this.snackErro = true;
          }
        } else {
          this.mensagem = "Ocorreu um erro indeterminado, verifique sua conexão";
          this.snackErro = true;
        }
      } finally {
        this.migrando = false;
      }
    },
    closeMigrar() {
      this.dialogMigrar = false;
    },

    filtrarObjetos(item, queryText, itemText) {
      return util.filtrarObjetos(item, queryText, itemText);
    }
  },
  beforeMount() {
    this.POSSUI_DIREITO(["WEBSUITA_ADMIN_SUITA", "ASSINATURAS_PLANOS_ACESSO"]);
    //* Verifica se o usuário logado possui direito para incluir
    this.$store.state.usuario.direitos.forEach(element => {
      var direito = element.modulo + "_" + element.grupo + "_" + element.direito;
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_PLANOS_CRIAR") {
        this.direitoCriar = true;
      }
      if (direito == "WEBSUITA_ADMIN_SUITA" || direito == "ASSINATURAS_PLANOS_EDITAR") {
        this.direitoEditar = true;
      }
    });

    this.carregarPlanosMigrar();
  },

  watch: {
    paginaAtual() {
      this.pesquisaDados();
    },
    options: {
      handler() {
        this.ordem = "";
        this.ordenacao = "";
        if (this.registrosPagina !== this.options.itemsPerPage) {
          this.registrosPagina = this.options.itemsPerPage;
          this.paginaAtual = 1;
        }

        if (this.options.sortBy.length > 0) {
          this.ordenacao = this.options.sortBy[0];
          if (this.options.sortDesc[0]) {
            this.ordem = "desc";
          } else {
            this.ordem = "asc";
          }
        }

        this.pesquisaDados();
      }
    }
  }
};
</script>
